import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { fetchJSON } from "./util.jsx";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';


export default function CategoryCreator(props) {
    const [categories, setCategories] = useState([]);
    const [option, setOption] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            fetch();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.parent]
    );
    async function fetch() {
        setOption(null);
        setLoading(true);
        const baseUrl = '/api/v2/product_categories/';
        const data = await fetchJSON(
            (props.parent && props.parent.pk) ?
            `${baseUrl}${props.parent.pk}/children/` : `${baseUrl}top_level/`
        );
        setCategories(data);
        setLoading(false);
    }
    const options = categories.map(category => {
        return {
            label: category.name,
            value: category.pk,
            category: category,
        };
    });
    function onOptionChange(option) {
        setOption(option);
        if (option) {
            props.onSelect(option.category);
        } else {
            props.onSelect(props.parent);
        }
    }
    function onCreateOption(name) {
        // need to create a fake category out of this
        const parent = props.parent || {
            pk: null,
            fullpath_strings: [],
            slug: '',
        };
        const newUnSavedCategory = {
            "pk": null,
            "name": name,
            "parent": parent.pk,
            "fullpath_strings": parent.fullpath_strings.concat([name]),
            "slug": parent.slug,
        };
        // also need a fake option
        setOption({
            label: name,
            value: null,
            category: newUnSavedCategory,
        });
        props.onSelect(newUnSavedCategory);
    }
    let placeholder = 'Select main category or suggest a new one';
    if (props.parent) {
        placeholder = '(Optional) select a sub category or suggest a new one';
    }
    return <div style={{marginTop: '5px'}}>
      <CreatableSelect placeholder={placeholder}
                       options={options}
                       onChange={onOptionChange}
                       onCreateOption={onCreateOption}
                       value={option}
                       isClearable={true}
                       isDisabled={loading}
                       isLoading={loading}
                       openMenuOnFocus={true} />
      {option && option.category.pk &&
       <CategoryCreator parent={option.category}
                        onSelect={props.onSelect} />
      }
    </div>;
}

CategoryCreator.propTypes = {
    // parent category
    parent: PropTypes.object,
    // pass the chosen category serializer
    onSelect: PropTypes.func.isRequired,
};
