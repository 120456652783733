import { fetchJSON } from './util.jsx';

const _cache = {};
const _uniuser_cache = {};
const _follower_cache = {};

/*
 * Note on the `signal` parameter to these APIs: when we have a cache hit
 * we'll return a result even if the associated AbortController has been
 * aborted. The result is that the caller might proceed when it actually
 * should stop whatever it's doing. See the usage of getUniUser in
 * ClaimButton for an example of how to handle this situation (you'll need
 * an additional flag on the component).
 */

/**

 * Returns a promise that will resolve to a json object with the user's
 * info.  Example usage:
 *
 *   async myFunc() {
 *       await user = getUser(userId);
 *       console.log(user.username);
 *   }
 */
async function getUser(userId, signal = null, nocache = false) {
    /* force to string */
    userId = userId + '';
    if (_cache.hasOwnProperty(userId) && !nocache)
        return _cache[userId];

    const url = `/api/v2/public_users/${userId}/`;
    const user = await fetchJSON(url, signal);
    if (user === null)
        return null;
    _cache[userId] = {
        ...user,
        profileLink: `/u/${user.username}/`,
        displayName: user.name || user.username,
    };
    return _cache[userId];
}

async function getFollowers(userId, signal = null, nocache = false) {
    /* force to string */
    userId = userId + '';

    if (userId === "-1")
        return [];

    if (_follower_cache.hasOwnProperty(userId) && !nocache)
        return _follower_cache[userId];

    const followers = fetchJSON(`/api/v2/social_following/${str_user_id}/`, signal);
    if (followers === null)
        return null;
    _follower_cache[userId] = followers;
    return _follower_cache[userId];
}

async function getUniUser(uuId, signal = null, nocache = false) {
    /* force to string */
    uuId = uuId + '';

    if (uuId === "0")
        return null;

    if (_uniuser_cache.hasOwnProperty(uuId) && !nocache)
        return _uniuser_cache[uuId];

    const uu = await fetchJSON(`/api/v2/uniuser/${uuId}/`, signal);
    if (uu === null)
        return null;
    _uniuser_cache[uuId] = uu;
    return _uniuser_cache[uuId];
}

export {
    getUser,
    getFollowers,
    getUniUser,
};
