import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FollowButton } from './FollowButton.jsx';
import { ModalContainer } from '../StrModal.jsx';

import styles from './css/FollowPanel.css';

class FollowPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            followers: [],
            following: [],
            showFollowers: false,
            showFollowing: false,
            loading: false,
        };

        this.load = this.load.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onClickFollowers = this.onClickFollowers.bind(this);
        this.onClickFollowing = this.onClickFollowing.bind(this);
    }

    load(key) {
        this.setState({loading: true});
        if (!['followers', 'following'].includes(key))
            return;
        const url = `/api/v2/social_${key}/${this.props.user.pk}/`;
        $.getJSON(url, (data) => {
            this.setState({
                [key]: data,
                loading: false,
            });
        });
    }

    onClose() {
        this.setState({
            showFollowers: false,
            showFollowing: false,
        });
    }

    onClickFollowers(e) {
        e.preventDefault();
        if (!StrUserInfo.uuid)
            return;
        this.load('followers');
        this.setState({showFollowers: true});
    }

    onClickFollowing(e) {
        e.preventDefault();
        if (!StrUserInfo.uuid)
            return;
        this.load('following');
        this.setState({showFollowing: true});
    }

    followingers(users, key) {
        return users.map(user => {
            const profilePhoto = user.profile_photo_thumbnail;
            return (
                <div key={user.pk} className="row">
                  <div className="col-md-9">
                    <img className="SocialProfileImage"
                         style={{maxWidth: '50px', maxHeight: '50px'}}
                         src={profilePhoto} />
                    <a href={`/u/${user.username}/`}>{user.name}</a>
                    <small className="text-muted"> {user.username}</small>
                  </div>
                  <div className="col-md-3">
                    <FollowButton user={user}
                                  style={{float: 'right', marginTop: '10px'}}
                                  onFollow={() => {
                                      this.props.onFollow();
                                      this.load(key);
                                  }}
                                  onUnfollow={() => {
                                      this.props.onUnfollow();
                                      this.load(key);
                                  }} />
                  </div>
                </div>
            );
        });
    }

    followingersModal(users, key) {
        const titleNoun = key === 'followers'
                        ? 'Follower' + (users.length === 1 ? '' : 's')
                        : 'Following';
        return (
            <ModalContainer onClose={this.onClose} isOpen={true}>
              <h3>{titleNoun}</h3>
              {this.followingers(users, key)}
            </ModalContainer>
        );
    }

    render() {
        const { following_count, follower_count } = this.props.user;
        const followersNoun = 'Follower' + (follower_count === 1 ? '' : 's');
        return (
            <div className="FollowPanel">
              <div>
                <FollowButton user={this.props.user}
                              onFollow={this.props.onFollow}
                              onUnfollow={this.props.onUnfollow} />
              </div>
              <br />
              <a onClick={this.onClickFollowers} href="">
                <span className="FollowCountCount">
                  {this.props.user.follower_count}
                </span>
                <span className="FollowCountTitle text-muted">
                  {followersNoun}
                </span>
              </a>
              {' '}
              <a onClick={this.onClickFollowing} href="">
                <span className="FollowCountCount">
                  {this.props.user.following_count}
                </span>
                <span className="FollowCountTitle text-muted">
                  Following
                </span>
              </a>
              {this.state.showFollowing &&
               this.followingersModal(this.state.following, 'following')
              }
              {this.state.showFollowers &&
               this.followingersModal(this.state.followers, 'followers')
              }
            </div>
        );
    }
}

FollowPanel.propTypes = {
    user: PropTypes.object.isRequired,
    onFollow: PropTypes.func,
    onUnfollow: PropTypes.func,
};

export default FollowPanel;
