import React from 'react';
import PropTypes from 'prop-types';
import socialNetworks from './socialNetworks.js';
import { ModalContainer } from '../StrModal.jsx';
import {strRequest} from '../util.jsx';

/**
 * Displays the registered social media links for the user.
 */


class EditableLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: !props.link.pk,
            url: props.link.url || '',
            pk: props.link.pk,
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    render() {
        const {rootUrl, iconStyle, color} = this.props.link;
        const {editing, url} = this.state;
        const editingView = (
            <span>
              <input className="form-control"
                     style={{
                         display: 'inline',
                         width: '250px',
                         maxWidth: 'calc(100% - 32px)',
                     }}
                     type="text"
                     placeholder={"https://" + rootUrl}
                     value={url}
                     autoCorrect="off"
                     autoCapitalize="off"
                     onKeyPress={this.onKeyPress}
                     onChange={this.onChange} />
              <p style={{marginLeft: '30px'}}>
                <button className="btn btn-link"
                        onClick={this.onSaveClick}>
                  Save
                </button>
              </p>
            </span>
        );
        const displayView = (
            <span>
              <a href={url}>{url}</a>
              <p style={{marginLeft: '30px'}}>
                <button className="btn btn-link" onClick={this.onEditClick}>
                  Edit
                </button>
                <button className="btn btn-link" onClick={this.onDeleteClick}>
                  Remove
                </button>
              </p>
            </span>
        );

        return (
            <li style={{marginBottom: '15px'}}>
              <i className={iconStyle}
                 style={{
                     marginRight: '7px',
                     marginBottom: '3px',
                     fontSize: '30px',
                     verticalAlign: 'middle',
                     color: color,
                     width: '30px',
                 }} />
              {editing && editingView || displayView}
            </li>
        );
    }

    onEditClick(e) {
        e.preventDefault();
        this.setState({editing: true});
    }

    onSaveClick(e) {
        e.preventDefault();
        const {sort_order, link, setWarning, onSuccess} = this.props;
        const {provider, title} = link;
        const {url, pk} = this.state;
        const isValidHref = /^https?:\/\//.test(url.toLowerCase());
        const matchesProvider = url.toLowerCase().includes(link.rootUrl);
        const validUrl = isValidHref && matchesProvider;
        if (url === '' && pk) {
            this.setState({editing: false});
            setWarning(null);
            return this.onDeleteClick(e);
        } else if (!validUrl) {
            return setWarning(`Must be valid ${title} url`);
        }
        this.setState({editing: false});
        setWarning(null);
        const self = this;
        this.props.save(pk, provider, url, sort_order)
            .then(res => res.json())
            .then(data => {
                onSuccess(data);
                if (self.state.pk !== data.pk)
                    self.setState({pk: data.pk});
            });
    }

    onDeleteClick(e) {
        e.preventDefault();
        const {link, onSuccess, onDelete} = this.props;
        const provider = link.provider;
        const {pk} = this.state;
        onDelete(pk)
            .then(() => {
                onSuccess({
                    provider,
                    pk: null,
                    url: '',
                });
                this.setState({
                    editing: true,
                    pk: null,
                    url: '',
                });
            });
    }

    onChange(e) {
        this.setState({url: e.target.value});
    }

    onKeyPress(e) {
        const ENTER = 13;
        if (e.charCode === ENTER) {
            this.onSaveClick(e);
        }
    }
}

const linkShape = PropTypes.shape({
    provider: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rootUrl: PropTypes.string.isRequired,
    iconStyle: PropTypes.string.isRequired,
    pk: PropTypes.number,
    sort_order: PropTypes.number,
    url: PropTypes.string,
    color: PropTypes.string,
});

EditableLink.propTypes = {
    link: linkShape.isRequired,
    sort_order: PropTypes.number.isRequired,
    save: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    setWarning: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

class SocialMediaLinks extends React.Component {
    constructor(props) {
        super(props);
        this.apiUrl = '/api/v2/social_media_links/';
        this.isOwner = str_user_id === props.user.pk;
        this.socialNetworks = socialNetworks;

        this.state = {
            editing: false,
            isShowingModal: false,
            links: this.addRegisteredLinks(
                props.user.social_media_links,
                socialNetworks,
            ),
        };

        this.save = this.save.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.setWarning = this.setWarning.bind(this);
        this.onClose = this.onClose.bind(this);
        this.updateReadOnlyLink = this.updateReadOnlyLink.bind(this);
    }

    renderControlModal() {
        return <ModalContainer onClose={this.onClose}
                               isOpen={this.state.isShowingModal}>
          <h3 style={{marginBottom: '20px'}}>
            Edit Social Links
          </h3>
          <ul className="list-unstyled text-left">
            {this.state.links.map((link, index) => {
                return <EditableLink link={link}
                                     editing={this.state.editing}
                                     sort_order={index}
                                     save={this.save}
                                     onSuccess={this.updateReadOnlyLink}
                                     key={link.provider}
                                     onDelete={this.deleteLink}
                                     setWarning={this.setWarning} />;
            })}
          </ul>
          {this.state.error &&
           <p className="alert alert-danger" style={{marginTop: '10px'}}>
             {this.state.error}
           </p>
          }
        </ModalContainer>;
    }

    render() {
        const connectedLinks = this.state.links
                                   .filter(link => link.pk)
                                   .sort((a, b) => a.sort_order - b.sort_order);
        const buttonText = connectedLinks.length
                         ? 'Edit social media links'
                         : 'Add social media links';
        const buttonClasses = this.props.bs5
                            ? 'btn btn-outline-secondary btn-xs'
                            : 'btn btn-default btn-xs';
        const liClasses = this.props.bs5 ? 'list-inline-item' : '';
        return (
            <div id="SocialMediaLinks" className="SocialMediaLinks">
              <ul className="list-inline" style={{marginTop: '10px'}}>
                {connectedLinks.map(link => {
                    return (
                        <li key={link.pk} className={liClasses}>
                          <a href={link.url} target="_blank">
                            <i className={link.iconStyle} style={{
                                fontSize: this.props.iconSize,
                                color: link.color,
                            }} />
                          </a>
                        </li>
                    );
                })}
              </ul>
              {!this.props.viewOnly && this.isOwner &&
               <button className={buttonClasses}
                       onClick={() => {this.setState({isShowingModal: true});}}
                       style={{marginBottom: "10px"}}>
                 {buttonText}
                 {' '}
                 <i className="fa fa-pencil-square-o" />
               </button>
              }
              {!this.props.viewOnly && this.state.isShowingModal &&
               this.renderControlModal()
              }
            </div>
        );
    }

    setWarning(text) {
        this.setState({error: text});
    }

    addRegisteredLinks(links, networks) {
        return networks.map(network => {
            const savedLink = links.find((link) => {
                return link.provider === network.provider;
            });
            if (savedLink)
                Object.assign(network, savedLink);
            return network;
        });
    }

    onClose() {
        this.setState({
            isShowingModal: false,
            error: null,
        });
    }

    updateReadOnlyLink(newLink) {
        this.setState(prevState => {
            const prevLink = prevState.links
                                      .find(link => link.provider === newLink.provider);
            Object.assign(prevLink, newLink);
            return {links: prevState.links};
        });
    }

    async save(pk, provider, url, sort_order) {
        this.setState({editing: false});
        if (pk)
            return await this.updateLink(pk, url);
        else
            return await this.createLink({provider, url, sort_order});
    }

    async updateLink(pk, url) {
        return await strRequest(`${this.apiUrl}${pk}/`, 'PATCH', {url});
    }

    async deleteLink(pk) {
        return await strRequest(`${this.apiUrl}${pk}/`, 'DELETE');
    }

    async createLink(data) {
        return await strRequest(this.apiUrl, 'POST', data);
    }
}

const userShape = PropTypes.shape({
    pk: PropTypes.number.isRequired,
    social_media_links: PropTypes.arrayOf(PropTypes.object).isRequired,
});

SocialMediaLinks.defaultProps = {
    iconSize: '40px',
    bs5: false,
};

SocialMediaLinks.propTypes = {
    user: userShape.isRequired,
    viewOnly: PropTypes.bool,
    iconSize: PropTypes.string,
    bs5: PropTypes.bool,
};

export default SocialMediaLinks;
