import React, { useState } from 'react';
import PropTypes from 'prop-types';

const HelpMe = ({ children }) => {
    const [isBodyVisible, setIsBodyVisible] = useState(false);

    const toggleBodyVisibility = () => {
        setIsBodyVisible(!isBodyVisible);
    };

    const caption = React.Children.toArray(children).find(
        (child) => child.type === Caption
    );

    const body = React.Children.toArray(children).find(
        (child) => child.type === Body
    );

    return (
        <div>
          <div onClick={toggleBodyVisibility}
               style={{cursor: "pointer"}}>{caption}</div>
          {isBodyVisible && body}
        </div>
    );
};

HelpMe.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

const Caption = ({ children }) => (
    <p>
      <i className="fa fa-info-circle text-info" aria-hidden="true"></i> {children}
    </p>
);

Caption.propTypes = {
    children: PropTypes.node.isRequired,
};

const Body = ({ children }) => <div className="alert alert-info" role="alert">{children}</div>;

Body.propTypes = {
    children: PropTypes.node.isRequired,
};

HelpMe.Caption = Caption;
HelpMe.Body = Body;

export { HelpMe };
