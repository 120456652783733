import React from 'react';
import PropTypes from 'prop-types';
import { fetchJSON, errorsToString, triggerUserInfoChanged } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';


function UniUserHasValidAddress(uniuser) {
    return uniuser.address1
        && uniuser.city
        && uniuser.state
        && uniuser.zipp
        && uniuser.phone
        && uniuser.first_name
        && uniuser.last_name;
}


class AddressUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipp: '',
            phone: '',
            first_name: '',
            last_name: '',
            updatingAddress: false,
        };

        this.UniUserApiUrl = str_user_id > 0 && `/api/v2/uniuser/${StrUserInfo.uuid}/`;
    }

    render() {
        if (str_user_id < 0) {
            return (
                <p className="text-danger">Programming error: this can only be used for logged-in users</p>
            );
        }
        return (
            <div>
              {this.props.noHeadings || <h2>Update Information</h2>}
              {this.state.errorMessage &&
               <div className="alert alert-danger" role="alert">
                 {this.state.errorMessage}
               </div>
              }

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    {this.props.noHeadings || <label htmlFor="anon-first_name">Name</label>}
                    <input type="text"
                           className="form-control"
                           placeholder="First name"
                           value={this.state.first_name}
                           onChange={this.updateStateThing.bind(this, 'first_name')} />
                  </div>
                  <div className="form-group">
                    <input type="text"
                           className="form-control"
                           placeholder="Last name"
                           value={this.state.last_name}
                           onChange={this.updateStateThing.bind(this, 'last_name')} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    {this.props.noHeadings || <label htmlFor="anon-address1">Address</label>}
                    <input type="text"
                           className="form-control"
                           placeholder="Address 1"
                           value={this.state.address1}
                           onChange={this.updateStateThing.bind(this, 'address1')} />
                  </div>
                  <div className="form-group">
                    <input type="text"
                           className="form-control"
                           placeholder="Address 2"
                           value={this.state.address2}
                           onChange={this.updateStateThing.bind(this, 'address2')} />
                  </div>
                </div>
              </div>

              <div className="row" style={{marginBottom: "15px"}}>
                <div className="col-md-4">
                  <input type="text"
                         className="form-control"
                         placeholder="City"
                         value={this.state.city}
                         onChange={this.updateStateThing.bind(this, 'city')} />
                </div>
                <div className="col-md-2">
                  <input type="text"
                         className="form-control"
                         placeholder="State"
                         value={this.state.state}
                         onChange={this.updateStateThing.bind(this, 'state')} />
                </div>
                <div className="col-md-3">
                  <input type="text"
                         className="form-control"
                         placeholder="Zip"
                         value={this.state.zipp}
                         onChange={this.updateStateThing.bind(this, 'zipp')} />
                </div>
              </div>
              <div className="row" style={{marginBottom: "15px"}}>
                <div className="col-md-3">
                  <input type="text"
                         className="form-control"
                         placeholder="Phone"
                         value={this.state.phone}
                         onChange={this.updateStateThing.bind(this, 'phone')} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button className="btn btn-primary"
                            onClick={(e) => this.onModalAddressUpdateClick(e)}
                            disabled={this.state.updatingAddress}>
                      {this.props.updateBtnText || "Update"}
                      {' '}
                      {this.state.updatingAddress && <LoadingSpinner />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchController = new AbortController();
        this.fetch();
    }

    componentWillUnmount() {
        this.fetchController.abort();
    }

    updateStateThing(thing, event) {
        this.setState({[thing]: event.target.value});
    }

    async fetch() {
        const uniuser = await fetchJSON(this.UniUserApiUrl, this.fetchController.signal);
        if (uniuser === null)
            return;
        const cleanedUniUser = {};
        for (let key in uniuser) {
            // don't update any null values. Avoid overwriting state.
            if (uniuser.hasOwnProperty(key) &&  uniuser[key] !== null)
                cleanedUniUser[key] = uniuser[key];
        }
        this.setState({...cleanedUniUser});
    }

    onModalAddressUpdateClick(event) {
        event.preventDefault();
        if (this.state.address1 === ''
            || this.state.city === ''
            || this.state.state === ''
            || this.state.zipp === ''
            || this.state.phone === ''
            || this.state.first_name === ''
            || this.state.last_name === '') {
            this.setState({errorMessage: "Please enter your name, address and phone number."});
        } else {
            this.setState({errorMessage: ""});
            const data = {
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                zipp: this.state.zipp,
                phone: this.state.phone,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
            };
            $.ajax({
                url: this.UniUserApiUrl,
                method: "PATCH",
                data: data,
                complete: (xhr, status) => {
                    if (status === 'success') {
                        this.setState({
                            errorMessage: "",
                            showAddressUpdateModal: false,
                            updatingAddress: false,
                            address1: '',
                            address2: '',
                            city: '',
                            state: '',
                            zipp: '',
                            phone: '',
                            first_name: '',
                            last_name: '',
                        });
                        this.fetch();
                        triggerUserInfoChanged();
                        if (this.props.onSuccess)
                            this.props.onSuccess();
                    } else {
                        this.setState({
                            errorMessage: errorsToString(xhr.responseJSON),
                        });
                        if (this.props.onError)
                            this.props.onError();
                    }
                },
            });
        }
    }
}

AddressUpdate.propTypes = {
    noHeadings: PropTypes.bool,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    updateBtnText: PropTypes.string,
};

export { AddressUpdate, UniUserHasValidAddress };
