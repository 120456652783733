import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContainer } from './StrModal.jsx';

export default function AdminRemoveItem(props) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [reason, setReason] = useState('');
    const [saving, setSaving] = useState(false);
    const url = `/api/v2/admin_remove_items_from_party/${props.partyPublicId}/`;
    const idKey = '' + props.stylePk + props.customAlbumPk + props.itemPk
                + props.attributeValue;

    function confirmRemove() {
        setSaving(true);
        const data = {
            itemPk: props.itemPk,
            reason: reason,
            stylePk: props.stylePk,
            attributeValue: props.attributeValue,
            customAlbumPk: props.customAlbumPk,
        };
        $.post(url, data, function(data, status, xhr) {
            setShowConfirmModal(false);
            const closestClass = props.itemPk ? '.thumbnail' : '.party-item';
            /* this is a little ghetto but better to have it centralized
             * to one spot than what it was before. */
            $(`#${idKey}`).closest(closestClass).hide(300);
        }).fail(function() {
            const noun = props.itemPk ? 'item' : 'album';
            toast.error(`There was an error removing the ${noun}`);
        }).always(function() {
            setSaving(false);
        });
    }

    const countText = props.count === 1
                    ? 'this item'
                    : `${props.count} items`;
    return <span>
      <button className="btn btn-danger btn-xs"
              id={idKey}
              disabled={saving}
              onClick={() => setShowConfirmModal(true)}>
        <i className="fa fa-trash"
           style={{position: "relative", top: "-1px"}}
           aria-hidden="true"></i>
      </button>

      {showConfirmModal &&
       <ModalContainer onClose={() => setShowConfirmModal(false)}
                       isOpen={true}>
         <h4>Confirm removal</h4>
         <p>
           <b>Really remove {countText}?</b>
         </p>
         <div className="form-group">
           <label htmlFor="removeReason">Reason</label>
           <textarea name="removeReason"
                     id="admin-removal-reason"
                     className="form-control"
                     onChange={(e) => setReason(e.target.value)}
                     value={reason} />
         </div>
         <button type="button"
                 onClick={confirmRemove}
                 disabled={saving}
                 id="admin-remove-btn"
                 className="btn btn-primary">
           Remove
         </button>
       </ModalContainer>
      }
    </span>;
}

AdminRemoveItem.defaultProps = {
    stylePk: null,
    customAlbumPk: null,
    itemPk: null,
    attributeValue: null,
    count: 1,
};

AdminRemoveItem.propTypes = {
    partyPublicId: PropTypes.number.isRequired,
    // one of these three is required
    stylePk: PropTypes.number,
    customAlbumPk: PropTypes.number,
    itemPk: PropTypes.number,

    attributeValue: PropTypes.string,
    count: PropTypes.number,
};
