import React, { useState } from 'react';
import PropTypes from 'prop-types';

const StrToggleable = ({ header, body, initial = false }) => {
    const [isVisible, setIsVisible] = useState(initial);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const headerWithOnClick = React.cloneElement(header, {
        onClick: toggleVisibility,
        style: { cursor: 'pointer', ...header.props.style },
        ...header.props,
    });

    return (
        <React.Fragment>
          {headerWithOnClick}
          {isVisible && body}
        </React.Fragment>
    );
};

StrToggleable.propTypes = {
    header: PropTypes.element.isRequired,
    body: PropTypes.element.isRequired,
    initial: PropTypes.bool,
};

export default StrToggleable;
