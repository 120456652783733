import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
    SORTABLE_ITEM: "sortableItem",
};

const Sortable = ({ id, index, moveItem, content }) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.SORTABLE_ITEM,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            const downwardDragging = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
            const upwardDragging = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;

            if (downwardDragging || upwardDragging) {
                return;
            }

            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally, it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.SORTABLE_ITEM,
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.1 : 1;

    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity }} className="is-draggable">
            {content}
        </div>
    );
};

Sortable.propTypes = {
    id: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    moveItem: PropTypes.func.isRequired,
    content: PropTypes.element.isRequired,
};

export default Sortable;
