import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ImageUploadOrSelect from "./ImageUploadOrSelect.jsx";
import Alert from "./Alert.jsx";
import Sortable from "./Sortable.jsx";
import ExtraImageListItem from "./ExtraImageListItem.jsx";
import { fetchJSON, strRequestJSON } from "./util.jsx";

const ExtraImagesContainer = (props) => {
    const [error, setError] = useState("");
    const [extraImages, setExtraImages] = useState(props.extraImages);
    const [showUploadWidget, setShowUploadWidget] = useState(props.showUploadWidget);

    useEffect(() => {
        if (props.invItemPk && props.extraImages.length === 0) {
            fetchData(props.invItemPk);
        }
    }, [props.invItemPk, props.extraImages]);

    const fetchData = async (pk) => {
        const url = `/api/v2/items/${pk}/`;
        const data = await fetchJSON(url);
        setExtraImages(data.extra_images);
    };

    const onDelete = (success, image, rsp) => {
        if (!success) {
            let reason = "We could not delete this image";
            if (rsp && rsp.responseJSON && rsp.responseJSON.length > 0) {
                reason = rsp.responseJSON[0];
            }
            setError(reason);
        }

        const newImages = extraImages.filter(x => x.id !== image.id);
        setExtraImages(newImages);
        notifyNewImages(newImages);
    };

    const updateSortOrder = (image, position) => {
        const url = `/api/v2/${props.resource}/${image.id}/`;
        strRequestJSON(url, "PATCH", { sort_order: position });
    };

    const onSort = (currentPosition, newPosition) => {
        const newImages = [...extraImages];
        const [draggedItem] = newImages.splice(currentPosition, 1);
        newImages.splice(newPosition, 0, draggedItem);
        setExtraImages(newImages);
        notifyNewImages(newImages);
        newImages.forEach(updateSortOrder);
    };

    const notifyNewImages = (newImages) => {
        if (props.setImages) {
            props.setImages(newImages);
        }
    };

    const onAdd = (result) => {
        const image = result.file;
        image.url = image.image_full;
        const newImages = [...extraImages, image];
        setExtraImages(newImages);
        notifyNewImages(newImages);
    };

    return (
        <div className="row">
          <div className="col-md-12" style={{padding: 0}}>
            <DndProvider backend={HTML5Backend}>
              <div className="invitem-image-container">
                {extraImages.map((image, i) => (
                    <Sortable
                        id={image.id}
                        key={image.id}
                        index={i}
                        moveItem={onSort}
                        content={
                            <ExtraImageListItem
                                image={image}
                                onDelete={onDelete}
                                endpoint={props.resource}
                            />
                        }
                    />
                ))}

                {!showUploadWidget && (
                    <button className="btn btn-default btn-sm"
                            onClick={() => setShowUploadWidget(true)}>
                      Add more images
                    </button>
                )}

                {error && (
                    <Alert
                        heading="An error occurred!"
                        message={error}
                        type="danger"
                    />
                )}
              </div>
            </DndProvider>

            {showUploadWidget &&
             <ImageUploadOrSelect
                 inputName="upload_extra_images"
                 callback={onAdd}
                 resource={props.resource}
                 extraImagesLength={extraImages.length}
                 invItemPk={props.invItemPk}
                 multiple={true}
             />
            }
          </div>
        </div>
    );
};

ExtraImagesContainer.defaultProps = {
    extraImages: [],
    showUploadWidget: false,
    resource: "extraimages",
};

ExtraImagesContainer.propTypes = {
    invItemPk: PropTypes.number,
    extraImages: PropTypes.array,
    showUploadWidget: PropTypes.bool,
    resource: PropTypes.string,
    setImages: PropTypes.func,
};

export default ExtraImagesContainer;
