import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash-es';


class LinkPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { link } = this.props;
        const url = link.data.url || link.url;
        const urlText = truncate(url, { length: 60 });
        const title = link.data.title || url;
        const description = link.data.description || title;
        const image = link.data.image;

        return (
            <div className="ffLinkPreview">
              {this.props.onClose &&
               <button className="btn btn-link pull-right"
                       onClick={this.props.onClose}>
                 <i className="fa fa-times"></i>
               </button>
              }
              {image &&
               <img className="img-responsive" src={image} />
              }
              <h5>{title}</h5>
              <h6><a target="_blank"
                     href={url}>{urlText}</a></h6>
              <div className="text-muted text-small">{description}</div>
            </div>
        );
    }
}

LinkPreview.propTypes = {
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
    }).isRequired,
    onClose: PropTypes.func,
};

export default LinkPreview;
