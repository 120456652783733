import React, { useState, useEffect, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { strPostJSONWithResponse, fetchJSONWithResponse } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { dispatchStrBucksBalanceChanged } from './StrBucks.jsx';

// settings is an object with any of the following keys:
//
//  - needDimsLWH (needs length, width, and height)
//  - needDimsLW  (needs length and width)
//
// All settings are assumed to be false (off) by default,
// so we only need to pass in the dudes that are true
const PredefinedPackage = (title, predefinedPackage, help, moreHelp, image, settings = {}) => {
    return {title, predefinedPackage, help, moreHelp, image, settings};
};

const PREDEFINED_PACKAGES = [
    PredefinedPackage(
        "Soft Pack, Envelope, Padded envelope, Tyvek envelope",
        "SoftPack",
        () => (
            <span>
              Any custom or plain mailing envelope, padded envelope
              (non-flat rate), poly bag, soft pack, or <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-tyvek-envelope-P_EP_14">Priority Mail Tyvek Envelope</a>.
              Supports Priority and Non-Priority USPS service levels.
            </span>
        ),
        {
            metrics: {
                dimensions: "Use the Length and Width of the empty envelope",
            },
            service: "If you're using Priority Mail branded packaging make sure you select Priority service here.",
        },
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-custom-envelope-including-priority.png",
        {needDimsLW: true},
    ),
    PredefinedPackage(
        "Custom box",
        "Parcel",
        "Any custom-branded or generic box, or Priority Mail (non-flat-rate) boxes. Supports Priority and Non-Priority USPS service levels.",
        {
            service: "If you're using Priority Mail branded packaging make sure you select Priority service here.",
        },
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-custom-box-including-priority.png",
        {needDimsLWH: true},
    ),
    PredefinedPackage(
        "Flat Rate Padded Envelope (Priority Mail)",
        "FlatRatePaddedEnvelope",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-padded-flat-rate-envelope-P_EP14PE">Priority Mail Flat Rate Padded Envelope</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-flat-rate-padded-envelope.png",
    ),
    PredefinedPackage(
        "Flat Rate Envelope (Priority Mail)",
        "FlatRateEnvelope",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-flat-rate-envelope-ep14f-P_EP_14_F">Non-padded Flat Rate Envelope</a>
              {', '}
              <a target="_blank" href="https://store.usps.com/store/product/cards-envelopes/priority-mail-window-flat-rate-envelope-ep14w-P_EP14W">Priority Mail Window Flat Rate Envelope</a>
              {', or '}
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-small-flat-rate-envelope-ep14b-P_EP_14_B">Priority Mail Small Flat Rate Envelope</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-flat-rate-envelope.png",
    ),
    PredefinedPackage(
        "Flat Rate Legal Envelope (Priority Mail)",
        "FlatRateLegalEnvelope",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-flat-rate-legal-envelope-P_EP14L">Legal Flat Rate Envelope</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-flat-rate-legal-envelope.png",
    ),
    PredefinedPackage(
        "Small Flat Rate Box (Priority Mail)",
        "SmallFlatRateBox",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-small-flat-rate-box-P_SMALL_FRB">Priority Mail Small Flate Rate Box</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-small-flat-rate-box.png",
    ),
    PredefinedPackage(
        "Medium Flat Rate Box (Priority Mail)",
        "MediumFlatRateBox",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-medium-flat-rate-box-1-P_O_FRB1">Priority Mail Medium Flat Rate Box 1 (Top-Loading)</a> or
              {' '}
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-flat-rate-medium-box-2-P_O_FRB2">Priority Mail Medium Flat Rate Box 2 (Side-Loading)</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-medium-flat-rate-box.png",
    ),
    PredefinedPackage(
        "Large Flat Rate Box (Priority Mail)",
        "LargeFlatRateBox",
        () => (
            <span>
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-large-flat-rate-box-largefrb-P_LARGE_FRB">Priority Mail Large Flat Rate Box</a> or
              {' '}
              <a target="_blank" href="https://store.usps.com/store/product/shipping-supplies/priority-mail-apofpo-flat-rate-box-milifrb-P_MILI_FRB">Priority Mail APO/FPO Flat Rate Box</a>.
              Priority Mail only.
            </span>
        ),
        "",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/shipping-supplies-large-flat-rate-box.png",
    ),
];

const ServiceLevel = (service, title, help, moreHelp, image) => {
    return {service, title, help, moreHelp, image};
};

const SERVICE_LEVELS = [
    ServiceLevel(
        "GroundAdvantage",
        "USPS Ground Advantage",
        "Formerly known as \"First Class\"",
        "Includes $100 insurance",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/usps.png",
    ),
    ServiceLevel(
        "Priority",
        "USPS Priority",
        "",
        "Includes $100 insurance",
        "https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/usps_priority_mail.png",
    ),
];

const getServiceLevel = (service) => {
    return SERVICE_LEVELS.find(level => level.service === service) || null;
};

const ParcelPicker = (props) => {
    const handleHelpClick = (e) => {
        if (e.target.tagName.toLowerCase() === 'a')
            e.stopPropagation();
    };
    return (
        <div>
          <h4>Choose your packaging type</h4>
          {PREDEFINED_PACKAGES.map((pkg) => (
              <div key={pkg.predefinedPackage}
                   className="predefined-package-option"
                   onClick={() => props.onParcelPick(pkg)}>
                <div className="predefined-package-option-image">
                  <img src={pkg.image} />
                </div>
                <div className="predefined-package-option-body">
                  <div className="predefined-package-option-title">{pkg.title}</div>
                  <div className="text-muted text-small"
                       onClick={handleHelpClick}>
                    {typeof pkg.help === 'function' ? pkg.help() : pkg.help}
                  </div>
                </div>
              </div>
          ))}
        </div>
    );
};

ParcelPicker.propTypes = {
    onParcelPick: PropTypes.func.isRequired,
};

const ParcelMetricsPicker = ({ parcel, onMetricsPick }) => {
    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [pounds, setPounds] = useState("");
    const [ounces, setOunces] = useState("");
    const [error, setError] = useState("");
    const [customParcels, setCustomParcels] = useState([]);
    // We just toggle this any time we want to re-fetch customParcels
    const [shouldRefreshCustomParcels, setShouldRefreshCustomParcels] = useState(false);
    const isMounted = useRef(true);

    const needDims = parcel.settings.needDimsLW || parcel.settings.needDimsLWH;
    const needHeight = parcel.settings.needDimsLWH;

    useEffect(() => {
        const fetchController = new AbortController();
        const doFetch = async () => {
            const url = "/api/v2/custom_parcels/";
            const [json, rsp] = await fetchJSONWithResponse(
                url, fetchController.signal);
            if (json === null)
                return;
            if (!rsp.ok) {
                return;
            }
            setCustomParcels(json.parcels);
        };
        doFetch();
        return () => {
            fetchController.abort();
        };
    }, [shouldRefreshCustomParcels]);

    /* isMounted tracking */
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const calculateWeights = () => {
        const weightPounds = pounds ? parseFloat(pounds): 0;
        const weightOz = ounces ? parseFloat(ounces) : 0;
        const weightTotalOz = (weightPounds * 16) + weightOz;
        return [weightPounds, weightOz, weightTotalOz];
    };

    const handleSave = () => {
        const [weightPounds, weightOz, weightTotalOz] = calculateWeights();

        // Check that all required fields are filled out
        if (
            (needDims && (!length || !width || (needHeight && !height))) ||
            weightTotalOz === 0
        ) {
            setError("Dimensions must be filled out and total weight must be greater than 0.");
            return;
        }

        const metrics = {
            weightTotalOz,
            weightPounds,
            weightOz,
            dimensions: needDims ? { length, width, ...(needHeight ? { height } : {}) } : {},
        };
        setError(null); // Clear error if save is successful
        onMetricsPick(metrics);
    };

    const handleHistoryPick = (metrics) => {
        if (metrics.weightOz !== null)
            setOunces(metrics.weightOz);
        if (metrics.weightPounds !== null)
            setPounds(metrics.weightPounds);
        if (metrics.dimensions.length !== null)
            setLength(metrics.dimensions.length);
        if (metrics.dimensions.width !== null)
            setWidth(metrics.dimensions.width);
        if (metrics.dimensions.height !== null)
            setHeight(metrics.dimensions.height);
    };

    const onToggleFavorite = async (parcel) => {
        const url = `/api/v2/edit_custom_parcel/${parcel.pk}/`;
        const requestData = {is_favorite: !parcel.is_favorite};
        const [json, rsp] = await strPostJSONWithResponse(url, requestData);
        if (json === null || !rsp.ok || !isMounted.current)
            return;
        setShouldRefreshCustomParcels(!shouldRefreshCustomParcels);
    };

    const parseNumberInput = (onChange) => {
        return (e) => {
            const value = e.target.value;
            onChange(value === "" ? "" : parseFloat(value));
        };
    };

    return (
        <div className="row">
          <div className="col-md-8">
            {needDims && (
                <div className="form-inline" style={{marginTop: "10px"}}>
                  <div className="form-group">
                    <div style={{ fontSize: "larger" }}>Dimensions (inches)</div>
                    <input className="form-control input-lg"
                           type="number"
                           min="0"
                           value={length}
                           style={{width: "150px", margin: "0 5px 0 0"}}
                           placeholder="Length"
                           onChange={parseNumberInput(setLength)} />
                    <input className="form-control input-lg"
                           type="number"
                           min="0"
                           value={width}
                           style={{width: "150px", margin: "0 5px 0 0"}}
                           placeholder="Width"
                           onChange={parseNumberInput(setWidth)} />
                    {needHeight && <input className="form-control input-lg"
                                          type="number"
                                          min="0"
                                          value={height}
                                          style={{width: "150px", margin: "0 5px 0 0"}}
                                          placeholder="Height"
                                          onChange={parseNumberInput(setHeight)} />}
                  </div>
                  {parcel.moreHelp && parcel.moreHelp.metrics && parcel.moreHelp.metrics.dimensions &&
                   <div className="text-muted text-small">{parcel.moreHelp.metrics.dimensions}</div>
                  }
                </div>
            )}
            <div className="form-inline" style={{marginTop: "10px"}}>
              <div className="form-group">
                <div style={{ fontSize: "larger" }}>Weight</div>
                <input className="form-control input-lg"
                       type="number"
                       min="0"
                       value={pounds}
                       style={{width: "150px", margin: "0 5px 0 0"}}
                       placeholder="Pounds"
                       onChange={parseNumberInput(setPounds)} />
                <input className="form-control input-lg"
                       type="number"
                       min="0"
                       value={ounces}
                       style={{width: "150px", margin: "0 5px 0 0"}}
                       placeholder="Ounces"
                       onChange={parseNumberInput(setOunces)} />
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <button className="btn btn-default"
                    style={{margin: "20px 0"}}
                    onClick={handleSave}>Continue</button>
          </div>
          <div className="col-md-4">
            {customParcels &&
             <div style={{border: "solid 1px #c0c0c0", borderRadius: "4px", padding: "10px"}}>
               <h5 style={{marginTop: "0px"}}>Measurements History</h5>
               <p className="text-muted text-small">
                 Click to re-use the dimensions/weight from a recent shipment.
               </p>
               {customParcels.map((parcel) => {
                   let metrics = {
                       weightTotalOz: parcel.weight,
                       weightOz: parcel.weight,
                       weightPounds: 0,
                       dimensions: {
                           length: parcel.length,
                           width: parcel.width,
                           height: parcel.height,
                       },
                   };
                   metrics = normalizeMetricsWeights(metrics);
                   const cls = `text-muted fa fa-star${parcel.is_favorite ? "" : "-o"}`;
                   let style = {cursor: "pointer"};
                   if (parcel.is_favorite)
                       style.color = "rgb(255, 215, 0)";
                   return (
                       <div key={parcel.pk} className="str-flex-container">
                         <div className="str-flex-fit"
                              onClick={() => onToggleFavorite(parcel)}>
                           <i className={cls}
                              style={style}
                              aria-hidden="true"></i>
                         </div>
                         <div onClick={() => handleHistoryPick(metrics)}
                              style={{cursor: "pointer", marginLeft: "3px", paddingLeft: "3px"}}
                              className="text-muted text-small recent-shipment str-flex-fill">
                           <MetricsDisplay metrics={metrics} />
                         </div>
                       </div>
                   );
               })}
             </div>
            }
          </div>
        </div>
    );
};

const normalizeMetricsWeights = (metrics) => {
    if (!metrics.weightPounds && metrics.weightOz >= 16) {
        metrics.weightPounds = Math.floor(metrics.weightOz / 16);
        metrics.weightOz %= 16;
    }
    return metrics;
};

ParcelMetricsPicker.propTypes = {
    parcel: PropTypes.object.isRequired,
    onMetricsPick: PropTypes.func.isRequired,
};

const MetricsDisplay = (props) => {
    const { metrics } = props;
    const { dimensions } = metrics;
    let dimensionsString = "";
    if (dimensions && dimensions.length) {
        const haveHeight = dimensions.height;
        const dimensionLabel = haveHeight ? "LxWxH" : "LxW";
        dimensionsString = `, ${dimensions.length}"x${dimensions.width}"`;
        if (haveHeight)
            dimensionsString += `x${dimensions.height}"`;
        dimensionsString = `${dimensionsString} (${dimensionLabel})`;
    }
    return (
        <span>
          {metrics.weightPounds} lbs, {metrics.weightOz} oz{dimensionsString}
        </span>
    );
};

MetricsDisplay.propTypes = {
    metrics: PropTypes.shape({
        weightTotalOz: PropTypes.number.isRequired,
        weightPounds: PropTypes.number.isRequired,
        weightOz: PropTypes.number.isRequired,
        dimensions: PropTypes.shape({
            length: PropTypes.number,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }),
};

const RatePicker = (props) => {
    const [error, setError] = useState("");
    const [rates, setRates] = useState(null);
    const [selectedRateId, setSelectedRateId] = useState(null);

    const { labelsApiArgs, parcel } = props;

    useEffect(() => {
        const fetchController = new AbortController();
        const doFetch = async () => {
            const url = "/api/v2/shipping_labels_full/";
            const [json, rsp] = await strPostJSONWithResponse(
                url, labelsApiArgs, fetchController.signal);
            if (json === null)
                return;
            if (!rsp.ok) {
                setError(`Error getting rates: ${json.error || rsp.statusText}`);
                return;
            }
            setRates(json.rates);
        };
        setRates(null);
        setSelectedRateId(null);
        setError("");
        doFetch();
        return () => {
            fetchController.abort();
        };
    }, [labelsApiArgs]);

    const _onRatePick = (rate) => {
        setSelectedRateId(rate.rate_id);
        props.onRatePick(rate);
    };

    if (!rates && !error)
        return <LoadingSpinner />;

    if (error) {
        return (
            <React.Fragment>
              <div className="alert alert-danger">{error}</div>
            </React.Fragment>
        );
    }

    return (
        <div>
          <h4>Choose a Service</h4>
          {parcel && parcel.moreHelp && parcel.moreHelp.service &&
           <p className="text-muted text-small">{parcel.moreHelp.service}</p>
          }
          {rates.sort((a, b) => parseFloat(a.rate) - parseFloat(b.rate))
                .map((rate) => {
                    let optionClasses = "row shipping-service-option";
                    if (rate.rate_id === selectedRateId)
                        optionClasses += " selected-shipping-service-option";
                    const serviceLevel = getServiceLevel(rate.service);
                    return (
                        <div key={rate.rate_id} className="row">
                          <div className="col-md-8">
                            <div className={optionClasses}
                                 onClick={() => _onRatePick(rate)}>
                              <div className="col-md-8">
                                <div className="shipping-service-header">
                                  <div>
                                    <img src={serviceLevel.image} />
                                  </div>
                                  <div>
                                    <strong>{serviceLevel.title}</strong>
                                    {serviceLevel.help && <div className="text-small" style={{fontStyle: "italic"}}>{serviceLevel.help}</div>}
                                    <div className="text-muted text-small">
                                      Delivery estimate: {rate.delivery_days} {rate.delivery_days === 1 ? 'day' : 'days'}
                                    </div>
                                    {serviceLevel.moreHelp && <div className="text-muted text-small">{serviceLevel.moreHelp}</div>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4" style={{textAlign: "right"}}>
                                <div style={{fontSize: "larger", fontWeight: "bold"}}>${rate.rate}</div>
                                {props.insuranceFee &&
                                 <div className="text-small text-muted">+ ${props.insuranceFee} insurance fee</div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                })
          }
        </div>
    );
};

RatePicker.propTypes = {
    parcel: PropTypes.object,
    onRatePick: PropTypes.func.isRequired,
    labelsApiArgs: PropTypes.object.isRequired,
    insuranceFee: PropTypes.number,
};

const ShippingQuotesV2 = (props) => {
    const [parcel, setParcel] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [rate, setRate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [doPurchase, setDoPurchase] = useState(null);
    const [purchaseInfo, setPurchaseInfo] = useState(null);

    const { orderData, setLabel, insuranceCoverage, isReturn, originalLabelPk } = props;

    useEffect(() => {
        setRate(null);
    }, [props]);

    useEffect(() => {
        if (rate === null)
            return;

        const fetchController = new AbortController();

        // We have to define this function inside a useEffect
        // and set it to the doPurchase useState variable because
        // we need a way to abort the fetch if the component is
        // unmounted, which has to be done from a useEffect cleanup
        // function that shares scope with the purchase
        // function so that they're both referring to the same
        // AbortController instance.
        const doPurchaseFn = async () => {
            const url = "/api/v2/shipping_labels_full/";
            let requestData;
            if (isReturn) {
                requestData = {
                    action: "buy_rate",
                    rate_id: rate.rate_id,
                    is_return: true,
                    original_label_pk: originalLabelPk,
                };
            } else {
                requestData = {
                    action: "buy_rate",
                    rate_id: rate.rate_id,
                    is_return: false,
                    insurance_amount: insuranceCoverage,
                    bless_order_no: orderData.blessOrderNo,
                    /*
                     * EP always requires a height, so sometimes we use a
                     * placeholder height while quoting, but we don't want
                     * to save placeholder heights in the measurements/CustomParcel
                     * history, so tell the backend to ignore the quoted height.
                     */
                    vacuous_height: metrics && metrics.dimensions && !metrics.dimensions.height,
                };
                if (!orderData.orderPkIsALie)
                    requestData.claimbatch_pk = orderData.orderPk;
            }
            setLoading(true);
            setError(null);
            const [json, rsp] = await strPostJSONWithResponse(
                url, requestData, fetchController.signal);
            if (json === null)
                return;
            setLoading(false);
            if (!rsp.ok) {
                const errorMessage = `Error purchasing label: ${json.error || rsp.statusText}`;
                const formattedErrorMessage = (
                    <ul>
                      {errorMessage.split('\n').map((line, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index}>
                            {line}
                          </li>
                      ))}
                    </ul>
                );
                setError(formattedErrorMessage);
                return;
            }
            dispatchStrBucksBalanceChanged(json.strbucks);
            setPurchaseInfo(json);
            setLabel(json.tracker_url, json.pdf_url, json.label_pk);
        };

        // When you pass a function to a useState setter it gets treated
        // as a "factory" function, i.e. it gets evaluated to get the value
        // for the state variable. We don't want doPurchaseFn to be
        // evaluated, so we pass in a function that returns our function.
        //  Whew... This seems overly complicated... :thinking_face:
        setDoPurchase(() => doPurchaseFn);

        return () => {
            fetchController.abort();
        };
    }, [rate, orderData, setLabel, insuranceCoverage, metrics, isReturn, originalLabelPk]);

    const labelsApiArgs = useMemo(() => {
        if (props.isReturn) {
            return {
                action: "get_return_rates",
                label_pk: props.originalLabelPk,
            };
        }
        if (!parcel || !metrics)
            return null;
        return {
            action: "get_rates",
            address_to: {
                name: props.name || '',
                street1: props.street1,
                street2: props.street2,
                city: props.city,
                state: props.state,
                zip: props.zip,
                email: props.email,
            },
            length: metrics.dimensions.length,
            width: metrics.dimensions.width,
            height: metrics.dimensions.height,
            weight_oz: metrics.weightTotalOz,
            predefined_package: parcel.predefinedPackage,
            signature: props.signature,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            customs_items: props.customsItems,
            label_size: props.labelSize,
        };
    }, [parcel, metrics, props]);

    if (loading)
        return <LoadingSpinner />;

    const haveInsuranceFee = !!(props.insuranceFee && props.insuranceFee > 0);
    const insuranceFee = haveInsuranceFee ? props.insuranceFee : null;

    return (
        <div className="shippingQuotesV2Container">
          {!parcel && !isReturn && <ParcelPicker onParcelPick={setParcel} />}
          {parcel &&
           <div className="text-muted text-small">
             <strong>Packaging type:</strong> {parcel.title}
             <button className="btn btn-xs btn-link"
                     style={{padding: "0 10px", margin: "0", position: "relative", bottom: "2px"}}
                     onClick={() => {
                         setParcel(null);
                         setMetrics(null);
                         setRate(null);
                     }}>
               Change
             </button>
           </div>
          }
          {parcel && !isReturn && !metrics &&
           <ParcelMetricsPicker parcel={parcel}
                                onMetricsPick={setMetrics} />
          }
          {parcel && metrics &&
           <div className="text-muted text-small">
             <strong>Measurements:</strong> <MetricsDisplay metrics={metrics} />
             <button className="btn btn-xs btn-link"
                     style={{padding: "0 10px", margin: "0", position: "relative", bottom: "2px"}}
                     onClick={() => {
                         setMetrics(null);
                         setRate(null);
                     }}>
               Change
             </button>
           </div>
          }
          {((parcel && metrics) || isReturn) && !purchaseInfo &&
           <React.Fragment>
             <RatePicker parcel={parcel}
                         onRatePick={setRate}
                         labelsApiArgs={labelsApiArgs}
                         insuranceFee={insuranceFee} />
             {rate &&
              <div>
                <button className="btn btn-lg btn-primary"
                        onClick={doPurchase}>
                  <i className="fa fa-truck" aria-hidden="true"></i>
                  {' '}
                  Purchase selected label
                  {' '}
                  (${(parseFloat(rate.rate) + (props.insuranceFee || 0)).toFixed(2)})
                </button>
              </div>
             }
           </React.Fragment>
          }
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

ShippingQuotesV2.propTypes = {
    /*
     * Required no matter the value of isReturn
     *
     * Called with (trackerUrl, pdfUrl, labelPk)
     */
    setLabel: PropTypes.func.isRequired,

    /* return specific props */
    isReturn: PropTypes.bool,
    originalLabelPk: PropTypes.number,
    /* Nothing below is required when isReturn=true */

    /* From ShippingLabels.propTypes.data */
    orderData: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        phone: PropTypes.string,
        labelOnly: PropTypes.bool,
        items: PropTypes.array,
        claimRecords: PropTypes.array,
        orderPk: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        orderPkIsALie: PropTypes.bool,
        groupType: PropTypes.string,
        shippingPdf: PropTypes.string,
        shippingTracker: PropTypes.string,
        labelPk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setLabel: PropTypes.func.isRequired,
        blessOrderNo: PropTypes.string,
        customsItems: PropTypes.array,
    }),
    name: PropTypes.string,
    email: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    needExtraConfirm: PropTypes.bool,
    signature: PropTypes.bool,
    insuranceFee: PropTypes.number,
    insuranceCoverage: PropTypes.number,
    customsItems: PropTypes.array,
    labelSize: PropTypes.string,
};

const ShippingReturnQuotes = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [rates, setRates] = useState([]);
    const [labelInfo, setLabelInfo] = useState(null);

    const onLabel = (trackerUrl, pdfUrl, labelPk) => {
        props.setLabel(trackerUrl, pdfUrl, labelPk);
        setLabelInfo({trackerUrl, pdfUrl, labelPk});
    };

    if (loading)
        return <LoadingSpinner />;

    if (labelInfo)
        return null;

    return (
        <div>
          <h3>Buy Return Label</h3>
          <p className="text-muted text-small">
            Need to take a return from a customer? Purchase a label below
            and send them the label PDF.
          </p>
          <ShippingQuotesV2 isReturn={true}
                            setLabel={onLabel}
                            originalLabelPk={props.originalLabelPk} />
        </div>
    );
};

ShippingReturnQuotes.propTypes = {
    originalLabelPk: PropTypes.number.isRequired,
    /* Called with (trackerUrl, pdfUrl, labelPk) */
    setLabel: PropTypes.func.isRequired,
};

let root = null;

export function ShippingReturnLabelApp(el, labelPk, setLabel) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<ShippingReturnQuotes originalLabelPk={labelPk}
                                      setLabel={setLabel} />);
}

export { ShippingQuotesV2, ShippingReturnQuotes };
