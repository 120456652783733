import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { fetchJSON } from './util.jsx';
import PartyPicker from './PartyPicker.jsx';
import ModalContainer from './StrModal.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { PartyJoinRules } from './party_join_rules_app.jsx';


const MultiPartyDirectoryAdder = (props) => {
    const [parties, setParties] = useState([]);
    const [isShowingModal, setIsShowingModal] = useState(false);
    const [isShowingConfirmModal, setIsShowingConfirmModal] = useState(false);
    const [selectedParty, setSelectedParty] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addedAParty, setAddedAParty] = useState(false);
    const [disableDirectoryJoin, setDisableDirectoryJoin] = useState(false);

    async function fetchAdminedParties() {
        setLoading(true);
        const data = await fetchJSON('/api/v2/users/parties/');
        const ownerParties = data.filter(
            party => party.is_owner && !party.in_multi_directory);
        setParties(ownerParties);
        setLoading(false);
    }

    function onPartyAddClick() {
        fetchAdminedParties();
        setIsShowingModal(true);
    }

    function onPartySelect(party) {
        setSelectedParty(party);
        setDisableDirectoryJoin(false);
        setIsShowingConfirmModal(true);
    }

    function onPartyConfirm() {
        const url = `/api/v2/directory_control/${selectedParty.public_id}/`;
        const data = {
            action: 'add',
            disableDirectoryJoin: disableDirectoryJoin,
        };
        $.post(url, data, rsp => {
            toast.success(`Added ${selectedParty.name} to directory`);
            setIsShowingConfirmModal(false);
            setAddedAParty(true);
            if (parties.length === 1) {
                /* if they added their only party then just
                 * refresh the page so they can see it */
                location.reload();
            }
            fetchAdminedParties();
        }).fail(rsp => {
            toast.error('Could not add party to the directory');
        });
    }

    function onClose() {
        if (addedAParty) {
            location.reload();
        } else {
            setIsShowingModal(false);
        }
    }

    return (
        <div>
          <button className="btn btn-lg btn-sonlet"
                  onClick={onPartyAddClick}
                  disabled={loading
                         || StrUserInfo.plan !== "bt_plan_biz"}
                  title={StrUserInfo.plan === "bt_plan_biz"
                       ? ''
                       : "Upgrade to a business plan to add parties to the directory."}>
            {loading && <LoadingSpinner />} Add Multi {StrUserInfo.words.party}
          </button>
          {isShowingModal &&
           <ModalContainer onClose={onClose}
                           isOpen={true}
                           style={{
                               width: 'calc(100% - 110px)',
                               maxWidth: '1000px',
                           }}>
             <div>
               <h3>Add a {StrUserInfo.words.party} to the Directory</h3>
               <PartyPicker onSelect={onPartySelect}
                            parties={parties} />
               {loading &&
                <div>
                  <LoadingSpinner />
                  {' '}
                  Loading parties...
                </div>
               }
               {selectedParty &&
                <ModalContainer onClose={() => setIsShowingConfirmModal(false)}
                                isOpen={isShowingConfirmModal}
                                style={{
                                    width: '50%',
                                    maxWidth: '1000px',
                                    minWidth: '300px',
                                }}>
                  <div>
                    <h3>Add {selectedParty.name}?</h3>
                    <p>Please confirm that you want to add this {StrUserInfo.words.party.toLowerCase()} to the multi directory.</p>
                    <label className="checkbox-inline">
                      <input type="checkbox"
                             onChange={(e) => setDisableDirectoryJoin(e.target.checked)}
                             checked={disableDirectoryJoin} />
                      {' '}
                      Disable request to join in directory. If checked
                      please provide instructions for interested
                      sellers in the Join Rules.
                    </label>
                    <PartyJoinRules publicId={selectedParty.public_id} />
                    <button className="btn btn-primary"
                            style={{marginTop: '15px'}}
                            onClick={onPartyConfirm}>
                      Confirm add to directory
                    </button>
                  </div>
                </ModalContainer>
               }
             </div>
           </ModalContainer>
          }
        </div>
    );
};

MultiPartyDirectoryAdder.propTypes = {};

function MultiPartyDirectoryAdderApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<MultiPartyDirectoryAdder />);
}

export default MultiPartyDirectoryAdderApp;
