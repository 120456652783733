import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { fetchJSON, strRequest, formatCurrency } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import MarketProduct from './MarketProduct.jsx';
import PartySelect from './PartySelect.jsx';
import PartyMonetization from './PartyMonetization.jsx';

const FAButton = ({ onClick, iconCls }) => {
    return (
        <i className={iconCls}
           style={{cursor: "pointer", fontSize: "26px"}}
           onClick={() => onClick()}
           aria-hidden="true"></i>
    );
};

FAButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    iconCls: PropTypes.string.isRequired,
};

const RemoveButton = ({ onClick }) => {
    return (
        <div>
          <span className="label label-info text-tiny"
                style={{position: "relative", bottom: "4px"}}>Added</span>
          {' '}
          <FAButton onClick={onClick} iconCls="fa fa-minus-circle" />
        </div>
    );
};

RemoveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const AddButton = ({ onClick }) => {
    return <FAButton onClick={onClick} iconCls="fa fa-plus-circle" />;
};

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const PromotedItems = (props) => {
    const [items, setItems] = useState(null);
    const [party, setParty] = useState(null);
    const [customizeForAPartyp, setCustomizeForAPartyp] = useState(!!props.partyPk);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchJSON("/api/v2/sonlet_catalog/promoted_items/");
            setItems(data.results);
        };
        fetchData();
    }, []);

    const refetchParty = async () => {
        if (!party)
            return;
        /* TODO: fetch party */
    };

    const onAdd = (item) => {
        console.log(item);
    };

    if (items === null)
        return <LoadingSpinner />;

    if (items.length === 0)
        return <div>No promoted items available</div>;

    return (
        <React.Fragment>
          <div className="row mb-4" style={{marginBottom: "10px"}}>
            <div className="col-md-4">
              {!customizeForAPartyp &&
               <div>
                 <h4>Customize account-wide promoted items selection</h4>
                 {' '}
                 <a href="#"
                    className="text-small"
                    onClick={(e) => {
                        e.preventDefault();
                        setCustomizeForAPartyp(true);
                    }}>Switch to party-specific customization</a>
               </div>
              }
              {customizeForAPartyp &&
               <React.Fragment>
                 <h4>
                   Customize promoted items selection for party
                 </h4>
                 <PartySelect
                     selectPreviousOnInit={false}
                     adminOnly={true}
                     orderByDate={true}
                     fullPartyInfo={true}
                     onSelect={(partyOption) => setParty(partyOption.party)}
                 />
                 <div className="text-small" style={{marginTop: "10px"}}>
                   <a href="#"
                      onClick={(e) => {
                          e.preventDefault();
                          setCustomizeForAPartyp(false);
                      }}>Switch to account-wide customization</a>
                 </div>
               </React.Fragment>
              }
              {party &&
               <React.Fragment>
                 <p className="text-muted text-small">Now customizing promoted items for {party.name}</p>
                 {party.enable_platform_ads ||
                  <div className="text-small">
                    Monetization is not enabled for this party.
                    <PartyMonetization party={party}
                                       onPartyChange={refetchParty} />
                  </div>
                 }
               </React.Fragment>
              }
            </div>
          </div>
          <div className="row">
            {items.map((item) => {
                const getBottomContent = () => <AddButton onClick={() => onAdd(item)} />;
                return <MarketProduct
                           key={item.pk}
                           item={item}
                           isLiked={item.is_liked}
                           includeLike={true}
                           extraBottomContent={getBottomContent}
                       />;
            })}
          </div>
        </React.Fragment>
    );
};

PromotedItems.propTypes = {
    partyPk: PropTypes.number,
};

const dropShipApiUrl = "/api/v2/sonlet_catalog/dropship/";

const DropShipItems = (props) => {
    const [items, setItems] = useState(null);
    const [excludeAlreadyAdded, setExcludeAlreadyAdded] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [fetching, setFetching] = useState(false);

    const fetchData = async () => {
        let params = {};
        if (excludeAlreadyAdded)
            params.exclude_already_added = "1";
        if (sortBy !== "")
            params.sort_by = sortBy;
        setFetching(true);
        const data = await fetchJSON(dropShipApiUrl, null, params);
        setFetching(false);
        setItems(data.results);
    };

    useEffect(() => {
        fetchData();
    }, [excludeAlreadyAdded, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

    const modifyItemSelection = async (action, item) => {
        const data = {
            action,
            item_id: item.pk,
        };
        const rsp = await strRequest(dropShipApiUrl, "POST", data);
        if (!rsp.ok)
            toast.error(`Error saving selection: ${rsp.statusText}`);
        else {
            const verbed = action === "add" ? "added to" : "removed from";
            toast.success(`${item.title} ${verbed} your inventory`);
            fetchData();
        }
    };

    const onAdd = (item) => {
        modifyItemSelection("add", item);
    };

    const onRemove = (item) => {
        modifyItemSelection("remove", item);
    };

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (items === null)
        return <LoadingSpinner />;

    return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-12" style={{marginBottom: "10px"}}>
              Click the <i className="fa fa-plus-circle" /> to add items to
              your <a href="/inventory/dropship/">Dropship inventory</a>.
            </div>
          </div>
          <div className="row row-cols-lg-auto g-3 mb-3">
            <div className="col-12">
              <div className="form-check">
                <input className="form-check-input"
                       type="checkbox"
                       onClick={(e) => setExcludeAlreadyAdded(!excludeAlreadyAdded)}
                       id="id_excludeAlreadyAdded"
                       value={excludeAlreadyAdded} />
                <label className="form-check-label"
                       htmlFor="id_excludeAlreadyAdded">Exclude items already in my inventory</label>
              </div>
            </div>
            <div className="col-12">
              <select className="form-select"
                      value={sortBy}
                      onChange={e => setSortBy(e.target.value)}>
                <option value="">Sort by...</option>
                <option value="recent">Recently added</option>
                <option value="commission">Commission</option>
              </select>
            </div>
          </div>
          <div className="row my-1" style={{height: "25px"}}>
            <div className="col-12">
              {fetching && <LoadingSpinner />}
            </div>
          </div>
          {items.length === 0 && !fetching &&
           <div className="row">
             <div className="col-12">
               <p className="text-muted">No items</p>
             </div>
           </div>
          }
          <div className="row">
            {items.map((item) => {
                const getPriceContent = () => (
                    <div style={{textAlign: "right"}}>
                      <div>Price: <strong className="text-success">{formatCurrency(item.price)}</strong></div>
                      <div>You make: <strong className="text-success">{formatCurrency(item.dropship_consultant_payout)}</strong></div>
                    </div>
                );
                const getBottomContent = () => {
                    return item.is_in_dropship_selections
                         ? <RemoveButton onClick={() => onRemove(item)} />
                         : <AddButton onClick={() => onAdd(item)} />;
                };
                return <MarketProduct
                           key={item.pk}
                           item={item}
                           isLiked={item.is_liked}
                           includeLike={true}
                           extraBottomContent={getBottomContent}
                           priceContent={getPriceContent}
                       />;
            })}
          </div>
        </React.Fragment>
    );
};

DropShipItems.propTypes = {};

const SonletCatalogTabs = () => {
    // "dropship" or "promoted"
    const [activeTab, setActiveTab] = useState("dropship");

    const onDropShipTabClick = (e) => {
        e.preventDefault();
        setActiveTab("dropship");
    };

    const onPromotedTabClick = (e) => {
        e.preventDefault();
        setActiveTab("promoted");
    };

    const dropShipTabClass = "nav-link" + (activeTab === "dropship" ? " active" : "");
    const promotedTabClass = "nav-link" + (activeTab === "promoted" ? " active" : "");
    const ComingSoon = () => <div><em>Promoted items customization options coming soon...</em></div>;
    const PanelComponent = activeTab === "dropship" ? DropShipItems : ComingSoon;

    return (
        <React.Fragment>
          <ul className="nav nav-tabs">
            <li className="nav-item pointer"
                onClick={onDropShipTabClick}>
              <a className={dropShipTabClass}>
                Dropship Items
              </a>
            </li>
            <li className="nav-item pointer"
                onClick={onPromotedTabClick}>
              <a className={promotedTabClass}>
                Promoted Items
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div role="tabpanel"
                 style={{paddingTop: "10px"}}
                 className="tab-pane active">
              <PanelComponent />
            </div>
          </div>
        </React.Fragment>
    );
};

let catalogTabsRoot = null;

function SonletCatalogTabsApp(el) {
    if (!el)
        return;
    if (catalogTabsRoot)
        catalogTabsRoot.unmount();
    catalogTabsRoot = createRoot(el);
    catalogTabsRoot.render(<SonletCatalogTabs />);
}

let promotedItemsRoot = null;

function SonletCatalogPromotedItemsApp(el) {
    if (promotedItemsRoot)
        promotedItemsRoot.unmount();
    promotedItemsRoot = createRoot(el);
    promotedItemsRoot.render(<PromotedItems />);
}

let dropShipRoot = null;

function SonletCatalogDropShipItemsApp(el) {
    if (dropShipRoot)
        dropShipRoot.unmount();
    dropShipRoot = createRoot(el);
    dropShipRoot.render(<DropShipItems />);
}

export {
    SonletCatalogTabsApp,
    SonletCatalogPromotedItemsApp,
    SonletCatalogDropShipItemsApp,
};
