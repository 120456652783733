import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import StrPartyAlbum from './StrPartyAlbum.jsx';


export default function AttrGroupedAlbums(props) {
    const { albumGroups, otherAlbums,
            groupByAttrName, filterAlbums } = props;
    const filteredOtherAlbums = filterAlbums(otherAlbums);
    return <div className="col-md-12">
      {Object.entries(albumGroups).map(([attrValue, albums]) => {
          const filteredAlbums = filterAlbums(albums);
          return <div className="row" key={attrValue}>
            {filteredAlbums.length > 0 &&
             <h2>{groupByAttrName}: {attrValue}</h2>
            }
            {filteredAlbums.map(album =>
                <StrPartyAlbum album={album}
                               key={album.url}
                               attrValue={attrValue}
                               isOther={false} />
            )}
          </div>;
      })}
      {filteredOtherAlbums.length > 0 &&
       <div className="row">
         <h2>{groupByAttrName}: Other</h2>
         {filteredOtherAlbums.map(album =>
             <StrPartyAlbum album={album}
                            key={album.url}
                            isOther={true} />
         )}
       </div>
      }
    </div>;
}

AttrGroupedAlbums.propTypes = {
    albumGroups: PropTypes.object,
    otherAlbums: PropTypes.array,
    groupByAttrName: PropTypes.string,
    filterAlbums: PropTypes.func,
};
