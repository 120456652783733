import React, { useState, useEffect } from 'react';
import SocialFeed from './SocialFeed.jsx';
import ScheduledPostList from "./ScheduledPostList.jsx";
import styles from "./css/SocialTimeline.css";

const SocialTimeline = () => {
    const [scheduledPosts, setScheduledPosts] = useState([]);
    const [activeTab, setActiveTab] = useState('feed');

    useEffect(() => {
        fetchScheduledPosts();
    }, []);

    const fetchScheduledPosts = () => {
        $.getJSON('/api/v2/social_scheduled_posts/').then((data) => {
            setScheduledPosts(data.results);
        });
    };

    const handleSelectTab = (e, tab) => {
        e.preventDefault();
        setActiveTab(tab);
    };

    return (
        <div>
          {/* Tab navigation */}
          <ul className="nav nav-tabs">
            <li className={activeTab === 'feed' ? 'active' : ''}>
              <a href="#feed" onClick={(e) => handleSelectTab(e, 'feed')}>Feed</a>
            </li>
            <li className={activeTab === 'scheduled' ? 'active' : ''}>
              <a href="#scheduled" onClick={(e) => handleSelectTab(e, 'scheduled')}>Scheduled</a>
            </li>
          </ul>

          {/* Tab panes */}
          <div className="tab-content">
            <div className={activeTab === 'feed' ? 'tab-pane active' : 'tab-pane'} id="feed">
              <SocialFeed doRefresh={fetchScheduledPosts} />
            </div>
            <div className={activeTab === 'scheduled' ? 'tab-pane active' : 'tab-pane'} id="scheduled">
              <ScheduledPostList scheduledPosts={scheduledPosts}
                                 doRefresh={fetchScheduledPosts} />
            </div>
          </div>
        </div>
    );
};

export default SocialTimeline;
