import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import insane from 'insane';


const renderer = new marked.Renderer();

/* Mostly lifted from the marked source. Adding img-responsive. */
renderer.image = function(href, title, text) {
    var out = '<img class="img-responsive" src="' + href + '" alt="' + text + '"';
    if (title) {
        out += ' title="' + title + '"';
    }
    out += this.options.xhtml ? '/>' : '>';
    return out;
};

class MarkdownView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const insaneOpts = {
            allowedTags: [
                "a", "article", "b", "blockquote", "br", "caption", "code", "del", "details", "div", "em", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "ins", "kbd", "li", "main", "ol", "p", "pre", "section", "span", "strike", "strong", "sub", "summary", "sup", "table", "tbody", "td", "th", "thead", "tr", "u", "ul", "nav",
            ],
            allowedClasses: {
                div: ['_udm'],
                img: ['img-responsive', 'img-rounded', 'img-circle', 'img-banner'],
                nav: ['menu', 'menu-white', 'menu-gray'],
                a: ['btn', 'btn-large', 'btn-small', 'btn-ghost', 'btn-light','btn-round', 'btn-lg', 'btn-sm', 'btn-xs', 'btn-color-pink', 'btn-color-green', 'btn-style-pill', 'btn-color-white', 'product', 'product_square', 'product_portrait', 'product_landscape'],
                section: ['collapse', 'well', 'promo', 'promo-lg', 'promo-green', 'promo-pink', 'promo-gray', 'spacer-25','spacer-50', 'spacer-100', 'columns', 'columns-2', 'columns-3', 'columns-4', 'menu_links', 'content', 'spaced', 'labels', 'rounded', 'align-center'],
                p: ['img-description', 'cursive', 'large', 'small'],
            },
            allowedAttributes: {
                'img': ['src', 'width', 'height'],
                'a': ['href','data-toggle', 'target', 'style'],
                'section': ['id', 'style'],
                'div': ['style'],
                'h1': ['style'],
                'h2': ['style'],
                'h3': ['style'],
                'h4': ['style'],
                'h5': ['style'],
                'h6': ['style'],
            },
        };
        const ipt = this.props.markdown || "";
        const markdown = insane(marked.parse(ipt, {renderer}), insaneOpts);
        return <div dangerouslySetInnerHTML={{__html: markdown}} />;
    }
}

MarkdownView.propTypes = {
    markdown: PropTypes.string,
};

export default MarkdownView;
