import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import LoadingSpinner from './LoadingSpinner.jsx';
import InventoryLevelAlertModal from './InventoryLevelAlertModal.jsx';

function InventoryLevelAlerts(props) {
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [createNewAlert, setCreateNewAlert] = useState(false);
    const [alertToEdit, setAlertToEdit] = useState(null);
    useEffect(fetch, []); // eslint-disable-line react-hooks/exhaustive-deps

    function fetch() {
        setLoading(true);
        const url = '/api/v2/inventory_level_alerts/with_quantities/';
        $.getJSON(url, function(data) {
            let quantityMap = {};
            data.quantity_infos.forEach(qi => {
                const styleKey = `${qi.itemchoice}`;
                styleKey in quantityMap || (quantityMap[styleKey] = 0);
                quantityMap[styleKey] += qi.quantity;
                quantityMap[`${qi.itemchoice}${qi.size}`] = qi.quantity;
            });
            setAlerts(data.alerts.map(alert => {
                const key = `${alert.itemchoice}${alert.size || ''}`;
                alert.inventory_quantity = quantityMap[key] || 0;
                return alert;
            }));
            setLoading(false);
            setFirstLoading(false);
        });
    }

    return <div>
      <div className="row">
        {firstLoading && <LoadingSpinner /> ||
         <div className="table-responsive">
           <table className="table">
             <caption>
               Receive a notification when your inventory level for a particular {StrUserInfo.words.style.toLowerCase()}/{StrUserInfo.words.size.toLowerCase()} drops below the threshold.
             </caption>
             <thead>
               <tr>
                 <th>
                   {StrUserInfo.words.style} / {StrUserInfo.words.size}
                 </th>
                 <th style={{textAlign: 'right'}}>Alert Threshold</th>
                 <th style={{textAlign: 'right'}}># On Hand</th>
                 <th></th>
                 <th></th>
               </tr>
             </thead>
             <tbody>
               {alerts.map(alert => {
                   const alerting = alert.inventory_quantity < alert.level;
                   return <tr key={alert.pk} className={alerting && 'danger' || ''}>
                     <td>
                       {alert.itemchoice_name}
                       {alert.size_name &&
                        <span>
                          {' '}
                          /
                          {' '}
                          {alert.size_name}
                        </span>}
                     </td>
                     <td style={{textAlign: 'right'}}>
                       {alert.level}
                     </td>
                     <td style={{textAlign: 'right'}}>
                       {alert.inventory_quantity}
                     </td>
                     <td>
                       {alerting &&
                        <span>
                          {' '}
                          <span className="label label-danger">!</span>
                        </span>
                       }
                     </td>
                     <td>
                       <button className="btn btn-link"
                               onClick={() => setAlertToEdit(alert)}>
                         edit
                       </button>
                     </td>
                   </tr>;
               })}
               {alerts.length === 0 &&
                <tr>
                  <td colSpan="5">No alerts created yet.</td>
                </tr>
               }
               <tr>
                 <td key="addnew" colSpan="5">
                   <button className="btn btn-default btn-sm"
                           onClick={() => setCreateNewAlert(true)}>
                     Add new alert
                   </button>
                 </td>
               </tr>
             </tbody>
           </table>
         </div>
        }
      </div>
      {createNewAlert &&
       <InventoryLevelAlertModal onClose={() => setCreateNewAlert(false)}
                                 callback={() => {
                                     setCreateNewAlert(false);
                                     fetch();
                                 }} />
      }
      {alertToEdit &&
       <InventoryLevelAlertModal onClose={() => setAlertToEdit(null)}
                                 existingAlert={alertToEdit}
                                 callback={() => {
                                     setAlertToEdit(null);
                                     fetch();
                                 }} />
      }
    </div>;
}

export default function InventoryLevelAlertsApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InventoryLevelAlerts />);
}
