import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import CreatableSelect from 'react-select/creatable';

import { fetchJSON, strRequest } from './util.jsx';

const PartyTagger = (props) => {
    const [options, setOptions] = useState([]);
    const [tags, setTags] = useState(props.initialTags || []);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const { partyPk } = props;
    const apiUrlByParty = useMemo(() => `/api/v2/party_tags/${partyPk}/`, [partyPk]);
    const apiUrl = "/api/v2/party_tags/";

    const fetchPartyTags = async () => {
        const params = {action: "list"};
        const data = await fetchJSON(apiUrlByParty, null, params);
        setTags(data.tags);
    };

    const fetchOptions = async (inputValue = null) => {
        setLoading(true);
        const params = inputValue ? { q: inputValue } : {};
        const response = await fetchJSON(apiUrl, null, params);
        setLoading(false);
        const results = response.tags.map(tag => ({label: `#${tag}`, value: tag}));
        setOptions(results);
    };

    const initiateEdit = () => {
        setEditing(true);
        fetchOptions();
    };

    const addTag = async (inputValue) => {
        let tagname = typeof inputValue === 'object' ? inputValue.value : inputValue;
        tagname = tagname.startsWith("#") ? tagname.substring(1) : tagname; // Remove # if present
        const tagnameNormalized = tagname.toLowerCase();
        if (tags.map(t => t.toLowerCase()).includes(tagnameNormalized)) {
            toast.info(`Can't add tag #${tagname} since it's already present`);
            return;
        }
        const data = {
            action: "add",
            tag: tagname,
        };
        const rsp = await strRequest(apiUrlByParty, "POST", data);
        if (!rsp.ok) {
            const jsonRsp = await rsp.json();
            const msg = (jsonRsp && jsonRsp.message) || rsp.statusText;
            toast.error(`Error saving: ${msg}`);
        } else {
            toast.success(`Added tag ${tagname}`);
            fetchPartyTags();
            fetchOptions();
        }
    };

    const removeTag = async (tagname) => {
        const data = {
            action: "remove",
            tag: tagname,
        };
        const rsp = await strRequest(apiUrlByParty, "POST", data);
        if (!rsp.ok) {
            toast.error(`Error deleting tag: ${rsp.statusText}`);
        } else {
            toast.success(`Removed tag ${tagname}`);
            fetchPartyTags();
        }
    };

    return (
        <div className="inhibitStrTableSelect"
             style={{display: editing ? "block" : "inline-block"}}>
          {editing &&
           <div style={{margin: "10px", zIndex: "10"}}>
             {/* have to set zIndex because bootstrap 3 input group last button gets zindex of 2 */}
             <CreatableSelect
                 value={null}
                 options={options}
                 loading={loading}
                 onChange={addTag}
                 placeholder="Type to search tags..."
                 isClearable
                 autoFocus
                 onCreateOption={addTag}
                 allowCreateWhileLoading={true}
                 formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                 menuPortalTarget={document.body}
                 styles={{ menuPortal: base => ({ ...base, zIndex: 99}) }}
             />
           </div>
          }
          {tags.map(tag => {
              if (editing) {
                  return (
                      <div key={tag}
                           className="input-group input-group-sm"
                           style={{margin: "0 5px 5px"}}>
                        <span className="input-group-btn">
                          <div className="btn btn-default">#{tag}</div>
                        </span>
                        <span className="input-group-btn">
                          <button className="btn btn-danger"
                                  onClick={() => removeTag(tag)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        </span>
                      </div>
                  );
              }
              return (
                  <div key={tag}
                       style={{
                           display: "inline-block",
                           marginRight: "5px",
                       }}
                       className="text-muted text-small">
                    <a href={`/party/?tag=${tag}`}
                       style={{color: "#777"}}>#{tag}</a>
                  </div>
              );
          })}
          {!editing &&
           <button className="btn btn-link"
                   style={{padding: "0", fontSize: "13px"}}
                   onClick={initiateEdit}>Edit tags</button>
          }
          {editing &&
           <div>
             <button className="btn btn-default btn-sm"
                     onClick={() => setEditing(false)}>Done editing</button>
           </div>
          }
        </div>
    );
};

PartyTagger.propTypes = {
    partyPk: PropTypes.number.isRequired,
    initialTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function PartyTaggerApp(el, partyPk, initialTags) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<PartyTagger partyPk={partyPk}
                             initialTags={initialTags} />);
}

export { PartyTagger, PartyTaggerApp };
