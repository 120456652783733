import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { isEqual, flatten } from 'lodash-es';

import Select from 'react-select';
import { strRequestJSON, updateStateThing, getUUID } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import AttrPicker from './AttrPicker.jsx';
import PartyAlbumTargeter from './PartyAlbumTargeter.jsx';

const getLabel = (partyOption) => {
    const { party } = partyOption;
    return (
        <div>
          {party.thumbnail &&
           <img src={party.thumbnail}
                style={{
                    maxWidth: '50px',
                    display: 'inline-block',
                    marginRight: '10px',
                    verticalAlign: 'middle',
                }} />
          }
          <span style={{verticalAlign: 'middle'}}>
            {party.name}
          </span>
        </div>
    );
};

function genPartyOptions(parties) {
    function partyOption(party) {
        return {
            value: party.pk,
            label: party.name,
            party: party,
        };
    }
    return parties.map(partyOption);
}

class SmartAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            selectedParty: null,
            isAdmin: false,
            filters: [],
            items: [],
            styleRowData: [], // spec is below
            selectedStyleSizes: [], // ["stylePk-sizePk", ...]
            selectedStyles: [], // ["stylePk", ...] this is for selectAllStyle to work well
            selectedAll: false,
            albums: [],
            preventAutoAlbums: false,
            showStyleAlbums: false,
            orderByAge: false,
        };

        this.updateStateThing = updateStateThing.bind(this);
        this.partySelected = this.partySelected.bind(this);
        this.fetch = this.fetch.bind(this);
        this.filterAddClick = this.filterAddClick.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.filterAndPrepItems = this.filterAndPrepItems.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
        this.renderItemsTable = this.renderItemsTable.bind(this);
        this.clickSize = this.clickSize.bind(this);
        this.clickSelectAllStyle = this.clickSelectAllStyle.bind(this);
        this.clickSelectAll = this.clickSelectAll.bind(this);
        this.clickAddAll = this.clickAddAll.bind(this);
        this.albumTargeterRefs = {};
        this.partyOptions = genPartyOptions(this.props.parties);
    }

    async fetch() {
        this.setState({loading: true});
        const { selectedParty, orderByAge } = this.state;
        if (!selectedParty)
            return;
        const url = `/api/v2/listings_smartadd/${selectedParty.value}/`;
        let postData = {};
        if (orderByAge) {
            postData['order_by'] = 'age';
        }
        const data = await strRequestJSON(url, 'POST', postData);
        this.setState({
            items: data.items,
            loading: false,
            saving: false,
            isAdmin: data.is_admin,
            albums: data.albums,
            preventAutoAlbums: data.prevent_auto_albums,
        }, this.filterAndPrepItems);
    }

    partySelected(event) {
        this.setState({
            selectedParty: event,
            selectedStyleSizes: [],
            selectedStyles: [],
            selectedAll: false,
        }, this.fetch);
    }

    filterAddClick() {
        this.setState({
            filters: update(this.state.filters, {
                $push: [{
                    name: '',
                    value: '',
                    mode: 'include',
                    key: getUUID(),
                }],
            }),
        });
    }

    onFilterChange(value, filterKey, keyToSet) {
        const idx = this.state.filters.findIndex(f => f.key === filterKey);
        const filter = update(this.state.filters[idx], {
            $merge: {
                [keyToSet]: value,
            },
        });
        // do item filtering
        this.setState({
            filters: update(this.state.filters, {
                $splice: [[idx, 1, filter]],
            }),
            selectedStyleSizes: [],
            selectedStyles: [],
            selectedAll: false,
        }, this.filterAndPrepItems);
    }

    filterAndPrepItems() {
        const { items, filters } = this.state;
        let styleRowData = [];
        const filteredItems = items.filter(item => {
            const itemAttrs = item.data && item.data.attributes
                            ? item.data.attributes : {};
            return filters.every(filter => {
                const hasName = filter.name === ""
                             || Object.keys(itemAttrs)
                                      .indexOf(filter.name) !== -1;
                const hasValue = filter.value === ""
                              || itemAttrs[filter.name] === filter.value
                              || !filter.value;
                if (filter.mode === 'include') {
                    return hasName && hasValue;
                } else if (filter.mode === 'exclude') {
                    return !hasName || !hasValue;
                }
            });
        });
        // styleRowData spec
        // [
        //     {
        //         pk: stylePk,
        //         name: 'Style name',
        //         sizes: [
        //             {
        //                 pk: sizePk,
        //                 name: 'some size',
        //                 itemPks: [123,123,123],
        //                 quantity: 123,
        //             },
        //             {
        //                 pk: sizePk,
        //                 name: 'another size',
        //                 itemPks: [123,123,123],
        //                 quantity: 123,
        //             },
        //         ],
        //     },
        // ]
        filteredItems.forEach(item => {
            let index = styleRowData.map(srd => srd.pk)
                                    .indexOf(item.itemchoice_pk);
            if (index === -1) {
                styleRowData.push({
                    pk: item.itemchoice_pk,
                    name: item.itemchoice,
                    sizes: [],
                });
                index = styleRowData.length - 1;
            }
            let sizeIndex = styleRowData[index]['sizes']
                .map(size => size.pk)
                .indexOf(item.size_pk);
            if (sizeIndex === -1) {
                styleRowData[index]['sizes'].push({
                    pk: item.size_pk,
                    name: item.size,
                    itemPks: [],
                    quantity: 0,
                });
                sizeIndex = styleRowData[index]['sizes'].length - 1;
            }
            styleRowData[index]['sizes'][sizeIndex]['itemPks'].push(item.pk);
            styleRowData[index]['sizes'][sizeIndex]['quantity'] += item.quantity;
        });
        this.setState({styleRowData});
    }

    onRemoveClick(filterKey) {
        const idx = this.state.filters.findIndex(f => f.key === filterKey);
        this.setState({
            filters: update(this.state.filters, {
                $splice: [[idx, 1]],
            }),
            selectedStyleSizes: [],
            selectedStyles: [],
            selectedAll: false,
        }, this.filterAndPrepItems);
    }

    renderFilters() {
        return this.state.filters.map(filter => {
            return (
                <div key={filter.key}
                     style={{marginBottom: '5px'}}
                     className="row">
                  <div className="col-md-8">
                    <AttrPicker creatable={false}
                                showClearable={true}
                                onChangeName={data => this.onFilterChange(
                                    data ? data.value : "",
                                    filter.key,
                                    "name",
                                )}
                                onChangeValue={data => this.onFilterChange(
                                    data ? data.value : "",
                                    filter.key,
                                    "value",
                                )} />
                  </div>
                  <div className="col-md-4"
                       style={{position: "relative", right: "30px"}}>
                    <label className="radio-inline">
                      <input type="radio"
                             value="include"
                             checked={filter.mode === "include"}
                             onChange={e => this.onFilterChange(
                                 e.target.value,
                                 filter.key,
                                 "mode",
                             )} /> Include
                    </label>
                    <label className="radio-inline">
                      <input type="radio"
                             value="exclude"
                             checked={filter.mode === "exclude"}
                             onChange={e => this.onFilterChange(
                                 e.target.value,
                                 filter.key,
                                 "mode",
                             )} /> Exclude
                    </label>
                    <span onClick={this.onRemoveClick.bind(this, filter.key)}
                          style={{marginLeft: "5px"}}
                          type="button"
                          className="btn btn-default btn-xs">
                      <span className="glyphicon glyphicon-remove"></span>
                    </span>
                  </div>
                </div>
            );
        });
    }

    clickSize(stylePk, sizePk) {
        const { selectedStyles, selectedStyleSizes } = this.state;
        const styleSizeKey = `${stylePk}-${sizePk}`;
        if (selectedStyleSizes.indexOf(styleSizeKey) === -1 ) {
            // select
            this.setState(prevState => {
                const selectedStyleSizes = prevState.selectedStyleSizes
                                                    .concat([styleSizeKey]);
                let selectedStyles = prevState.selectedStyles;
                // check if all of the sizes are now selected
                const selectedSizePks = selectedStyleSizes
                    .filter(sspk => Number(sspk.split('-')[0]) === stylePk)
                    .map(sspk => Number(sspk.split('-')[1]));
                const sizePks = prevState.styleRowData
                                         .find(style => style.pk === stylePk)
                                         .sizes
                                         .map(size => size.pk);
                if (isEqual(selectedSizePks.sort(), sizePks.sort())) {
                    // select the Select All button for this style
                    selectedStyles = prevState.selectedStyles.concat([stylePk]);
                }
                return {
                    selectedStyles: selectedStyles,
                    selectedStyleSizes: selectedStyleSizes,
                };
            });
        } else {
            // un-select
            this.setState(prevState => ({
                selectedStyles: prevState.selectedStyles
                                         .filter(k => k !== stylePk),
                selectedStyleSizes: prevState.selectedStyleSizes
                                             .filter(k => k !== styleSizeKey),
                selectedAll: false,
            }));
        }
        /* fix for button staying active
         * https://github.com/react-bootstrap/react-bootstrap/issues/1300#issuecomment-280792221 */
        document.activeElement.blur();
    }

    clickSelectAllStyle(stylePk) {
        const { selectedStyles, selectedStyleSizes } = this.state;
        if (selectedStyles.indexOf(stylePk) === -1 ) {
            /* loop through styleRowData adding the style-size keys to
             * selectedStyleSizes */
            let keysToAdd = [];
            this.state.styleRowData
                .filter(style => style.pk === stylePk)
                .forEach(style => {
                    style.sizes.forEach(size => {
                        const key = `${style.pk}-${size.pk}`;
                        keysToAdd.push(key);
                    });
                });
            this.setState(prevState => ({
                selectedStyles: prevState.selectedStyles.concat([stylePk]),
                selectedStyleSizes: prevState.selectedStyleSizes
                                             .concat(keysToAdd),
            }));
        } else {
            this.setState(prevState => ({
                selectedStyles: prevState.selectedStyles
                                         .filter(k => k !== stylePk),
                selectedStyleSizes: prevState.selectedStyleSizes.filter(
                    k => !k.startsWith("" + stylePk)),
                selectedAll: false,
            }));
        }
    }

    clickSelectAll() {
        if (this.state.selectedAll) {
            // deselect everything
            this.setState(prevState => ({
                selectedStyles: [],
                selectedStyleSizes: [],
                selectedAll: false,
            }));
        } else {
            // select everything
            this.setState(prevState => {
                const selectedStyleSizes = flatten(
                    prevState.styleRowData.map(style => {
                        return style.sizes.map(
                            size => `${style.pk}-${size.pk}`
                        );
                    })
                );
                return {
                    selectedStyles: prevState.styleRowData
                                             .map(style => style.pk),
                    selectedStyleSizes: selectedStyleSizes,
                    selectedAll: true,
                };
            });
        }
    }

    clickAddAll() {
        this.setState({loading: true, saving: true});
        Promise.all(
            Object.values(this.albumTargeterRefs).map(
                albumTargeter => albumTargeter.addItems()
            )
        ).then((results) => {
            this.fetch();
            const addedItems = results.filter(res => !!res && res.success_count > 0)
                                      .reduce(
                                          (acc, res) => acc + res.success_count,
                                          0,
                                      );
            toast.success(`Added ${addedItems} item${addedItems === 1 ? '' : 's'}`);
            Object.values(this.albumTargeterRefs).map(
                albumTargeter => albumTargeter.setSaving(false)
            );
        });
    }

    renderItemsTable() {
        const { styleRowData, selectedStyles, selectedStyleSizes,
                isAdmin, selectedParty, albums, selectedAll,
                preventAutoAlbums, loading, showStyleAlbums, saving,
                orderByAge } = this.state;
        let totalCount = 0;
        let totalSelectedCount = 0;
        const styleRows = styleRowData.map(
            styleInfo => {
                let itemPks = [];
                let styleSelectedCount = 0;
                const sizes = styleInfo.sizes.map(
                    sizeInfo => {
                        const styleSizeKey = `${styleInfo.pk}-${sizeInfo.pk}`;
                        const selected = selectedStyles.indexOf(styleInfo.pk) !== -1
                                      || selectedStyleSizes
                                          .indexOf(styleSizeKey) !== -1;
                        const btnCls = selected ? '' : 'btn-outline';
                        const checkCls = selected ? 'check' : 'unchecked';
                        totalCount += sizeInfo.quantity;
                        if (selected) {
                            itemPks = itemPks.concat(sizeInfo.itemPks);
                            styleSelectedCount += sizeInfo.quantity;
                            totalSelectedCount += sizeInfo.quantity;
                        }
                        return (
                            <button
                                key={sizeInfo.pk}
                                style={{margin: '0px 5px 5px 0px'}}
                                onClick={() => this.clickSize(styleInfo.pk, sizeInfo.pk)}
                                className={`btn btn-primary btn-sm ${btnCls}`}>
                              <span className={`glyphicon glyphicon-${checkCls}`}>
                              </span>
                              {' '}
                              {sizeInfo.name} ({sizeInfo.quantity})
                            </button>
                        );
                    }
                );
                const selectVerb = selectedStyles.indexOf(styleInfo.pk) !== -1
                                 ? 'de-select' : 'select';
                return (
                    <tr key={styleInfo.pk}>
                      <td>
                        {styleInfo.name}
                        <br />
                        <button className="btn btn-link btn-xs"
                                onClick={() =>
                                    this.clickSelectAllStyle(styleInfo.pk)}>
                          ({selectVerb} all)
                        </button>
                      </td>
                      <td>{sizes}</td>
                      <td>
                        <PartyAlbumTargeter
                            ref={addButton => {
                                if (addButton) {
                                    this.albumTargeterRefs[styleInfo.pk] = addButton;
                                }
                            }}
                            partyPk={selectedParty.value}
                            partyName={selectedParty.label}
                            albums={albums}
                            itemPks={itemPks}
                            quantity={styleSelectedCount}
                            targetAlbumName={styleInfo.name}
                            isAdmin={isAdmin}
                            preventAutoAlbums={preventAutoAlbums}
                            showStyleAlbums={showStyleAlbums}
                            callback={this.fetch} />
                      </td>
                    </tr>
                );
            }
        );
        const countString = totalCount > 0 ? ` (${totalCount})` : '';
        const selectAllVerb = selectedAll ? 'De-select' : 'Select';
        const selectedCountString = totalSelectedCount > 0 ? ` (${totalSelectedCount})` : '';
        const addAllContent = saving
                            ? <span>
                              <LoadingSpinner /> Adding all selected...
                            </span>
                            : `Add all selected${selectedCountString}`;
        return (
            <div>
              <button className="btn btn-default"
                      onClick={this.clickSelectAll}>
                {selectAllVerb} all{countString}
              </button>
              <button className="btn btn-default"
                      disabled={loading || saving || selectedStyleSizes.length === 0}
                      style={{marginLeft: '15px'}}
                      onClick={this.clickAddAll}>
                {addAllContent}
              </button>
              <label style={{marginLeft: '15px', fontWeight: 'normal'}}>
                <input type="checkbox"
                       disabled={loading}
                       checked={showStyleAlbums}
                       name="showStyleAlbums"
                       onChange={this.updateStateThing} />
                <span style={{marginLeft: '5px'}}>
                  Show auto {StrUserInfo.words.style.toLowerCase()} albums
                </span>
              </label>
              <label style={{marginLeft: '15px', fontWeight: 'normal'}}>
                <input type="checkbox"
                       disabled={loading}
                       checked={orderByAge}
                       name="orderByAge"
                       onChange={e => this.updateStateThing(e, this.fetch)} />
                <span style={{marginLeft: '5px'}}>
                  Newest {StrUserInfo.words.style.toLowerCase()}/{StrUserInfo.words.size.toLowerCase()} first
                </span>
              </label>
              <table className="table">
                <thead>
                  <tr>
                    <th>{StrUserInfo.words.style}</th>
                    <th>{StrUserInfo.words.sizes}</th>
                    <th>Target Album</th>
                  </tr>
                </thead>
                <tbody>
                  {styleRows.length > 0 && styleRows
                  ||
                   <tr>
                     <td>
                       Looks like all your available items are already in
                       that
                       {' '}
                       {StrUserInfo.words.party.toLowerCase()}. Try
                       using less filters or picking a different
                       {' '}
                       {StrUserInfo.words.party.toLowerCase()}.
                     </td>
                   </tr>}
                </tbody>
              </table>
            </div>
        );
    }

    render() {
        const { selectedParty, items, loading } = this.state;
        return (
            <div>
              <h3>Step 1: Pick a {StrUserInfo.words.party}</h3>
              <div className="row">
                <div className="col-md-5">
                  <Select options={this.partyOptions}
                          getOptionLabel={getLabel}
                          getOptionValue={o => o.label}
                          isClearable={false}
                          isDisabled={loading}
                          placeholder={`Pick a ${StrUserInfo.words.party.toLowerCase()}`}
                          value={selectedParty}
                          onChange={this.partySelected}
                  />
                </div>
              </div>

              {selectedParty &&
               <div>
                 <h3>Step 2: Filter by Attributes (optional)</h3>
                 {this.renderFilters()}
                 <button type="button"
                         className="btn btn-default"
                         style={{marginTop: "5px"}}
                         onClick={this.filterAddClick}>
                   <i className="glyphicon glyphicon-plus"></i> Add filter
                 </button>

                 <h3>Step 3: Add Items to {selectedParty.label}</h3>
                 {items.length > 0
                 && this.renderItemsTable()
                 || <p>
                   Looks like all your available items are already in
                   that
                   {' '}
                   {StrUserInfo.words.party.toLowerCase()}.
                 </p>
                 }
               </div>
              }
            </div>
        );
    }
}

SmartAdd.propTypes = {
    parties: PropTypes.array.isRequired,
};

function SmartAddApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SmartAdd parties={data.parties} />);
}

export default SmartAddApp;
