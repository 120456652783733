// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ripped off from bootstrap */
.BioContainer table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.BioContainer table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.BioContainer table>tbody>tr>td,
.BioContainer table>tbody>tr>th,
.BioContainer table>tfoot>tr>td,
.BioContainer table>tfoot>tr>th,
.BioContainer table>thead>tr>td,
.BioContainer table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}
/* end bootstrap ripoff */
`, "",{"version":3,"sources":["webpack://./social/css/UserBio.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;;;;;;IAMI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;AAC9B;AACA,yBAAyB","sourcesContent":["/* ripped off from bootstrap */\n.BioContainer table>thead:first-child>tr:first-child>th {\n    border-top: 0;\n}\n\n.BioContainer table>thead>tr>th {\n    vertical-align: bottom;\n    border-bottom: 2px solid #ddd;\n}\n\n.BioContainer table>tbody>tr>td,\n.BioContainer table>tbody>tr>th,\n.BioContainer table>tfoot>tr>td,\n.BioContainer table>tfoot>tr>th,\n.BioContainer table>thead>tr>td,\n.BioContainer table>thead>tr>th {\n    padding: 8px;\n    line-height: 1.42857143;\n    vertical-align: top;\n    border-top: 1px solid #ddd;\n}\n/* end bootstrap ripoff */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
