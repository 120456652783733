import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer } from '../StrModal.jsx';


export default class UserProfileChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingModal: false,
            error: null,
            saving: false,
            newProfilePhoto: null,
        };
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onClose() {
        if (this.state.saving)
            return;
        this.setState({
            isShowingModal: false,
            newProfilePhoto: null,
            error: null,
        });
    }

    onChange() {
        let image = document.createElement('img');
        image.src = window.URL.createObjectURL(this.fileInput.files[0]);
        this.setState({
            newProfilePhoto: image.src,
            error: null,
        });
    }

    onSave() {
        this.setState({saving: true});
        const self = this;
        let formData = new FormData();
        formData.append('profile_photo',
                        this.fileInput.files[0],
                        this.fileInput.files[0].name);
        $.ajax({
            url: `/api/v2/uniuser/${StrUserInfo.uuid}/`,
            method: "PATCH",
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            complete: (xhr, status) => {
                if (status === 'success') {
                    this.props.callback();
                    this.setState({
                        saving: false,
                        isShowingModal: false,
                    });
                } else {
                    this.setState({
                        saving: false,
                        error: "Something went wrong, please try again",
                    });
                }
            },
        });
    }

    render() {
        const buttonText = this.state.saving ? "Saving..." : "Save";
        const changeBtnClasses = this.props.bs5
                               ? "btn btn-outline-secondary btn-xs"
                               : "btn btn-default btn-xs";
        const chooseBtnClasses = this.props.bs5
                               ? "btn btn-secondary"
                               : "btn btn-default";
        const changeText = this.props.minimal ? "Edit" : "Change profile picture";
        return (
            <div className="text-center">
              <button className={changeBtnClasses}
                      onClick={() => {this.setState({isShowingModal:true});}}>
                {changeText} <i className="fa fa-picture-o"></i>
              </button>
              <ModalContainer onClose={this.onClose} className="text-center" isOpen={this.state.isShowingModal}>
                <img className="SocialProfileImage"
                     src={this.state.newProfilePhoto || this.props.profilePhoto} />
                <div className="form-group">
                  <label className={chooseBtnClasses}
                         disabled={this.state.saving}
                         htmlFor="image_upload">
                    Choose image
                  </label>
                  <input type="file" id="image_upload"
                         name="image_upload"
                         style={{display: "none"}}
                         onChange={this.onChange}
                         ref={input => {this.fileInput = input;}}
                         accept=".jpg, .jpeg, .png, .bmp, .gif" />
                  {this.state.error &&
                   <p className="alert alert-danger"
                      style={{marginTop: '10px'}}>{this.state.error}</p>
                  }
                </div>
                {this.state.newProfilePhoto &&
                 <button type="button"
                         className="btn btn-primary"
                         disabled={this.state.saving}
                         onClick={this.onSave}>
                   {buttonText}
                 </button>
                }
              </ModalContainer>
            </div>
        );
    }
}

UserProfileChange.defaultProps = {
    bs5: false,
    minimal: false,
};

UserProfileChange.propTypes = {
    callback: PropTypes.func.isRequired,
    profilePhoto: PropTypes.string.isRequired,
    bs5: PropTypes.bool,
    minimal: PropTypes.bool,
};
