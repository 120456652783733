import React from 'react';
import PropTypes from 'prop-types';

class DefaultCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
              <tt>Debug: {this.props.activity.id}</tt>
            </div>
        );
    }
}

DefaultCard.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default DefaultCard;
