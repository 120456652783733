import React from 'react';
import { createRoot } from 'react-dom/client';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'style-loader!css-loader!react-toastify/dist/ReactToastify.css';


class Toastie extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <ToastContainer {...this.props} transition={Slide} />;
    }
}

export default (() => {
    /*
     * defaultToastOpts: https://github.com/fkhadra/react-toastify#toastcontainer
     */
    toast.init = (defaultToastOpts) => {
        const toastDiv = document.createElement('div');
        toastDiv.id = 'id_ToastContainer';
        document.getElementsByTagName('body')[0].appendChild(toastDiv);
        const opts = defaultToastOpts || {};
        const root = createRoot(toastDiv);
        root.render(<Toastie {...opts} />);
    };

    /*
     * So that our legacy js friends can supply custom html strings.
     *
     * onClick: Optional click handler. Caller should set closeOnClick=false
     *          when calling the actual .toast() function in order for this
     *          handler to actually run.
     */
    toast.prepHtmlString = (html, onClick) => {
        const opts = {};
        if (onClick)
            opts['onClick'] = onClick;
        return <div {...opts} dangerouslySetInnerHTML={{__html: html}} />;
    };
    toast.prepHtmlString.displayName = 'prepHtmlString';

    return toast;
})();
