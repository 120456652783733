import React from 'react';
import { createRoot } from 'react-dom/client';
import OrderPicker from './OrderPicker.jsx';


function OrderPickerApp(el, props) {
    if (el === null)
        return;
    props = props || {};
    const root = createRoot(el);
    root.render(<OrderPicker {...props} />);
}

export default OrderPickerApp;
