import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import MarkdownEditor from './MarkdownEditor.jsx';
import MarkdownView from './MarkdownView.jsx';
import { fetchJSON, strRequestJSONWithResponse } from './util.jsx';
import { isCodemode } from './codeMode.js';
import LoadingSpinner from './LoadingSpinner.jsx';

export class PartyAnnouncement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            saving: false,
            announcement: '',
            announcementIsMarkdown: false,
            announcementIsCodemode: false,
        };

        this.fetch = this.fetch.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.url = `/api/v2/${this.props.apiPath}/${this.props.publicId}/`;
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.publicId !== this.props.publicId) {
            this.fetch();
        }
    }

    async fetch() {
        const data = await fetchJSON(this.url);
        this.setState({
            announcement: data.announcement,
            announcementIsMarkdown: !!data.announcement_is_markdown,
            announcementIsCodemode: isCodemode(data.announcement),
        });
    }

    onSave(markdown) {
        this.setState({saving: true, announcement: markdown});
        const data = {announcementMarkdown: markdown};
        strRequestJSONWithResponse(this.url, "POST", data).then(
            ([json, rsp]) => {
                if (rsp.ok) {
                    this.setState({
                        saving: false,
                        announcementIsMarkdown: true,
                        editing: false,
                        announcementIsCodemode: isCodemode(
                            markdown
                        ),
                    });
                    toast.success('Announcement updated');
                } else {
                    this.setState({saving: false});
                    toast.error('Unable to update announcement');
                }
            }
        );
    }

    onCancel() {
        this.setState({
            editing: false,
        });
    }

    render() {
        const { announcement, announcementIsMarkdown,
                editing, saving, announcementIsCodemode } = this.state;
        const btnText = saving ?
                        <span>
                          <LoadingSpinner />
                          {' '}
                          Saving
                          ...
                        </span>
                      : 'Save';
        const wellClass = announcementIsCodemode ? '' : 'well well-sm';
        return (
            <div>
              {!editing && announcement &&
               <div className={wellClass}>
                 {!announcementIsCodemode &&
                  <h6>{this.props.partyWord} Announcement</h6>
                 }
                 {announcementIsMarkdown &&
                  <MarkdownView markdown={announcement} />
                 ||
                  <div>{announcement}</div>
                 }
               </div>
              }
              {editing &&
               <div>
                 <MarkdownEditor markdown={announcement}
                                 onSave={this.onSave}
                                 onCancel={this.onCancel}
                                 source="party-announcement"
                                 sourceData={{ publicId: this.props.publicId }}
                 />
               </div>
              }
              {this.props.showEditControls && !editing &&
               <div>
                 <button className="btn btn-default btn-xs"
                         onClick={() => this.setState({editing: true})}>
                   <i className="fa fa-bullhorn"
                      style={{color: "#e6292f"}}
                      aria-hidden="true"></i>
                   {' '}
                   Edit {this.props.partyWord} Announcement
                 </button>
               </div>
              }
            </div>
        );
    }
}

PartyAnnouncement.defaultProps = {
    partyWord: "Party",
    apiPath: "party_announcement",
};

PartyAnnouncement.propTypes = {
    showEditControls: PropTypes.bool.isRequired,
    publicId: PropTypes.number.isRequired,
    partyWord: PropTypes.string,
    apiPath: PropTypes.string,
};

let root = null;

function PartyAnnouncementApp(el, props) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<PartyAnnouncement {...props} />);
}

export default PartyAnnouncementApp;
