import React from 'react';
import PropTypes from 'prop-types';
import {strRequest} from "../util.jsx";
import LoadingSpinner from '../LoadingSpinner.jsx';
import { ModalContainer } from '../StrModal.jsx';

class RewardProgramPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardsPrograms: [],
            loading: true,
        };
        this.onProgramClick = this.onProgramClick.bind(this);
        this.rewardsProgram = this.rewardsProgram.bind(this);
        this.getRewardsPrograms = this.getRewardsPrograms.bind(this);
    }

    async componentDidMount() {
        this.getRewardsPrograms()
            .then(res => res.json())
            .then(json => json.results)
            .then(rewardsPrograms => {
                this.setState({
                    rewardsPrograms: rewardsPrograms,
                    loading: false,
                });
            });
    }

    async getRewardsPrograms() {
        const url = "/api/v2/reward_programs/";
        return await strRequest(url, 'GET');
    }

    render() {
        return (
            <ModalContainer onClose={this.props.onClose} isOpen={true}>
              <div>
                <h4>Insert a program link</h4>
                <div>
                  {this.state.loading &&
                   <div>Loading your rewards... <LoadingSpinner /></div> ||
                   this.state.rewardsPrograms.map(this.rewardsProgram)
                  }
                </div>
              </div>
            </ModalContainer>
        );
    }

    onProgramClick(program) {
        this.props.selectCallback(
            `${window.location.origin}${program.join_link}`);
    }

    rewardsProgram(program) {
        return (
            <div key={program.pk}
                 onClick={() => this.onProgramClick(program)}
                 style={{cursor: "pointer"}}>
              <h3 className="rewardsTitle">{program.name}</h3>
              <p>{program.description}</p>
              <hr />
            </div>
        );
    }
}

RewardProgramPicker.propTypes = {
    selectCallback: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RewardProgramPicker;
