import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import InjectedAchSetup from './AchSetup.jsx';

const stripePromise = loadStripe(stripe_pub_api_key);

const Ach = () => {
    return (
        <Elements stripe={stripePromise}>
          <InjectedAchSetup />
        </Elements>
    );
};

export default function AchSetupApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<Ach />);
}
