import React from 'react';
import PropTypes from 'prop-types';

import { ElementsConsumer } from '@stripe/react-stripe-js';
import { fetchJSON, updateStateThing } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

class AchSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            depositsVerified: null,
            bankInfo: {},
            routingNumber: '',
            accountNumber: '',
            name: '',
            holderType: 'individual',
            amt1: '',
            amt2: '',
            loading: null,
            errorMessage: '',
            showBankSetupForm: false,
        };

        this.fetch = this.fetch.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.submitBankInfo = this.submitBankInfo.bind(this);
        this.submitDeposits = this.submitDeposits.bind(this);
        this.setupUrl = '/api/v2/ach_setup/';
        this.removeBankAccount = this.removeBankAccount.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch () {
        const data = await fetchJSON(this.setupUrl);
        this.setState({
            depositsVerified: data.deposits_verified,
            bankInfo: data.bank_info,
            loading: false,
            errorMessage: '',
        });
    }

    submitDeposits(e) {
        e.preventDefault();
        this.setState({loading: true, errorMessage: ''});
        const { amt1, amt2 } = this.state;
        if (!amt1 || !amt2) {
            return this.setState({
                errorMessage: "Please enter both deposit amounts",
                loading: false,
            });
        }
        const data = {
            achAction: 'verify',
            amt1: amt1,
            amt2: amt2,
        };
        $.post(this.setupUrl, data, async () => {
            /* awaiting the fetch so that it always finishes before doing
             * our callback, the callback can unmount us so we dont want
             * to leave the fetch dangling */
            await this.fetch();
            if (this.props.callback) {
                this.props.callback();
            }
        })
         .fail((rsp) => {
             this.setState({
                 errorMessage: `Error: ${JSON.stringify(rsp.responseJSON)}`,
                 loading: false,
             });
         });
    }

    renderDepositsForm() {
        return (
            <form className="form-horizontal">
              <span id="helpBlock" className="help-block">
                <p>
                  Verification deposits should appear in your bank
                  account within 2 business days of entering bank
                  account information. Please enter them here.
                </p>
                <p>
                  Note, there is a limit of 10 failed verification
                  attempts. Once this limit has been crossed, the bank
                  account will be unable to be verified.
                </p>
              </span>

              <div className="form-group">
                <label htmlFor="routing-number"
                       className="col-sm-4 control-label">
                  Amount #1
                </label>
                <div className="col-sm-5">
                  <input className="form-control"
                         type="text"
                         name="amt1"
                         onChange={this.updateStateThing}
                         value={this.state.amt1}
                         disabled={this.state.loading}
                         placeholder="0.12" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="routing-number"
                       className="col-sm-4 control-label">
                  Amount #2
                </label>
                <div className="col-sm-5">
                  <input className="form-control"
                         type="text"
                         name="amt2"
                         onChange={this.updateStateThing}
                         value={this.state.amt2}
                         disabled={this.state.loading}
                         placeholder="0.34" />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-8">
                  <button className="btn btn-primary"
                          onClick={this.submitDeposits}
                          disabled={this.state.loading}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
        );
    }

    async submitBankInfo(e) {
        e.preventDefault();
        this.setState({loading: true, erorrMessage: ''});
        const bankAccountParams = {
            country: 'US',
            currency: 'usd',
            account_number: this.state.accountNumber,
            account_holder_name: this.state.name,
            account_holder_type: this.state.holderType,
            routing_number: this.state.routingNumber,
        };
        const tokenRsp = await this.props.stripe.createToken(
            'bank_account',
            bankAccountParams,
        );
        if (tokenRsp.token) {
            const tokenId = tokenRsp.token.id;
            const data = {achAction: 'create', stripeToken: tokenId};
            $.post(this.setupUrl, data, this.fetch)
             .fail((rsp) => {
                 this.setState({
                     errorMessage: `Error: ${JSON.stringify(rsp.responseJSON)}`,
                     loading: false,
                 });
             });
        } else {
            this.setState({
                errorMessage: `Error: ${tokenRsp.error.message}`,
                loading: false,
            });
        }
    }

    renderBankSetupForm() {
        if (!this.state.showBankSetupForm)
            return (
                <a href="#" onClick={e => {
                    e.preventDefault();
                    this.setState({showBankSetupForm: true});
                }}>
                  Add bank account
                </a>
            );

        return (
            <form className="form-horizontal">
              <div className="form-group">
                <label htmlFor="routing-number"
                       className="col-sm-4 control-label">
                  Routing Number
                </label>
                <div className="col-sm-5">
                  <input className="form-control"
                         type="text"
                         name="routingNumber"
                         value={this.state.routingNumber}
                         disabled={this.state.loading}
                         onChange={this.updateStateThing}
                         placeholder="110000000" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="account-number"
                       className="col-sm-4 control-label">
                  Account Number
                </label>
                <div className="col-sm-5">
                  <input className="form-control"
                         type="text"
                         name="accountNumber"
                         value={this.state.accountNumber}
                         disabled={this.state.loading}
                         onChange={this.updateStateThing}
                         placeholder="000123456789" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="account-holder-name"
                       className="col-sm-4 control-label">
                  Account Holder Name
                </label>
                <div className="col-sm-5">
                  <input className="form-control"
                         type="text"
                         name="name"
                         value={this.state.name}
                         disabled={this.state.loading}
                         onChange={this.updateStateThing}
                         placeholder="Jane Austen" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="account-holder-type"
                       className="col-sm-4 control-label">
                  Account Holder Type
                </label>
                <div className="col-sm-5">
                  <select id="account-holder-type"
                          name="holderType"
                          onChange={this.updateStateThing}
                          value={this.state.holderType}
                          disabled={this.state.loading}
                          className="form-control">
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-8">
                  <button className="btn btn-primary"
                          onClick={this.submitBankInfo}
                          disabled={this.state.loading || !this.props.stripe}
                          type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
        );
    }

    renderBankInfo() {
        const { bankInfo } = this.state;
        return (
            <div>
              <i className="fa fa-university"
                 style={{marginRight: '10px'}}></i>
              <span>{bankInfo.bank_name}</span>
              <br />
              <code>********{bankInfo.last4}</code>
            </div>
        );
    }

    removeBankAccount(e) {
        e.preventDefault();
        this.setState({loading: true, errorMessage: ''});
        const data = {achAction: 'remove'};
        $.post(this.setupUrl, data, this.fetch)
         .fail((rsp) => {
             this.setState({
                 errorMessage: `Error: ${JSON.stringify(rsp.responseJSON)} ` +
                               'Please try again later. If the problem persists ' +
                               'please contact customer support.',
                 loading: false,
             });
         });
    }

    render() {
        const { depositsVerified, loading } = this.state;
        if (loading === null) {
            return <LoadingSpinner />;
        }
        return (
            <div>
              {depositsVerified === 'pending' &&
               this.renderDepositsForm()
              }
              {depositsVerified === 'unverified' &&
               this.renderBankSetupForm()
              }
              {depositsVerified !== 'unverified' &&
               <div className="col-sm-8">
                 {this.renderBankInfo()}
                 <small>
                   <button
                       className="btn btn-link"
                       onClick={this.removeBankAccount}>
                     Remove bank account
                   </button>
                 </small>
               </div>
              }
              {this.state.errorMessage &&
               <div className="col-sm-12 alert alert-danger">
                 {this.state.errorMessage}
               </div>
              }
            </div>
        );
    }
}

AchSetup.propTypes = {
    stripe: PropTypes.object,
    callback: PropTypes.func,
};

export default function InjectedAchSetup(props) {
    return (
        <ElementsConsumer>
          {({stripe, elements}) => (
              <AchSetup stripe={stripe}
                        {...props} />
          )}
        </ElementsConsumer>
    );
}
