import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MarketItemVariantImagePicker = props => {
    const [isChoosing, setIsChoosing] = useState(false);
    function chooseImage(imageId) {
        props.onPick(imageId);
        setIsChoosing(false);
    }
    if (!props.images) return null;
    let image;
    if (props.variantData.image_id) {
        // if the user deletes the image we won't have it in props.images and it
        // will not render, having a bogus image_id on the variantData is OK the
        // backend will handle it, trying to get the variant data clean in here in
        // the frontend resulted in strange infinite update behavior
        image = props.images.find(image => image.id === props.variantData.image_id);
    }
    return <React.Fragment>
      {isChoosing &&
       <React.Fragment>
         {props.images.map(
             image => <img className="img-rounded"
                           style={{
                               display: "inline",
                               maxWidth: "50px",
                               margin: "5px",
                               cursor: "pointer",
                           }}
                           onClick={() => chooseImage(image.id)}
                           key={image.id}
                           src={image.image_thumbnail}
             />
         )}
         <button className="btn btn-link btn-sm"
                 onClick={() => setIsChoosing(false)}>
           Cancel
         </button>
       </React.Fragment>
      ||
       <React.Fragment>
         {image &&
          <React.Fragment>
            <img className="img-rounded" style={{
                maxWidth: "50px",
                margin: "5px",
            }} src={image.image_thumbnail} />
            <button className="btn btn-link btn-sm"
                    onClick={() => chooseImage(null)}>
              Clear image
            </button>
          </React.Fragment>
         }
         <button className="btn btn-link btn-sm"
                 onClick={() => setIsChoosing(true)}>
           Choose {image && 'new '}image
         </button>
       </React.Fragment>
      }
    </React.Fragment>;
};

MarketItemVariantImagePicker.propTypes = {
    // images may be null/empty
    images: PropTypes.array.isRequired,
    variantData: PropTypes.object.isRequired,
    // onPick: we let the parent handle updating the itemVariant
    // arguments:
    //    image_id, can be null
    onPick: PropTypes.func.isRequired,
};

export default MarketItemVariantImagePicker;
