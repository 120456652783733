import React from 'react';
import { createRoot } from 'react-dom/client';

import InvItemEditor from './InvItemEditor.jsx';
import { ModalContainer } from './StrModal.jsx';
import PropTypes from 'prop-types';
import { assign } from 'lodash-es';

class InvItemEditorApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        this.normalizedProps = assign({}, this.props.data, {
            wholesalePrice: this.props.data.wholesale_price,
            purchaseDate: moment(this.props.data.purchase_date),
        });

        this.handleUnMount = this.handleUnMount.bind(this);
        this.handleSaveComplete = this.handleSaveComplete.bind(this);
    }

    componentDidMount() {
        let self = this;
        const listed = this.props.listed ? "listed" : "unlisted";
        let url = `/api/v2/${listed}_items/${this.props.data.item_id}/`;
        fetch(url, {
            credentials: 'same-origin',
        }).then(response => {
            if (response.status !== 200) {
                return;
            }
            response.json().then(json => {
                const attributes = (json.data && json.data.attributes) ?
                                   json.data.attributes : {};
                self.normalizedProps = assign({}, json, {
                    itemchoice: json.itemchoice ? {
                        'value': json.itemchoice.pk,
                        'label': json.itemchoice.name,
                    } : null,
                    size: json.size ? {
                        'value': json.size.pk,
                        'label': json.size.name,
                    } : null,
                    wholesalePrice: json.wholesale_price,
                    purchaseDate: moment(json.purchase_date),
                    // {attr: value} => [{name:, value:}]
                    attrs: Object.entries(attributes)
                                 .map(([attr, value], idx) => {
                                     return {
                                         name: attr,
                                         value: value,
                                         id: idx,
                                     };
                                 }),
                });
                self.setState({loading: false});
            });
        });
    }

    handleSaveComplete(response) {
        const $table = $(this.props.tid).dataTable();
        const itemPk = response.pk;
        if ($table.api().ajax.params()) {
            $table.api().ajax.reload();
        } else {
            let url = `/api/v1/items/${itemPk}/`;
            fetch(url, {
                credentials: 'same-origin',
            }).then(response => {
                if (response.status !== 200) {
                    return;
                }
                response.json().then(json => {
                    let updatedItem = json.data[0];
                    $table.api().row(`#str-item-${updatedItem.item_id}`)
                          .data(updatedItem).invalidate().draw();
                });
            });
        }

        $table.trigger('filter-update');

        this.handleUnMount();
    }

    handleUnMount() {
        this.props.root.unmount();
    }

    render() {
        let image = null;
        if (!this.state.loading) {
            image = {
                id: this.normalizedProps.image.pk,
                url: this.normalizedProps.image.image_medium,
                full_url: this.normalizedProps.image.image_full,
            };
        }

        if (this.state.loading) {
            return null;
        }

        /* I would have set 100% for mobile however in testing i found
         * that if there are any super long item names they will make the
         * width larger than the viewport
         *
         * the 1060 maxwidth is the point that allows 4 additional images
         * and looks decent, full width on a fullscreen is not bueno */
        const maxWidth = isMobile ? document.documentElement.clientWidth
                       : '1060px';
        return (
            <ModalContainer isOpen={true}
                            noVerticalCenter
                            onClose={this.handleUnMount}
                            style={{maxWidth: maxWidth}}>
              <InvItemEditor
                  onDeleteClick={this.handleUnMount}
                  onSaveComplete={this.handleSaveComplete}
                  defaultSettings={this.normalizedProps}
                  image={image}
                  noPanel
                  cancelButtonEnabled={true}
                  showAddCustoms={this.props.showAddCustoms}
                  listed={this.props.listed}
              />
            </ModalContainer>
        );
    }
}

InvItemEditorApp.propTypes = {
    data: PropTypes.object.isRequired,
    root: PropTypes.object.isRequired,
    tid: PropTypes.string.isRequired,
    showAddCustoms: PropTypes.bool,
    listed: PropTypes.bool,
};

InvItemEditorApp.defaultProps = {
    showAddCustoms: true,
};

function InvEditorApp(el, data, tid, showAddCustoms, listed) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InvItemEditorApp
                    data={data}
                    root={root}
                    tid={tid}
                    showAddCustoms={showAddCustoms}
                    listed={listed} />);
}

export default InvEditorApp;
