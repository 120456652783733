import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { updateStateThing } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

class NexusStateSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: props.states,
            saving: false,
            input: '',
        };

        this.url = '/api/v2/payment_processing_ctl/';

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
    }

    onSaveClick(e) {
        this.setState({saving: true});
        $.post(this.url, {add_nexus_state: this.state.input})
         .always((rsp, textStatus) => {
             if (textStatus == 'success') {
                 toast.success('State added');
                 this.setState({
                     input: '',
                     states: rsp.nexus_states,
                     saving: false,
                 });
             } else {
                 toast.error(`Error adding state: ${rsp.responseJSON.error}`);
                 this.setState({saving: false});
             }
         });
    }

    onRemoveClick(e, state) {
        e.preventDefault();
        $.post(this.url, {remove_nexus_state: state})
         .always((rsp, textStatus) => {
             if (textStatus == 'success') {
                 toast.success('Nexus state removed');
                 this.setState({
                     states: rsp.nexus_states,
                     saving: false,
                 });
             } else {
                 toast.error('Error removing');
                 this.setState({saving: false});
             }
         });
    }

    render() {
        const { states, saving, input } = this.state;
        const stateList = states.map(state => {
            return (
                <div key={state}>
                  {state}
                  <a className="glyphicon glyphicon-remove"
                     style={{marginLeft: '5px'}}
                     href=""
                     onClick={(e) => this.onRemoveClick(e, state)}></a>
                </div>
            );
        });
        const btnText = saving
                      ? <span>
                        <LoadingSpinner />
                        {' '}
                        Adding Nexus state...
                      </span>
                      : 'Add Nexus State';
        return (
            <div>
              {stateList}
              {stateList.length === 0 &&
               <p className="text-info">Add any additional nexus states needed.</p>
              }
              <div className="row" style={{marginTop: '10px', marginBottom: '10px'}}>
                <div className="col-md-5">
                  <input type="text"
                         className="form-control"
                         name="input"
                         placeholder="Two-letter state abbreviation, eg 'CA'"
                         value={input}
                         disabled={saving}
                         onChange={this.updateStateThing} />
                </div>
                <div className="col-md-2">
                  <button className="btn btn-primary"
                          onClick={this.onSaveClick}
                          disabled={saving || input === ''}>
                    {btnText}
                  </button>
                </div>
              </div>
            </div>
        );
    }
}

NexusStateSetup.propTypes = {
    states: PropTypes.array.isRequired,
};

export default function NexusStateSetupApp(el, states=[]) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<NexusStateSetup states={states} />);
}
