import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from "prop-types";
import { cloneDeep } from 'lodash-es';

const VariantButtons = (props) => {
    const onClick = (e, variant) => {
        e.preventDefault();
        props.onClick(variant);
    };

    const {product} = props;

    return (
        <div>
          {product.name && product.variants.filter(v => !!v.name).map(variant => (
              <button className="btn btn-primary btn-xs sizechoice-btn"
                      key={variant.variant_id}
                      onClick={e => onClick(e, variant)}>
                {variant.name} <span className="badge">{variant.quantity_in_stock}</span>
              </button>
          ))}
          <button className="btn btn-primary btn-xs sizechoice-btn"
                  data-size-name="All sizes"
                  onClick={e => onClick(e, {
                      variant_id: -1,
                      name: "All",
                      quantity_in_stock: product.quantity_in_stock,
                      count: product.total_items,
                  })}>
            All items <span className="badge">{product.quantity_in_stock}</span>
          </button>
        </div>
    );
};

VariantButtons.propTypes = {
    product: PropTypes.object.isRequired,
    // will receive (variant)
    // variant.variant_id will be -1 if "All" was clicked
    onClick: PropTypes.func.isRequired,
};

class InventoryOverviewTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            hideOutOfStock: props.initialHideOOS,
            search: "",
            selectedProductId: null,
            selectedCategoryId: null,
            sortBy: 'name',
            sortDescending: false, //matches default from the endpoint
        };
        this.apiUrl = "/api/v2/inventory_summary/";
        this.fetchData = this.fetchData.bind(this);
        this.onVariantClick = this.onVariantClick.bind(this);
        this.setHideOOS = this.setHideOOS.bind(this);
        this.onHideOutOfStockChange = this.onHideOutOfStockChange.bind(this);
    }

    fetchData() {
        $.getJSON(this.apiUrl, (data) => {
            this.setState({products: data.products});
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    onVariantClick(product, variant) {
        this.setState({
            selectedProductId: product.product_id,
            selectedCategoryId: product.category_id,
        });
        this.props.onClick(product, variant);
    }

    setHideOOS(newHideOOS) {
        this.setState({hideOutOfStock: newHideOOS});
    }

    onHideOutOfStockChange(newHideOOS) {
        this.setHideOOS(newHideOOS);
        this.props.onHideOOSChange(newHideOOS);
    }

    sortData(sortBy) {
        let products = this.state.products.slice();
        products.sort(
            (a, b) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            }
        );
        this.setState(prevState => {
            // flip the sort direction if clicked the same column
            const sortDescending = sortBy === prevState.sortBy
                                 ? !prevState.sortDescending
                                 : prevState.sortDescending;

            if (sortDescending) {
                products.reverse();
            }
            return {
                products: products,
                sortBy: sortBy,
                sortDescending: sortDescending,
            };
        });
    }

    render() {
        const { hideOutOfStock, search, products,
                selectedProductId, selectedCategoryId } = this.state;

        let productsToDisplay =
            hideOutOfStock || search.length > 0
                                            ? cloneDeep(products)
                                            : products;

        if (search) {
            let s = search.toLowerCase();
            let matched = [];
            for (const product of productsToDisplay) {
                if (product.name.toLowerCase().indexOf(s) !== -1) {
                    matched.push(product);
                    continue;
                }
                for (const variant of product.variants) {
                    if (variant.name.toLowerCase().indexOf(s) !== -1) {
                        matched.push(product);
                        break;
                    }
                }
            }
            productsToDisplay = matched;
        }

        if (hideOutOfStock) {
            for (let i = 0; i < productsToDisplay.length; i++) {
                const product = productsToDisplay[i];
                product.variants = product.variants
                                          .filter(v => v.quantity_in_stock > 0);
            }
            productsToDisplay = productsToDisplay.filter(p =>
                p.variants.length > 0 || p.quantity_in_stock > 0
            );
        }
        const sortClass = this.state.sortDescending ? 'desc' : 'asc';
        return (
            <div className="table-responsive form-inline">
              <div className="row">
                <div className="col-sm-6" style={{paddingLeft: 0}}>
                  <div className="btn-group">
                    <button className="btn btn-default"
                            type="button"
                            onClick={() => this.onHideOutOfStockChange(!hideOutOfStock)}>
                      <span>
                        {hideOutOfStock &&
                         <i className="fa fa-check-square-o" aria-hidden="true"></i>
                        }
                        {hideOutOfStock ||
                         <i className="fa fa-square-o" aria-hidden="true"></i>
                        }
                        {' '}Hide out-of-stock items
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-sm-6" style={{textAlign: "right", paddingRight: 0}}>
                  <label style={{fontWeight: "normal"}}>
                    Search: <input type="search"
                                   onChange={e => this.setState({search: e.target.value})}
                                   className="form-control input-sm"
                                   placeholder="" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" style={{paddingLeft: 0, paddingRight: 0}}>
                  <table className="table table-bordered table-condensed table-hover">
                    <thead>
                      <tr role="row">
                        <th style={{cursor: 'pointer'}}
                          onClick={() => this.sortData('name')}>
                          {StrUserInfo.words.style}
                          {this.state.sortBy === 'name' &&
                           <span>
                             {' '}
                             <i className={`fa fa-sort-amount-${sortClass}`} />
                           </span>
                          }
                        </th>
                        <th>{StrUserInfo.words.sizes}</th>
                        <th style={{cursor: 'pointer'}}
                            onClick={() => this.sortData('quantity_listed_for_sale')}>
                          Listed for Sale
                          {this.state.sortBy === 'quantity_listed_for_sale' &&
                           <span>
                             {' '}
                             <i className={`fa fa-sort-amount-${sortClass}`} />
                           </span>
                          }
                        </th>
                        <th style={{cursor: 'pointer'}}
                            onClick={() => this.sortData('quantity_sold')}>
                          Sold
                          {this.state.sortBy === 'quantity_sold' &&
                           <span>
                             {' '}
                             <i className={`fa fa-sort-amount-${sortClass}`} />
                           </span>
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsToDisplay.map(product => {
                          const isSelected = selectedCategoryId === product.category_id
                                          && selectedProductId === product.product_id;
                          const listingsUrl = product.product_id
                                            ? `/inventory/listings/v2/${product.product_id}/`
                                            : '/inventory/listings/v2/';
                          return (
                              <tr key={`${product.product_id}${product.category_id}`}
                                  className={isSelected ? "info" : undefined}>
                                <td>{product.name}</td>
                                <td>
                                  <VariantButtons product={product}
                                                  onClick={(variant) => this.onVariantClick(product, variant)} />
                                </td>
                                <td>
                                  {product.quantity_listed_for_sale > 0 &&
                                   <a href={listingsUrl}>
                                     {product.quantity_listed_for_sale}
                                   </a>
                                  }
                                  {product.quantity_listed_for_sale === 0 && product.quantity_listed_for_sale}
                                </td>
                                <td>
                                  {product.quantity_sold > 0 &&
                                   <a href="/sales/">{product.quantity_sold}</a>
                                  }
                                  {product.quantity_sold === 0 && product.quantity_sold}
                                </td>
                              </tr>
                          );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        );
    }
}

InventoryOverviewTable.propTypes = {
    // will receive (product, variant)
    // variant.variant_id will be -1 if "All" was clicked
    onClick: PropTypes.func.isRequired,
    // callback for OOS change will be called with new hideOOSItems value
    onHideOOSChange: PropTypes.func.isRequired,
    initialHideOOS: PropTypes.bool.isRequired,
};

export default function InventoryOverviewTableApp(
    el, refCb, onClick, onHideOOSChange, initialHideOOS
) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InventoryOverviewTable ref={refCb}
                                        onClick={onClick}
                                        onHideOOSChange={onHideOOSChange}
                                        initialHideOOS={initialHideOOS} />);
}
