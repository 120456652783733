import React from 'react';
import PropTypes from 'prop-types';
import {socialStylify} from "../../util.jsx";

class SocialFeaturedPartyCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { activity } = this.props;
        const party = JSON.parse(activity.party);

        return (
            <div className="text-muted">
              <i className="fa fa-star" style={{color: '#ffc000'}} /> <a href={activity.userData.profileLink}>{activity.userData.displayName}</a> featured a party: <a href={`/party/${party.public_id}p/`}>{party.name}</a>
              <img className="ffArticleMediaImage img-responsive" src={party.cover_photo} />
              <div className="SocialText" dangerouslySetInnerHTML={{
                  __html: socialStylify(activity.text || ''),
              }} />
            </div>
        );
    }
}

SocialFeaturedPartyCard.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default SocialFeaturedPartyCard;
