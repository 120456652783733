import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Select from 'react-select';

import AttrPicker from './AttrPicker.jsx';
import PartySelect from './PartySelect.jsx';

const filterTypeProps = {
    filter: PropTypes.object.isRequired,
    updateFilterKeyValue: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
};

function FilterBase(props) {
    const downUp = props.filter.require ? 'down' : 'up';
    const additionalRequired = props.filter.require ? 'additional' : 'required';
    return <div className="row" style={{marginTop: '10px'}}>
      <div className="col-md-2">
        <a
            onClick={(e) => {
                e.preventDefault();
                props.updateFilterKeyValue(
                    'require',
                    !props.filter.require,
                );
            }}
            style={{marginLeft: "5px"}}
            title={`move ${downUp} to ${additionalRequired} filters`}
            href="#"
        >
          <span className={`glyphicon glyphicon-chevron-${downUp}`}></span>
        </a>
        {' '}
        <span style={{textTransform: 'capitalize'}}>{props.name}</span>
      </div>
      <div className="col-md-6">
        {props.children}
      </div>
      <div className="col-md-4">
        <label className="radio-inline">
          <input
              type="radio"
              value="include"
              disabled={!props.filter.require}
              checked={props.filter.include}
              onChange={() => props.updateFilterKeyValue(
                  'include',
                  true,
              )}
          />
          include
        </label>
        <label className="radio-inline">
          <input
              type="radio"
              value="exclude"
              disabled={!props.filter.require}
              checked={!props.filter.include}
              onChange={() => props.updateFilterKeyValue(
                  'include',
                  false,
              )}
          />
          exclude
        </label>
        <span
            onClick={props.removeFilter}
            style={{marginLeft: "10px"}}
            type="button"
            className="btn btn-default btn-xs"
        >
          <span className="glyphicon glyphicon-remove"></span>
        </span>
      </div>
    </div>;
}

FilterBase.propTypes = {
    name: PropTypes.string.isRequired,
    disableRequire: PropTypes.bool,
    ...filterTypeProps,
};

function DatePurchasedFilter(props) {
    const operatorMap = {
        'exact': 'equal to',
        'gt': 'after',
        'gte': 'on or after',
        'lt': 'before',
        'lte': 'on or before',
    };
    return <FilterBase
               name="Date purchased"
               disableRequire={true}
               {...props}
           >
      <div className="row">
        <div className="col-md-4">
          <select
              className="form-control"
              name="operator"
              value={props.filter.operator}
              onChange={(e) => props.updateFilterKeyValue(
                  'operator',
                  e.target.value,
              )}
          >
            {Object.entries(operatorMap).map(([val, nice]) => {
                return <option value={val} key={val}>{nice}</option>;
            })}
          </select>
        </div>
        <div className="col-md-4">
          <Datetime
              value={props.filter.value}
              dateFormat='M-D-YYYY'
              timeFormat={false}
              closeOnSelect={true}
              onChange={(momentDate) => props.updateFilterKeyValue(
                  'value',
                  momentDate.format('M-D-YYYY'),
              )}
          />
        </div>
        <div className="col-md-4"></div>
      </div>
    </FilterBase>;
}

DatePurchasedFilter.propTypes = filterTypeProps;

function NumberFilter(props) {
    const operatorMap = {
        'exact': 'equal to',
        'gt': 'greather than',
        'gte': 'greather than or equal to',
        'lt': 'less than',
        'lte': 'less than or equal to',
    };
    const prettyName = props.filter.filterType.name.replace('_', ' ');
    return <FilterBase
               name={prettyName}
               disableRequire={true}
               {...props}
           >
      <div className="row">
        <div className="col-md-4">
          <select
              className="form-control"
              name="operator"
              value={props.filter.operator}
              onChange={(e) => props.updateFilterKeyValue(
                  'operator',
                  e.target.value,
              )}
          >
            {Object.entries(operatorMap).map(([val, nice]) => {
                return <option value={val} key={val}>{nice}</option>;
            })}
          </select>
        </div>
        <div className="col-md-2 col-md-offset-2">
          <input
              className="form-control"
              name="value"
              type="number"
              value={props.filter.value}
              onChange={(e) => props.updateFilterKeyValue(
                  'value',
                  e.target.value,
              )}
          />
        </div>
        <div className="col-md-4"></div>
      </div>
    </FilterBase>;
}

NumberFilter.propTypes = filterTypeProps;


function AttributeFilter(props) {
    // props.filter.value is an object on this one
    // {name: something, value: something}
    return <FilterBase
               name="Attribute"
               {...props}
           >
      <div className="row">
        <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
          <AttrPicker
              creatable={false}
              showClearable={true}
              onChangeName={data => props.updateFilterKeyValue(
                  'value',
                  {name: data.value},
              )}
              nameProps={{
                  defaultValue: props.filter.value.name,
                  placeholder: 'Select name...',
              }}
              onChangeValue={data => props.updateFilterKeyValue(
                  'value',
                  {
                      name: props.filter.value.name,
                      value: data ? data.value : null,
                  },
              )}
              valueProps={{
                  defaultValue: props.filter.value.value,
                  placeholder: 'Select value...',
              }}
          />
        </div>
      </div>
    </FilterBase>;
}

AttributeFilter.propTypes = filterTypeProps;

function StyleSizeFilter(props) {
    const [style, setStyle] = useState(
        props.filter.value.style
        ? StrApp.listingsStyles.find(el => el.pk === props.filter.value.style)
        : null
    );
    const [size, setSize] = useState(
        (style && props.filter.value.size)
        ? style.sizes.find(el => el.pk === props.filter.value.size)
        : null
    );
    const [options, setOptions] = useState(StrApp.listingsStyles);

    function onStyleChange(data) {
        setStyle(data);
        setSize(null);
        props.updateFilterKeyValue(
            'value',
            {style: data && data.pk}
        );
    }

    function onSizeChange(data) {
        setSize(data);
        props.updateFilterKeyValue(
            'value',
            {style: style.pk, size: data && data.pk}
        );
    }

    function formatOptionLabel(option) {
        return <div>
          {option.name}
          {' '}
          <span
              className="badge"
              style={{
                  color: '#fff',
                  backgroundColor: '#2e6da4',
              }}
          >
            {option.count}
          </span>
        </div>;
    }

    return <FilterBase name={`${StrUserInfo.words.style} / ${StrUserInfo.words.size}`}
               {...props}>
      <div className="row">
        <div className="col-md-6" style={{paddingLeft: '0'}}>
          <Select
              placeholder={`Select ${StrUserInfo.words.style.toLowerCase()}...`}
              options={StrApp.listingsStyles}
              formatOptionLabel={formatOptionLabel}
              getOptionLabel={o => o.name}
              getOptionValue={o => o.pk}
              onChange={onStyleChange}
              value={style}
              isClearable={true}
              openMenuOnFocus={true}
          />
        </div>
        {style &&
         <div className="col-md-6" style={{paddingLeft: '0'}}>
           <Select
               placeholder={`Select ${StrUserInfo.words.size.toLowerCase()}...`}
               options={style.sizes}
               formatOptionLabel={formatOptionLabel}
               getOptionLabel={o => o.name}
               getOptionValue={o => o.pk}
               onChange={onSizeChange}
               value={size}
               isClearable={true}
               openMenuOnFocus={true}
           />
         </div>
        }
      </div>
    </FilterBase>;
}

StyleSizeFilter.propTypes = filterTypeProps;

function PartyFilter(props) {
    function onPartySelect(data) {
        props.updateFilterKeyValue(
            'value',
            // data.value is the party pk
            data.value
        );
    }
    return <FilterBase
               name={props.filter.filterType.prettyName || "Party"}
               {...props}
           >
      <div className="row">
        <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
          <PartySelect onSelect={onPartySelect} />
        </div>
      </div>
    </FilterBase>;
}

PartyFilter.propTypes = filterTypeProps;


function TextFilter(props) {
    const prettyName = props.filter.filterType.prettyName
                    || props.filter.filterType.name.replace('_', ' ');
    return <FilterBase name={prettyName} {...props}>
      <div className="row">
        <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
          <input
              className="form-control"
              name="value"
              type="text"
              style={{width: '100%'}}
              value={props.filter.value}
              onChange={(e) => props.updateFilterKeyValue(
                  'value',
                  e.target.value,
              )}
          />
        </div>
      </div>
    </FilterBase>;
}

TextFilter.propTypes = filterTypeProps;

export {
    DatePurchasedFilter,
    NumberFilter,
    AttributeFilter,
    StyleSizeFilter,
    PartyFilter,
    TextFilter,
};
