import React from 'react';
import PropTypes from 'prop-types';
import SocialFeedCard from './SocialFeedCard.jsx';
import { enrichActivity } from './social_util.jsx';
import ItemPicker from '../ItemPicker.jsx';
import PartyPicker from '../PartyPicker.jsx';
import TextareaAutosize from 'react-textarea-autosize';
import LoadingSpinner from '../LoadingSpinner.jsx';
import { ModalContainer } from '../StrModal.jsx';


class FeaturedContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showItemPicker: false,
            showPartyPicker: false,
            showFeaturedPreview: false,
            errors: [],
            featuredActivities: [],
            newActivity: {},
            featuredText: '',
            activityImage: null,
            featureType: null,
            saving: false,
            loading: true,
        };

        this.isOwner = str_user_id === this.props.user.pk;

        this.onAddItemClick = this.onAddItemClick.bind(this);
        this.fetchFeatured = this.fetchFeatured.bind(this);
        this.onAddItemClick = this.onAddItemClick.bind(this);
        this.onAddPartyClick = this.onAddPartyClick.bind(this);
        this.onItemPickerClose = this.onItemPickerClose.bind(this);
        this.onPartyPickerClose = this.onPartyPickerClose.bind(this);
        this.onFeaturedPreviewClose = this.onFeaturedPreviewClose.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onPartySelect = this.onPartySelect.bind(this);
        this.onFeaturedTextChange = this.onFeaturedTextChange.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    render() {
        if (this.props.user.is_shopper)
            return null;

        const errors = this.state.errors.map((err, idx) =>
            // eslint-disable-next-line react/no-array-index-key
            <p key={idx} className="text-danger text-small">
              {err}
            </p>
        );
        const itemPicker = (
            <ModalContainer onClose={this.onItemPickerClose}
                            isOpen={this.state.showItemPicker}
                            style={{
                                width: 'calc(100% - 110px)',
                                maxWidth: '1000px',
                            }}>
              <ItemPicker onSelect={this.onItemSelect} />
            </ModalContainer>
        );
        const partyPicker = (
            <ModalContainer onClose={this.onPartyPickerClose}
                            isOpen={this.state.showPartyPicker}
                            style={{
                                top: 0,
                                transform: "translateY(0%)",
                                width: 'calc(100% - 110px)',
                                maxWidth: '1000px',
                            }}>
                <PartyPicker onSelect={this.onPartySelect} />
            </ModalContainer>
        );

        const featuredPreview = (
            <ModalContainer onClose={this.onFeaturedPreviewClose}
                            isOpen={this.state.showFeaturedPreview}>
              <img className="img-responsive"
                   style={{maxHeight: window.innerHeight * 0.7}}
                   src={this.state.activityImage} />
              <TextareaAutosize className="form-control"
                                style={{width: "100%", marginTop: "8px", borderRadius: "1em"}}
                                value={this.state.featuredText}
                                disabled={this.state.saving}
                                autoFocus
                                placeholder={`Write something about your ${this.state.featureType}`}
                                onChange={this.onFeaturedTextChange} />
              <div className="text-center">
                {this.state.saving &&
                 <span>Saving... <LoadingSpinner /></span>
                ||
                 (errors.length && errors)
                ||
                 <br />
                }
              </div>
              <div className="text-center">
                <input className="btn btn-info"
                       type="button"
                       value="Feature"
                       disabled={this.state.saving}
                       onClick={() => {
                           this.create(this.state.newActivity, this.state.featuredText);
                       }} />
              </div>
            </ModalContainer>
        );

        let featuredActivities = [];
        this.state.featuredActivities.forEach((a, ind) => {
            featuredActivities.push(
                <div key={a.id}
                     className="col-xs-12 col-sm-6 col-md-4">
                  <SocialFeedCard activity={a} doRefresh={() => {
                      this.fetchFeatured();
                      this.clearErrors();
                  }} />
                </div>
            );
            const ind1 = ind + 1;
            if (ind1 % 2 === 0)
                featuredActivities.push(<div key={`${a.id}_sm`}
                                             className="clearfix visible-sm-block" />);
            if (ind1 % 3 === 0)
                featuredActivities.push(<div key={`${a.id}_md`}
                                             className="clearfix visible-md-block visible-lg-block" />);
        });

        return (
            <div className="FeaturedContainer">
              {errors}
              {this.isOwner &&
               <div className="btn-group btn-group-xs"
                    style={{marginLeft: '15px'}}
                    role="group">
                 <button className="btn btn-default"
                         style={{marginBottom: "10px"}}
                         onClick={this.onAddItemClick}>
                   <i className="fa fa-plus-circle" /> Add Item
                 </button>
                 <button className="btn btn-default"
                         style={{marginBottom: "10px"}}
                         onClick={this.onAddPartyClick}>
                   <i className="fa fa-plus-circle" /> Add {StrUserInfo.words.party}
                 </button>
                 {itemPicker}
                 {partyPicker}
                 {featuredPreview}
               </div>
              }
              <div className="row">
                {featuredActivities}
                {!this.state.loading &&
                 this.isOwner && featuredActivities.length === 0 &&
                 <p className="text-small text-muted"
                    style={{marginLeft: '15px'}}>
                   Your featured content goes here.
                   Click <em>Add Item</em> or{' '}
                   <em>Add {StrUserInfo.words.party}</em>{' '}
                   to get started.
                 </p>
                }
                {!this.state.loading &&
                 !this.isOwner && featuredActivities.length === 0 &&
                 <p className="text-small text-muted"
                    style={{marginLeft: '15px'}}>
                   {this.props.user.name} hasn't featured any content.
                 </p>
                }
              </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchFeatured();
    }

    async fetchFeatured() {
        const creds = {credentials: 'same-origin'};

        const partiesUrl = `/api/v2/social_featured_parties/${this.props.user.pk}/`;
        const itemsUrl = `/api/v2/social_featured_items/${this.props.user.pk}/`;
        const partiesFetch = fetch(partiesUrl, creds).then(response => response.json());
        const itemsFetch = fetch(itemsUrl, creds).then(response => response.json());
        const results = await Promise.all([partiesFetch, itemsFetch]);
        const featuredActivities = results[0]
            .concat(results[1])
            .sort((a1, a2) => moment.utc(a2.time).unix() - moment.utc(a1.time).unix())
            .map(enrichActivity);
        this.setState({
            featuredActivities: featuredActivities,
            loading: false,
        });
    }

    onAddItemClick(e) {
        e.preventDefault();
        this.setState({showItemPicker: true});
    }

    onAddPartyClick(e) {
        e.preventDefault();
        this.setState({showPartyPicker: true});
    }

    onItemPickerClose() {
        this.setState({showItemPicker: false});
    }

    onPartyPickerClose() {
        this.setState({showPartyPicker: false});
    }

    onFeaturedPreviewClose() {
        this.setState({
            showFeaturedPreview: false,
            featuredText: '',
            newActivity: null,
            featureType: null,
        });
    }

    onItemSelect(item) {
        this.setState({
            showFeaturedPreview: true,
            newActivity: item,
            activityImage: item.image.image_medium,
            featureType: 'item',
            showItemPicker: false,
            errors: [],
        });
    }

    onPartySelect(party) {
        this.setState({
            showFeaturedPreview: true,
            newActivity: party,
            activityImage: party.cover_photo,
            featureType: 'party',
            showPartyPicker: false,
            errors: [],
        });
    }

    onFeaturedTextChange(e) {
        this.setState({featuredText: e.target.value});
    }

    create(activity, text) {
        const {featureType} = this.state;
        const url = `/api/v2/social_featured_${featureType}/`;
        const data = {};
        if (text) data.text = text;
        if (featureType === 'item') {
            data.item_pk = activity.pk;
        } else if (featureType === 'party') {
            data.public_id = activity.public_id;
        } else {
            throw new RangeError('invalid activity type');
        }

        this.setState({saving: true});

        $.post(url, data)
         .fail((xhr) => {
             this.setState({
                 errors: xhr.responseJSON || [],
                 saving: false,
             });
         })
         .success(() => {
             this.setState({
                 showFeaturedPreview: false,
                 newActivity: null,
                 featuredText: '',
                 saving: false,
                 errors: [],
             });
             this.fetchFeatured();
         });
    }

    clearErrors() {
        this.setState({errors: []});
    }
}

FeaturedContent.propTypes = {
    user: PropTypes.object.isRequired,
};

export default FeaturedContent;
