import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import OptionNameAndValues from './OptionNameAndValues.jsx';
import VariantsTable from './VariantsTable.jsx';
import { moveInArray } from './util.jsx';

/* we are using this incremented id to represent unsaved option names as well as
 * unsaved option values, if we didn't share the series across both of them we
 * would need to track each of them with a unique combination key similar to
 * OptionValue.option_key */
let id = 1;

function getId() {
    return id++;
}

function namesAndValuesFromItemVariants(itemVariants) {
    const namesAndValues = [];
    itemVariants.forEach(iv => {
        iv.optionValues.forEach(ov => {
            if (!namesAndValues.map(nav => nav.pk).includes(ov.name_pk)) {
                namesAndValues.push({
                    pk: ov.name_pk,
                    id: ov.name_pk,
                    name: ov.name,
                    values: [],
                });
            }
            const navIndex = namesAndValues.map(nav => nav.pk)
                                           .indexOf(ov.name_pk);
            if (!namesAndValues[navIndex].values.map(
                value => value.pk).includes(ov.pk
            )) {
                namesAndValues[navIndex].values.push({
                    id: `${ov.name_pk}-${ov.pk}`,
                    pk: ov.pk,
                    value: ov.value,
                });
            }
        });
    });
    /* add an empty value at the end of each name so that we can add new values to
     * existing options on edit */
    namesAndValues.map(nav => {
        nav.values.push({id: getId(), value: ''});
        return nav;
    });
    return namesAndValues;
}

const OptionsDesigner = props => {
    const [namesAndValues, setNamesAndValues] = useState(
        props.itemVariants.length !== 0
        ? namesAndValuesFromItemVariants(props.itemVariants)
        : [{
            id: getId(),
            name: '',
            values: [{id: getId(), value: ''}],
        }]
    );

    function nameValuesUpdater(id) {
        return function(name, values) {
            setNamesAndValues(prev => {
                /* if the last one has data then append a new empty value,
                 * mimicking competitors */
                if (values[values.length - 1].value.length > 0) {
                    values.push({id: getId(), value: ''});
                }
                const newValue = {id: id, name: name, values: values};
                let newValues = prev.slice();
                newValues.splice(
                    prev.findIndex(v => v.id == id),
                    1,
                    newValue,
                );
                return newValues;
            });
        };
    }

    function removeName(id) {
        setNamesAndValues(prev => prev.filter(nv => nv.id !== id));
    }

    function moveNamesAndValues(id, direction) {
        setNamesAndValues(prevNamesAndValues => {
            const oldIndex = prevNamesAndValues.findIndex(v => v.id == id);
            const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;
            return moveInArray(prevNamesAndValues.slice(), oldIndex, newIndex);
        });
    }
    return <div>
      <hr style={{margin: '10px 0'}} />
      {namesAndValues.map((obj, idx) => {
          return <div key={obj.id}>
            <div>
              <button className="btn btn-link name-move-up"
                      disabled={idx === 0}
                      onClick={() => moveNamesAndValues(obj.id, 'up')}
                      style={{display: 'inline-block', verticalAlign: 'top', padding: '5px 5px'}}
                      tabIndex="-1">
                <span className={`glyphicon glyphicon-chevron-up`}></span>
              </button>
              <button className="btn btn-link name-move-down"
                      disabled={idx === namesAndValues.length - 1}
                      onClick={() => moveNamesAndValues(obj.id, 'down')}
                      style={{display: 'inline-block', verticalAlign: 'top', padding: '5px 5px'}}
                      tabIndex="-1">
                <span className={`glyphicon glyphicon-chevron-down`}></span>
              </button>
              <div style={{display: 'inline-block', width: 'calc(100% - 55px)'}}>
                <OptionNameAndValues nameAndValues={obj}
                                     remove={() => removeName(obj.id)}
                                     update={nameValuesUpdater(obj.id)} />
              </div>
            </div>
            <hr style={{margin: '10px 0'}} />
          </div>;
      })}
      {namesAndValues.length < 3 &&
       <div>
         <button className="btn btn-link add-option"
                 onClick={() => setNamesAndValues(namesAndValues.concat(
                     [{
                         id: getId(),
                         name: '',
                         values: [{id: getId(id), value: ''}],
                     }]
                 ))}>
           <i className="fa fa-plus" /> Add another option
         </button>
         <hr style={{margin: '10px 0'}} />
       </div>
      }
      <VariantsTable namesAndValues={namesAndValues}
                     itemVariants={props.itemVariants}
                     initialPrice={props.initialPrice}
                     images={props.images}
                     updateItemVariants={props.updateItemVariants} />
    </div>;
};

OptionsDesigner.propTypes = {
    itemVariants: PropTypes.array,
    updateItemVariants: PropTypes.func.isRequired,
    initialPrice: PropTypes.string,
    images: PropTypes.array,
};

export default OptionsDesigner;
