import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner.jsx';
import { isEqual } from 'lodash-es';


class PartyPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parties: props.parties,
        };

        this.onSelect = this.onSelect.bind(this);
    }

    render() {
        if (this.state.parties === null)
            return <LoadingSpinner />;

        const parties = this.state.parties.map(party => {
            return (
                <div key={party.pk}
                     className="col-sm-6 col-md-3">
                  <div className="thumbnail"
                       style={{cursor: "pointer"}}
                       onClick={(e) => this.onSelect(e, party)}>
                    <img src={party.cover_photo}
                         style={{
                             height: '200px',
                             objectFit: 'cover',
                             objectPosition: '50% 50%',
                         }} />
                    <div className="caption text-small text-center">
                      {party.name}
                      <a target="_blank"
                         href={`/party/${party.public_id}p/`}
                         onClick={(e) => e.stopPropagation()}>
                        {' '}<i className="fa fa-external-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
            );
        });

        return (
            <div>
              {parties.length > 0 &&
               <div className="row">
                 {parties}
               </div>
              }
              {parties.length === 0 &&
               <div className="row">
                 <div className="col-md-12">
                   <p>Looks like you don't have any eligible parties. Start <a href="/party/">one</a>!</p>
                 </div>
               </div>
              }
            </div>
        );
    }

    componentDidMount() {
        if (!this.props.parties) {
            $.getJSON('/api/v2/users/parties/', (parties) => {
                this.setState({parties});
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.parties, this.props.parties)) {
            this.setState({parties: this.props.parties});
        }
    }

    onSelect(e, party) {
        e.preventDefault();
        this.props.onSelect(party);
    }
}


PartyPicker.defaultProps = {
    parties: null,
};

PartyPicker.propTypes = {
    onSelect: PropTypes.func.isRequired,
    parties: PropTypes.array,
};

export default PartyPicker;
