import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import FilterAppBase from './FilterAppBase.jsx';
import {
    PartyFilter,
    TextFilter,
} from './FilterTypes.jsx';


const filterTypes = {
    claimedFromParty: {
        name: 'claimrecord_claimed_from_party',
        prettyName: 'Claim Party',
        component: PartyFilter,
    },
    claimedFromFacebookGroup: {
        name: 'claimrecord_claimed_from_facebook_group',
        prettyName: 'Claim FB Group',
        component: TextFilter,
    },
};

function checkFiltersForErrors(filters) {
    let errors = [];
    return errors;
}

const salesAddFiltersDropdownData = [
    {
        name: "Party Claimed From",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.claimedFromParty,
        initialValue: '',
    },
    {
        name: "Facebook Group Claimed From",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.claimedFromFacebookGroup,
        initialValue: '',
    },
];

function SalesFilters(props) {
    return <FilterAppBase
               callback={props.callback}
               addFiltersDropdownData={salesAddFiltersDropdownData}
               checkFiltersForErrors={checkFiltersForErrors}
           />;
}

SalesFilters.propTypes = {
    callback: PropTypes.func.isRequired,
};

export default function SaleFiltersApp(el, callback) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SalesFilters callback={callback} />);
}
