import React, { useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { formatCurrency, getUUID } from './util.jsx';

function checkCustomsItem(customsItem) {
    return customsItem.description !== ''
        && customsItem.value !== ''
        && customsItem.weight !== '';
}

export function checkCustomsItems(customsItems) {
    return customsItems.length !== 0
        && customsItems.every(checkCustomsItem);
}

function newEmptyItem() {
    return {
        key: getUUID(),
        description: '',
        quantity: 1,
        value: '',
        weight: '',
        hs_tariff_number: '',
    };
}

const ShippingCustomsForm = (props) => {
    const baseCustomsItems = props.customsItems.length > 0
                           ? props.customsItems
                           : [newEmptyItem()];
    const [customsItems, setCustomsItems] = useState(baseCustomsItems);

    const updateByKeys = (e) => {
        const target = e.target;
        const itemKey = target.dataset.itemkey;
        const updateKey = target.name;
        const updateValue = target.type === 'number'
                          ? Number(target.value)
                          : target.value;
        const idx = customsItems.findIndex(ci => ci.key === itemKey);
        const newCustomsItems = update(customsItems, {
            [idx]: {
                [updateKey]: {
                    $set: updateValue,
                },
            },
        });
        setCustomsItems(newCustomsItems);
    };

    const items = customsItems.map(item => {
        return (
            <div className="row"
                 key={item.key}
                 style={{marginBottom: '15px'}}>
              <div className="col-md-4">
                <input type="text"
                       className="form-control"
                       name="description"
                       data-itemkey={item.key}
                       value={item.description}
                       onChange={updateByKeys}
                       placeholder="Item name/description" />
              </div>
              <div className="col-md-2">
                <div className="input-group">
                  <div className="input-group-addon">$</div>
                  <input type="number"
                         className="form-control"
                         data-itemkey={item.key}
                         name="value"
                         value={item.value}
                         onChange={updateByKeys}
                         placeholder="6.00" />
                </div>
              </div>
              <div className="col-md-2">
                <input type="number"
                       className="form-control"
                       data-itemkey={item.key}
                       name="weight"
                       value={item.weight}
                       onChange={updateByKeys}
                       placeholder="Weight (oz)" />
              </div>
              <div className="col-md-2">
                <input type="text"
                       className="form-control"
                       data-itemkey={item.key}
                       name="hs_tariff_number"
                       value={item.hs_tariff_number}
                       onChange={updateByKeys}
                       title="Tariff code is optional with a good description"
                       placeholder="Tariff code" />
              </div>
              <button className="btn btn-link"
                      onClick={() => {
                          const filteredCustomsItems =
                              customsItems.filter(ii => ii.key !== item.key);
                          setCustomsItems(filteredCustomsItems);
                      }}>
                <span className="glyphicon glyphicon-minus"></span>
              </button>
            </div>
        );
    });

    return (
        <div className="col-md-12">
          <div className="alert alert-info">
            This address requires customs information for the items
            being shipped.
          </div>
          <p className="text-small text-muted">
            Include tariff code for easier customs check. Look up
            Tariff codes at
            {' '}
            <a href="https://hts.usitc.gov/" target="_blank">
              https://hts.usitc.gov/
            </a>.
          </p>
          {items}
          <div className="row" style={{margin: '20px 0px'}}>
            <button className="btn btn-default"
                    onClick={
                    () => setCustomsItems([...customsItems, newEmptyItem()])
                    }
                    style={{
                        marginLeft: '15px',
                        cursor: 'pointer',
                    }}>
              <span className="glyphicon glyphicon-plus"></span>
              {' '}
              Add item
            </button>
          </div>
          {!checkCustomsItems(customsItems) &&
           <p className="text text-warning">
             Name/description, value, and weight are required for each item.
           </p>
          }
          <button className="btn btn-primary"
                  disabled={!checkCustomsItems(customsItems)}
                  onClick={() => props.updateCustomsItems(customsItems)}
                  style={{marginTop: '20px'}}>
            Continue
          </button>
        </div>
    );
};


ShippingCustomsForm.propTypes = {
    updateCustomsItems: PropTypes.func.isRequired,
    customsItems: PropTypes.array.isRequired,
};

export default ShippingCustomsForm;
