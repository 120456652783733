import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StrTypeAhead from "./StrTypeAhead.jsx";
import { fetchJSON } from './util.jsx';


export default function MarketItemTags(props) {
    const [userTags, setUserTags] = useState([]);
    const [error, setError] = useState("");
    async function fetch() {
        const tags = await fetchJSON('/api/v2/users/market_tag_names/');
        setUserTags(tags);
    }
    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    function removeTag(tag) {
        const newTags = props.tags.filter(currTag => currTag !== tag);
        props.setTags(newTags);
    }
    const isDupe = newTag => props.tags.includes(newTag);
    function addTag(newTag, cb) {
        if (!newTag) return;
        if (isDupe(newTag)) {
            setError("Tags must be unique");
            cb();
            return;
        }
        setError("");
        props.setTags(props.tags.concat(newTag));
        cb();
    }
    function source(inputValue) {
        let retSource = userTags.filter(
            tagName => {
                return tagName.toLowerCase().includes(inputValue.toLowerCase())
                && !props.tags.includes(tagName);
            }
        ).map(tagName => {
            return {
                triggerLength: inputValue.length,
                output: tagName,
                key: tagName,
                displayData: tagName,
            };
        });
        if (!userTags.includes(inputValue)) {
            retSource.push({
                triggerLength: inputValue.length,
                output: inputValue,
                key: inputValue,
                displayData: `Create a new tag: ${inputValue}`,
            });
        }
        return retSource;
    }
    function display(displayData) {
        return <div>{displayData}</div>;
    }
    const footer = <div className="partyChatFooter">
      Click an option or press enter
    </div>;
    return <React.Fragment>
      <div className="col-md-8" style={{paddingLeft: 0}}>
        <StrTypeAhead
            placeholder="Enter a new tag, eg shape, color, purpose, etc."
            inputClassName="form-control"
            footer={footer}
            source={source}
            display={display}
            onSubmit={addTag}
            submitOnSelect
        />
      </div>
      <div className="col-md-12" style={{paddingLeft: 0}}>
        {error && <p className="text text-warning">{error}</p>}
        {props.tags.map(tag => {
            return <div className="btn-group"
                        key={tag}
                        style={{marginRight: '10px', marginTop: '10px'}}
                        role="group">
              <button className="btn btn-default"
                      style={{opacity: 1, cursor: 'default'}}
                      disabled>
                {tag}
              </button>
              <button onClick={() => removeTag(tag)}
                      className="btn btn-default">
                  &times;
              </button>
            </div>;
        })}
      </div>
    </React.Fragment>;
}

MarketItemTags.propTypes = {
    // ['tag1', 'tag2',]
    tags: PropTypes.array.isRequired,
    setTags: PropTypes.func.isRequired,
};
