import React from 'react';
import { createRoot } from 'react-dom/client';
import update from 'immutability-helper';

class QRLabelPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: Array(30).fill().map((el, idx) => {
                return {idx: idx, trashed: false};
            }),
        };
    }

    render() {
        const labels = this.state.labels.map(label => {
            let btn_cls = "btn-primary";
            let checked = "checked";
            const divStyle = {
                'margin': '4px 0px',
            };
            if (label.trashed) {
                btn_cls = "btn-default";
                checked = "";
            }
            return (
                <div key={label.idx}
                     className="col-xs-4"
                     style={divStyle}>
                  <label className={"btn btn-block " + btn_cls}>
                    <input
                        type="checkbox"
                        name={"qrlabel." + label.idx}
                        checked={checked}
                        onChange={this.labelClicked.bind(this, label.idx)}>
                    </input>
                    <span className="hidden-xs"> Label</span>
                  </label>
                </div>
            );
        });
        return (
            <div>
              <p>Please choose which labels to use on your first page (if printing more than one page the second sheet should be a full sheet of labels):</p>
              <pre>
                <br />
                {labels}
                <div className="clearfix"></div>
              </pre>
            </div>
        );
    }

    labelClicked(idx) {
        this.setState(prevState => {
            return {
                labels: update(prevState.labels, {
                    [idx]: {
                        trashed: {$set: !prevState.labels[idx].trashed},
                    },
                }),
            };
        });
    }
}

function QRLabelPickerApp(el) {
    const root = createRoot(el);
    root.render(<QRLabelPicker />);
}

export default QRLabelPickerApp;
