import React from "react";
import PropTypes from "prop-types";

class ExtraImagesListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    handleDelete(e, image) {
        e.preventDefault();

        $.ajax({
            url: `/api/v2/${this.props.endpoint}/${image.id}/`,
            method: "DELETE",
            success: () => {
                this.props.onDelete(true, image);
            },
            error: (rsp) => {
                this.props.onDelete(false, image, rsp);
            },
        });
    }

    render() {
        const {image} = this.props;
        return (
            <figure className="img-rounded invitem-image-thumbnail">
                <img src={image.image_thumbnail} alt={`Extra images`} />

                <a onClick={e => this.handleDelete(e, image)}>
                    <i className="remove fa fa-times-rectangle" />
                </a>
            </figure>
        );
    }
}

ExtraImagesListItem.defaultProps = {
    endpoint: 'extraimages',
};

ExtraImagesListItem.propTypes = {
    image: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    endpoint: PropTypes.string,
};

export default ExtraImagesListItem;
