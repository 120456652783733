import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner.jsx';
import SocialFeedCard from './SocialFeedCard.jsx';
import { foreignIdParse, enrichActivity } from './social_util.jsx';
import { Waypoint } from 'react-waypoint';
import update from 'immutability-helper';
import SocialPostEntry from './SocialPostEntry.jsx';


class SocialFeed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            feed: [],
            endOfFeed: false,
            index: 0,
            offset: 0,
        };

        this.fetchFeed = this.fetchFeed.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.insertPost = this.insertPost.bind(this);
        this.reloadFeed = this.reloadFeed.bind(this);
        this.initRTFeed = this.initRTFeed.bind(this);
        this.feedCallback = this.feedCallback.bind(this);

        this.rtFeed = null;
        this.rtToken = null;
        this.rtKind = null;
        this.rtValue = null;
    }

    render() {
        let feedCardProps = {};
        const { feedParams } = this.props;
        if (feedParams && feedParams.kind === 'party')
            feedCardProps['partyPublicId'] = feedParams.value;
        const feedItems = this.state.feed
                              .slice(this.state.offset, this.state.index)
                              .map(x => <SocialFeedCard activity={x}
                                                        key={x.id}
                                                        doRefresh={this.reloadFeed}
                                                        {...feedCardProps} />);
        const showPostEntry = !this.props.hidePostEntry;

        return (
            <div>
              {showPostEntry &&
               <SocialPostEntry doRefresh={this.props.doRefresh} />
              }
              {this.state.offset > 0 &&
               <button className="btn btn-link"
                       onClick={() => this.setState({offset: 0})}
                       style={{marginLeft: '20px'}}>
                 Load new post{this.state.offset !== 1 && 's'}
                 {' '}<span className="badge">{this.state.offset}</span>
               </button>
              }
              <div>
                {!this.props.hideHelp && !this.state.loading && feedItems.length === 0 &&
                 <div className="text-small text-muted">
                   Nothing here!  Start getting social by checking out your
                   enhanced <a href="/me/">social profile page</a> and following other users.
                 </div>
                }
                {feedItems}
                {this.state.loading &&
                 <div style={{textAlign: 'center', marginTop: '30px'}}>
                   <LoadingSpinner />
                 </div>
                }
                {!this.state.loading && !this.state.endOfFeed &&
                 <Waypoint onEnter={this.loadMoreItems} />
                }
                {this.state.endOfFeed &&
                 <p className="text-muted">This is the end. Of this feed.</p>
                }
              </div>
            </div>
        );
    }

    insertPost(activity) {
        const actor = foreignIdParse(activity.actor);
        const wePosted = parseInt(actor.objId) === str_user_id;
        this.setState(prevState => ({
            feed: update(prevState.feed, {
                $unshift: [enrichActivity(activity)],
            }),
            index: prevState.index + 1,
            offset: (wePosted || this.props.showNewPosts) ? 0 : prevState.offset + 1,
        }));
    }

    fetchFeed(offset=0, limit=50, append=true) {
        this.setState({loading: true});
        const params = {limit, offset};

        const { feedParams } = this.props;
        if (feedParams) {
            switch (feedParams.kind) {
            case 'tag':
                params.tag = feedParams.value;
                break;
            case 'user':
                params.user_id = feedParams.value;
                break;
            case 'party':
                params.party_public_id = feedParams.value;
                break;
            default:
                throw "Unknown feedParam kind";
            }
        }

        const url = '/api/v2/social_feed/';
        $.getJSON(url, params, (data) => {
            const updateOp = append ? '$push' : '$set';

            this.rtToken = data.token;
            this.rtKind = data.kind;
            this.rtValue = data.value;
            this.initRTFeed();

            this.setState(prevState => {
                let feed = prevState.feed;
                let endOfFeed = true;
                let index = prevState.index;
                if (data.results.length > 0) {
                    feed = update(prevState.feed, {
                        [updateOp]: data.results.map(enrichActivity),
                    });
                    endOfFeed = false;
                    index = prevState.index + 5;
                }
                return {
                    feed: feed,
                    loading: false,
                    index: index,
                    endOfFeed: endOfFeed,
                };
            });
        });
    }

    loadMoreItems() {
        if (this.state.index >= this.state.feed.length) {
            this.fetchFeed(this.state.feed.length);
        } else {
            this.setState(prevState => ({
                index: prevState.index + 5,
            }));
        }
    }

    reloadFeed() {
        this.fetchFeed(0, this.state.feed.length, false);
    }

    initRTFeed() {
        if (this.rtFeed
            || [getstream_api_key,
                getstream_app_id,
                this.rtToken,
                this.rtKind,
                this.rtValue]
                .some(el => el === null))
            return;
        const streamClient = stream.connect(getstream_api_key,
                                            null,
                                            getstream_app_id);
        this.rtFeed = streamClient.feed(
            this.rtKind,
            this.rtValue,
            this.rtToken);
        this.rtFeed
            .subscribe(this.feedCallback)
            .then(this.successCallback, this.failCallback);
    }

    feedCallback(data) {
        data.new.forEach(this.insertPost);
    }

    successCallback(data) {
        // just succeed silently
    }

    failCallback(data) {
        console.error("Realtime social feed failed to connect"); // eslint-disable-line
        console.error(data);                                     // eslint-disable-line
    }
}

SocialFeed.propTypes = {
    feedParams: PropTypes.shape({
        kind: PropTypes.string, /* "tag", "user", "party" */
        value: PropTypes.any,
    }),
    hidePostEntry: PropTypes.bool,
    hideHelp: PropTypes.bool,
    showNewPosts: PropTypes.bool,
    doRefresh: PropTypes.func,
};

export default SocialFeed;
