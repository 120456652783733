/**
 * Depends on the following globals:
 *   - str_user_id
 *   - str_login_url
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { fetchJSONWithResponse } from './util.jsx';

class StrNextButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            itemData: {
                consultant: null,
                usersInLine: [],
            },
        };
        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        if (this.state.loading
            || this.state.itemData.disable_nexts
            || StrUserInfo.banned)
            return null;

        if (str_user_id == -1) {
            var the_login_url = `${str_login_url}?next=${window.location.pathname}`;
            return (
                <p className="text-small">
                  <a href={the_login_url}>Login</a> to get in line for this item.
                </p>
            );
        }

        if (this.state.itemData.usersInLine.includes(str_user_id))
            return <p className="text-info">You are in line for this item.</p>;

        return (
            <p>
              <button className={`btn btn-default ${this.props.btnSize}`}
                      style={this.props.btnStyle}
                      data-toggle="tooltip"
                      onClick={this.handleClick}
                      title="Get in line for this item">
                <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                {' '}
                Next
              </button>
            </p>
        );
    }

    componentDidMount() {
        this.fetchController = new AbortController();
        this.refreshInLine();
    }

    componentWillUnmount() {
        this.fetchController.abort();
    }

    async refreshInLine() {
        if (str_user_id === -1) {
            this.setState({loading: false});
            return;
        }
        const url = `/api/v2/public_items/${this.props.itemPk}/`;
        const [itemData, rsp] = await fetchJSONWithResponse(url, this.fetchController.signal);
        if (itemData === null)
            return;
        let newState = {loading: false};
        if (rsp.ok)
            newState.itemData = itemData;
        this.setState(newState);
    }

    handleClick() {
        var self = this;
        const url = `/api/v2/public_items/${this.props.itemPk}/get_in_line/`;
        const data = {
            'claimed_from_pk': this.props.claimedFromPartyPk,
            'claimed_from_fb_group_fbid': this.props.claimedFromFbGroupFbId,
            'claimed_from_omniparty': this.props.claimedFromOmniParty,
        };
        $.post(url, data, function() {
            self.refreshInLine();
        });
    }
}

StrNextButton.propTypes = {
    itemPk: PropTypes.number.isRequired,
    btnSize: PropTypes.string,
    btnStyle: PropTypes.object,
    claimedFromPartyPk: PropTypes.number,
    claimedFromFbGroupFbId: PropTypes.number,
    claimedFromOmniParty: PropTypes.bool,
};

StrNextButton.defaultProps = {
    btnSize: "btn-sm",
    btnStyle: {},
};

export default StrNextButton;
