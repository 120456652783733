const socialNetworks = [
    {
        provider: "facebook",
        title: "Facebook",
        rootUrl: "facebook.com/",
        iconStyle: "fa fa-facebook",
        color: "#4167b2",
    },
    {
        provider: "linkedIn",
        title: "LinkedIn",
        rootUrl: "linkedin.com/in/",
        iconStyle: "fa fa-linkedin-square",
        color: "#0076b5",
    },
    {
        provider: "twitter",
        title: "Twitter",
        rootUrl: "twitter.com/",
        iconStyle: "fa fa-twitter",
        color: "#1DA1F2",

    },
    {
        provider: "instagram",
        title: "Instagram",
        rootUrl: "instagram.com/",
        iconStyle: "fa fa-instagram",
        color: "#bb00ae",

    },
];

export default socialNetworks;
