import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { fetchJSON, formatCurrency } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

export class InvoiceRefund extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefundForm: false,
            invoice: {},
            didInitialLoad: false,
            loading: true,
            refundAmt: '',
            error: '',
            maxRefund: '',
        };

        this.fetchData = this.fetchData.bind(this);
        this.submitRefund = this.submitRefund.bind(this);

        this.url = `/api/v2/refund_invoice/${props.invoicePk}/`;
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const res = await fetchJSON(this.url);
        this.setState({
            invoice: res.invoice,
            maxRefund: res.max_refund,
            refundAmt: res.max_refund,
            loading: false,
            didInitialLoad: true,
        });
    }

    submitRefund(e) {
        e.preventDefault();
        this.setState({loading: true});
        const data = {
            amount: this.state.refundAmt,
        };
        $.post(this.url, data, res => {
            this.setState({
                invoice: res.invoice,
                maxRefund: res.max_refund,
                refundAmt: res.max_refund,
                loading: false,
                error: '',
            });
            this.props.callback();
        }).fail(jqXHR => {
            this.setState({
                loading: false,
                error: jqXHR.responseJSON.error,
            });
        });
    }

    render() {
        const { invoice, error, refundAmt, maxRefund,
                loading, didInitialLoad, showRefundForm } = this.state;
        const fullyRefunded = Number(invoice.amount_refunded) === invoice.total;
        const overMax = refundAmt > maxRefund;
        const refundForm = didInitialLoad && (
            <div className="well">
              {error &&
               <div className="alert alert-danger" role="alert">
                 {error}
               </div>
              }
              {overMax &&
               <div className="alert alert-danger" role="alert">
                 Amount to refund is too high.
               </div>
              }
              <form className="form-horizontal"
                    disabled={loading}
                    onSubmit={this.submitRefund}>
                <div className="form-group">
                  <label className="col-md-3 col-md-offset-2"
                         style={{textAlign: 'right'}}>
                    Invoice total
                  </label>
                  <label className="col-md-5">
                    {formatCurrency(invoice.total)}
                    {fullyRefunded &&
                     <span className="text text-info">{' '}Fully refunded</span>
                    }
                  </label>
                </div>
                <div className="form-group">
                  <label className="col-md-3 col-md-offset-2 control-label">
                    Amount to refund
                  </label>
                  <div className="col-md-4">
                    <div className="input-group">
                      <div className="input-group-addon input-sm">$</div>
                      <input name="refundAmt"
                             type="number"
                             className="form-control"
                             value={refundAmt}
                             disabled={loading || fullyRefunded}
                             onChange={e =>
                                 this.setState({refundAmt: e.target.value})}
                      />
                    </div>
                    {invoice.amount_refunded &&
                     <span id="helpBlock" className="help-block">
                       {formatCurrency(invoice.amount_refunded)}
                       {' '}
                       refunded
                     </span>
                    }
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-3 col-md-offset-5">
                    <button className="btn btn-primary"
                            onClick={this.submitRefund}
                            disabled={loading || fullyRefunded || overMax}>
                      {this.state.loading
                      && <span><LoadingSpinner /> Refunding...</span>
                      || <span>Refund Invoice</span>}
                    </button>
                  </div>
                  <div className="col-md-1">
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.setState({refundAmt: maxRefund});
                    }}
                            className="btn btn-link btn-text">
                      Set to max
                    </button>
                  </div>
                </div>
              </form>
            </div>
        );
        return (
            <div>
              {showRefundForm && refundForm}
              {!showRefundForm &&
               <button className="btn btn-default btn-ghost btn-small"
                       onClick={() => this.setState({showRefundForm: true})}>
                 Refund this Invoice
               </button>
              }
            </div>
        );
    }
}

InvoiceRefund.propTypes = {
    invoicePk:  PropTypes.number.isRequired,
    callback: PropTypes.func,
};

let root = null;

export default function InvoiceRefundApp(el, invoicePk) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<InvoiceRefund
                    invoicePk={invoicePk} />);
}
