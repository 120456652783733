import React from 'react';
import { createRoot } from 'react-dom/client';
import SocialPostEntry from './social/SocialPostEntry.jsx';

let root = null;

function SocialPostEntryApp(el, props) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    props = props || {};
    root.render(<SocialPostEntry {...props} />);
}

/* not `default` since we might add more widgets here */
export { SocialPostEntryApp };
