import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'style-loader!css-loader!react-image-crop/dist/ReactCrop.css';
import LoadingSpinner from './LoadingSpinner.jsx';

function ImageCropper(props) {
    // when rendered assume that we are cropping, so show everything
    const [croppingImageSrc, setCroppingImageSrc] = useState(null);
    /*
       cropParams format is straight from the crop libary
       https://github.com/DominicTobias/react-image-crop/
       {
       x: 66.33877563476562,
       y: 52.1519775390625,
       width: 664.1618957519531,
       height: 269.93963623046875,
       unit: 'px'
       }
     */
    const [cropParams, setCropParams] = useState();
    const [savingCrop, setSavingCrop] = useState(false);
    const cropImgRef = useRef(null);

    useEffect(() => {
        const file = props.serializedImage.originalImage;
        const reader = new FileReader();
        reader.onload = () => {
            setCroppingImageSrc(reader.result);
        };
        reader.readAsDataURL(file);
        return () => {};
    }, [props.serializedImage]);

    async function cropWithCanvas() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = cropParams.width;
        canvas.height = cropParams.height;
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        ctx.drawImage(
            cropImgRef.current,
            cropParams.x,
            cropParams.y,
            cropParams.width,
            cropParams.height,
            0,
            0,
            cropParams.width,
            cropParams.height,
        );
        const blob = await new Promise(resolve => canvas.toBlob(resolve));
        return new File([blob], props.serializedImage.name, {type: props.serializedImage.type});
    }

    async function confirmCrop(e) {
        e.preventDefault();
        setSavingCrop(true);
        let formData = new FormData();
        formData.append("invitemimage_pk", props.serializedImage.id);
        const imageData = await cropWithCanvas();
        formData.append("image_full", imageData);
        fetch("/api/v2/replace_invitemimage/", {
            method: "POST",
            body: formData,
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
            },
        }).then(rsp => {
            if (rsp.ok) {
                return rsp.json().then(responseCropImageFile => {
                    props.callback({
                        ...responseCropImageFile,
                        // allow for croppin the crop
                        originalImage: imageData,
                    });
                    setCroppingImageSrc(null);
                    setCropParams(undefined);
                    setSavingCrop(false);
                });
            } else {
                setSavingCrop(false);
                toast.error('Error cropping image. Please try again.');
            }
        });
    }

    if (!croppingImageSrc) return null;

    return <div style={{
        position: "absolute",
        zIndex: 1,
        background: "white",
        padding: "15px",
        border: "1px solid gray",
        borderRadius: "3px",
    }}>
        <div style={{marginBottom: "5px"}}>
            <button
                className="btn btn-primary"
                style={{marginBottom: "5px"}}
                disabled={savingCrop}
                onClick={confirmCrop}
            >
                {savingCrop &&
                 <span><LoadingSpinner /> Saving cropped image...</span>
                ||
                 <span>Confirm Crop</span>
                }
            </button>
            <button
                className="btn btn-secondary"
                style={{marginBottom: "5px", marginLeft: "5px"}}
                onClick={() => {
                    setCroppingImageSrc(null);
                }}
            >
                Cancel
            </button>
        </div>
        <div style={{marginBottom: "5px"}}>
            <small className="help-block">
                Click and drag to select the image crop
            </small>
        </div>
        <div>
            <ReactCrop
                crop={cropParams}
                onChange={setCropParams}
                style={{maxWidth: 'none'}}
            >
                <img
                    src={croppingImageSrc}
                    ref={cropImgRef}
                    style={{maxWidth: 'none'}}
                />
            </ReactCrop>
        </div>
    </div>;
}

ImageCropper.propTypes = {
    serializedImage: PropTypes.shape({
        // from jqueryforms.serialize.serialize, not using all of them
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        // originalImage added in
        originalImage: PropTypes.object,
    }),
    // new serializedImage is passed in the callback, includes all fields from
    // the serializer plus originalImage
    callback: PropTypes.func,
};

export default ImageCropper;
