import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import StrSelect from './StrSelect.jsx';

export function PartyDetailsFilters(props) {
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(() => {
        props.refreshCards(selectedStyles, selectedSizes, selectedCategories);
    }, [selectedStyles, selectedSizes, selectedCategories]); // eslint-disable-line react-hooks/exhaustive-deps

    const getLabel = (option) => {
        return <div>
          {option.value}
          {' '}
          <span className="badge">{option.count}</span>
        </div>;
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // bump the zIndex out from 1 to 101 to cover featured item stars
            zIndex: 101,
        }),
    };

    return <div className="row">
      {props.styleCounts && props.styleCounts.length !== 0 &&
       <div className="col-md-4" style={{marginBottom: '10px'}}>
         <StrSelect
             isMulti={true}
             options={props.styleCounts.map(
                 styleCount => {
                     return {
                         name: `${styleCount.name} (${styleCount.all_count})`,
                         value: styleCount.name,
                         count: styleCount.all_count,
                     };
                 }
             )}
             onChange={(options) => {
                 setSelectedStyles(
                     Array.from(
                         options,
                         option => option.value
                     )
                 );
             }}
             getLabel={getLabel}
             placeholder={`Filter by ${StrUserInfo.words.style}`}
             customStyles={customStyles}
         />
       </div>
      }
      {props.sizeCounts && props.sizeCounts.length !== 0 &&
       <div className="col-md-4" style={{marginBottom: '10px'}}>
         <StrSelect
             isMulti={true}
             options={props.sizeCounts.map(
                 sizeCount => {
                     return {
                         name: `${sizeCount.size} (${sizeCount.size__count})`,
                         value: sizeCount.size,
                         count: sizeCount.size__count,
                     };
                 }
             )}
             onChange={(options) => {
                 setSelectedSizes(
                     Array.from(
                         options,
                         option => option.value
                     )
                 );
             }}
             getLabel={getLabel}
             placeholder={`Filter by ${StrUserInfo.words.size}`}
             customStyles={customStyles}
         />
       </div>
      }
      {props.categoryCounts && props.categoryCounts.length !== 0 &&
       <div className="col-md-4" style={{marginBottom: '10px'}}>
         <StrSelect
             isMulti={true}
             options={props.categoryCounts.map(
                 categoryCount => {
                     return {
                         name: `${categoryCount.name} (${categoryCount.all_count})`,
                         value: categoryCount.name,
                         count: categoryCount.all_count,
                     };
                 }
             )}
             onChange={(options) => {
                 setSelectedCategories(
                     Array.from(
                         options,
                         option => option.value
                     )
                 );
             }}
             getLabel={getLabel}
             placeholder={`Filter by Category`}
             customStyles={customStyles}
         />
       </div>
      }
    </div>;
}

PartyDetailsFilters.propTypes = {
    styleCounts: PropTypes.array,
    sizeCounts: PropTypes.array,
    categoryCounts: PropTypes.array,
    refreshCards: PropTypes.func.isRequired,
};

export default function PartyDetailsFiltersApp(
    el,
    styleCounts,
    sizeCounts,
    categoryCounts,
    refreshCards,
) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<PartyDetailsFilters styleCounts={styleCounts}
                                     sizeCounts={sizeCounts}
                                     categoryCounts={categoryCounts}
                                     refreshCards={refreshCards} />);
}
