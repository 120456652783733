import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StrToggleable from './StrToggleable.jsx';
import ItemChoicePicker from './ItemChoicePicker.jsx';
import { fetchJSON } from './util.jsx';

const ImageLibraryPicker = (props) => {
    const [selected, setSelected] = useState([]);
    const [images, setImages] = useState([]);
    const [nextApiUrl, setNextApiUrl] = useState(false);
    const [selectedItemChoice, setSelectedItemChoice] = useState(null);

    const fetchData = async (url = null) => {
        const lastImage = images.length > 0 ? images[images.length - 1] : null;
        let urlToFetch = url || "/api/v2/images_list/?sizes=thumb";
        if (selectedItemChoice)
            urlToFetch += `&itemchoice=${selectedItemChoice.pk}`;
        if (lastImage)
            urlToFetch += `&lt=${lastImage.pk}`;
        const data = await fetchJSON(urlToFetch);
        setImages(images.concat(data.results));
        setNextApiUrl(data.next);
    };

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchData();
    }, [selectedItemChoice]); // eslint-disable-line react-hooks/exhaustive-deps

    const isSelected = (imagePk) => {
        return selected.some(i => i.pk === imagePk);
    };

    const toggle = (image) => {
        const newSelected = isSelected(image.pk)
                          ? selected.filter(i => i.pk !== image.pk)
                          : selected.concat(image);
        setSelected(newSelected);
        props.onPick(newSelected);
    };

    const onItemChoicePick = (itemChoice) => {
        setImages([]);
        setSelectedItemChoice(itemChoice);
        setSelected([]);
        props.onPick([]);
    };

    const header = <button type="button"
                           className="btn btn-link">Select from your Image Library</button>;
    const content = (
        <div>
          <div className="row">
            <div className="col-md-5">
              <ItemChoicePicker onPick={onItemChoicePick}
                                isMulti={false} />
            </div>
          </div>
          <div className="row" style={{marginTop: "10px"}}>
            {images.map(img => {
                const thumbStyle = isSelected(img.pk)
                                 ? { backgroundColor: "#aaaac6" }
                                 : {};
                return (
                    <div key={img.pk}
                         className="col-md-3">
                      <div style={thumbStyle}
                           onClick={() => toggle(img)}
                           className="thumbnail">
                        <img src={img.thumb}
                             className="img-responsive" />
                      </div>
                    </div>
                );
            })}
            {nextApiUrl &&
             <button type="button"
                     className="btn btn-link"
                     onClick={(e) => fetchData(nextApiUrl)}>
               More...
             </button>
            }
          </div>
        </div>
    );

    return (
        <div>
          <StrToggleable
              header={header}
              body={content}
          />
        </div>
    );
};

ImageLibraryPicker.propTypes = {
    // Receives an array of all selected images
    onPick: PropTypes.func.isRequired,
};

export default ImageLibraryPicker;
