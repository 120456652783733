import { strRequestJSON, filterOutEmoji } from './util.jsx';
import { flatten } from 'lodash-es';

// @partySelectNode - DOM node
// @itemPks - array of item pks. Might have duplicates. Can be null
// @batchPks - array of batch pks. Can be null
// @dropship - Bool indicating if this is a dropship request
async function populateSelects(
    partySelectNode,
    itemPks,
    batchPks,
    dropship,
) {
    itemPks = itemPks || [];
    batchPks = batchPks || [];
    dropship = !!dropship;
    // quick and dirty loading indicator
    const presentNodes = [
        partySelectNode,
    ].filter(Boolean);
    for (const node of presentNodes) {
        node.disabled = true;
        $(node).html("<option>Loading...</option>");
    }

    /* disable these nodes and submit buttons near them, banks on them
     * being in a collapse */
    const formInputsToDisable = $(flatten(
        [
            partySelectNode,
        ].map(function(node) {
            return $(node).closest('div.collapse').find(':input').toArray();
        })
    ));
    formInputsToDisable.prop('disabled', true);

    // grab the parties and fbposts
    const url = "/api/v2/postings_info/";
    const data = await strRequestJSON(url, 'POST', {
        itemPks,
        batchPks,
        dropship,
    });

    // some mappings from pks to names
    const parties = {};

    for (const party of data.parties)
        parties[party.pk] = party.name;

    // and update the selects!
    let selects = [{
        node: partySelectNode,
        options: parties,
    }];

    for (const select of selects) {
        select.node.disabled = false;
        // party like it's 2008!
        const $select = $(select.node);
        $select.html("");
        const options = Object.entries(select.options)
                              .sort(([aPk, aName], [bPk, bName]) => {
                                  return filterOutEmoji(aName)
                                      .trim()
                                      .localeCompare(
                                          filterOutEmoji(bName).trim()
                                      );
                              });
        for (const [pk, name] of options)
            $("<option/>", {'value': pk, 'text': name}).appendTo($select);
    }

    formInputsToDisable.prop('disabled', false);
}

export default {
    populateSelects,
};
