import React from 'react';
import PropTypes from 'prop-types';
import { getUser } from '../../UserCache.jsx';


class SocialClaimItemCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            consultant: null,
        };

        this.item = JSON.parse(this.props.activity.item);

        this.fetch = this.fetch.bind(this);
    }

    render() {
        const { activity } = this.props;
        const { consultant } = this.state;
        const party = JSON.parse(activity.party || null);

        let what;
        if (this.item.listingLink)
            what = <a href={this.item.listingLink}>{this.item.shortName}</a>;
        else
            what = this.item.shortName;

        return (
            <div className="text-muted">
              <strong>
                <a href={activity.userData.profileLink}>{activity.userData.displayName}</a> claimed an item: {what}
              </strong>
              {consultant &&
               /* TODO: make this a profile link once social is announced */
               <div>Retailer: {consultant.displayName}</div>
              }
              {party &&
               <div>
                 {StrUserInfo.words.party}:{' '}
                 <a href={`/party/${party.public_id}p/`}>{party.name}</a>
               </div>
              }
              <img className="ffArticleMediaImage img-responsive" src={this.item.image.image_full} />
            </div>
        );
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        const consultant = await getUser(this.item.consultant);
        this.setState({consultant});
    }
}

SocialClaimItemCard.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default SocialClaimItemCard;
