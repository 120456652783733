import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const LOCALSTORAGE_KEY = 'strLastPartySelected';

function storeSelectedPartyPk(partyPk) {
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(partyPk));
}

function getPreviousSelectedPartyPk() {
    try {
        return Number(window.localStorage.getItem(LOCALSTORAGE_KEY));
    } catch(e) {
        return;
    }
}

export default function PartySelect(props) {
    const {
        partyFilter,
        adminOnly = false,
        orderByDate = false,
        selectPreviousOnInit = true,
        fullPartyInfo = false,
    } = props;
    const [parties, setParties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [party, setParty] = useState(null);
    const partyOption = (party) => {
        return {
            value: party.pk,
            label: party.name,
            cover: party.cover_photo,
            party: party,
        };
    };
    useEffect(() => {
        let url = fullPartyInfo
                ? '/api/v2/users/parties/'
                : '/api/v2/users/parties_name_and_cover/';
        let getParams = {};
        if (adminOnly)
            getParams["admin_only"] = "1";
        if (orderByDate)
            getParams["order_by_date"] = "1";
        if (Object.keys(getParams).length > 0)
            url += "?" + (new URLSearchParams(getParams)).toString();
        $.getJSON(url, (parties) => {
            setParties(parties);
            setLoading(false);
            if (selectPreviousOnInit && getPreviousSelectedPartyPk()) {
                const previous = parties.find(p => p.pk === getPreviousSelectedPartyPk());
                if (previous) {
                    setParty(partyOption(previous));
                    props.onSelect({
                        "pk": previous.pk,
                        "name": previous.name,
                        "value": previous.pk,
                    });
                }
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const options = useMemo(() => {
        const partiesFiltered = partyFilter
                              ? parties.filter(partyFilter)
                              : parties;
        return partiesFiltered.map(partyOption);
    }, [parties, partyFilter]);
    function formatOptionLabel(option) {
        return <div style={{zIndex: 5}}>
          <img height={30}
               style={{
                   height: '30px',
                   maxWidth: '100%',
                   display: 'inline-block',
                   marginRight: '10px',
               }}
               src={option.cover} />
          {option.label}
        </div>;
    }
    function onOptionChange(option) {
        setParty(option);
        props.onSelect({"pk": option.value, "name": option.label, ...option});
        storeSelectedPartyPk(option.value);
    }
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // bump the zIndex out from 1 to 4 to cover datatables
            // paginator's selected value
            zIndex: 4,
        }),
    };
    return <Select placeholder={loading ? "Parties loading..." : "Select party"}
                   styles={customStyles}
                   options={options}
                   formatOptionLabel={formatOptionLabel}
                   onChange={onOptionChange}
                   value={party}
                   isClearable={true}
                   isDisabled={loading}
                   isLoading={loading}
                   openMenuOnFocus={true} />;
}

PartySelect.propTypes = {
    onSelect: PropTypes.func.isRequired,
    orderByDate: PropTypes.bool,
    // When true, automatically select the last party selected as per
    // localstorage history.
    selectPreviousOnInit: PropTypes.bool,
    // Function that can be provided to filter down the list of parties.
    // Useful if you'd like to exclude a party or two, for example.
    partyFilter: PropTypes.func,
    // Return full party info rather than just name and cover. Results in a
    // slightly more expensive API call.
    fullPartyInfo: PropTypes.bool,
    adminOnly: PropTypes.bool,
};
