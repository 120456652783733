import React from 'react';
import { createRoot } from 'react-dom/client';
import RewardCard from './RewardCard.jsx';
import {formatCurrency} from '../util.jsx';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import {successOrRedirect} from '../util.jsx';

class RewardsSales extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            rewardCards: [],
        };
        this.updateRewardCardEarned = this.updateRewardCardEarned.bind(this);
        this.alterAwards = this.alterAwards.bind(this);
        this.handleHiddenChk = this.handleHiddenChk.bind(this);
        this.fetchRewardCards = this.fetchRewardCards.bind(this);
        this.fetchRewardCards();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.customerPk !== prevProps.data.customerPk)
            this.fetchRewardCards();
    }

    render() {
        const rewardCards = this.state.rewardCards.map(rewardCard => {
            return (
                <div className="row well"
                     style={{backgroundColor: '#f9f9f9'}}
                     key={rewardCard.pk}>
                  <div className="col-md-6">
                    <h4>{rewardCard.reward_program.name}</h4>
                    <p>
                      {rewardCard.reward_program.description_if}
                      {' '}
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      {' '}
                      {rewardCard.reward_program.description_then}
                    </p>
                    <span className="checkbox" style={{marginRight:'10px'}}>
                      <label
                          title="Shoppers will not see hidden reward cards in their dashboard">
                        <input type="checkbox"
                               checked={rewardCard.hidden}
                               onChange={(e) => this.handleHiddenChk(rewardCard, event)} />
                        <span style={{fontSize: '12px'}}>Hide from shopper</span>
                      </label>
                    </span>
                  </div>
                  <div className="col-md-6">
                    <RewardCard
                        programData={rewardCard.reward_program}
                        rewardCard={rewardCard}
                        owner={true}
                        updateFunc={this.updateRewardCardEarned}
                        awardFunc={this.alterAwards}
                        orderPrice={this.props.data.orderPrice}
                        orderNo={this.props.data.orderNo}
                    />
                  </div>
                </div>
            );
        });
        // STR does not have order number
        const orderNo = is_str ? ": " : ` (Order #${this.props.data.orderNo})`;
        const underlyingCustomer = this.state.rewardCards[0] ?
                                   this.state.rewardCards[0].customer_name
                                 : null;
        return(
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h2 className="modal-title">Rewards Cards{orderNo} {underlyingCustomer}</h2>
              </div>
              <div className="modal-body">
                <h3>Items: {this.props.data.itemCount} - <strong>{formatCurrency(this.props.data.orderPrice)}</strong></h3>
                {rewardCards}
              </div>
            </div>
        );
    }

    fetchRewardCards() {
        const self = this;
        const url = `/api/v2/reward_cards/${this.props.data.customerPk}/user_cards/`;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            self.setState({rewardCards: json});
        });
    }

    // TODO shared function with other component
    updateRewardCardEarned(rewardCard, earned) {
        const self = this;
        /* updating the earned may trigger any number of rewards and the
         * earned value to change so we need to get all that data*/
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        // doing this parsing in case earned is null
        const parsedEarned = rewardCard.earned ? parseFloat(rewardCard.earned) : 0.0;
        const newEarned = parseFloat(earned) + parsedEarned;
        const data = {pk: rewardCard.pk, earned: newEarned};
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

    // TODO shared function with other component
    alterAwards(rewardCard, redeemInc, remove=null) {
        const self = this;
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        let newEarnedRewards = rewardCard.earned_rewards -= redeemInc;
        let newRedeemedRewards = rewardCard.redeemed_rewards += redeemInc;
        if (remove) {
            newEarnedRewards = rewardCard.earned_rewards;
            newRedeemedRewards = rewardCard.redeemed_rewards -= 1;
        }
        const data = {
            pk: rewardCard.pk,
            earned_rewards: newEarnedRewards,
            redeemed_rewards: newRedeemedRewards,
        };
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

    handleHiddenChk(rewardCard, event) {
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        const data = {
            pk: rewardCard.pk,
            hidden: !rewardCard.hidden,
        };
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

}

RewardsSales.propTypes = {
    data: PropTypes.object.isRequired,
};

function RewardsSalesApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<RewardsSales data={data} />);
}

export default RewardsSalesApp;
