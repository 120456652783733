import { setIdleTimeout } from './util.jsx';

async function strWebsocket(group_name, callback, staleFallback) {
    var fallbackTimer = null;
    if (!strConfig.websocketBaseUrl) {
        return {};
    }
    var url = strConfig.websocketBaseUrl + '/ws/' + group_name + '/';
    if (strConfig.onCustomDomain && group_name.startsWith("private-")) {
        var token = strConfig.websocketAuthToken;
        if (!token) {
            token = await fetch('/api/v2/websocket_auth_token/')
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    }
                });
            strConfig.websocketAuthToken = token;
            if (!token) {
                return {};
            }
        }
        url += '?t=' + token;
    }
    var ws = new WebSocket(url);
    ws.onopen = function(e) {
        if (strConfig.isDebug || strConfig.isStaging) {
            // eslint-disable-next-line no-console
            console.log("strWebsocket connection succeeded: ", group_name);
        }
        if (staleFallback) {
            setIdleTimeout(
                1000 * 60 * 60 * 24,
                // onIdle
                () => {
                    if (strConfig.isDebug || strConfig.isStaging) {
                        // eslint-disable-next-line no-console
                        console.log("switching to staleFallback: ", group_name);
                    }
                    ws.closeNoRetry();
                    setInterval(staleFallback, 1000 * 30);
                },
                // onUnidle
                () => {},
                // onStartIdleTimer
                (timer) => {
                    // so that the timer can be cleared when we want to as well
                    fallbackTimer = timer;
                }
            );
        }
    };
    ws.onmessage = function(e) {
        if (callback) {
            callback(JSON.parse(e.data));
        }
        if (strConfig.isDebug || strConfig.isStaging) {
            // eslint-disable-next-line no-console
            console.log("strWebsocket message: ", group_name, JSON.parse(e.data));
        }
    };
    ws.addExtraListener = function(callback) {
        ws.addEventListener("message", function(e) {
            if (callback) {
                callback(JSON.parse(e.data));
            }
        });
    };
    ws.onerror = function(err) {
        ws.close();
    };
    ws.closeNoRetry = function() {
        if (ws.readyState === WebSocket.OPEN) {
            ws.close();
            if (strConfig.isDebug || strConfig.isStaging) {
                // eslint-disable-next-line no-console
                console.log("strWebsocket closed (noretry): ", group_name);
            }
            if (fallbackTimer) {
                clearTimeout(fallbackTimer);
            }
        } else if (ws.readyState === WebSocket.CONNECTING) {
            // connection isn't set up yet. When it does connect, make sure
            // we don't process any messages or errors (which could result
            // in callbacks being fired that try to set state on unmounted
            // components), and then set the onopen handler to immediately
            // close ourself.
            ws.onmessage = null;
            ws.onerror = null;
            ws.onopen = function() {
                ws.closeNoRetry();
            };
        }
    };
    return ws;
}

export default strWebsocket;
