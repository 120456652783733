import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ScheduledPost from './ScheduledPost.jsx';

const ScheduledPostContainer = ({ post, doRefresh }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => setOpen(!open);

    return (
        <div>
          <button
              onClick={toggleOpen}
              className="btn btn-default btn-block text-left"
              style={{ whiteSpace: 'normal', marginBottom: '5px' }}
              aria-expanded={open}
          >
            <i className="fa fa-caret-down"></i>
            {' '}
            {moment(post.post_later).format('LLLL')}
            <div>
              <small className="text-left">
                {post.text}
              </small>
            </div>
          </button>
          <div className={`collapse ${open ? 'in' : ''}`}>
            <ScheduledPost post={post} doRefresh={doRefresh} />
          </div>
        </div>
    );
};

ScheduledPostContainer.propTypes = {
    doRefresh: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
};

export default ScheduledPostContainer;
