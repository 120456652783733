import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Datetime from 'react-datetime';
import styles from 'react-datetime/css/react-datetime.css';

const CalendarPicker = ({postDatetime, onDatetimeChange,
                         onChange, timezone}) => {
    return (
        <div className="row" style={{paddingBottom: "10px"}}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Datetime value={postDatetime} onChange={onDatetimeChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Timezone:</label>
                <select className="form-control"
                        placeholder="Select time zone"
                        name="timezone"
                        value={timezone}
                        onChange={onChange}>
                  {[""].concat(window.StrTimezones).map(tz => {
                      return <option value={tz} key={tz}>{tz}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
    );
};

CalendarPicker.propTypes = {
    onChange: PropTypes.func,
    onDatetimeChange: PropTypes.func,
    postDatetime: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    timezone: PropTypes.string,
};


class PostDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFocused: false,
            showDatetimePicker: false,
        };
    }
    render() {
        const { postNow, postDatetime, timezone,
                onChange, onDatetimeChange } = this.props;
        return (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="radio">
                    <span>{' '}
                      <label>
                        <input type="radio"
                               name="postNow"
                               value={"NOW"}
                               onChange={onChange}
                               checked={postNow} />
                          Now
                      </label>
                    </span>
                    <span>{' '}
                      <label>
                        <input type="radio"
                               name="postNow"
                               value={''}
                               onChange={onChange}
                               checked={!postNow} />
                        Later
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              {!postNow &&
               <CalendarPicker postDatetime={postDatetime}
                               timezone={timezone}
                               onChange={onChange}
                               onDatetimeChange={onDatetimeChange} />
              }
            </div>
        );
    }
}

PostDatePicker.propTypes = {
    onChange: PropTypes.func,
    onDatetimeChange: PropTypes.func,
    postDatetime: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    postNow: PropTypes.string,
    timezone: PropTypes.string,
};

export default PostDatePicker;
