import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { fetchJSON } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import Invoice from './invoice/Invoice.jsx';
import InvoiceList from './invoice/InvoiceList.jsx';

class Invoicing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            invoiceIdx: null,
            claimbatch: {},
            consultant: {},
            loading: null,
            paymentInfo: {},
            error: null,
        };

        this.fetchData = this.fetchData.bind(this);
        this.setInvoiceIdx = this.setInvoiceIdx.bind(this);
        this.stripePromise = loadStripe(stripe_pub_api_key);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData(passedData=null, customLineItems=null, addNew=null) {
        this.setState(prevState => ({
            loading: this.state.loading !== null ? true : null,
        }));
        if (passedData) {
            return this.setState({
                claimbatch: passedData.claimbatch,
                invoices: passedData.invoices,
                consultant: passedData.consultant,
                loading: false,
                paymentInfo: passedData.payment_info,
            });
        }
        const url = `/api/v2/invoices/${this.props.data.orderPk}/`;
        let data = {};
        if (customLineItems !== null)
            data['customLineItems'] = JSON.stringify(
                customLineItems.map(cli => {
                    if (cli.crPk) {
                        /* if there is a pk, only pass price and pk.
                         * this saves a ton of space in the GET request
                         * and will be filled back in on the
                         * server. stripped fields: name, original price,
                         * thumbnail, id */
                        return {
                            crPk: cli.crPk,
                            price: cli.price,
                        };
                    } else {
                        return cli;
                    }
                })
            );
        if (addNew)
            data['addNew'] = true;
        $.getJSON(url, data, (rsp) => {
            return this.setState(prevState => {
                /* if we are on a specific invoices page then we just
                 * want the one! */
                const invoices = this.props.data.invoicePk
                               ? [rsp.invoices.find(inv =>
                                   inv.pk === this.props.data.invoicePk)]
                               : rsp.invoices;
                /* we want to select the first invoice (idx 0) for
                 * initial load if there is one invoice or if we have
                 * added a new invoice just now, otherwise we want to
                 * keep the same invoice selected */
                const invoiceIdx = addNew
                                || (invoices.length === 1
                                 && prevState.invoices.length <= 1)
                                 ? 0 : prevState.invoiceIdx;
                return {
                    claimbatch: rsp.claimbatch,
                    invoices: invoices,
                    invoiceIdx: invoiceIdx,
                    consultant: rsp.consultant,
                    loading: false,
                    paymentInfo: rsp.payment_info,
                    error: rsp.error,
                };
            });
        });
    }

    setInvoiceIdx(idx) {
        this.setState({invoiceIdx: idx});
    }

    renderTheBody() {
        // only want this guy to show on the initial load, subsequent
        // loading events handled elsewhere
        if (this.state.loading === null)
            return <LoadingSpinner />;
        const { invoiceIdx, invoices, consultant,
                claimbatch, loading, error} = this.state;
        const addNewInvoice = consultant
                           && consultant.pk === StrUserInfo.uuid
                           && invoices.filter(inv => inv.pk === null)
                                      .length === 0
                           && <button onClick={() => this.fetchData(null,
                                                                    null,
                                                                    true)}
                                      disabled={loading}
                                      className="btn btn-default">
                             Create new invoice for this order
                           </button>;
        const sharedProps = {
            claimbatch: claimbatch,
            consultant: consultant,
            callback: this.fetchData,
            markInvoiced: this.props.data.markInvoiced,
            loading: loading,
            addNewInvoice: addNewInvoice,
        };
        const invoice = invoiceIdx !== null && invoices[invoiceIdx];
        let invoiceWidget = null;
        if (invoice) {
            if (invoice.pk) {   // existing invoice
                invoiceWidget = <Invoice allowPay={this.props.data.allowPay}
                                         allowCreate={this.props.data.allowCreate}
                                         invoice={invoice}
                                         paymentInfo={this.state.paymentInfo}
                                         {...sharedProps} />;
            } else {            // brand new unsaved invoice
                invoiceWidget = <Invoice invoice={invoice}
                                         {...sharedProps} />;
            }
        } else if (invoices.length > 0) {
            invoiceWidget = <InvoiceList invoices={invoices}
                                         setInvoiceIdx={this.setInvoiceIdx}
                                         {...sharedProps} />;
        }
        return (
            <Elements stripe={this.stripePromise}>
              <div>
                {error && <p className="text-danger">{error}</p>}
                {invoices.length > 1 && invoiceIdx !== null &&
                 <button className="btn btn-default btn-small"
                         onClick={() => this.setState({invoiceIdx: null})}>
                   <span className="glyphicon glyphicon-menu-left"></span>
                   {' '}
                   All Invoices
                 </button>
                }
                {invoiceWidget}
              </div>
            </Elements>
        );
    }

    render() {
        if (this.props.data.inModal) {
            return (
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.renderTheBody()}
                  </div>
                </div>
            );
        } else {
            return this.renderTheBody();
        }
    }
}

Invoicing.propTypes = {
    data: PropTypes.shape({
        orderPk:  PropTypes.number.isRequired,
        invoicePk:  PropTypes.number,
        inModal: PropTypes.bool,
        allowPay: PropTypes.bool,
        allowCreate: PropTypes.bool,
        markInvoiced: PropTypes.func,
    }),
};

let root = null;

export default function InvoiceApp(el, data) {
    if (el === null)
        return;
    if (root)
        root.unmount(el);
    root = createRoot(el);
    root.render(<Invoicing data={data} />);
}
