import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CategoryPicker, { categoryPathToHtml } from './CategoryPicker.jsx';
import ExtraImagesContainer from './ExtraImagesContainer.jsx';
import DigitalDownloadsEditor from './DigitalDownloadsEditor.jsx';
import OptionsDesigner from './OptionsDesigner.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import MarketItemTags from './MarketItemTags.jsx';
import ClaimQuestionsPicker from './ClaimQuestionsPicker.jsx';
import { fetchJSON, strRequest } from './util.jsx';
import { HelpMe } from './HelpMe.jsx';
import { ItemAvails } from './constants.js';

const MarketItemForm = (props) => {
    const [loading, setLoading] = useState(!!props.itemPk);
    const [userPrefs, setUserPrefs] = useState(null);
    const [saving, setSaving] = useState(false);
    const [itemPk, setItemPk] = useState(props.itemPk);
    const [title, setTitle] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [tags, setTags] = useState([]);
    const [isDigital, setIsDigital] = useState(false);
    const [digitalDownloadPks, setDigitalDownloadPks] = useState([]);
    const [hasOptions, setHasOptions] = useState(false);
    const [itemVariants, setItemVariants] = useState([]);
    const [price, setPrice] = useState('');
    const [costPerItem, setCostPerItem] = useState('');
    const [quantity, setQuantity] = useState('1');
    const [claimQuestionPks, setClaimQuestionPks] = useState([]);
    const [shippingCalculationMethod, setShippingCalculationMethod] = useState(0);
    const [shippingFixedPrice, setShippingFixedPrice] = useState('');
    const [isPromoted, setIsPromoted] = useState(false);
    const [referralIncentive, setReferralIncentive] = useState('');
    const [dropShipConsultantPayout, setDropShipConsultantPayout] = useState('');
    const [dropShipLossDismissed, setDropShipLossDismissed] = useState(false);
    const [showDropShipLossConfirm, setShowDropShipLossConfirm] = useState(false);
    const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    function setItemFields(data) {
        setTitle(data.title);
        setSelectedCategory(data.category);
        setDescription(data.description);
        setTags(data.tags);
        setIsDigital(data.is_digital);
        setHasOptions(data.itemvariant_data.length !== 0);
        setItemVariants(data.itemvariant_data);
        setPrice(data.price);
        setCostPerItem(data.wholesale_price);
        setQuantity(data.quantity);
        setImages(data.images);
        setClaimQuestionPks(data.claimquestion_pks);
        setShippingCalculationMethod(data.shipping_calculation_method || 0);
        setShippingFixedPrice(data.data.shipping_fixed_price || 0);
        setReferralIncentive(data.referral_incentive || 0);
        setDropShipConsultantPayout(data.dropship_consultant_payout || 0);
        setIsPromoted(!!data.referral_incentive);
    }

    useEffect(() => {
        fetchJSON('/api/v2/user_preferences/').then((prefs) => {
            setUserPrefs(prefs);
        });
    }, []);

    useEffect(() => {
        if (itemPk) {
            $.getJSON(`/api/v2/market_items/${itemPk}/`, rsp => {
                setItemFields(rsp);
                setLoading(false);
            });
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!itemPk && params.category) {
            $.getJSON(`/api/v2/product_categories/${params.category}/`, rsp => {
                setSelectedCategory(rsp);
            });
        }
    }, [itemPk]);

    const onCategorySelect = useCallback((match) => {
        setSelectedCategory(match.displayData);
    }, []);

    async function onSave() {
        const requiredFields = [
            [title, 'title'],
            [selectedCategory, 'category'],
            [description, 'description'],
            [images, 'image'],
        ];
        const missingFieldNames = [];
        requiredFields.forEach(
            ([field, fieldName]) => {
                if (!field || field.length === 0) {
                    missingFieldNames.push(fieldName);
                }
            }
        );
        if (missingFieldNames.length > 0) {
            setErrorMessage('Please provide all required fields');
            setFieldsWithErrors(missingFieldNames);
            return;
        }
        if (isPromoted) {
            if (!referralIncentive || referralIncentive <= 0) {
                setErrorMessage('Referral incentive must be set for promoted products');
                setFieldsWithErrors(['referralIncentive']);
                return;
            }
        }
        if (props.isCatalogUser) {
            const dsPayout = Number(dropShipConsultantPayout);
            const cpi = Number(costPerItem);
            const priceNum = Number(price);
            if (!dropShipConsultantPayout || dsPayout <= 0) {
                setErrorMessage('Dropship retailer payout must be set for Sonlet Catalog products');
                setFieldsWithErrors(["dropShipConsultantPayout"]);
                return;
            }
            if (!dropShipLossDismissed && ((dsPayout + cpi) > priceNum)) {
                setErrorMessage(`Dropship retailer payout plus wholesale cost exceeds the price. You'll lose money on this sale.`);
                setFieldsWithErrors(["dropShipConsultantPayout"]);
                setShowDropShipLossConfirm(true);
                return;
            }
        }
        setErrorMessage('');
        setFieldsWithErrors([]);
        setSaving(true);
        const data = {
            title: title,
            image_id: images[0].id,
            image_pks: images.map(image => image.id),
            category_params: {
                pk: selectedCategory.pk,
                name: selectedCategory.name,
                parent_pk: selectedCategory.parent,
            },
            price: itemVariants.length > 0 ? null : (price || null),
            description: description,
            wholesale_price: costPerItem,
            quantity: quantity,
            availability: ItemAvails.FORSALE,
            is_digital: isDigital,
            digital_download_pks: digitalDownloadPks,
            item_variants: itemVariants,
            tags: tags,
            claimquestion_pks: claimQuestionPks,
            shipping_calculation_method: shippingCalculationMethod,
            shipping_fixed_price: shippingFixedPrice,
            referral_incentive: isPromoted ? referralIncentive : null,
            dropship_consultant_payout: props.isCatalogUser ? dropShipConsultantPayout : null,
        };

        if (itemPk) {
            const rsp = await strRequest(`/api/v2/market_items/${itemPk}/`, "PATCH", data);
            setSaving(false);
            if (rsp.ok) {
                const json = await rsp.json();
                setItemFields(json);
                toast.success("Saved");
            } else {
                if (rsp.status >= 500) {
                    toast.error(`${rsp.statusText}. Please try again later.`);
                } else {
                    const json = await rsp.json();
                    Object.entries(json).forEach(([fieldName, errorMessage]) => {
                        toast.error(`${fieldName}: ${errorMessage}`);
                    });
                }
            }
        } else {
            const rsp = await strRequest("/api/v2/market_items/", "POST", data);
            setSaving(false);
            if (rsp.ok) {
                const json = await rsp.json();
                setItemPk(json.pk);
                setItemFields(json);
                // update the url to the market_edit path
                const path = `/market/edit/${json.pk}/`;
                window.history.pushState({}, null, `${window.location.origin}${path}`);
                document.title = document.title.replace('Add', 'Edit');
                toast.success('Saved');
            } else {
                if (rsp.status >= 500) {
                    toast.error(`${rsp.statusText}. Please try again later.`);
                } else {
                    const json = await rsp.json();
                    Object.entries(json).forEach(([fieldName, errorMessage]) => {
                        toast.error(`${fieldName}: ${errorMessage}`);
                    });
                }
            }
        }
    }

    function onDigitalFileAdd(digitalDownload) {
        setDigitalDownloadPks(digitalDownloadPks.concat([digitalDownload.pk]));
    }

    function fieldErrorClass(classes, fieldName) {
        let hasError = '';
        if (fieldsWithErrors.includes(fieldName)) {
            hasError = ' has-error';
        }
        return `${classes}${hasError}`;
    }

    const uprefs = userPrefs || {};
    const isMarketSeller = uprefs.is_market_seller;
    const isPendingMarketSeller = isMarketSeller && !uprefs.can_process_payments;

    return (
        <div className="form">
          {userPrefs && isPendingMarketSeller &&
           <div className="alert alert-info">
             <p>
               <i className="fa fa-exclamation-circle"></i>
               {' '}
               Heads-up! You still haven't connected a bank account, so you
               won't be able to publicly list items for sale.
             </p>
             <p className="text-small">
               You can still add items
               to your inventory, but shoppers won't be able to find or
               purchase them until you connect a bank account, which you can
               do from the <a href="/accounts/setup/">Account Setup wizard</a>.
             </p>
             <p className="text-small">
               Any items you add now will go into your "Unlisted Items".
               After connecting a bank account you can move those items to
               "Listed for Sale".
             </p>
           </div>
          }
          <h2>Item details</h2>
          {errorMessage &&
           <div className="alert alert-danger" role="alert">
             {errorMessage}
           </div>
          }
          <div className={fieldErrorClass('row form-group', 'title')}>
            <div className="col-md-2">
              <label>
                Title *
              </label>
              <div className="help-block">
                Include keywords that buyers would use to search for your item.
              </div>
            </div>
            <div className="col-md-10" >
              <input type="text"
                     className="form-control"
                     name="item-title"
                     value={title}
                     onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>

          <div className={fieldErrorClass('row form-group', 'category')}>
            <div className="col-md-2">
              <label>
                Category *
              </label>
              <div className="help-block">
                Help more shoppers find your item by picking a category that describes your item.
              </div>
            </div>
            <div className="col-md-10">
              <CategoryPicker onSelect={onCategorySelect}
                              categoryName={selectedCategory && selectedCategory.name} />
              <br />
              {selectedCategory &&
               <div style={{marginTop: "15px"}}>
                 <i className="fa fa-check-circle text-success"></i>
                 {' '}
                 This item will be available in
                 {' '}
                 <span style={{borderBottom: "dotted 1px #555"}}>
                   <a href={`/market/category/${selectedCategory.slug}`}
                      target="_blank">
                     {categoryPathToHtml(selectedCategory.fullpath_strings)}
                   </a>
                 </span>
               </div>
              }
            </div>
          </div>

          <div className={fieldErrorClass('row form-group', 'description')}>
            <div className="col-md-2">
              <label>
                Description *
              </label>
              <div className="help-block">
                Describe your items finest features.
              </div>
            </div>
            <div className="col-md-10" >
              <textarea className="form-control"
                        name="item-description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>

          <div className={fieldErrorClass('row form-group', 'image')}>
            <div className="col-md-2">
              <label>
                Images *
              </label>
              <div className="help-block">
                Add photos so buyers can see every detail of your product.
              </div>
            </div>
            <div className="col-md-10">
              <ExtraImagesContainer showUploadWidget
                                    resource="invitemimage"
                                    invItemPk={itemPk}
                                    setImages={setImages} />
              <p>
                Images should be square for best results. The first image will
                be used as the item's thumbnail.
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-2">
              <label>
                Tags
              </label>
              <div className="help-block">
                This helps buyers find related products.
              </div>
            </div>
            <div className="col-md-10" >
              <MarketItemTags tags={tags} setTags={setTags} />
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-2">
              <label>
                Type *
              </label>
            </div>
            <div className="col-md-10" >
              <div className="radio-inline" style={{marginRight: '10px'}}>
                <label style={{cursor: 'pointer'}}>
                  <input type="radio"
                         name="typeRadio"
                         value="physical"
                         onChange={() => setIsDigital(false)}
                         disabled={!!itemPk}
                         checked={!isDigital} />
                  Physical
                  <br />
                  <span style={{fontWeight: '500'}}>
                    A physical item that will be shipped to buyers
                  </span>
                </label>
              </div>
              <div className="radio-inline" style={{marginLeft: 0}}>
                <label style={{cursor: 'pointer'}}>
                  <input type="radio"
                         name="typeRadio"
                         value="digital"
                         disabled={!!itemPk}
                         onChange={() => {
                             setIsDigital(true);
                             setHasOptions(false);
                         }}
                         checked={isDigital} />
                  Digital
                  <br />
                  <span style={{fontWeight: '500'}}>
                    Digital file(s) that buyers will download after paying
                  </span>
                </label>
              </div>
            </div>
          </div>

          {isDigital && <div>
            <div className="row form-group">
              <div className="col-md-2">
                <label>
                  Digital Files
                </label>
              </div>
              <div className="col-md-10" >
                <DigitalDownloadsEditor onAdd={onDigitalFileAdd}
                                        invItemPk={itemPk} />
              </div>
            </div>
          </div>
          }

          <h2>Options</h2>

          {!isDigital && <div>
            <div className="row form-group">
              <div className="col-md-8 col-md-offset-2">
                {!loading && !(itemVariants.length !== 0 && itemPk) &&
                 <label style={{cursor: 'pointer', fontWeight: '500'}}>
                   <input type="checkbox"
                          disabled={false}
                          checked={hasOptions}
                          name="hasOptions"
                          onChange={() => setHasOptions(!hasOptions)} />
                   {' '}
                   Add specific options for shoppers to purchase such as size,
                   color, etc.
                 </label>
                }
                {!loading && hasOptions &&
                 <OptionsDesigner itemVariants={itemVariants}
                                  initialPrice={price + ""}
                                  images={images}
                                  updateItemVariants={setItemVariants} />
                }
              </div>
            </div>
          </div>
          }

          {!hasOptions && !loading && <div>
            {!isDigital && <div>
              <div className="row form-group">
                <div className="col-md-2">
                  <label>
                    Quantity
                  </label>
                </div>
                <div className="col-md-2" >
                  <input type="number"
                         className="form-control"
                         value={quantity}
                         onChange={(e) => setQuantity(e.target.value)} />
                </div>
              </div>
            </div>
            }

            <div className="row form-group">
              <div className="col-md-2">
                <label>
                  Price
                </label>
              </div>
              <div className="col-md-2" >
                <div className="input-group">
                  <span className="input-group-addon">$</span>
                  <input type="number"
                         className="form-control"
                         placeholder="0.00"
                         value={price}
                         onChange={(e) => setPrice(e.target.value)} />
                </div>
              </div>
            </div>

          </div>
          }

          {!isDigital &&
           <div className="row form-group">
             <div className="col-md-2">
               <label>
                 Cost per item
               </label>
               <div className="help-block">
                 Customers won't see this
               </div>
             </div>
             <div className="col-md-2" >
               <div className="input-group">
                 <span className="input-group-addon">$</span>
                 <input type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={costPerItem}
                        onChange={(e) => setCostPerItem(e.target.value)} />
               </div>
             </div>
           </div>
          }

          <h2>Personalization</h2>
          <div className="row form-group">
            <div className="col-md-2">
              <label>
                Custom checkout questions
              </label>
              <div className="help-block">
                Collect additional information from the customer for this item
                (personalization options, etc)
              </div>
            </div>
            <div className="col-md-7" >
              {!loading &&
               <ClaimQuestionsPicker callback={setClaimQuestionPks}
                                     selectedQuestionPks={claimQuestionPks} />
              }
            </div>
          </div>

          {!isDigital && <div>
            <h2>Shipping</h2>

            {!loading &&
             <div className="row form-group">
               <div className="col-md-10 col-md-offset-2" >

                 <div className="radio-inline" style={{marginRight: '10px'}}>
                   <label style={{cursor: 'pointer'}}>
                     <input type="radio"
                            name="shippingRadio"
                            value="default"
                            onChange={() => {
                                setShippingCalculationMethod(0);
                            }}
                            checked={shippingCalculationMethod === 0} />
                     Default
                     <br />
                     <span style={{fontWeight: '500'}}>
                       Use your
                       {' '}
                       <a href="/settings/billing/"
                          target="_blank">
                         account shipping settings
                       </a>.
                     </span>
                   </label>
                 </div>
                 <br />
                 <div className="radio-inline" style={{marginLeft: 0}}>
                   <label style={{cursor: 'pointer'}}>
                     <input type="radio"
                            name="shippingRadio"
                            value="free"
                            onChange={() => {
                                setShippingCalculationMethod(1);
                            }}
                            checked={shippingCalculationMethod === 1} />
                     Free shipping
                     <br />
                     <span style={{fontWeight: '500'}}>
                       The price of this item includes the shipping cost.
                       Overrides account shipping settings.
                     </span>
                   </label>
                 </div>
                 <br />
                 <div className="radio-inline" style={{marginLeft: 0}}>
                   <label style={{cursor: 'pointer'}}>
                     <input type="radio"
                            name="shippingRadio"
                            value="fixed-price"
                            onChange={() => {
                                setShippingCalculationMethod(2);
                            }}
                            checked={shippingCalculationMethod === 2} />
                     Fixed price
                     <br />
                     <span style={{fontWeight: '500'}}>
                       This item has a fixed cost to ship. Overrides account
                       shipping settings.
                     </span>
                   </label>
                   {shippingCalculationMethod === 2 &&
                    <React.Fragment>
                      <p style={{fontWeight: '500'}}>
                        Cost to ship each of these items:
                      </p>
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input type="number"
                               className="form-control"
                               placeholder="0.00"
                               value={shippingFixedPrice}
                               onChange={(e) => setShippingFixedPrice(e.target.value)} />
                      </div>
                    </React.Fragment>
                   }
                 </div>

               </div>
             </div>
            }
          </div>
          }

          <h2>Promoted Product Settings</h2>
          <div className="row form-group">
            <div className="col-md-8 col-md-offset-2" >
              <HelpMe>
                <HelpMe.Caption>What are promoted products?</HelpMe.Caption>
                <HelpMe.Body>
                  Enabling product promotion increases your product's
                  visibility across Sonlet.com at no upfront cost. By promoting
                  your product, you agree to share a portion of your sales
                  proceeds with the store owner who brings a buyer to your
                  item. This is your <em>Referral Incentive</em>. The more generous
                  your incentive, the more likely store owners are to
                  feature your product. The referral incentive is paid out to the
                  referrer automatically when the product sells.
                </HelpMe.Body>
              </HelpMe>
              <label style={{cursor: 'pointer', fontWeight: '500'}}>
                <input type="checkbox"
                       disabled={false}
                       checked={isPromoted}
                       name="isPromoted"
                       onChange={() => setIsPromoted(!isPromoted)} />
                {' '}
                Highlight this product across Sonlet.com as a promoted item.
              </label>
            </div>
          </div>
          {isPromoted &&
           <div className={fieldErrorClass('row form-group', 'referralIncentive')}>
             <div className="col-md-2">
               <label>
                 Referral incentive *
               </label>
               <div className="help-block">
                 How much of this sale should be given to the referring
                 store owner when a sale is made from a promoted product
                 listing. A higher incentive will increase the chances
                 of your product being chosen by other store owners for
                 promotion.
               </div>
             </div>
             <div className="col-md-2" >
               <div className="input-group">
                 <span className="input-group-addon">$</span>
                 <input type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={referralIncentive}
                        onChange={(e) => setReferralIncentive(e.target.value)} />
               </div>
             </div>
           </div>
          }

          {props.isCatalogUser &&
           <React.Fragment>
             <h2>Dropship Settings</h2>
             <div className={fieldErrorClass('row form-group', 'dropShipConsultantPayout')}>
               <div className="col-md-2">
                 <label>
                   Retailer payout *
                 </label>
                 <div className="help-block">
                   How much of this dropship sale should be paid out to the retailer.
                 </div>
               </div>
               <div className="col-md-2" >
                 <div className="input-group">
                   <span className="input-group-addon">$</span>
                   <input type="number"
                          className="form-control"
                          placeholder="0.00"
                          value={dropShipConsultantPayout}
                          onChange={(e) => setDropShipConsultantPayout(e.target.value)} />
                 </div>
               </div>
             </div>
           </React.Fragment>
          }

          <hr />

          {errorMessage &&
           <div className="alert alert-danger" role="alert">
             {errorMessage}
           </div>
          }

          {showDropShipLossConfirm &&
           <div>
             <button type="button"
                     className="btn btn-link"
                     onClick={(e) => {
                         setDropShipLossDismissed(true);
                         setShowDropShipLossConfirm(false);
                         setFieldsWithErrors([]);
                         setErrorMessage("");
                     }}>I know what I'm doing, proceed.</button>
           </div>
          }

          <div className="row form-group">
            <div className="col-md-10 col-md-offset-2">
              <div className="btn-group">
                <button className="btn btn-primary btn-lg"
                        disabled={saving}
                        onClick={onSave}>
                  {saving &&
                   <React.Fragment>
                     <LoadingSpinner /> Saving...
                   </React.Fragment>
                  ||
                   <React.Fragment>
                     Save
                   </React.Fragment>
                  }
                </button>
                {itemPk &&
                 <React.Fragment>
                   <a className="btn btn-default btn-lg" href={`/i/${itemPk}/`}>
                     Customer View
                   </a>
                   <a className="btn btn-default btn-lg" href={`/market/add/`}>
                     Add new item
                   </a>
                   <a className="btn btn-default btn-lg" href={`/inventory/listed/`}>
                     Back to Inventory
                   </a>
                 </React.Fragment>
                }
              </div>
              <br />
            </div>
          </div>

        </div>
    );
};

MarketItemForm.propTypes = {
    itemPk: PropTypes.number,
    isCatalogUser: PropTypes.bool,
};

export function MarketItemFormApp(el, itemPk, isCatalogUser) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<MarketItemForm itemPk={itemPk}
                                isCatalogUser={isCatalogUser} />);
}
