// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FollowPanel > div {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
}

.FollowCountCount {
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./social/css/FollowPanel.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".FollowPanel > div {\n    display: inline-block;\n    margin: 0 10px;\n    vertical-align: middle;\n}\n\n.FollowCountCount {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
