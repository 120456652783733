import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash-es';
import update from 'immutability-helper';
import { StrDayPicker } from './StrDates.jsx';
import CreatableSelect from 'react-select/creatable';

import { ItemAttrsEditor } from './item_attrs_editor.jsx';
import { AddItemChoice } from './AddItemChoice.jsx';
import { AddSizeChoice } from './AddSizeChoice.jsx';
import ExtraImagesContainer from './ExtraImagesContainer.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import ImageUploadOrSelect from './ImageUploadOrSelect.jsx';
import { updateStateThing, styleSizeCustomLabel } from './util.jsx';
import OmniPartyHideItem from './OmniPartyHideItem.jsx';
import DigitalDownloadsEditor from './DigitalDownloadsEditor.jsx';
import ImageCropper from './ImageCropper.jsx';

import styles from './css/invitemeditor.css';

class InvItemEditor extends React.Component {
    constructor(props) {
        super(props);

        const defaults = this.props.defaultSettings || {};
        const quantity = defaults.quantity !== undefined
                       ? defaults.quantity
                       : 1;
        this.state = {
            pk: defaults.pk || null,
            error: null,
            fieldsWithErrors: [],
            quantity: quantity,
            wholesalePrice: defaults.wholesalePrice || "",
            price: defaults.price || "",
            description: defaults.description || "",
            purchaseDate: defaults.purchaseDate || moment(),
            attrs: defaults.attrs || [],
            collapsed: false,
            saving: false,
            saveDisabled: false,
            pricesEdited: false,
            itemchoice: defaults.itemchoice || null,
            size: defaults.size || null,
            styleOptions: [],
            sizeOptions: [],
            title: defaults.title || "",
            image: props.image || {},
            originalImage: props.originalImage || null,
            showMainImageUpload: false,
            createAsListed: defaults.createAsListed !== undefined
                          ? defaults.createAsListed : true,
            showItemChoiceModal: false,
            itemChoiceInputValue: "",
            showSizeChoiceModal: false,
            sizeInputValue: "",
            isDigitalItem: props.isDigitalItem || defaults.is_digital,
            croppingImageFile: null,
        };

        this.recentlyAddedExtraImages = [];
        this.recentlyAddedDigitalFiles = [];

        this.requiredFields = [
            "wholesalePrice", "price", "purchaseDate", "quantity",
        ];
        this.nonDigitalFields = [
            "wholesalePrice", "purchaseDate", "quantity",
        ];

        this.refreshStyleSizeOptions = this.refreshStyleSizeOptions.bind(this);
        this.getItemForm = this.getItemForm.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onHeadingClick = this.onHeadingClick.bind(this);
        this.onChange = updateStateThing.bind(this);
        this.receivedAttrs = this.receivedAttrs.bind(this);
        this.getPlatformAttrs = this.getPlatformAttrs.bind(this);
        this.onStyleChange = this.onStyleChange.bind(this);
        this.onCreateItemChoiceOption = this.onCreateItemChoiceOption.bind(this);
        this.onCreateSizeChoiceOption = this.onCreateSizeChoiceOption.bind(this);
        this.onItemChoiceAdd = this.onItemChoiceAdd.bind(this);
        this.onSizeChoiceAdd = this.onSizeChoiceAdd.bind(this);
        this.onExtraImageAdd = this.onExtraImageAdd.bind(this);
        this.onDigitalFileAdd = this.onDigitalFileAdd.bind(this);
        this.onAddMainImage = this.onAddMainImage.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.startCrop = this.startCrop.bind(this);
        this.cropCallback = this.cropCallback.bind(this);
    }

    componentDidMount() {
        this.refreshStyleSizeOptions();
    }

    refreshStyleSizeOptions() {
        this.loadStyleOptions();
        this.loadSizeOptions();
    }

    render() {
        const heading = this.state.pk
                      ? `Item ${this.state.pk} `
                      : "New item ";
        const panelCls = this.state.pk
                       ? "panel panel-success"
                       : "panel panel-primary";
        const closeIcon = this.props.closeIcon
                        ? `fa ${this.props.closeIcon}`
                        : "fa fa-trash-o";
        const formContent = <div>
          {this.state.pk &&
           <div className="row" style={{marginBottom: '10px'}}>
             <div className="col-md-12">
               <a target="_blank"
                  href={`/i/${this.state.pk}/`}
                  className="text-primary">
                 {' '}
                 Item #{this.state.pk}
               </a>
             </div>
           </div>
          }
          <div className="row">
            <div className="col-md-4 enlarge">
              <a href={this.state.image.full_url}
                 target="_blank">
                <img src={this.state.image.url}
                     style={{marginBottom: '15px'}}
                     className="img-responsive" />
              </a>
              <img className="preview-full"
                   data-src={this.state.image.full_url} />
              {this.state.originalImage &&
               <div
                   className="text-small text-muted"
                   role="button"
                   onClick={this.startCrop}
               >
                   <u>crop</u>
               </div>
              }
              {this.state.croppingImageFile &&
               <ImageCropper
                   serializedImage={this.state.croppingImageFile}
                   callback={this.cropCallback}
               />
              }
            </div>
            <div className="col-md-8">
              {!this.state.showMainImageUpload &&
               <button className="btn btn-default btn-sm"
                       onClick={() => this.setState({showMainImageUpload: true})}>
                 {this.state.image.url ? 'Change' : 'Add'} main image
               </button>
              }
              {this.state.showMainImageUpload &&
               <div>
                 <p>Main Image</p>
                 <ImageUploadOrSelect
                     multiple={false}
                     inputName="upload_main_image"
                     callback={this.onAddMainImage}
                     invItemPk={this.state.pk} />
               </div>
              }
              <p style={{margin: "10px 0"}}>
                Extra Images
              </p>
              <ExtraImagesContainer
                  onAdd={this.onExtraImageAdd}
                  extraImages={this.props.defaultSettings.extra_images}
                  invItemPk={this.state.pk}
              />
              {this.state.isDigitalItem &&
               <div>
                 <p style={{margin: "10px 0"}}>Digital Files</p>
                 <DigitalDownloadsEditor onAdd={this.onDigitalFileAdd}
                                         invItemPk={this.state.pk} />
               </div>
              }
            </div>
          </div>
          {this.getItemForm()}
          <div className="row">
            <div className="col-md-12 text-center">
              {this.props.cancelButtonEnabled &&
               <button className="btn btn-default"
                       disabled={this.state.saving}
                       onClick={this.onDeleteClick}>
                 Cancel
               </button>
              }
              <button className="btn btn-primary"
                      style={{marginLeft: '5px'}}
                      disabled={this.state.saving || this.state.saveDisabled}
                      onClick={this.onSaveClick}>
                Save
                {this.state.saving &&
                 <i className="fa fa-refresh fa-spin fa-fw"></i>
                }
              </button>
            </div>
          </div>
          {this.state.error &&
           <div className="row">
             <div className="col-md-12 alert alert-danger">
               {this.state.error}
             </div>
           </div>
          }
        </div>;
        return (
            <div className="InvItemForm">
              {this.props.noPanel && formContent ||
               <div className={panelCls}>
                 <div className="panel-heading"
                      style={{cursor: "pointer"}}
                      onClick={this.onHeadingClick}>
                   <h2 className="visible-lg-inline-block">{heading}</h2>

                   {this.state.saving && !this.state.pk && <span>&nbsp; saving... <LoadingSpinner /></span>}

                   {!this.state.saving &&
                    <div className="panel-ctrls button-icon-bg">
                      <span className="button-icon" onClick={this.onDeleteClick}><i className={closeIcon}></i></span>
                    </div>
                   }
                 </div>
                 <div className="panel-body">
                   {formContent}
                 </div>
               </div>
              }
            </div>
        );
    }

    // "ExtraImages" can be added before an inventory item is even created.
    // This method stores recently added extra images so we can associate
    // these images with the inventory item during save
    onExtraImageAdd(id) {
        this.recentlyAddedExtraImages = [...this.recentlyAddedExtraImages, id];
    }

    updateExtraImage(id, data) {
        $.ajax({
            url: `/api/v2/extraimages/${id}/`,
            method: "PATCH",
            data: data,
        });
    }

    // DigitalDownloads, same thing as ExtraImages
    onDigitalFileAdd(digitalDownload) {
        this.recentlyAddedDigitalFiles = [
            ...this.recentlyAddedDigitalFiles,
            digitalDownload.pk,
        ];
    }

    updateDigitalFile(id, data) {
        $.ajax({
            url: `/api/v2/digital_downloads/${id}/`,
            method: "PATCH",
            data: data,
        });
    }

    onAddMainImage(imageFile, originalImage) {
        this.setState({
            image: {
                id: imageFile.file.id,
                url: imageFile.file.url,
            },
            showMainImageUpload: false,
            originalImage: originalImage,
        });
    }

    onCreateItemChoiceOption(inputValue) {
        this.setState({
            showItemChoiceModal: true,
            itemChoiceInputValue: inputValue,
        });
    }

    onCreateSizeChoiceOption(inputValue) {
        this.setState({
            showSizeChoiceModal: true,
            sizeInputValue: inputValue,
        });
    }

    getItemForm() {
        if (this.state.collapsed)
            return null;

        let bizParams = (typeof str_biz_params !== 'undefined')
                      ? str_biz_params
                      : {'suggested_attrs': []};

        let formClasses = {};
        for (const field of this.requiredFields) {
            formClasses[field] = "form-group";
            if (includes(this.state.fieldsWithErrors, field))
                formClasses[field] += " has-error";
        }

        let fieldsWithErrors = this.state.fieldsWithErrors;
        function alertDangerIfErrorsKey(key) {
            return includes(fieldsWithErrors, key)
                 ? "alert alert-danger"
                 : "";
        }
        const attrsEditorCls = alertDangerIfErrorsKey('attrs');
        const styleCls = alertDangerIfErrorsKey('itemchoice');
        const sizeCls = alertDangerIfErrorsKey('size');
        const titleCls = alertDangerIfErrorsKey('title');

        return (
            <div className="row" style={{marginTop: '10px'}}>
              <div className="col-md-12">
                <div>
                  <div className={styleCls} style={{marginBottom: '10px'}}>
                    <CreatableSelect name="itemchoice_id"
                                     value={this.state.itemchoice}
                                     onChange={this.onStyleChange}
                                     placeholder={StrUserInfo.words.style}
                                     options={this.state.styleOptions}
                                     onCreateOption={this.onCreateItemChoiceOption}
                                     isClearable
                    />
                    {this.props.showAddCustoms &&
                     <AddItemChoice onAdd={this.onItemChoiceAdd}
                                    initialName={this.state.itemChoiceInputValue}
                                    showModal={this.state.showItemChoiceModal}
                     />
                    }
                  </div>
                  <div className={sizeCls}>
                    <CreatableSelect name="size_id"
                                     value={this.state.size}
                                     onChange={(size) => this.setState({size}) }
                                     placeholder={StrUserInfo.words.size}
                                     options={this.state.sizeOptions}
                                     onCreateOption={this.onCreateSizeChoiceOption}
                                     isClearable
                    />
                    {this.props.showAddCustoms &&
                     <AddSizeChoice onAdd={this.onSizeChoiceAdd}
                                    initialName={this.state.sizeInputValue}
                                    showModal={this.state.showSizeChoiceModal}
                     />
                    }
                  </div>
                </div>

                <div className={titleCls} style={{marginTop: 10, marginBottom: 10}}>
                  <input type="text"
                         className="form-control"
                         onChange={this.onChange}
                         name="title"
                         placeholder={`Title (optional if ${StrUserInfo.words.style.toLowerCase()} is provided)`}
                         value={this.state.title} />
                </div>

                <div className={attrsEditorCls}>
                  <p className="help-block">Attributes</p>
                  <ItemAttrsEditor itemId={this.state.pk}
                                   bizParams={bizParams}
                                   defaultAttrs={this.state.attrs}
                                   receivedAttrs={this.receivedAttrs}
                                   liteStyle={false} />
                </div>
              </div>
              <div className="col-md-12 form-horizontal">

                {!this.state.isDigitalItem &&
                 <div>
                   <div className={formClasses.quantity}>
                     <label className="col-sm-4 control-label">Quantity</label>
                     <div className="col-sm-5">
                       <input type="number" className="form-control" min="0"
                              onChange={this.onChange}
                              name="quantity"
                              value={this.state.quantity} />
                     </div>
                   </div>
                   <div className={formClasses.wholesalePrice}>
                     <label className="col-sm-4 control-label">Wholesale price</label>
                     <div className="col-sm-5">
                       <input type="number" className="form-control" min="0" step="0.01"
                              onChange={this.onChange}
                              onKeyPress={() => this.setState({pricesEdited: true})}
                              name="wholesalePrice"
                              value={this.state.wholesalePrice} />
                     </div>
                   </div>
                 </div>
                }
                <div className={formClasses.price}>
                  <label className="col-sm-4 control-label">Price</label>
                  <div className="col-sm-5">
                    <input type="number" className="form-control" min="0" step="0.01"
                           onChange={this.onChange}
                           onKeyPress={() => this.setState({pricesEdited: true})}
                           name="price"
                           value={this.state.price} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Description</label>
                  <div className="col-sm-8">
                    <textarea className="form-control" rows="3"
                              onChange={this.onChange}
                              name="description"
                              value={this.state.description} />
                  </div>
                </div>
                {!this.state.isDigitalItem &&
                 <div className={formClasses.purchaseDate}>
                   <label className="col-sm-4 control-label">Purchase date</label>
                   <div className="col-sm-8" style={{paddingTop: "6px"}}>
                     <StrDayPicker
                         mode="single"
                         selected={this.state.purchaseDate.toDate()}
                         onSelect={d => this.setState({ purchaseDate: moment(d) })}
                     />
                   </div>
                 </div>
                }
                {!this.state.pk &&
                 <div>
                   <div className="form-group">
                     <label className="col-sm-4 control-label">Auto-list</label>
                     <div className="col-sm-8"
                          style={{paddingTop: '7px'}}>
                       <label className="text-muted text-small"
                              style={{fontWeight: 'normal'}}>
                         <input type="checkbox"
                                checked={this.state.createAsListed}
                                name="createAsListed"
                                onChange={this.onChange} />
                         {' '}
                         Make this item available for sale right away.
                       </label>
                     </div>
                   </div>
                   <div className="form-group">
                     <label className="col-sm-4 control-label">Digital Item</label>
                     <div className="col-sm-8"
                          style={{paddingTop: '7px'}}>
                       <label className="text-muted text-small"
                              style={{fontWeight: 'normal'}}>
                         <input type="checkbox"
                                checked={this.state.isDigitalItem}
                                name="isDigitalItem"
                                onChange={this.onChange} />
                         {' '}
                         Include digital files that users can download after purchasing.
                       </label>
                     </div>
                   </div>
                 </div>
                }
                {this.props.listed && StrUserInfo.storefront_enabled &&
                 <div className="form-group">
                   <div className="col-sm-8 col-sm-offset-4"
                        style={{paddingTop: '7px'}}>
                     <OmniPartyHideItem itemPk={this.state.pk}
                                        hidden={this.props.defaultSettings.hide_from_omniparty}
                                        showText />
                   </div>
                 </div>
                }
              </div>
            </div>
        );
    }

    onSaveClick() {
        if (this.state.saving)
            return;

        this.setState({fieldsWithErrors: []});

        const fieldsWithErrors = [];
        for (const field of this.requiredFields) {
            const val = this.state[field];
            if (this.state.isDigitalItem
                && this.nonDigitalFields.indexOf(field) !== -1) {
                continue;
            }
            if (val === "" || val === null || val === undefined)
                fieldsWithErrors.push(field);
        }

        let hasAttrErrors = false;
        if (this.state.attrs.length !== 0) {
            for (const attr of this.state.attrs) {
                if (attr.name && attr.value &&
                    attr.name.trim() !== '' && attr.value.trim() !== '')
                    continue;

                hasAttrErrors = true;
                break;
            }
        }
        if (hasAttrErrors)
            fieldsWithErrors.push('attrs');

        if (!this.state.title) {
            // show an error on style when title is empty
            if (!this.state.itemchoice || !this.state.itemchoice.value)
                fieldsWithErrors.push('itemchoice');
        }

        if (!this.state.title &&
            (!this.state.itemchoice || !this.state.itemchoice.value)) {
            // show an error on all three if they are all empty
            fieldsWithErrors.push('itemchoice');
            fieldsWithErrors.push('title');
        }

        if (fieldsWithErrors.length > 0) {
            this.setState(prevState => ({
                fieldsWithErrors: update(prevState.fieldsWithErrors, {
                    $set: fieldsWithErrors,
                }),
            }));
            this.props.onSaveError && this.props.onSaveError();
            return;
        }
        const self = this;
        const data = {
            image_id: this.state.image.id,
            price: this.state.price,
            description: this.state.description,
            wholesale_price: this.state.wholesalePrice,
            purchase_date: this.state.purchaseDate.format("YYYY-MM-DD"),
            quantity: this.state.quantity,
            availability: this.props.availability,
            data: JSON.stringify({
                'attributes': this.getPlatformAttrs(),
            }),
            title: this.state.title,
            itemchoice_id: this.state.itemchoice ? this.state.itemchoice.value : null,
            size_id: this.state.size ? this.state.size.value : null,
        };

        if (!this.state.pk) {
            data['listed'] = this.state.createAsListed;
        }

        this.setState({saving: true});
        if (this.state.pk) {
            const listed = this.props.listed ? "listed" : "unlisted";
            const url = `/api/v2/${listed}_items/${this.state.pk}/`;
            $.ajax({
                type: 'PATCH',
                contentType: 'application/x-www-form-urlencoded',
                url: url,
                data: data,
                headers: {
                    "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                },
            }).fail(rsp => {
                self.setState({error: rsp.responseText});
                self.props.onSaveError && self.props.onSaveError();
            }).always(response => {
                self.setState({saving: false});
                self.props.onSaveComplete && self.props.onSaveComplete(response);
            });
            return;
        }

        $.post('/api/v2/items/', data, (rsp, status, xhr) => {
            self.setState({
                pk: rsp.pk,
                saveDisabled: true,
            });
            self.props.onSaveComplete && self.props.onSaveComplete(rsp);

            this.recentlyAddedExtraImages.forEach(
                id => this.updateExtraImage(id, {item: rsp.pk})
            );
            if (self.state.isDigitalItem) {
                /* don't save/assign these files if is_digital was
                 * unchecked after the user uploaded some files */
                this.recentlyAddedDigitalFiles.forEach(
                    id => this.updateDigitalFile(id, {item_id: rsp.pk})
                );
            }
        }).fail(rsp => {
            self.setState({
                error: rsp.responseText,
            });
            self.props.onSaveError && self.props.onSaveError();
        }).always(() => {
            self.setState({saving: false});
        });
    }

    onDeleteClick(e) {
        e.stopPropagation();
        this.props.onDeleteClick(e);
    }

    onHeadingClick() {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
    }

    receivedAttrs(attrs) {
        this.setState({attrs:attrs});
        if (!is_str && !this.state.pricesEdited) {
            const self = this;
            const data = encodeURIComponent(JSON.stringify(attrs));
            const url = '/api/v2/stockitems/pricing/?attrs=' + data;
            $.getJSON(url, function(json) {
                if (json.length > 0) {
                    self.setState({
                        wholesalePrice: json[0].wholesale_price,
                        price: json[0].suggested_retail_price,
                    });
                }
            });
        }
    }

    getPlatformAttrs() {
        /* need to convert to an object on STR */
        const attrs = {};
        this.state.attrs.forEach(a => attrs[a.name] = a.value);
        return attrs;
    }

    loadStyleOptions() {
        const url = '/api/v2/itemchoices/';
        const self = this;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              const options = json.results.map(el => ({
                  label: styleSizeCustomLabel(el),
                  value: el.pk,
              }));
              self.setState({ styleOptions: options });
          });
    }

    loadSizeOptions() {
        const url = '/api/v2/sizes/';
        const self = this;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              const options = json.results.sort(
                  (a, b) => Number.parseInt(a.sort_order)
                        - Number.parseInt(b.sort_order)
              ).map(el => ({
                  label: styleSizeCustomLabel(el),
                  value: el.pk,
              }));
              self.setState({ sizeOptions: options });
          });
    }

    onStyleChange(data) {
        this.setState({itemchoice: data});
        if (!data) return;
        const url = `/itemchoice_params/${data.value}/`;
        const self = this;
        $.getJSON(url, function(data) {
            self.setState({
                wholesalePrice: data.wholesale_price + '',
                price: data.suggested_retail_price_top + '',
            });
        });
    }

    onItemChoiceAdd(data) {
        const newStyle = {label: data.name, value: data.pk};
        this.setState((prevState, props) => ({
            styleOptions: update(prevState.styleOptions, {
                $push: [newStyle],
            }),
            showItemChoiceModal: false,
        }), () => {
            this.onStyleChange(newStyle);
            this.props.onItemChoiceAdd && this.props.onItemChoiceAdd(newStyle);
        });
    }

    onSizeChoiceAdd(data) {
        const newSize = {label: data.name, value: data.pk};
        this.setState((prevState, props) => ({
            sizeOptions: update(prevState.sizeOptions, {
                $push: [newSize],
            }),
            showSizeChoiceModal: false,
        }), () => {
            this.setState({size: newSize});
            this.props.onSizeChoiceAdd && this.props.onSizeChoiceAdd(newSize);
        });
    }

    startCrop() {
        this.setState({
            croppingImageFile: {
                ...this.state.image,
                // otherwise it can be a string sometimes
                id: Number(this.state.image.id),
                originalImage: this.state.originalImage,
            },
        });
    }

    cropCallback(responseCropImageFile) {
        this.setState({
            image: responseCropImageFile,
            originalImage: responseCropImageFile.originalImage,
            croppingImageFile: null,
        });
    }
}

InvItemEditor.propTypes = {
    showAddCustoms: PropTypes.bool,
    onDeleteClick: PropTypes.func.isRequired,
    onSaveComplete: PropTypes.func,
    onSaveError: PropTypes.func,
    image: PropTypes.object,
    originalImage: PropTypes.object,
    availability: PropTypes.number,
    defaultSettings: PropTypes.object,
    closeIcon: PropTypes.string,
    noPanel: PropTypes.bool,
    cancelButtonEnabled: PropTypes.bool,
    listed: PropTypes.bool,
    onItemChoiceAdd: PropTypes.func,
    onSizeChoiceAdd: PropTypes.func,
    isDigitalItem: PropTypes.bool,
};

InvItemEditor.defaultProps = {
    showAddCustoms: true,
    defaultSettings: {},
};

export default InvItemEditor;
