import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MarketItemVariantImagePicker from './MarketItemVariantImagePicker.jsx';

// https://gist.github.com/cybercase/db7dde901d7070c98c48
function arrayProduct(...arrays) {
    return arrays.reduce((prevAccumulator, currentArray) => {
        let newAccumulator = [];
        prevAccumulator.forEach(prevAccumulatorArray => {
            currentArray.forEach(currentValue => {
                newAccumulator.push(prevAccumulatorArray.concat(currentValue));
            });
        });
        return newAccumulator;
    }, [[]]);
}

function populateItemVariants(namesAndValues, currItemVariants, initialPrice) {
    // create an itemVariant for each option name/value combination
    // namesAndValues:
    // [{pk: x || null, id: '', name: '', values: [{id: '', pk: x || nul, value: ''},]},]
    if (!namesAndValues) {
        return currItemVariants;
    }
    const valuesArrs = namesAndValues
        .filter(nav => !!nav.values)
        .map(nav => nav.values.filter(v => !!v.value).map(v => {
            return {
                // object format matches InvItem.itemvariant_data.option_values
                name: nav.name,
                name_pk: nav.pk,
                ...v,
            };
        }))
        .filter(v => v.length !== 0);
    if (valuesArrs.length === 0) {
        return currItemVariants;
    }
    const newItemVariants = arrayProduct(...valuesArrs).map(valuesCombination => {
        // see models.ItemVariant.optionvalues_key
        const optionvaluesKey = valuesCombination.map(val => val.id).sort().join('-');
        const existingItemVariant = currItemVariants.find(el => el.id === optionvaluesKey);
        // remove used ItemVariant so we can track unused/remaining items
        if (existingItemVariant) {
            currItemVariants = currItemVariants.filter(
                el => el.id !== existingItemVariant.id
            );
        }
        const quantity = existingItemVariant ? existingItemVariant.quantity : 1;
        const price = existingItemVariant
                    ? existingItemVariant.price : initialPrice;
        const pk = existingItemVariant ? existingItemVariant.pk : null;
        const image_id = existingItemVariant ? existingItemVariant.image_id : null;
        return {
            // format matches item_variants in InvItem.itemvariant_data
            pk: pk,
            id: optionvaluesKey,
            optionValues: valuesCombination,
            name: valuesCombination.map(val => val.value).join(' / '),
            quantity: quantity,
            price: price,
            image_id: image_id,
        };
    });
    // append remaining currItemVariants with pks that did not get used
    const res = newItemVariants.concat(
        currItemVariants.filter(el => el.pk).map(iv => {
            return {
                'delete': true,
                ...iv,
            };
        })
    );
    return res;
}

const VariantsTable = props => {
    // [{pk: x, id: x, name: '', quantity: x, price: x.xx},]
    const [itemVariants, setItemVariants] = useState([]);
    // control all prices at once if we are a brand new item
    // until weve onBlur'd one of them later on
    const [controlAllPrices, setControlAllPrices] = useState(
        props.itemVariants.every(variant => !variant.pk) && !props.initialPrice
    );

    useEffect(
        () => {
            const newItemVariants = populateItemVariants(
                props.namesAndValues,
                props.itemVariants || itemVariants,
                props.initialPrice,
            );
            setItemVariants(newItemVariants);
            props.updateItemVariants(newItemVariants);
        },
        /* we dont want this to trigger for changes in itemVariants, since we are
         * updating itemVariants here */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.namesAndValues],
    );

    useEffect(
        /* props.itemVariants are authoritative since we've either just set them or
         * they have come from the server in the case of an edit, so slam them
         * right in, and make sure to not call props.updateItemVariants since that
         * will loop forever
         */
        () => setItemVariants(props.itemVariants),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.itemVariants],
    );

    function updateItemVariant(itemVariantKey, valueKey, newValue) {
        setItemVariants(prev => {
            let newItemVariants = prev.slice();
            if (valueKey === 'price' && controlAllPrices) {
                // update price on all of the variants
                newItemVariants.forEach((variant, idx) => {
                    newItemVariants[idx][valueKey] = newValue;
                });
            } else {
                // only update one
                const variantIndex = prev.findIndex(oi => oi.id === itemVariantKey);
                newItemVariants[variantIndex][valueKey] = newValue;
            }
            return newItemVariants;
        });
    }

    function updateItemVariantEvent(event, itemVariantKey, valueKey) {
        const newValue = event.target.value;
        updateItemVariant(itemVariantKey, valueKey, newValue);
    }

    return <div>
      <table className="table table-condensed variants-table">
        <thead>
          <tr role="row">
            <th>Variant</th>
            <th>Price</th>
            <th>Quantity</th>
            {props.images && props.images.length !== 0 &&
             <th>Image (optional)</th>
            }
          </tr>
        </thead>
        <tbody>
          {itemVariants.map(itemVariant => {
              let className = '';
              let rowTitle = '';
              if (!itemVariant.pk) {
                  className = 'success';
              }
              if (itemVariant.delete) {
                  className = 'danger';
                  rowTitle = 'This variant will be deleted after saving';
              }
              return <tr key={itemVariant.id}
                         title={rowTitle}
                         className={className}>
                <td>
                  {itemVariant.name}
                  {' '}
                  {!itemVariant.pk &&
                   <span className="label label-success">new</span>
                  }
                </td>
                <td>
                  <input type="number"
                         className="form-control variant-price"
                         min="0"
                         step="0.01"
                         placeholder="0.00"
                         onChange={(e) => updateItemVariantEvent(e, itemVariant.id, 'price')}
                         onBlur={() => setControlAllPrices(false)}
                         value={itemVariant.price} />
                </td>
                <td>
                  <input type="number"
                         className="form-control variant-quantity"
                         min="0"
                         step="1"
                         onChange={(e) => updateItemVariantEvent(e, itemVariant.id, 'quantity')}
                         value={itemVariant.quantity} />
                </td>
                {props.images && props.images.length !== 0 &&
                 <td>
                   <MarketItemVariantImagePicker images={props.images}
                                                 variantData={itemVariant}
                                                 onPick={(image_id) => updateItemVariant(
                                                     itemVariant.id,
                                                     'image_id',
                                                     image_id,
                                                 )}
                   />
                 </td>
                }
              </tr>;
          })}
          {itemVariants.length === 0 && <tr>
            <td colSpan="3">Variants will populate as you add options above</td>
          </tr>
          }
        </tbody>
      </table>
    </div>;
};

VariantsTable.defaultProps = {
    namesAndValues: [],
    itemVariants: [],
    initialPrice: '',
};

VariantsTable.propTypes = {
    /* these get passed in as we update the option names and values. we need to
     * respect them */
    namesAndValues: PropTypes.array.isRequired,
    /* set the updated itemVariants on the parent so they get saved to the backend
     * when the form is saved */
    updateItemVariants: PropTypes.func.isRequired,
    /* these are passed and get updated when there is a saved item. we need to
     * respect them */
    itemVariants: PropTypes.array.isRequired,
    initialPrice: PropTypes.string,
    images: PropTypes.array,
};

export default VariantsTable;
