import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import { updateStateThing } from './util.jsx';
import AchSetup from './AchSetup.jsx';
import { BillingInfo } from './BillingInfo.jsx';
import { ModalContainer } from './StrModal.jsx';

const methods = {
    'onFile': 'onFile',
    'newCard': 'newCard',
    'ach': 'ach',
};

const getInitialMethod = (props) => {
    if (props.achAble)
        return methods.ach;
    if (props.hasSubscription)
        return methods.onFile;
    return methods.newCard;
};

export class StrBucksModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 50,
            error: null,
            loading: false,
            method: getInitialMethod(props),
            enterCard: null,
            showAchSetup: false,
        };
        this.onAmountChange = this.onAmountChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.performSubmit = this.performSubmit.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.achCallback = this.achCallback.bind(this);
    }

    achCallback() {
        this.setState({showAchSetup: false}, () => this.props.callback(true));
    }

    onSubmit() {
        strConfig.executeCaptcha("onBucksSubmit", this.performSubmit);
    }

    async performSubmit(captchaToken) {
        this.setState({loading: true});
        let tokenId = '';
        if (this.state.method === methods.newCard) {
            if (this.props.paymentInfo && this.props.paymentInfo.payment_token
                && !this.state.enterCard) {
                tokenId = this.props.paymentInfo.payment_token;
            } else {
                const cardElement = this.props.elements.getElement(CardElement);
                const tokenRsp = await this.props.stripe
                                           .createToken(cardElement);
                if (tokenRsp['token'] === undefined) {
                    return this.setState({
                        loading: false,
                        error: "Please enter valid payment information",
                    });
                }
                tokenId = tokenRsp['token'].id;
            }
        }
        const url = '/strbucks_json/';
        const data = {
            amount: this.state.amount,
            method: this.state.method,
            source: tokenId,
            captchaToken: captchaToken,
        };
        const self = this;
        $.post(url, data, function(result) {
            if (result.success) {
                self.setState({
                    loading: false,
                }, self.props.callback);
            } else {
                const errorMessage = result.errors[Object.keys(result.errors)[0]];
                if (typeof errorMessage === 'string' && errorMessage.endsWith('Throttled'))
                    alert(errorMessage);
                self.setState({
                    error: errorMessage,
                    loading: false,
                });
            }
        })
         .fail(function(xhr) {
             self.setState({loading: false, error: xhr.responseJSON.error});
         });
    }

    onAmountChange(e) {
        this.setState({amount: e.target.value});
    }

    render() {
        const { paymentInfo, onClose, achAble, hasSubscription } = this.props;
        const company = is_str ? "Sonlet" : "Pop";
        const buttonText = this.state.loading ?
                           <span><i className="fa fa-spinner fa-spin"></i> Saving...</span>
                         : "Confirm Purchase";
        const amountToCharge = (this.state.method !== methods.ach)
                             ? parseFloat(this.state.amount)
                             + (parseFloat(this.state.amount) * 0.029) + 0.30
                             : parseFloat(this.state.amount);
        const showCardForm = !(paymentInfo && paymentInfo.last_four)
                          || this.state.enterCard;
        return (
            <ModalContainer onClose={onClose} isOpen={true}>
              <div className="container-fluid" style={{cursor: 'default'}}>
                <h3>Buy {company} Bucks</h3>
                {is_str &&
                 <div className="col-md-12">
                   {hasSubscription &&
                    <div>
                      <label style={{display: 'block', marginTop: '10px'}}>
                        <input
                            type="radio"
                            name="method"
                            style={{marginRight: '10px'}}
                            value={methods.onFile}
                            disabled={this.state.loading}
                            onChange={this.updateStateThing}
                            checked={this.state.method === methods.onFile}
                        />
                        Use subscription billing on file
                      </label>
                      <div style={{marginLeft: '30px'}}>
                        <BillingInfo />
                      </div>
                    </div>
                   }

                   <label style={{display: 'block', marginTop: '10px'}}>
                     <input
                         type="radio"
                         name="method"
                         style={{marginRight: '10px'}}
                         value={methods.newCard}
                         disabled={this.state.loading}
                         onChange={this.updateStateThing}
                         checked={this.state.method === methods.newCard}
                     />
                     Credit/Debit Card
                   </label>
                   <div style={{marginLeft: '10px', maxWidth: '600px'}}>
                     {!showCardForm &&
                      <div style={{marginLeft: '20px'}}>
                        {paymentInfo.card_brand} on file ending in <code>
                        {paymentInfo.last_four}
                        </code>
                        <button className="btn btn-default btn-sm"
                                type="button"
                                style={{marginLeft: '15px'}}
                                onClick={() => this.setState({
                                    enterCard: true,
                                })} >
                          Choose a different card
                        </button>
                        <p className="help-block">
                          Card saved from prior Sonlet Bucks
                          or shopping purchase.
                        </p>
                      </div>
                     }
                     {showCardForm &&
                      <CardElement onFocus={() =>
                          this.setState({method: methods.newCard})} />
                     }
                     {this.state.enterCard &&
                      <p>
                        <button className="btn btn-default btn-sm"
                                type="button"
                                style={{marginTop: '10px'}}
                                onClick={() => this.setState({
                                    enterCard: null,
                                })} >
                          Back to previous card
                        </button>
                      </p>
                     }
                   </div>

                   <div>
                     <label style={{display: 'block', marginTop: '10px'}}>
                       <input
                           type="radio"
                           name="method"
                           style={{marginRight: '10px'}}
                           value={methods.ach}
                           disabled={this.state.loading || !achAble}
                           onChange={this.updateStateThing}
                           checked={this.state.method === methods.ach}
                       />
                       Bank transfer
                     </label>
                     <div className="help-block"
                          style={{marginLeft: '30px'}}>
                       <p>
                         Buy Sonlet Bucks with your bank account and
                         avoid processing fees.
                       </p>
                       {!achAble && !this.state.showAchSetup &&
                        <button type="button"
                                className="btn btn-link"
                                onClick={() => this.setState({showAchSetup: true})}>
                          Setup a Bank Account
                        </button>
                       }
                       {this.state.showAchSetup &&
                        <AchSetup stripe={this.props.stripe}
                                  callback={this.achCallback} />
                       }
                     </div>
                   </div>
                   <hr />
                 </div>
                }

                <div className="form-group">
                  <label className="col-md-3 control-label">Amount</label>
                  <div className="col-md-9">
                    <div className="input-group">
                      <span className="input-group-addon"
                            style={{width: "auto"}}>$</span>
                      <input
                          autoFocus
                          className="form-control"
                          type="number"
                          disabled={this.state.loading}
                          value={this.state.amount}
                          onChange={this.onAmountChange}
                      />
                    </div>
                  </div>
                  {this.state.method !== methods.ach &&
                   <div style={{maxWidth: '400px'}}>
                     <p className="help-block">
                       Due to payment processing costs, a 2.9% + $0.30
                       fee applies.
                       <span>
                         {' '}
                         If you pay with a Bank Transfer this
                         processing fee does not apply.
                       </span>
                     </p>
                   </div>
                  }
                </div>

                <div className="col-md-12"
                     style={{marginTop: '15px'}}>
                  {!!amountToCharge &&
                   <p className="text-muted text-small">
                     You will be charged ${amountToCharge.toFixed(2)}.
                   </p>
                  }
                  <p className="text-muted text-small">
                    Each {company} Buck is worth $1 USD.
                  </p>
                  <button type="submit"
                          className="btn btn-primary"
                          disabled={this.state.loading || !amountToCharge || !this.props.stripe}
                          onClick={this.onSubmit}>
                    {buttonText}
                  </button>
                </div>

                {this.state.error &&
                 <p className="alert alert-danger"
                    style={{marginTop: '10px'}}>{this.state.error}</p>
                }
              </div>
            </ModalContainer>
        );
    }
}

StrBucksModal.defaultProps = {
    achAble: false,
};

StrBucksModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    achAble: PropTypes.bool,
    hasSubscription: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
    stripe: PropTypes.object,
    elements: PropTypes.object,
    paymentInfo: PropTypes.object,
};

export default function InjectedStrBucksModal(props) {
    return (
        <ElementsConsumer>
          {({stripe, elements}) => (
              <StrBucksModal stripe={stripe}
                             elements={elements}
                             {...props} />
          )}
        </ElementsConsumer>
    );
}
