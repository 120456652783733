import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { strRequestJSONWithResponse, useDebounce } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import StrDropzone from './StrDropzone.jsx';

const PartyCreateForm = (props) => {
    const [name, setName] = useState("");
    const [subdomain, setSubdomain] = useState("");
    const [subdomainError, setSubdomainError] = useState("");
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [useCompanyLogoAsCover, setUseCompanyLogoAsCover] = useState(false);
    const [companyLogoURL, setCompanyLogoURL] = useState(null);
    const [saveError, setSaveError] = useState(null);

    const apiUrl = '/api/v2/party_create/';

    const loadData = () => {
        $.getJSON(apiUrl, (data) => {
            if (data.company_logo) {
                setUseCompanyLogoAsCover(true);
                setCompanyLogoURL(data.company_logo);
            }
            setLoading(false);
        });
    };
    useEffect(loadData, []);

    const onNameChange = (e) => {
        setName(e.target.value);
        const slug = strConvertToSlug(e.target.value);
        setSubdomain(slug);
    };

    const onSubdomainChange = (e) => {
        setSubdomain(e.target.value);
    };

    const _subdomainCheck = async () => {
        if (subdomain === "")
            return;
        const params = {
            action: 'check_subdomain',
            subdomain,
        };
        const [json, rsp] = await strRequestJSONWithResponse(apiUrl, 'POST', params);
        if (!rsp.ok) {
            setSubdomainError(`Error checking URL availability: ${json.error || rsp.statusText}`);
        } else {
            setSubdomainError(json.success ? "" : "URL is already in use. Please pick a different one.");
        }
    };

    const subdomainCheck = useCallback(_subdomainCheck, [subdomain]);

    useDebounce(subdomainCheck, subdomain, 1000);

    const onSaveClick = () => {
        if (subdomain === "") {
            setSubdomainError("URL cannot be blank");
            setSaveError("URL cannot be blank");
            return;
        }

        const formData = new FormData();
        formData.append('action', 'create');
        formData.append('name', name);
        formData.append('subdomain', subdomain);
        formData.append('useCompanyLogoAsCover', useCompanyLogoAsCover ? "yes" : "no");
        if (!useCompanyLogoAsCover) {
            if (!coverPhoto) {
                setSaveError("Cover photo cannot be blank");
                return;
            }
            formData.append('coverPhoto', coverPhoto.file);
        }
        setSaving(true);
        $.ajax({
            url: apiUrl,
            method: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            complete: (xhr, status) => {
                setSaving(false);
                const rsp = xhr.responseJSON;
                if (status === "success") {
                    setSaveError("");
                    props.onCreate(rsp);
                } else {
                    setSaveError(`Couldn't save ${StrUserInfo.words.party}. ${rsp.error}`);
                }
            },
            error: (xhr, textStatus) => {
                setSaveError(textStatus);
            },
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setCoverPhoto({
            file,
            previewURL: URL.createObjectURL(file),
        });
    }, []);

    const coverPreviewURL = useCompanyLogoAsCover
                          ? companyLogoURL
                          : (coverPhoto && coverPhoto.previewURL);

    if (loading)
        return <div />;

    return (
        <div className="form-horizontal form-horizontal-compact">
          <div className="form-group">
            <label className="col-md-4 control-label">
              {StrUserInfo.words.party} name:
            </label>
            <div className="col-md-7">
              <input type="text"
                     className="form-control input-lg"
                     value={name}
                     onChange={onNameChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-md-4 control-label">
              URL:
            </label>
            <div className="col-md-7" style={{textAlign: "left"}}>
              <div className="form-inline">
                <input type="text"
                       className="form-control input-lg"
                       value={subdomain}
                       onChange={onSubdomainChange}
                />
                <span style={{paddingLeft: "5px"}}
                      className="hidden-xs">.sonlet.store</span>
              </div>
              {subdomainError &&
               <div className="text-danger">{subdomainError}</div>
              }
              {!subdomainError && subdomain &&
               <div className="text-success" style={{marginTop: "5px"}}>
                 Your {StrUserInfo.words.party} will be available at
                 {' '}
                 <a target="_blank"
                    href={`https://${subdomain}.sonlet.store`}>{subdomain}.sonlet.store</a>.
               </div>
              }
            </div>
          </div>

          <div className="form-group">
            <label className="col-md-4 control-label">
              Cover photo:
            </label>
            <div className="col-md-7" style={{textAlign: "left", marginTop: "5px"}}>
              {companyLogoURL &&
               <div style={{marginBottom: "10px"}}>
                 <label className="radio-inline">
                   <input type="radio"
                          checked={useCompanyLogoAsCover}
                          onChange={() => setUseCompanyLogoAsCover(true)} />
                   Use company logo
                 </label>
                 <label className="radio-inline">
                   <input type="radio"
                          checked={!useCompanyLogoAsCover}
                          onChange={() => setUseCompanyLogoAsCover(false) } />
                   Upload cover photo
                 </label>
               </div>
              }
              {useCompanyLogoAsCover ||
               <div>
                 <StrDropzone onDrop={onDrop} multiple={false} />
                 {coverPhoto &&
                  <div className="text-small text-muted">{coverPhoto.name}</div>
                 }
               </div>
              }
              {coverPreviewURL &&
               <img style={{width: "300px", marginTop: "10px"}} src={coverPreviewURL} />
              }
            </div>
          </div>
          {props.children}
          <button className="btn btn-lg btn-primary"
                  style={{marginTop: "30px"}}
                  onClick={onSaveClick}>
            Save and continue
          </button>
          {saving && <LoadingSpinner />}
          {saveError &&
           <div className="alert alert-danger" style={{marginTop: "10px"}}>{saveError}</div>
          }
        </div>
    );
};

PartyCreateForm.propTypes = {
    onCreate: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default PartyCreateForm;
