import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from "react-dom/client";
import { fetchJSON, formatCurrency } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const NotDoneClaim = function(props) {
    const { claim } = props;
    function removeClick(e) {
        e.preventDefault();
        $.ajax({
            url: `/api/v2/claimrecord/${props.claim.pk}/?reassign`,
            type: 'DELETE',
            success: props.refresh,
            error: function() {
                toast.error("Error removing item. Refresh the page and try again.");
            },
        });
    }
    return (
        <li className="done-shopping-item">
          <a href="#">
            <div className="item-img">
              <img src={claim.image.image_thumbnail} />
            </div>
            <div className="item-details">
              <span className="bag-product-title">
                {claim.item.shortName}
                {claim.itemvariant &&
                 <span className="label label-default"
                       style={{marginLeft: '5px'}}>
                   {claim.itemvariant}
                 </span>
                }
              </span>
              <span className="bag-product-price">{formatCurrency(claim.price)}</span>
              <span>
                <button className="btn btn-link text-small text-muted"
                        style={{paddingLeft: 0, paddingTop: 0}}
                        onClick={removeClick}>
                  remove
                </button>
              </span>
            </div>
          </a>
        </li>
    );
};


const claimShape = PropTypes.shape({
    pk: PropTypes.number.isRequired,
    itemvariant: PropTypes.string,
    item: PropTypes.shape({
        image: PropTypes.shape({
            image_thumbnail: PropTypes.string.isRequired,
        }).isRequired,
        shortName: PropTypes.string.isRequired,
        size: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        itemchoice: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        price: PropTypes.number.isRequired,
    }).isRequired,
    image: PropTypes.object,
    price: PropTypes.number,
});

NotDoneClaim.propTypes = {
    claim: claimShape,
    refresh: PropTypes.func,
};


const NotDoneClaimsCart = function(props) {
    const {notDoneClaims, refresh} = props;
    if (!notDoneClaims.length)
        return <span></span>;
    const subtotal = notDoneClaims.reduce((sum, claim) => sum + claim.price, 0);
    return (
        <ul className="list-unstyled">
          <li className="bag-header done-shopping-item">
            {StrUserInfo.words.bag}
          </li>
          {notDoneClaims.map(claim => {
              return <NotDoneClaim claim={claim}
                                   key={claim.pk}
                                   refresh={refresh} />;
          })}
          <li className="done-shopping-item">
            <div className="done-shopping-totals">
              <h5>Subtotal {formatCurrency(subtotal)}</h5>
              <small>
                Shipping, taxes & payment not included
              </small>
              <br />
              <a href="/done_shopping/"
                 className="btn btn-primary btn-bag">
                {StrUserInfo.words.done_shopping}
              </a>
            </div>
          </li>
        </ul>
    );
};

NotDoneClaimsCart.propTypes = {
    notDoneClaims: PropTypes.arrayOf(claimShape),
    refresh: PropTypes.func,
};

class NavCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            initialized: false,
            notDoneClaims: [],
            loading: false,
            itemCount: props.notDoneClaimsCount,
        };
        this.url = '/api/v2/nav_cart/';
        this.initialize = this.initialize.bind(this);
        this.updateCarts = this.updateCarts.bind(this);
        this.onDropdownClick = this.onDropdownClick.bind(this);
        this.handleBodyClick = this.handleBodyClick.bind(this);
        this.getCartItems = this.getCartItems.bind(this);
        this.updateHiddenState = this.updateHiddenState.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.inNavCart = true;
    }

    render() {
        const {notDoneClaims, dropdownOpen, loading, itemCount} = this.state;
        const dropdownStatus = dropdownOpen ? 'open' : '';

        let dropdownContent;
        if (notDoneClaims.length > 0) {
            dropdownContent = (
                <div>
                  <NotDoneClaimsCart notDoneClaims={notDoneClaims}
                                     refresh={this.getCartItems} />
                </div>
            );
        } else if (loading)  {
            dropdownContent = NavCart.showLoading();
        } else {
            dropdownContent = NavCart.renderEmptyCart();
        }
        const icon = 'shopping-bag';
        return (
            <div className={"dropdown navcart-container " + dropdownStatus}
                 id={this.props.containerId}>
              <button type="button"
                      className="btn dropdown-toggle sonlet-bag"
                      id={this.props.buttonId}
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-haspopup="true" aria-expanded="false"
                      onClick={this.onDropdownClick} >
                <i className={`fa fa-${icon}`} aria-hidden="true"></i>
                &nbsp;{/*prevent UI shift on component mount*/}
                <span className="badge rounded-pill claimed-count">
                  {itemCount}
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end scrollable-menu"
                   onMouseEnter={() => {this.inNavCart = true;}}
                   onMouseLeave={() => {this.inNavCart = false;}}>
                {dropdownContent}
              </div>
            </div>
        );
    }

    componentDidMount() {
        $(document).on('touchstart click', this.handleBodyClick);
        $(document).on("sync-carts", () => this.getCartItems(false));
        $(document).on("sync-and-expand-carts", () => this.getCartItems(true));
    }

    handleScroll(e) {
        if (!isMobile && !this.inNavCart) {
            window.removeEventListener('scroll', this.handleScroll);
            this.setState({dropdownOpen: false});
        }
    }

    onDropdownClick() {
        this.setState((prevState) => {
            const open = !prevState.dropdownOpen;
            if (open) {
                window.addEventListener('scroll', this.handleScroll);
                /* We do not want FD to cover up Done Shopping so make
                 * the cart appear on top of the freshesk chat. Doing
                 * this here instead of main.css since we still want the
                 * freshdesk chat to appear over things like invitem and
                 * strbucks modals. */
                $('#lc_chat_layout').css('z-index', '999');
            } else {
                window.removeEventListener('scroll', this.handleScroll);
                $('#lc_chat_layout').css('z-index', '100000');
            }
            return {
                dropdownOpen: open,
            };
        });

        if (!this.state.intialized) {
            this.initialize();
        }
    }

    initialize() {
        this.getCartItems();
    }

    async getCartItems(showIfNonEmpty=false) {
        this.setState({loading: true});
        const cartItems = await fetchJSON(this.url);
        this.updateCarts(cartItems, showIfNonEmpty);
    }

    updateCarts(cartItems, showIfNonEmpty) {
        const itemCount = cartItems.not_done_claims.length;
        this.setState({
            notDoneClaims: cartItems.not_done_claims,
            loading: false,
            initialized: true,
            itemCount: itemCount,
        }, () => {
            this.updateHiddenState();
            if (showIfNonEmpty && itemCount > 0)
                this.setState({dropdownOpen: true});
        });
    }

    handleBodyClick(e) {
        if ($(e.target).closest('.navcart-container').length === 0) {
            this.setState({dropdownOpen: false});
        }
    }

    static renderEmptyCart() {
        return (
            <ul className="list-unstyled text-center" style={{margin: "15px 0"}}>
              <li><h3>Your cart is empty.</h3></li>
            </ul>
        );
    }

    static showLoading() {
        return (
            <ul className="list-unstyled text-center">
              <li><LoadingSpinner /></li>
            </ul>
        );
    }

    updateHiddenState() {
        const { notDoneClaims } = this.state;
        const cartCount = notDoneClaims.length;
        const $els = $(".claim-count-area-for-hiding");
        if (cartCount > 0) {
            $els.show();
        } else {
            $els.hide();
        }
    }
}

NavCart.propTypes = {
    notDoneClaimsCount: PropTypes.number.isRequired,
    buttonId: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
};

export default function NavCartApp(el, notDoneClaimsCount, buttonId, containerId) {
    const root = createRoot(el);
    root.render(<NavCart notDoneClaimsCount={notDoneClaimsCount}
                         buttonId={buttonId}
                         containerId={containerId} />);
}
