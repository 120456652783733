import React from 'react';
import PropTypes from 'prop-types';
import SocialPostEntryForm from './SocialPostEntryForm.jsx';

export default class SocialPostEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEntryForm: !!props.initialPartyPublicId,
            text: '',
        };
        this.onEditFinish = this.onEditFinish.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onEditFinish(activity) {
        this.setState({
            showEntryForm: false,
        });
        this.props.onEditFinish && this.props.onEditFinish(activity);
    }

    onClose() {
        this.setState({showEntryForm: false});
        this.props.onClose && this.props.onClose();
    }

    renderStaticEntry() {
        return (
            <div onClick={() => {this.setState({showEntryForm: true});}}>
              <form className="form-inline">
                <img className="SocialProfileImageSmall"
                     src={StrUserInfo.profile_thumbnail}></img>
                <input type="text"
                       className="form-control"
                       defaultValue={this.state.text}
                       placeholder="Write something..."></input>
              </form>
            </div>
        );
    }

    renderEntryForm() {
        const displayName = (StrUserInfo.first_name || StrUserInfo.last_name)
                          ? `${StrUserInfo.first_name} ${StrUserInfo.last_name}`
                          : StrUserInfo.username;
        const { initialPartyPublicId } = this.props;
        let moreEntryFormProps = {};
        if (initialPartyPublicId)
            moreEntryFormProps.initialPartyPublicId = initialPartyPublicId;
        return (
            <div className="container-fluid bubble-container"
                 style={{backgroundColor: '#fff', padding: '15px'}}>
              <a style={{float: 'right', cursor: 'pointer'}}
                 onClick={this.onClose}>
                <i className="fa fa-times"></i>
              </a>
              <p>
                <img className="SocialProfileImageSmall"
                     src={StrUserInfo.profile_thumbnail}></img>
                <span className="ffArticleHeaderUser ffAnchorColor">
                  {displayName}
                </span>
              </p>
              <SocialPostEntryForm onEditFinish={this.onEditFinish}
                                   doRefresh={this.props.doRefresh}
                                   {...moreEntryFormProps}
              />
            </div>
        );
    }

    render() {
        return (
            <div style={{margin: '20px 20px 30px 20px'}}>
              {!this.state.showEntryForm &&
               this.renderStaticEntry()
              }
              {this.state.showEntryForm &&
               this.renderEntryForm()
              }
            </div>
        );
    }
}

SocialPostEntry.propTypes = {
    doRefresh: PropTypes.func,
    onEditFinish: PropTypes.func,
    onClose: PropTypes.func,
    initialPartyPublicId: PropTypes.number,
};
