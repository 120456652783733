import React from 'react';
import PropTypes from 'prop-types';
import LinkPreview from './LinkPreview.jsx';
import { debounce } from 'lodash-es';
import update from 'immutability-helper';
import { foreignIdParse } from './social_util.jsx';
import { MentionsInput, Mention } from 'react-mentions';
import { getFollowers } from '../UserCache.jsx';
import RewardProgramPicker from '../rewards/RewardProgramPicker.jsx';
import MultiSocialPicker from './MultiSocialPicker.jsx';
import PostDatePicker from '../PostDatePicker.jsx';
import { updateStateThing, fetchJSON } from '../util.jsx';
import ItemPicker from '../ItemPicker.jsx';
import PartyPicker from '../PartyPicker.jsx';
import { ModalContainer } from '../StrModal.jsx';


const sonletSocialChannel = {
    value: 'sonlet-social',
    label: 'Sonlet Social',
    image: 'https://s3-us-west-1.amazonaws.com/str-static/static/images/logo_sonlet_300.png',
    icon: '',
};

const defaultChannels = [sonletSocialChannel];


export default class SocialPostEntryForm extends React.Component {
    constructor(props) {
        super(props);
        // older posts dont have data.imageUrl
        const imageUrls = props.activity
                       && props.activity.data
                       && props.activity.data.imageUrls
                       || [];
        this.state = {
            text: props.activity ? props.activity.text : '',
            plainText: props.activity ? props.activity.text : '',
            loading: false,
            links: props.activity ? props.activity.data.opengraph : [],
            excludedLinks: {},
            imagesForPostEntry: imageUrls,
            imagePks: [],
            imageUrls: imageUrls,
            mentionUsers: [],
            isShowingRewards: false,
            selectedChannels: defaultChannels.slice(),
            postDatetime: moment().toDate(),
            timezone: window.StrUserInfo.last_post_timezone,
            validDate: true,
            postNow: 'NOW',
            showItemPicker: false,
            showPartyPicker: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePost = this.handlePost.bind(this);
        this.ignoreLink = this.ignoreLink.bind(this);
        this.parseLinks = debounce(this.parseLinks, 1000).bind(this);
        this.linkDelete = this.linkDelete.bind(this);
        this.getActiveLinks = this.getActiveLinks.bind(this);
        this.pushLoadingImage = this.pushLoadingImage.bind(this);
        this.popLoadingImage = this.popLoadingImage.bind(this);
        this.onImageSelect = this.onImageSelect.bind(this);
        this.onRewardSelect = this.onRewardSelect.bind(this);
        this.onChannelChange = this.onChannelChange.bind(this);
        this.onDatetimeChange = this.onDatetimeChange.bind(this);
        this.copyAndInsertImage = this.copyAndInsertImage.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onPartySelect = this.onPartySelect.bind(this);
        this.updateState = updateStateThing.bind(this);

        this._linksCache = {};
        this._linksToIgnore = {};
    }

    async componentDidMount() {
        if (this.state.mentionUsers.length === 0) {
            const followUserData = await getFollowers(str_user_id);
            const users = followUserData.map(f => {
                return {
                    id: `@${f.username}`,
                    display: `@${f.username} ${f.name}`,
                };
            });
            this.setState({mentionUsers: users});
        }
        const { initialPartyPublicId } = this.props;
        if (initialPartyPublicId) {
            const url = `/api/v2/myparties/${initialPartyPublicId}`;
            const party = await fetchJSON(url);
            this.onPartySelect(party);
        }
    }

    handleChange(e, newValue, newPlainTextValue) {
        this.setState({
            text: newValue,
            plainText: newPlainTextValue,
        });
        this.parseLinks(newPlainTextValue);
    }

    ignoreLink(url) {
        this._linksToIgnore[url] = true;
    }

    async parseLinks(text) {
        const creds = {credentials: 'same-origin'};
        const urlRe = /(https?:\/\/[^\s]+)/g;
        const urls = text.match(urlRe);

        if (!urls)
            return;

        /*
         * We keep a cache of link metadata to avoid re-fetching the same
         * URLs multiple times.
         */
        const fetches = [];
        for (const url of urls) {
            if (this._linksCache.hasOwnProperty(url))
                continue;
            if (this._linksToIgnore.hasOwnProperty(url))
                continue;
            const fetchurl = '/api/v2/url_info/?' + $.param({url});
            fetches.push(
                fetch(fetchurl, creds)
                    .then(async (r) => {
                        return {
                            'data': await r.json(),
                            'ok': r.ok,
                            'url': url,
                        };
                    })
            );
        }

        /* resolve them in parallel and sync up state */
        const results = await Promise.all(fetches);
        for (const result of results) {
            if (result.ok)
                this._linksCache[result.url] = result.data;
        }
        this.setState({
            links: urls
                .filter(url => this._linksCache.hasOwnProperty(url))
                .map(url => ({
                    url: url,
                    data: this._linksCache[url],
                })),
        });
    }

    linkDelete(link) {
        this.setState(prevState => ({
            excludedLinks: update(prevState.excludedLinks, {
                $merge: {
                    [link.url]: true,
                },
            }),
        }));
    }

    getActiveLinks() {
        return this.state.links
                   .filter(link => !this.state.excludedLinks.hasOwnProperty(link.url));
    }

    handlePost() {
        this.setState({loading: true});
        const { selectedChannels, postNow, postDatetime, timezone } = this.state;
        const isPrivate = !selectedChannels.find(c => c.value === sonletSocialChannel.value);
        const externalChannelPks = selectedChannels.filter(c => c.value !== sonletSocialChannel.value)
                                                   .map(c => c.value);

        const data = {
            socialPostPk: this.props.activity
                        ? foreignIdParse(this.props.activity.foreign_id).objId
                        : null,
            text: this.state.plainText,
            links: JSON.stringify(this.getActiveLinks()),
            imagePks: JSON.stringify(this.state.imagePks),
            imageUrls: JSON.stringify(this.state.imageUrls),
            externalChannelPks: JSON.stringify(externalChannelPks),
        };

        let msg = "Successfully queued your post";
        if (!postNow && postDatetime && timezone) {
            data.postDatetime = parseInt(postDatetime.getTime() / 1000);
            data.timezone = timezone;
            msg = msg + ". Scheduled posts can be viewed in the Scheduled tab on the Social Feed page.";
        }

        if (isPrivate)
            data.isPrivate = 1;
        $.post('/api/v2/social_feed/', data, (activity) => {
            this.props.onEditFinish(activity);
            if (!postNow) {
                this.props.doRefresh && this.props.doRefresh();
                toast.success(msg);
            }
        });
    }

    pushLoadingImage() {
        this.setState({
            imagesForPostEntry: this.state.imagesForPostEntry.concat(
                "https://s3-us-west-1.amazonaws.com/directangular-marketing-media-public/spinner.gif"),
            loading: true,
        });
    }

    /*
     * pops the loading image and replaces it with a real image.
     * Should be called after pushLoadingImage with the result of
     * an upload or similar.
     *
     * newImage - An object with 'src', 'image_full', and 'id' keys.
     *            If null, pops the loading image without adding
     *            a new image (useful in case of failed upload).
     */
    popLoadingImage(newImage) {
        if (!newImage) {
            this.setState((prevState) => {
                let oldImages = prevState.imagesForPostEntry.slice();
                oldImages.pop();
                return {
                    imagesForPostEntry: oldImages,
                    loading: false,
                };
            });
            return;
        }
        this.setState((prevState) => {
            /* pop off the loading image */
            let newImages = prevState.imagesForPostEntry.slice();
            /* and replace it with the new image */
            newImages[newImages.length - 1] = newImage.src;
            return {
                imagesForPostEntry: newImages,
                imageUrls: prevState.imageUrls.concat(newImage.image_full),
                imagePks: prevState.imagePks.concat(newImage.id),
                loading: false,
            };
        });
    }

    onImageSelect() {
        let image = document.createElement('img');
        image.src = window.URL.createObjectURL(this.fileInput.files[0]);
        this.pushLoadingImage();
        let formData = new FormData();
        formData.append('image_full',
                        this.fileInput.files[0],
                        this.fileInput.files[0].name);
        $.ajax({
            url: '/api/v2/social_post_images/',
            method: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            complete: (xhr, status) => {
                const error = status === "success" ? null : xhr.responseText;
                const result = error ? null : xhr.responseJSON;
                let newImage = null;
                if (result) {
                    newImage = {
                        src: image.src,
                        image_full: result.image_full,
                        id: result.id,
                    };
                }
                this.popLoadingImage(newImage);
            },
        });
    }

    copyAndInsertImage(url, data, publicLink) {
        this.pushLoadingImage();

        $.post(url, data, (rsp, status, xhr) => {
            if (status !== "success") {
                toast.error(`Error: ${JSON.stringify(rsp.responseJSON)}`);
                this.popLoadingImage(null);
                return;
            }
            /* attach image to post */
            this.popLoadingImage({
                src: rsp.image.image_full,
                image_full: rsp.image.image_full,
                id: rsp.image.id,
            });
            /* and append the link to the post text */
            const { text, plainText } = this.state;
            /* since the image is already attached to the post, don't fetch
               its metadata. Otherwise the image is slapped in there twice. */
            this.ignoreLink(publicLink);
            this.handleChange(null,
                              text + '\n' + publicLink,
                              plainText + '\n' + publicLink);
        }).fail((rsp) => {
            toast.error(`Error: ${JSON.stringify(rsp.responseJSON)}`);
        });
    }

    onItemSelect(item) {
        this.setState({showItemPicker: false});
        const url = '/api/v2/social_post_image_from_invitemimage/';
        const data = { invitemimage_pk: item.image.pk };
        this.copyAndInsertImage(url, data, item.listingLink);
    }

    onPartySelect(party) {
        this.setState({showPartyPicker: false});
        const url = '/api/v2/social_post_image_from_party/';
        const data = { party_pk: party.pk };
        this.copyAndInsertImage(url, data, party.publicLink);
    }

    onRewardSelect(url) {
        this.setState((prevState) => {
            this.parseLinks(prevState.plainText + '\n' + url);
            return {
                text: prevState.text + '\n' + url,
                plainText: prevState.plainText + '\n' + url,
                isShowingRewards: false,
            };
        });
    }

    onChannelChange(selectedChannels) {
        this.setState({selectedChannels: selectedChannels});
    }

    onDatetimeChange(postDatetime) {
        const newState = typeof(postDatetime) === 'string' ? {
            // react-datetime returns a string for invalid date
            postDatetime: postDatetime,
            validDate: false,
        } : {
            postDatetime: postDatetime.toDate(),
            validDate: true,
        };
        this.setState(newState);
    }

    render() {
        const { text, loading, mentionUsers, isShowingRewards, postDatetime,
                postNow, validDate, timezone, imagesForPostEntry } = this.state;
        const links = this.getActiveLinks()
                          .map(link =>
                              <LinkPreview key={link.url}
                                           link={link}
                                           onClose={() => this.linkDelete(link) } />);
        const displayName = (StrUserInfo.first_name || StrUserInfo.last_name)
                          ? `${StrUserInfo.first_name} ${StrUserInfo.last_name}`
                          : StrUserInfo.username;
        const images = imagesForPostEntry.map((image, idx) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <img key={idx} src={image}
                     style={{maxWidth: '50px', maxHeight: '50px', marginBottom: '10px'}} />
            );
        });
        const showMultiSocialPicker = StrUserInfo.isStaffOrConsultant;
        return (
            <div>
              <MentionsInput value={text}
                             className="ffCommentInput"
                             onChange={this.handleChange}
                             placeholder="Write something..."
                             autoFocus
                             disabled={loading}>
                <Mention
                    trigger="@"
                    data={mentionUsers}
                />
              </MentionsInput>
              <div style={{marginTop: '10px', marginBottom: '5px', marginLeft: '10px'}}>
                <div className="btn-group btn-group-sm" role="group">
                  <div className="btn-group btn-group-sm" role="group">
                    <button className="btn btn-default dropdown-toggle"
                            type="button"
                            data-toggle="dropdown">
                      <i className="fa fa-picture-o"
                         style={{color: loading ? '#ccc' : '#1c4f8c'}}></i> Photo
                    </button>
                    <ul className="dropdown-menu">
                      {StrUserInfo.isStaffOrConsultant &&
                       <li>
                         <a role="button"
                            onClick={() => this.setState({showItemPicker: true})}>
                           From your inventory...
                         </a>
                       </li>
                      }
                      <li>
                        <a role="button"
                           onClick={() => this.fileInput.click()}>
                          From your computer...
                          <input type="file" id="image_upload"
                                 name="image_upload"
                                 style={{display: "none"}}
                                 disabled={loading}
                                 onChange={this.onImageSelect}
                                 ref={input => this.fileInput = input}
                                 accept=".jpg, .jpeg, .png, .bmp, .gif" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {StrUserInfo.isStaffOrConsultant &&
                   <button type="button"
                           className="btn btn-default"
                           onClick={() => this.setState({showPartyPicker: true})}>
                     <i className="fa fa-birthday-cake"
                        style={{color: loading ? '#ccc' : '#9fa8da'}}></i> Party
                   </button>
                  }
                  {StrUserInfo.rewards_enabled &&
                   <button type="button"
                           className="btn btn-default"
                           onClick={() => this.setState({isShowingRewards: true})}>
                     <i className="fa fa-trophy"
                        style={{color: loading ? '#ccc' : '#ffc107'}}></i> Rewards
                   </button>
                  }
                </div>

                <ModalContainer onClose={() => this.setState({showItemPicker: false})}
                                isOpen={this.state.showItemPicker}
                                style={{
                                    width: 'calc(100% - 110px)',
                                    maxWidth: '1000px',
                                }}>
                  <ItemPicker onSelect={this.onItemSelect} />
                </ModalContainer>

                <ModalContainer onClose={() => this.setState({showPartyPicker: false})}
                                isOpen={this.state.showPartyPicker}
                                style={{
                                    width: 'calc(100% - 110px)',
                                    maxWidth: '1000px',
                                }}>
                  <PartyPicker onSelect={this.onPartySelect} />
                </ModalContainer>

                {isShowingRewards &&
                 <RewardProgramPicker selectCallback={this.onRewardSelect}
                                      onClose={() => this.setState({isShowingRewards: false})} />
                }
              </div>
              {images}
              {links}
              <div>
                {showMultiSocialPicker &&
                 <div>
                   <hr style={{margin: "10px"}} />
                   <MultiSocialPicker defaultOptions={defaultChannels}
                                      onChange={this.onChannelChange} />
                   <PostDatePicker
                       postNow={postNow}
                       onDatetimeChange={this.onDatetimeChange}
                       onChange={this.updateState}
                       postDatetime={postDatetime}
                       timezone={timezone}
                   />
                 </div>
                }
                <button type="submit"
                        className="btn btn-primary"
                        onClick={this.handlePost}
                        disabled={loading
                               || (!postNow && (!validDate || !timezone))}>
                  {this.props.editing && 'Update '}Post
                </button>
                {this.props.editing &&
                 <button type="button"
                         className="btn btn-default"
                         onClick={() => this.props.onEditFinish()}
                         style={{marginLeft: '15px'}}
                         disabled={loading}>
                   Cancel
                 </button>
                }
              </div>
            </div>
        );
    }
}

SocialPostEntryForm.propTypes = {
    onEditFinish: PropTypes.func.isRequired,
    activity: PropTypes.object,
    editing: PropTypes.bool,
    doRefresh: PropTypes.func,
    initialPartyPublicId: PropTypes.number,
};
