import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import FollowPanel from './FollowPanel.jsx';
import UserBio from './UserBio.jsx';
import FeaturedContent from './FeaturedContent.jsx';
import UserProfileChange from './UserProfileChange.jsx';
import SocialFeed from './SocialFeed.jsx';
import SocialMediaLinks from './SocialMediaLinks.jsx';
import { SendDirectMessage } from '../SendDirectMessage.jsx';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.isOwner = str_user_id === this.props.userPk;
    }

    socialFeed() {
        return <div>
          <h3 className="text-center">Activity</h3>
          <SocialFeed feedParams={{kind: 'user', value: this.state.user.pk}}
                      hidePostEntry />
        </div>;
    }

    featuredContent() {
        return <FeaturedContent user={this.state.user} />;
    }

    render() {
        if (this.state.user === null) return null;
        const profilePhoto = this.state.user.profile_photo_medium;
        return (
            <div className="row UserProfileApp">
              <div className="col-md-3 col-md-offset-1">
                <div className="TopMatter text-center">
                  <img className="SocialProfileImage" src={profilePhoto} />
                  {this.isOwner &&
                   <UserProfileChange
                       profilePhoto={profilePhoto}
                       callback={this.fetchUser} />
                  }
                  <h3>{this.state.user.name}</h3>
                  <FollowPanel user={this.state.user}
                               onFollow={this.fetchUser}
                               onUnfollow={this.fetchUser} />
                  <SendDirectMessage username={this.state.user.username} />
                  <SocialMediaLinks user={this.state.user} bs5={this.props.bs5} />
                  {this.state.user.omniparty &&
                   <p>
                     <a href={this.state.user.omniparty.url}>
                       {this.state.user.omniparty.name}
                     </a>
                   </p>
                  }
                  <UserBio user={this.state.user}
                           onBioChange={this.fetchUser} />
                  <hr />
                  {isMobile && this.featuredContent() || this.socialFeed()}
                </div>
              </div>

              <div className="col-md-7">
                {isMobile && this.socialFeed() || this.featuredContent()}
              </div>

            </div>
        );
    }

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser() {
        $.getJSON(`/api/v2/public_users/${this.props.userPk}/`, (data) =>  {
            this.setState({user: data});
        });
    }
}

UserProfile.defaultProps = {
    bs5: false,
};

UserProfile.propTypes = {
    userPk: PropTypes.number.isRequired,
    bs5: PropTypes.bool,
};

function UserProfileApp(el, props) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<UserProfile {...props} />);
}

export default UserProfileApp;
