import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from './util.jsx';

export default function VariantPicker(props) {
    const [selectedVariantPk, setSelectedVariantPk] = useState("");
    useEffect(() => {
        if (props.variantData && props.variantData.length > 0) {
            let variant;
            // use valid variant without an image so we don't trigger an image
            // change event
            variant = props.variantData.find(v => v.quantity && !v.image_id);
            if (!variant) {
                // go ahead and use a variant with an image, if the variant image
                // is different than the InvItem.image then the carousel on the buy
                // page will transition to the variant image
                variant = props.variantData.find(v => v.quantity);
            }
            if (!variant) {
                // Must be all sold out! Just take the first variant.
                variant = props.variantData[0];
            }
            changeVariant(variant);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    function changeVariant(variant) {
        setSelectedVariantPk(variant.pk);
        props.onPick(variant);
        if (window.VARIANT_TOGGLED_EVENT && variant.image_id) {
            window.dispatchEvent(
                new CustomEvent(window.VARIANT_TOGGLED_EVENT, {
                    detail: variant.image_id,
                })
            );
        }
    }
    function onChange(event) {
        const variantPk = Number(event.target.value);
        const variant = props.variantData.find(v => v.pk === variantPk);
        changeVariant(variant);
    }
    return <select className="form-control form-select"
                   value={selectedVariantPk}
                   onChange={onChange}
                   style={{width: '100%'}}>
      {props.variantData.map(variant => {
          return <option key={variant.pk}
                         value={variant.pk}>
            {variant.name}
            {' '}
            {formatCurrency(variant.price)}
            {!variant.quantity && ` - ${StrUserInfo.words.sold}`}
          </option>;
      })}
    </select>;
}

VariantPicker.propTypes = {
    variantData: PropTypes.array.isRequired,
    onPick: PropTypes.func.isRequired,
};
