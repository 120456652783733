import React from 'react';
import PropTypes from 'prop-types';
import { uniqWith, debounce } from 'lodash-es';

import { fetchJSON } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const DEFAULT_LIMIT = 10;

function getKeyFromCustomer(customer) {
    const { customer__pk, customer_name,
            address1, city, state, zipp } = customer;
    return `${customer__pk}${customer_name}${address1}${city}${state}${zipp}`;
}

class ShippingAddressPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: null,
            customers: [],
            sortedFilteredCustomers: [],
            didInitialLoad: false,
            limit: DEFAULT_LIMIT,
            filter: '',
        };

        this.onClick = this.onClick.bind(this);
        this.onMoreClick = this.onMoreClick.bind(this);
        this.handleBodyClick = this.handleBodyClick.bind(this);
        this.handleFilterChange = debounce(this.handleFilterChange, 500).bind(this);
        this.sortFilterCustomers = this.sortFilterCustomers.bind(this);
    }

    async componentDidMount() {
        const data = await fetchJSON('/api/v2/customers_view/');
        const customers = data.customers;
        this.setState({
            customers,
            didInitialLoad: true,
        }, this.sortFilterCustomers);
        document.body.addEventListener('click', this.handleBodyClick);
    }

    onClick(customer) {
        if (this.props.disabled)
            return;
        const key = getKeyFromCustomer(customer);
        if (this.state.selectedKey === key) {
            this.setState({selectedKey: null});
        } else {
            this.setState({selectedKey: key});
            this.props.onPick(
                customer.customer_name,
                customer.email,
                customer.address1,
                customer.address2,
                customer.city,
                customer.state,
                customer.zipp
            );
        }
    }

    onMoreClick(e) {
        e.preventDefault();
        this.setState((prevState) => {
            return { limit: prevState.limit + 5 };
        });
    }

    handleBodyClick(e) {
        const $target = $(e.target);
        if ($target.attr('type') === 'submit')
            return;

        if (this.state.selectedKey
            && $target.closest('.StrShippingAddressPickerRoot').length === 0) {
            this.setState({selectedKey: null});
        }
    }

    sortFilterCustomers() {
        const uniqueCustomers = uniqWith(this.state.customers, (a, b) => {
            return getKeyFromCustomer(a) === getKeyFromCustomer(b);
        });
        const filteredCustomers =
            this.state.filter !== '' ? uniqueCustomers.filter(
                c => c && getKeyFromCustomer(c)
                    .toLocaleLowerCase()
                    .includes(this.state.filter.toLocaleLowerCase()))
            : uniqueCustomers;
        function customer_sorter (a, b) {
            if (a.customer_name < b.customer_name) { return -1; }
            if (a.customer_name > b.customer_name) { return 1; }
            return 0;
        }
        this.setState({
            sortedFilteredCustomers: filteredCustomers.sort(customer_sorter),
        });
    }

    handleFilterChange(value) {
        this.setState({
            filter: value,
            limit: DEFAULT_LIMIT,
            selectedKey: null,
        }, this.sortFilterCustomers);
    }

    render() {
        const { sortedFilteredCustomers, limit,
                selectedKey, customers, didInitialLoad } = this.state;
        if (!didInitialLoad) {
            return <LoadingSpinner />;
        }
        const groupItemStyle = {
            cursor: "pointer",
        };
        const customersList = sortedFilteredCustomers.slice(0, limit)
            .map(customer => {
                const { customer__pk, customer_name, email, address1,
                        address2, city, state, zipp, phone } = customer;
                let groupItemCls = "list-group-item";
                const key = getKeyFromCustomer(customer);
                if (key === selectedKey)
                    groupItemCls += " active";
                return (
                    <div className={groupItemCls}
                         style={groupItemStyle}
                         key={key}
                         disabled={this.props.disabled}
                         onClick={e => this.onClick(customer)}>
                      <h6 className="list-group-item-heading">
                        {customer_name}
                        {customer__pk &&
                         <span>{' '}(customer #{customer__pk})</span>}
                      </h6>
                      <div className="list-group-item-text">
                        <div className="text-small" style={{marginBottom: "5px"}}>
                          {address1}
                          <br />
                          {address2}
                          {address2 && <span><br /></span>}
                          {city}, {state} {zipp}
                        </div>
                      </div>
                    </div>
                );
            });

        return (
            <div className="StrShippingAddressPickerRoot">
              <h4>Choose from existing customers</h4>
              <input className="form-control"
                     type="text"
                     placeholder="Filter customers.."
                     onChange={(e) => this.handleFilterChange(e.target.value)}
                     style={{marginBottom: '10px'}}
                     disabled={this.props.disabled}
                     name="filter" />
              <div className="list-group">
                {customersList}
                {limit < customers.length &&
                 <div className="list-group-item text-center">
                   <a href="#"
                      onClick={this.onMoreClick}>More</a>
                 </div>
                }
              </div>
            </div>
        );
    }
}

ShippingAddressPicker.propTypes = {
    onPick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ShippingAddressPicker;
