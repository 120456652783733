import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ItemCatTree, useItemCatTree } from './ItemCatTree.jsx';
import { TreePicker } from './TreePicker.jsx';

// Page where this is used should also include tree-picker.css (due to TreePicker)

const catXform = (cat) => ({
    key: cat.pk,
    name: cat.name,
    childItems: cat.children.map(catXform),
});

const ItemCategoryPicker = (props) => {
    const [expanded, setExpanded] = useState(true);
    const [selectedItemCats, setSelectedItemCats] = useState([]);
    const itemCatTree = useItemCatTree();

    useEffect(() => {
        if (!itemCatTree || !props.initialSelection || props.initialSelection.length === 0)
            return;
        const initialItemCats = props
            .initialSelection
            .map(icpk => itemCatTree.getItemCategoryByPk(icpk));
        setSelectedItemCats(initialItemCats);
    }, [props.initialSelection, itemCatTree]);

    const catItems = useMemo(() => {
        if (!itemCatTree)
            return null;
        return itemCatTree.catTree.map(catXform);
    }, [itemCatTree]);

    const handleSelection = (itemKeys) => {
        const itemCats = itemKeys.map(
            catPk => itemCatTree.getItemCategoryByPk(catPk));
        setSelectedItemCats(itemCats);
        if (props.onSelect)
            props.onSelect(itemCats);
    };

    const onDone = (e) => {
        e.preventDefault();
        setExpanded(false);
        if (props.onDone)
            props.onDone(selectedItemCats);
    };

    if (!itemCatTree)
        return null;

    return (
        <React.Fragment>
          {expanded && (
              <TreePicker
                  items={catItems}
                  onSelectionChange={handleSelection}
                  multiSelect={props.multiSelect}
                  initialSelection={props.initialSelection}
              />
          )}
          {!expanded &&
           selectedItemCats.length === 0 && (
               <button className="btn btn-sm"
                       onClick={(e) => {
                           e.preventDefault();
                           setExpanded(true);
                       }}>
                 Select
               </button>
          )}
          {selectedItemCats.length > 0 && (
              <React.Fragment>
                <div className="well text-small"
                     style={{padding: "10px 10px 0"}}>
                  <ul>
                    {selectedItemCats.map(itemCat => (
                        <li key={itemCat.pk}>
                          {itemCat.fullpathStrings()}
                        </li>
                    ))}
                  </ul>
                </div>
                <div className="btn-group" role="group">
                  {expanded && (
                      <button className="btn btn-sm btn-primary"
                              onClick={onDone}>
                        Done
                      </button>
                  )}
                  {!expanded && (
                      <button className="btn btn-sm"
                              onClick={(e) => {
                                  setExpanded(true);
                              }}>
                        Change
                      </button>
                  )}
                </div>
              </React.Fragment>
          )}
        </React.Fragment>
    );
};

ItemCategoryPicker.propTypes = {
    // Users will typically want to provide either onSelect (which is called
    // every time the cat selection changes), or onDone (which is called when
    // the user clicks "Done").

    // Receives array of ItemCategory objects (type from ItemCatTree.jsx)
    onSelect: PropTypes.func,
    // Receives same as onSelect
    onDone: PropTypes.func,
    multiSelect: PropTypes.bool.isRequired,
    // array of item cat pks
    initialSelection: PropTypes.arrayOf(PropTypes.number),
};

export { ItemCategoryPicker };
