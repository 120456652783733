import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer } from '../StrModal.jsx';


class SocialReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            saving: false,
        };
        this.submit = this.submit.bind(this);
    }

    submit() {
        this.setState({saving: true});
        const url = "/api/v2/report_social_activity/";
        const data = {
            uuid: this.props.uuid,
            text: this.state.text,
        };
        $.post(url, data, () => {
            this.setState({text: '', saving: false});
            this.props.onClose();
        });
    }

    render() {
        return (
            <div>
              <ModalContainer onClose={this.props.onClose} isOpen={this.props.show}>
                <div style={{minWidth: '300px'}}>
                  <h2>Report Post</h2>
                  <textarea
                      id="formControlsTextArea"
                      className="form-control"
                      value={this.state.text}
                      onChange={(e) => {this.setState({text: e.target.value});}}
                      disabled={this.state.saving}
                      placeholder="Please explain why you are reporting this post"
                  ></textarea>
                  <br />
                  <button type="button"
                          className="btn btn-primary"
                          disabled={this.state.saving}
                          onClick={this.submit}>
                    Submit
                  </button>
                </div>
              </ModalContainer>
            </div>
        );
    }
}

SocialReport.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    uuid: PropTypes.number.isRequired,
};

export default SocialReport;
