import { useState, useEffect } from 'react';
import { fetchJSON } from "./util.jsx";

class ItemCategory {
    constructor(catData, parent, callback) {
        const cat = catData.category;
        this.children = catData.children
                               .filter(node => !node.category.hidden)
                               .map(node => new ItemCategory(node, this, callback));
        this.parent = parent;
        this.pk = cat.pk;
        this.name = cat.name;
        this._fullpathCategories = null;
        if (callback)
            callback(this);
    }

    fullpathCategories() {
        if (this._fullpathCategories)
            return this._fullpathCategories;
        const path = [];
        let cat = this;
        while (cat) {
            path.unshift(cat);
            cat = cat.parent;
        }
        this._fullpathCategories = path;
        return this._fullpathCategories;
    }

    fullpathStrings(joiner = " -> ") {
        return this.fullpathCategories().map(c => c.name).join(joiner);
    }
}

const _pruneMark = (cats, pksToKeep) => {
    for (const cat of cats) {
        cat._keep = pksToKeep.has(cat.pk);
        if (cat._keep) {
            let node = cat;
            while (node) {
                node._keep = true;
                node = node.parent;
            }
        }
        _pruneMark(cat.children, pksToKeep);
    }
};

const _pruneSweep = (cats) => {
    // iterate in reverse so that we can modify the array in place without
    // worrying about adjusting array index as we remove items
    // https://stackoverflow.com/a/28122081/209050
    for (let i = cats.length - 1; i >= 0; i--) {
        if (!cats[i]._keep) {
            cats.splice(i, 1);
        } else {
            _pruneSweep(cats[i].children);
        }
    }
};

const _collectDescendantPks = (cats, pred, descendantPks) => {
    for (const cat of cats) {
        if (pred(cat)) {
            descendantPks.add(cat.pk);
            // Small optimization: collect all descendants unconditionally
            // (avoids the set membership test from pred)
            _collectDescendantPks(cat.children, c => true, descendantPks);
        } else {
            _collectDescendantPks(cat.children, pred, descendantPks);
        }
    }
};

//** ItemCatTree **
//
// Recursive tree data structure to represent the Item category hierarchy.
//
// This class should be instantiated with the static asynchronous factory
// method, ItemCatTree.create(), not with `new`.
//
// Bad:
//
//     tree = new ItemCatTree();
//
// Good:
//
//     tree = await ItemCatTree.create();
//
// The .catTree property contains the actual tree structure. It is an
// array of ItemCategory objects, which each have a .children property of
// ItemCategory objects for sub-categories, and so on recursively.
class ItemCatTree {
    // Since this class can be used many times on a page, we'd like to
    // avoid repeated network requests, or requiring that the caller
    // implement caching of their own.

    static _catTreeData = null;
    static _catTreeDataPromise = null; // "lock" to protect _catTreeData initialization
    static _pkToCatCache = {};

    // For internal use only. See create().
    constructor(catTree) {
        this.catTree = catTree;
    }

    static async create() {
        await ItemCatTree._fetchData();
        const catTree = [];

        for (const node of ItemCatTree._catTreeData) {
            if (node.category.hidden)
                continue;
            const cat = new ItemCategory(
                node,
                null,
                (itemCat) => ItemCatTree._pkToCatCache[itemCat.pk] = itemCat,
            );
            catTree.push(cat);
        }

        return new ItemCatTree(catTree);
    }

    static async _fetchData() {
        if (ItemCatTree._catTreeData !== null) {
            // fetch is already complete
            return ItemCatTree._catTreeData;
        }

        if (ItemCatTree._catTreeDataPromise !== null) {
            // fetch is already in progress
            return ItemCatTree._catTreeDataPromise;
        }

        ItemCatTree._catTreeDataPromise = fetchJSON("/api/v2/item_category_tree/")
            .then(data => {
                ItemCatTree._catTreeData = data;
                // nullify the promise so it can be garbage collected
                ItemCatTree._catTreeDataPromise = null;
            })
            .catch(error => {
                ItemCatTree._catTreeDataPromise = null;
                throw error;
            });
        return ItemCatTree._catTreeDataPromise;
    }

    // Prunes the tree to only contain the provided cats
    prune(catPks) {
        const pks = new Set(catPks);
        // mark and sweep
        _pruneMark(this.catTree, pks);
        _pruneSweep(this.catTree);
    }

    getItemCategoryByPk(pk) {
        return ItemCatTree._pkToCatCache[pk];
    }

    // Get a set of the combined descendancy chain for all of the
    // given catPks.
    getDescendantCatPksForCatPks(catPks) {
        const descendantPks = new Set();
        _collectDescendantPks(
            this.catTree,
            (cat) => catPks.has(cat.pk),
            descendantPks,
        );
        return descendantPks;
    }

    getAncestorCatPksForCatPks(catPks) {
        const ancestorPks = new Set();
        for (const catPk of catPks) {
            let itemCat = this.getItemCategoryByPk(catPk);
            while (itemCat) {
                ancestorPks.add(itemCat.pk);
                itemCat = itemCat.parent;
            }
        }
        return ancestorPks;
    }
}

const useItemCatTree = () => {
    const [itemCatTree, setItemCatTree] = useState(null);

    useEffect(() => {
        const doInit = async () => {
            const tree = await ItemCatTree.create();
            setItemCatTree(tree);
        };
        doInit();
    }, []);

    return itemCatTree;
};

export { ItemCatTree, useItemCatTree };
