import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import SocialFeed from './SocialFeed.jsx';


export class SocialPartyFeed extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <SocialFeed feedParams={{kind: 'party', value: this.props.publicId}}
                        hidePostEntry
                        showNewPosts
                        hideHelp />
        );
    }
}

SocialPartyFeed.propTypes = {
    publicId: PropTypes.number,
};

function SocialPartyFeedApp(el, publicId) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SocialPartyFeed publicId={publicId} />);
}

export default SocialPartyFeedApp;
