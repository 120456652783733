import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { fetchJSON } from "./util.jsx";
import StrTypeAhead from "./StrTypeAhead.jsx";
import CategoryCreator from "./CategoryCreator.jsx";

const searchSource = async (query) => {
    const qlen = query.length;
    const apiUrl = '/api/v2/product_categories/';
    const data = await fetchJSON(apiUrl, null, {q: query});

    return data.results
               .map((cat) => ({
                   triggerLength: qlen,
                   output: cat.name,
                   key: cat.pk,
                   displayData: {
                       ...cat,
                   },
               }))
               .concat([{
                   triggerLength: qlen,
                   output: 'Choose manually or create a new category',
                   key: 'NEWCATKEY',
                   displayData: {
                       name: 'Choose manually or create a new category',
                       fullpath_strings: ['New category'],
                   },
               }]);
};

const categoryPathToHtml = (categoryPath) => {
    let pathNodes = [];
    for (let i = 0; i < categoryPath.length; i++) {
        if (i > 0)
            pathNodes.push(<i key={`caret-${i}`} className="fa fa-caret-right" style={{padding: "0 7px"}}></i>);
        pathNodes.push(<span key={`span-${i}`}>{categoryPath[i]}</span>);
    }
    return pathNodes;
};

const display = (data) => {
    const pathNodes = categoryPathToHtml(data.fullpath_strings);
    return (
        <div>
          <strong>{data.name}</strong>
          <div className="text-small text-muted">{pathNodes}</div>
        </div>
    );
};

const CategoryPicker = (props) => {
    const { onSelect, categoryName } = props;
    const [creating, setCreating] = useState(false);

    const handleSelect = useCallback((match) => {
        if (match.key === 'NEWCATKEY') {
            setCreating(true);
        } else {
            setCreating(false);
            onSelect(match);
        }
    }, [onSelect]);

    function onSelectCategory(category) {
        // convert a Category serializer response to a StrTypeAhead match object
        // and pass that to onSelect
        const match = {
            triggerLength: null, //not sure I need this one
            output: category.name,
            // may not exist if we are creating a category
            key: category.pk,
            displayData: {
                ...category,
            },
        };
        props.onSelect(match);
    }

    return (
        <React.Fragment>
          <div className="col-md-6" style={{paddingLeft: 0}}>
            <StrTypeAhead
                placeholder="Start typing to search..."
                inputClassName="form-control"
                source={searchSource}
                display={display}
                onSelect={handleSelect}
                debounceDelay={500}
                initialValue={categoryName}
            />
          </div>
          {creating && <div className="col-md-12" style={{
              paddingLeft: 0,
              marginTop: '5px',
              marginBottom: '5px',
          }}>
            <CategoryCreator onSelect={onSelectCategory} />
          </div>
          }
        </React.Fragment>
    );
};

CategoryPicker.propTypes = {
    /* Receives (match), which is from the StrTypeAhead `source` */
    onSelect: PropTypes.func.isRequired,
    categoryName: PropTypes.string,
};

export { categoryPathToHtml };
export default CategoryPicker;
