import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const StrTooltip = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const childrenContainerRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        const childrenEl = childrenContainerRef.current;
        const tipEl = tooltipRef.current;
        if (showTooltip && childrenEl && tipEl) {
            const tipWidth = tipEl.offsetWidth;
            const tipHeight = tipEl.offsetHeight;
            const iconTop = childrenEl.offsetTop;
            const iconLeft = childrenEl.offsetLeft;
            const iconWidth = childrenEl.offsetWidth;
            const top = iconTop - (tipHeight);
            const left = iconLeft - (tipWidth / 2) + (iconWidth / 2);
            setTooltipPosition({ top, left });
        }
    }, [showTooltip]);

    return (
        <div style={{display: "inline-block"}}>
          <div ref={childrenContainerRef}
               onMouseEnter={() => setShowTooltip(true)}
               onMouseLeave={() => setShowTooltip(false)}
          >
            {props.children}
          </div>
          {showTooltip && (
              <div ref={tooltipRef}
                   className="tooltip in top"
                   role="tooltip"
                   style={{
                       position: 'absolute',
                       top: tooltipPosition.top,
                       left: tooltipPosition.left,
                       zIndex: 1000,
                   }}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-inner">
                  {props.message}
                </div>
              </div>
          )}
        </div>
    );
};

StrTooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    message: PropTypes.string.isRequired,
};

export default StrTooltip;
