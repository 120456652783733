import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import StrChat from './StrChat.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { ModalContainer } from './StrModal.jsx';


function Messages(props) {
    const [didFirstLoad, setDidFirstLoad] = useState(false);
    const [chatrooms, setChatrooms] = useState([]);
    const [selectedChatroom, setSelectedChatroom] = useState(null);
    const [desiredChatroom, setDesiredChatroom] = useState(null);
    const [filterText, setFilterText] = useState("");
    const [showNewChatModal, setShowNewChatModal] = useState(false);
    const [newChatUsername, setNewChatUsername] = useState("");
    const [newChatError, setNewChatError] = useState("");
    useEffect(fetchChatrooms, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(changeChatroom, [desiredChatroom]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setNewChatUsername("");
        setNewChatError("");
    }, [showNewChatModal]);

    const chatroomsUrl = '/api/v2/chatrooms/';

    function fetchChatrooms() {
        $.getJSON(chatroomsUrl, (data) => {
            setChatrooms(data);
            if (!didFirstLoad) {
                // set initial chatroom if we have one
                if (StrApp.chatroomPublicId) {
                    setDesiredChatroom(data.find(chatroom => {
                        return chatroom.public_id === StrApp.chatroomPublicId;
                    }));
                }
                setDidFirstLoad(true);
            }
        });
    }

    function changeChatroom() {
        if (!desiredChatroom) return;
        if (location.pathname.indexOf(desiredChatroom.public_id) < 0) {
            // dont update the history if the public_id is there already
            const path = `/messages/${desiredChatroom.public_id}p/`;
            window.history.pushState({}, null, `${window.location.origin}${path}`);
        }
        if (selectedChatroom) {
            /* unmount and remount StrChat after a delay because it
             * doesn't listen to prop changes very well yet */
            setSelectedChatroom(null);
            setTimeout(() => setSelectedChatroom(desiredChatroom), 100);
        } else {
            setSelectedChatroom(desiredChatroom);
        }
    }

    function unsetChatroom() {
        setSelectedChatroom(null);
        setDesiredChatroom(null);
        const path = '/messages/';
        window.history.pushState({}, null, `${window.location.origin}${path}`);
    }

    function newChatroom() {
        const data = {usernames: JSON.stringify([newChatUsername])};
        $.post(chatroomsUrl, data, (rsp, status, xhr) => {
            fetchChatrooms();
            setShowNewChatModal(false);
            setNewChatError("");
            setNewChatUsername("");
        }).fail(rsp => {
            if (rsp.responseJSON[0]) {
                setNewChatError(rsp.responseJSON[0]);
            }
        });
    }

    let filteredChatrooms = chatrooms;
    if (filterText) {
        filteredChatrooms = chatrooms.filter(chatroom => {
            const textToSearch = chatroom.other_uniusers
                                         .join('')
                                         .toLowerCase();
            return textToSearch.indexOf(
                filterText.toLowerCase()
            ) > -1;
        });
    }

    const chatroomsPane = <div>
      <div className="form-inline" style={{marginBottom: '10px'}}>
        {!isMobile &&
         <button className="btn btn-default"
                 onClick={(e) => {
                     e.preventDefault();
                     setShowNewChatModal(true);
                 }}>
           <span className="glyphicon glyphicon-plus" />
         </button>
        }
        <input className="form-control"
               type="text"
               style={{
                   marginLeft: isMobile ? '' : '10px',
                   width: isMobile ? '100%' : 'calc(100% - 50px)',
               }}
               onChange={(e) => setFilterText(e.target.value)}
               value={filterText}
               placeholder="Filter by username" />
      </div>
      <div className="list-group chatroomsList"
           style={{
               height: '400px',
               fontSize: '17px',
               padding: '5px',
           }}>
        {filteredChatrooms.map(chatroom => {
            const active = (
                chatroom && desiredChatroom
                && chatroom.pk === desiredChatroom.pk
            ) ? ' active' : '';
            return <a onClick={() => setDesiredChatroom(chatroom)}
                      className={`list-group-item${active}`}
                      key={chatroom.pk}
                      href="#">
              <h4 className="list-group-item-heading">
                {chatroom.other_uniusers.join(", ")}
              </h4>
            </a>;
        })}
      </div>
    </div>;

    const mobileChatHeader = <div>
      <p style={{cursor: 'pointer'}}
         onClick={unsetChatroom}>
        <span className="glyphicon glyphicon-chevron-left" />
        {' '}
        <span>
          {selectedChatroom && selectedChatroom.other_uniusers.join(", ")}
        </span>
      </p>
    </div>;

    return <div className="row">
      <div className="panel-body"
           style={{padding: '15px 0px', height: '480px'}}>
        <div className="col-md-8 col-md-push-4">
          {isMobile && selectedChatroom && mobileChatHeader}
          {selectedChatroom &&
           <StrChat chatTypeKey="chatroom"
                    chatId={selectedChatroom.public_id}
                    autoFocus={true} />
          }
          {!selectedChatroom && !desiredChatroom &&
           <p className="text-center">
             Select an existing chat or
             {' '}
             <button className="btn btn-default"
                     onClick={() => setShowNewChatModal(true)}>
               Start a new one
             </button>
           </p>
          }
        </div>
        <div className="col-md-4 col-md-pull-8">
          {(!isMobile || (isMobile && !selectedChatroom)) && chatroomsPane}
        </div>
      </div>
      {showNewChatModal &&
       <ModalContainer onClose={() => setShowNewChatModal(false)}
                       isOpen={true}
                       style={{
                           width: '400px',
                           maxWidth: '400px',
                       }}>
         <div className="row">
           <div className="col-md-12">
             <div className="form-group">
               <label htmlFor="username">Username</label>
               <input type="text"
                      className="form-control"
                      name="username"
                      value={newChatUsername}
                      autoFocus
                      onChange={(e) => setNewChatUsername(e.target.value)} />
             </div>
             {newChatError &&
              <div className="alert alert-danger">
                {newChatError}
              </div>
             }
             <button className="btn btn-primary"
                     onClick={newChatroom}
                     disabled={!newChatUsername}>
               Start new chat
             </button>
           </div>
         </div>
       </ModalContainer>
      }
    </div>;
}


export default function MessagesApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<Messages />);
}
