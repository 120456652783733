import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { fetchJSON, formatCurrency } from './util.jsx';
import Gallery from './Gallery.jsx';
import { ModalContainer } from './StrModal.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import ClaimButton from './ClaimButton.jsx';
import DigitalDownloadsEditor from './DigitalDownloadsEditor.jsx';


class ItemDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getItemData = this.getItemData.bind(this);
        this.shareLink = this.shareLink.bind(this);
        this.updateItemData = this.updateItemData.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    render() {
        const { item, itemLink } = this.state;
        const itemAttrs = item && item.data && item.data.attributes || {};
        const attributes = Object.entries(itemAttrs).map(
            ([attrName, attrVal]) => {
                const key = `${attrName}${attrVal}`;
                return <li key={key}><span className="label label-light">{attrName}: {attrVal}</span></li>;
            }
        );
        if (StrUserInfo.banned) {
            return null;
        }
        const maxWidth = isMobile ? document.documentElement.clientWidth
                       : '700px';
        let images = [];
        if (item) {
            images = item.is_market_item
                   ? item.extra_images
                   : [item.image, ...item.extra_images];
        }
        return <ModalContainer isOpen={true}
                               style={{maxWidth: maxWidth}}
                               onClose={this.onClose}>
          {!item &&
           <div>
             <LoadingSpinner />
             {' '}
             Loading item details...
           </div>
          }
          {item &&
           <div>
             <h4>{item.shortName}</h4>
             <div className="row text-center">
               <div className="col-md-12">
                 <div className="product-image-wrapper">
                   <Gallery item={item}
                            images={images} />
                 </div>
               </div>
             </div>
             <div className="row" style={{marginTop: '20px'}}>
               <div className="col-md-6">
                 <ClaimButton itemPk={item.pk}
                              btnSize='btn-lg'
                              claimedFromPartyPk={this.props.partyPk}
                              claimedFromPartyAlbumPk={this.props.partyAlbumPk}
                              claimedFromOmniParty={this.props.isOmniParty}
                              disableAnonClaims={item.disable_anon_claims}
                              quantity={item.quantity}
                              iclaimed={item.iclaimed}
                              words={str_data_party_words}
                              variantData={item.itemvariant_data} />
                 <ul className="list-unstyled text-small">
                   {attributes}
                 </ul>
                 <ul className="list-unstyled text-small">
                   <li>Item #<a href={itemLink}>{item.pk}</a></li>
                 </ul>
               </div>
               <div className="col-md-6 text-right">
                 {item.title &&
                  <h5 style={{color: '#333', margin: "5px 0"}}>
                    {item.title}
                  </h5>
                 }
                 {item.itemchoice &&
                  <h5 style={{color: '#333', margin: "5px 0"}}>
                    {item.itemchoice.name}{item.size ? ` (${item.size.name})` : ''}
                  </h5>
                 }
                 <p style={{color: "#555", margin: "0 0 5px"}}>
                   {formatCurrency(item.price)}
                 </p>
                 {item.is_digital &&
                  <div className="text-small text-muted">
                    <i className="fa fa-cloud-download" aria-hidden="true"></i>
                    {' '}
                    Digital download
                    <DigitalDownloadsEditor invItemPk={item.pk} viewOnly />
                  </div>
                 }
               </div>
               <div className="col-md-12">
                 <p className="text-smaller str-linebreaksbr">{item.description || "" }</p>
                 {this.shareLink()}
               </div>
             </div>
             <div>
               <button type="button"
                       className="btn btn-default"
                       onClick={this.onClose}>
                 Close
               </button>
             </div>
           </div>
          }
        </ModalContainer>;
    }

    componentDidMount() {
        this.updateItemData();
        $.post(`/api/v2/${this.props.itemId}/view/`);
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemId !== prevProps.itemId)
            this.updateItemData();
    }

    updateItemData() {
        this.getItemData(this.props.itemId).then((item) => {
            let itemLink = item.listingLink;
            const queryParams = [];
            if (this.props.partyPk) {
                queryParams.push(`claimed_from=${encodeURIComponent(this.props.partyPk)}`);
            }
            if (this.props.partyAlbumPk) {
                queryParams.push(`claimed_from_album=${encodeURIComponent(this.props.partyAlbumPk)}`);
            }
            if (queryParams.length > 0) {
                itemLink += "?" + queryParams.join("&");
            }
            this.setState({item, itemLink});
        });
    }

    async getItemData(itemId) {
        const url = `/api/v2/public_items/${itemId}/`;
        return await fetchJSON(url);
    }

    shareLink() {
        const { item, itemLink } = this.state;
        return (
            <div style={{textAlign: 'center'}}>
              <hr style={{margin: "20px auto"}} />
              <div className="text-smaller">
                You can use or share this link to access this item's details page directly
              </div>
              <div className="form-inline">
                <div className="form-group">
                  <label className="sr-only" htmlFor="the-buy-link">
                    Link to this sale</label>
                  <div className="input-group col-md-push-1">
                    <span style={{position: "absolute", left: "-10000px"}}>
                      {itemLink}</span>
                    <span className="exists-for-styling">
                      <input type="text" style={{backgroundColor: '#fff'}}
                             className="form-control"
                             id="the-buy-link"
                             value={itemLink}
                             readOnly
                             ref={(el) => {
                                 if (el)
                                     this.listingLinkRef = el;
                             }} />
                    </span>
                    {this.copyButton()}
                  </div>
                </div>
              </div>
            </div>
        );
    }

    copyButton() {
        if (document.queryCommandSupported('copy')) {
            return (
                <div id="the-buy-clipboard-button"
                     data-clipboard-target="#the-buy-link"
                     style={{display: "table-cell"}}
                     className="btn input-group-addon"
                     onClick={(e) => {
                         this.listingLinkRef.select();
                         document.execCommand('copy');
                     }}>
                  <span className="glyphicon glyphicon-copy"
                        aria-hidden="true"></span>
                </div>
            );
        }
    }

    onClose(e) {
        this.props.onClose && this.props.onClose(e);
    }
}

ItemDetails.propTypes = {
    itemId: PropTypes.number.isRequired,
    partyPk: PropTypes.number,
    partyAlbumPk: PropTypes.number,
    isOmniParty: PropTypes.bool,
    onClose: PropTypes.func,
};

function ItemDetailsApp(el, itemId, partyPk, partyAlbumPk) {
    const root = createRoot(el);
    root.render(<ItemDetails itemId={itemId}
                             partyPk={partyPk}
                             partyAlbumPk={partyAlbumPk} />);
}

export { ItemDetailsApp, ItemDetails };
