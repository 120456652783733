import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const StrDropzone = (props) => {
    const {
        accept = {"image/*": [".jpeg", ".png", ".jpg"]},
        maxSize = 10000000, // 10 MB
    } = props;

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: props.onDrop,
        multiple: props.multiple,
        maxSize: maxSize,
        accept: accept,
    });

    const style = {
        cursor: "pointer",
        height: "auto",
        background: "#f0f0f0",
        padding: "15px 30px",
        borderRadius: "3px",
        border: "solid 1px #e0e0e0",
        display: "inline-block",
        textAlign: "center",
    };

    return (
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <div style={{color: "#737373"}}>
            <i className="fa fa-cloud-upload"
               style={{fontSize: "40px"}}
               aria-hidden="true"></i>
            <div style={{
                fontSize: "13px",
            }}>Drag and Drop or Select File</div>
          </div>
        </div>
    );
};

StrDropzone.propTypes = {
    onDrop: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
    maxSize: PropTypes.number,
    accept: PropTypes.string,
};

export default StrDropzone;
