import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';

import { updateStateThing, fetchJSON } from '../util.jsx';
import FlatRateItemCounts from './FlatRateItemCounts.jsx';

const STRATEGIES = {
    'singleRate': 'single_rate',
    'flatRateItemCounts': 'flatrate_item_counts',
};

export default class ShippingCostSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            strategy: '',
            singleRate: '0',
            flatrateItemCounts: {rules: [], max_rate: '0'},
            allowLocalPickup: false,
        };

        this.url = '/api/v2/shipping_cost_setup/';

        this.fetch = this.fetch.bind(this);
        this.post = debounce(this.post, 500).bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.updateAndPost = this.updateAndPost.bind(this);
    }

    async fetch() {
        const data = await fetchJSON(this.url);
        this.setState({
            strategy: data.strategy,
            singleRate: data.single_rate,
            flatrateItemCounts: data.flatrate_item_counts,
            allowLocalPickup: data.allow_local_pickup,
        });
    }

    componentDidMount() {
        this.fetch();
    }

    post() {
        const data = {
            strategy: this.state.strategy,
            singleRate: this.state.singleRate,
            flatrateItemCounts: JSON.stringify(this.state.flatrateItemCounts),
            allowLocalPickup: this.state.allowLocalPickup,
        };
        $.post(this.url, data, (rsp) => {
            toast.success('Saved');
        }).fail((rsp) => {
            toast.error('Error saving');
        });
    }

    updateAndPost(e=null, name=null, value=null, max=null) {
        value = max ? Math.min(max, value) : value;
        if (name) {
            this.setState({[name]: value}, this.post);
        } else {
            this.updateStateThing(e, this.post);
        }
    }

    render() {
        const { disabled } = this.props;
        const { strategy, allowLocalPickup } = this.state;
        const entireTitle = disabled
                          ? `These options only available when Collect payment with ${site_brand_name} is selected`
                          : null;
        return (
            <div title={entireTitle}>
              <div className="help-block">
                <p>
                  When you are collecting payment with {' '}
                  {site_brand_name} customers will pay right on the
                  {' '}{StrUserInfo.words.done_shopping.toLowerCase()} page
                  so you need to setup a way to calculate shipping
                  costs.
                </p>
              </div>
              <div className="radio">
                <label>
                  <input type="radio"
                         name="strategy"
                         value={STRATEGIES.singleRate}
                         onChange={this.updateAndPost}
                         disabled={disabled}
                         checked={strategy === STRATEGIES.singleRate}
                  />
                  Single Rate
                  <span id="helpBlock" className="help-block">
                    Shipping is the same, flat rate for every order.
                  </span>
                </label>
              </div>
              {strategy === STRATEGIES.singleRate &&
               <form className="form-horizontal">
                 <div className="form-group">
                   <label htmlFor="noItems"
                          className="col-sm-4 control-label">
                     Single Rate
                   </label>
                   <div className="col-sm-5">
                     <div className="input-group">
                       <div className="input-group-addon">$</div>
                       <input className="form-control"
                              type="number"
                              name="singleRate"
                              onChange={(e) => this.updateAndPost(
                                  null,
                                  'singleRate',
                                  e.target.value,
                                  e.target.max,
                              )}
                              value={this.state.singleRate}
                              disabled={disabled}
                              max="9999.99"
                              placeholder="eg 6.00" />
                     </div>
                   </div>
                 </div>
               </form>
              }
              <div className="radio">
                <label>
                  <input type="radio"
                         name="strategy"
                         value={STRATEGIES.flatRateItemCounts}
                         onChange={this.updateAndPost}
                         disabled={disabled}
                         checked={strategy === STRATEGIES.flatRateItemCounts}
                  />
                  Item Count Rules
                  <span id="helpBlock" className="help-block">
                    Set price rules based on the number of items a
                    customer is purchasing, with optional maximum
                    cost.
                  </span>
                  <span id="helpBlock" className="help-block">
                    Examples:
                    <ul>
                      <li>$2 for first item, $1 for each item after that.</li>
                      <li>$5 for first item, $1 for each item after that up to a maximum of $10.</li>
                    </ul>
                  </span>
                </label>
              </div>
              {strategy === STRATEGIES.flatRateItemCounts &&
               <FlatRateItemCounts flatrateItemCounts={this.state.flatrateItemCounts}
                                   updateAndPost={this.updateAndPost}
                                   disabled={disabled} />
              }
              <div className="checkbox">
                <label>
                  <input type="checkbox"
                         onChange={this.updateAndPost}
                         checked={allowLocalPickup}
                         name="allowLocalPickup" />
                    <span style={{
                        display: "inline-block",
                        position: "relative",
                        top: "-2px",
                    }}>
                      Allow local pickup
                    </span>
                </label>
              </div>
            </div>
        );
    }
}

ShippingCostSetup.defaultProps = {
    disabled: false,
};

ShippingCostSetup.propTypes = {
    disabled: PropTypes.bool,
};
