import React, { useEffect } from "react";
import PropTypes from 'prop-types';

/* this exists because i like clippy more than the react copytoclipboard
 * and react bootstrap tooltip */

export default function StrCopyToClipboard(props) {
    useEffect(str_reinstate_clippies, []);
    return <a className="clippy"
              style={{cursor: 'pointer', color: '#333'}}
              data-clipboard-text={props.text}>
      <i className="glyphicon glyphicon-copy" />
    </a>;
}

StrCopyToClipboard.propTypes = {
    text: PropTypes.string.isRequired,
};
