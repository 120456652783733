import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { fetchJSON } from './util.jsx';

const ItemChoicePicker = ({ isMulti = false, placeholder = "Filter by Style", onPick }) => {
    const [itemChoices, setItemChoices] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const doInit = async () => {
            const data = await fetchJSON("/api/v2/itemchoices/");
            setItemChoices(data.results);
        };
        doInit();
    }, []);

    const onChange = (options) => {
        setSelectedOptions(options);
        onPick(options);
    };

    const shouldEagerlyClose = !isMulti || selectedOptions.length === 0;

    return (
        <Select
            placeholder={placeholder}
            isMulti={isMulti}
            onChange={onChange}
            blurInputOnSelect={shouldEagerlyClose}
            closeMenuOnSelect={shouldEagerlyClose}
            options={itemChoices}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.pk}
        />
    );
};

ItemChoicePicker.propTypes = {
    // Gets an array of ItemChoice objects in multi-mode,
    // or a single ItemChoice object in single-mode.
    onPick: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
};

export default ItemChoicePicker;
