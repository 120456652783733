import React from 'react';
import PropTypes from 'prop-types';

import { ShippingLabels } from './shipping_labels_app.jsx';
import { ModalContainer } from './StrModal.jsx';

export default class ShippingBlessOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLabelPicker: false,
            hasLabel: false,
            labelPdf: "",
            labelTracker: "",
        };

        this.setLabel = this.setLabel.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hasLabel !== this.props.hasLabel
            || prevState.labelPdf !== this.props.labelPdf
            || prevState.labelTracker !== this.props.labelTracker)
            this.setState({
                hasLabel: this.props.hasLabel,
                labelPdf: this.props.labelPdf,
                labelTracker: this.props.labelTracker,
            });
    }

    setLabel(tracker, pdf, labelPk) {
        this.setState({
            showLabelPicker: false,
            hasLabel: true,
            labelPdf: pdf,
            labelTracker: tracker,
        });
    }

    render() {
        const {name, email, street1, street2, city,
               state, zip, blessOrderNo, customsItems} = this.props;
        let body = null;
        if (this.state.showLabelPicker === true) {
            const data = {
                name: name,
                email: email,
                street1: street1,
                street2: street2,
                city: city,
                state: state,
                zip: zip,
                labelOnly: true,
                claimRecords: [],
                orderPk: Math.floor(Math.random() * 99999) * -1,
                orderPkIsALie: true,
                setLabel: this.setLabel,
                blessOrderNo: blessOrderNo,
                customsItems: customsItems,
            };
            return <div style={{marginBottom: '20px'}}>
              <ModalContainer onClose={() => this.setState({showLabelPicker: false})}
                              isOpen={true}
                              style={{
                                  maxWidth: '1000px',
                                  zIndex: 1001,
                              }}>
                <ShippingLabels data={data}
                                isModal={false} />
              </ModalContainer>
            </div>;
        }
        return (
            <div style={{marginBottom: '20px'}} className="row">
              <div className="col-md-2 col-md-offset-2">
                {this.state.hasLabel &&
                 <div>
                   <p>Has a label</p>
                   {this.state.labelPdf &&
                    <a href={this.state.labelPdf}
                        style={{marginRight: '10px'}}
                        target="_blank">
                      PDF
                    </a>
                   }
                   {this.state.labelTracker &&
                    <a href={this.state.labelTracker}
                        target="_blank">
                      Tracking
                    </a>
                   }
                 </div>
                }
                {!this.state.hasLabel &&
                 <button className="btn btn-primary"
                         onClick={() => this.setState({showLabelPicker: true})}>
                   Create Label
                 </button>
                }
              </div>
              <div className="col-md-4">
                {name}
                <div className="text-small" style={{marginBottom: "5px"}}>
                  {street1}
                  <br />
                  {street2}
                  {street2 && <span><br /></span>}
                  {city},
                  {' '}
                  {state}
                  {' '}
                  {zip}
                  <br />
                  Bless order #{blessOrderNo}
                </div>
              </div>
            </div>
        );
    }
}

ShippingBlessOrder.propTypes = {
    hasLabel: PropTypes.bool,
    labelPdf: PropTypes.string,
    labelTracker: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    blessOrderNo: PropTypes.string,
    customsItems: PropTypes.array,
};
