import React from 'react';
import PropTypes from 'prop-types';

class NotificationsList extends React.Component {
    render() {
        let list = this.props.notifications.map((notification) => {
            return (
                <li key={notification.pk}>
                  <div onClick={(e) => this.handleClick(e, notification)}>
                    <div className="notfThumb">
                      {notification.image &&
                       <img src={notification.image} />
                      }
                    </div>
                    <div className="notfContent">
                      {notification.content}
                      <div className="text-muted text-small">{moment(notification.date).fromNow()}</div>
                    </div>
                  </div>
                </li>
            );
        });
        return (
            <div className="strNotificationList">
              <ul>
                {list}
              </ul>
              <div>
                <a className="readMore" href="/notifications/">
                  See all <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
        );
    }

    handleClick(e, notf) {
        window.location = notf.link;
    }

}

NotificationsList.propTypes = {
    notifications: PropTypes.array.isRequired,
};

export default NotificationsList;
