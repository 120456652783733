/* See also _market_product.html. Need to keep in sync. */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { formatCurrency } from './util.jsx';
import { LikeButton } from './LikeButton.jsx';

const ItemQuantityInfo = (props) => {
    const { item } = props;

    if (item.quantity === 0)
        return <p className="card-text text-sm-end text-warning">Out of stock</p>;
    if (item.quantity <= 2)
        return <p className="card-text text-sm-end text-danger">Only {item.quantity} left</p>;
    return null;
};

ItemQuantityInfo.propTypes = {
    item: PropTypes.object.isRequired,
};

const MarketProduct = (props) => {
    const { item, isLiked, includeLike, extraBottomContent, priceContent } = props;

    const defaultPriceContent = (
        <p className="card-text text-sm-end text-success" style={{marginBottom: 0}}>
          <strong>
            {formatCurrency(item.price)}
          </strong>
        </p>
    );

    const numVariants = item.itemvariant_data.length;

    return (
        <div className="col-md-3 col-6 mb-4">
          <div className="card border-0">
            <div className="responsive-square-image-wrapper">
              <a href={item.listingLink}
                 target="_blank">
                <img src={item.image.image_full}
                     className="card-img-top rounded-4" />
              </a>
            </div>

            <div className="card-body px-0 py-1">
              <div className="row"
                   style={{fontSize: "14px"}}>
                <div className="col-sm-5 order-sm-2 col-sm-push-7">
                  {priceContent ? priceContent() : defaultPriceContent}
                  {item.is_digital &&
                   <p className="card-text text-sm-end">
                     <span className="badge rounded-pill bg-light text-dark">
                       Digital Download
                     </span>
                   </p>
                  }
                  {!item.is_digital && <ItemQuantityInfo item={item} />}
                </div>
                <div className="col-sm-7 order-sm-1 col-sm-pull-5">
                  <p style={{marginBottom: 0}}>
                    <a href={item.listingLink}
                       target="_blank">{item.shortName}</a>
                  </p>
                  {numVariants > 0 &&
                   <div className="text-muted" style={{fontStyle: "italic"}}>
                     {numVariants} option{numVariants === 1 ? "" : "s"} available
                   </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-12">
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      {includeLike && <LikeButton itemPk={item.pk} isLiked={isLiked} />}
                    </div>
                    <div>
                      {extraBottomContent && extraBottomContent()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

MarketProduct.propTypes = {
    item: PropTypes.object.isRequired,
    isLiked: PropTypes.bool.isRequired,
    includeLike: PropTypes.bool.isRequired,
    extraBottomContent: PropTypes.func,
    priceContent: PropTypes.func,
};

export default MarketProduct;
