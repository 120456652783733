import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import moment from 'moment';

import { formatCurrency, updateStateThing, isValidEmail } from '../util.jsx';
import LoadingSpinner from '../LoadingSpinner.jsx';
import { InvoiceRefund } from '../invoice_refund_app.jsx';
import MarkdownView from '../MarkdownView.jsx';
import PrivateEye from '../PrivateEye.jsx';

const FeeAndEarningsBreakdown = (props) => {
    const { invoice, includeTax } = props;
    let totalExplanation;
    if (includeTax)
        totalExplanation = "Total minus fees. Includes collected tax.";
    else
        totalExplanation = "Total minus fees";
    return (
        <dl className="dl-horizontal">
          {invoice.referral_fee_amount > 0 &&
           <React.Fragment>
             <dt>Referral fee:</dt>
             <dd>{formatCurrency(invoice.referral_fee_amount)}</dd>
           </React.Fragment>
          }
          {invoice.transfer_amount &&
           <React.Fragment>
             <dt>Seller earnings:</dt>
             <dd>
               <b className="text-success">
                 {formatCurrency(invoice.transfer_amount)}
               </b>
               {invoice.amount_reversed &&
                <span className="text-small text-muted">
                  {' '}
                  ({formatCurrency(invoice.amount_reversed)} reversed for {formatCurrency(invoice.amount_refunded)} refund)
                </span>
               }
               <br />
               <small>({totalExplanation})</small>
             </dd>
           </React.Fragment>
          }
        </dl>
    );
};

FeeAndEarningsBreakdown.propTypes = {
    invoice: PropTypes.object.isRequired,
    includeTax: PropTypes.bool.isRequired,
};

class Invoice extends React.Component {
    constructor(props) {
        super(props);
        this.id = 1;
        this.state = {
            saving: false,
            showAdvanced: false,
            includeTax: props.invoice.include_tax,
            emailCustomer: props.claimbatch && !!props.claimbatch.email,
            includeShipping: props.invoice.include_shipping,
            emailRecieptWhenPaid: props.invoice.email_receipt_when_paid,
            lineItems: this.getLineItems(),
            notes: '',
            addName: '',
            addAmt: '',
            errorMessage: null,
            showPersonal: null,
            enterCard: null,
            sending: false,
            copied: false,
            editingLineItemId: null,
            editPrice: 0,
            email: '',
        };

        this.updateStateThing = updateStateThing.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.payClicked = this.payClicked.bind(this);
        this.sendInvoice = this.sendInvoice.bind(this);
        this.sendReceipt = this.sendReceipt.bind(this);
        this.getId = this.getId.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.isOwner = this.isOwner.bind(this);
        this.toggleEditItemPrice = this.toggleEditItemPrice.bind(this);
        this.setPrice = this.setPrice.bind(this);
        this.deactivateInvoice = this.deactivateInvoice.bind(this);
        this.getLineItems = this.getLineItems.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    getLineItems() {
        return this.props.invoice.line_items.map(li => {
            return {
                thumbnail: li.thumbnail,
                name: li.name,
                price: li.price,
                original_price: li.original_price,
                id: this.getId(),
                crPk: li.claimrecord_pk,
            };
        });
    }

    isOwner() {
        const { consultant } = this.props;
        return consultant && consultant.pk === StrUserInfo.uuid;
    }

    getId() {
        return this.id++;
    }

    onCopy() {
        this.setState({copied: true});
        setTimeout(() => this.setState({copied: false}), 1500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.invoice.pk !== this.props.invoice.pk) {
            this.setState({lineItems: this.getLineItems()});
        }
    }

    renderImage() {
        const { invoice } = this.props;
        if (!invoice.image) {
            if (this.isOwner()) {
                return (
                    <p className="text-small text-muted">
                      You can add a custom header image for your invoices
                      in your <a href="/settings/billing/#invoicing-settings">settings</a>.
                    </p>
                );
            }
            return;
        }
        return (
            <div className="row">
              <div className="col-md-12">
                <img style={{
                    maxWidth: '100%',
                    maxHeight: '200px',
                    display: 'block',
                    margin: '0 auto',
                }}
                     src={invoice.image} />
              </div>
            </div>
        );
    }

    renderDateAndNumber() {
        const { invoice } = this.props;
        return (
            <div className="row">
              <div className="col-md-12">
                {invoice.pk && invoice.is_paid &&
                 <h3>PAID{invoice.amount_refunded && ' AND REFUNDED'}</h3>
                }
                {invoice.pk && invoice.deactivated &&
                 <h3>CANCELLED</h3>
                }
                Invoice no: {invoice.number}
                <br />
                Date: {moment(invoice.date).format('MMM DD, YYYY')}
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </div>
        );
    }

    renderInfo() {
        const {claimbatch, consultant} = this.props;
        const hasAddress = claimbatch.state && claimbatch.zip;
        return (
            <div className="row">
              <div className="col-md-6">
                {hasAddress &&
                 <div>
                   <h5>Ship to:</h5>
                   <small>
                     {claimbatch.customer_name} <br />
                     {claimbatch.email} <br />
                     {claimbatch.address1} <br />
                     {claimbatch.address2 &&
                      <span>
                        {claimbatch.address2} <br />
                      </span>
                     }
                     {claimbatch.city} {claimbatch.state}, {claimbatch.zip} <br />
                   </small>
                 </div>
                ||
                 <div>
                   <h5>Customer:</h5>
                   <small>
                     {claimbatch.customer_name} <br />
                     {claimbatch.email}
                   </small>
                 </div>
                }
              </div>
              <div className="col-md-6">
                <h5>Ship from:</h5>
                <small>
                  {consultant.name} <br />
                  {consultant.email} <br />
                  {consultant.address1} <br />
                  {consultant.address2 &&
                   <span>
                     {consultant.address2} <br />
                   </span>
                  }
                  {consultant.city} {consultant.state}, {consultant.zipp} <br />
                </small>
              </div>
              <div className="col-md-12"><hr /></div>
            </div>
        );
    }

    removeItem(e, key) {
        e.preventDefault();
        this.setState(prevState => {
            return update(prevState, {
                lineItems: {
                    $apply: lineItems =>
                        lineItems.filter(lItem =>
                            key !== lItem.id),
                },
            });
        }, () => {this.props.callback(null, this.state.lineItems);});
    }

    addItem(e) {
        e.preventDefault();
        const newItem = {
            name: this.state.addName,
            price: this.state.addAmt,
            id: this.getId(),
        };
        this.setState(prevState => {
            return update(prevState, {
                lineItems: {$push: [newItem]},
                addName: {$set: ''},
                addAmt: {$set: ''},
            });
        }, () => {this.props.callback(null, this.state.lineItems);});
    }

    toggleEditItemPrice(e, key) {
        // toggles edit mode of a line item price
        e.preventDefault();
        this.setState(prevState => {
            const editingLineItemId = prevState.editingLineItemId === key
                                    ? null : key;
            const editPrice = prevState.editingLineItemId === key
                            ? 0
                            : prevState.lineItems.find(li => li.id === key).price;
            return {editingLineItemId: editingLineItemId, editPrice: editPrice};
        });
    }

    setPrice(e, key) {
        e.preventDefault();
        this.setState(prevState => {
            const idx = prevState.lineItems.findIndex(li => li.id === key);
            return update(prevState, {
                lineItems: {
                    [idx]: {
                        price: {$set: prevState.editPrice},
                    },
                },
                editingLineItemId: {$set: null},
            });
        }, () => this.props.callback(null, this.state.lineItems));
    }

    renderLineItems() {
        const lineItems = this.state.lineItems.map(li => {
            const key = li.id;
            return (
                <div key={key} className="col-md-12 row">
                  <div className="col-md-2">
                    <img src={li.thumbnail} />
                  </div>
                  <div className="col-md-4">
                    {li.name}
                  </div>
                  <div className="col-md-4">
                    {li.original_price && li.original_price > li.price &&
                     <span>
                       <strike>
                         {formatCurrency(li.original_price)}
                       </strike>
                       {' '}
                     </span>
                    }
                    {formatCurrency(li.price)}
                    {this.state.editingLineItemId === key &&
                     <p>
                       <input type="number"
                              className="form-control"
                              value={this.state.editPrice}
                              onChange={(e) => this.setState({editPrice: e.target.value})} />
                       <a href=""
                          className="btn btn-sm btn-primary"
                          onClick={(e) => this.setPrice(e, key)}>
                         {' '}save
                       </a>
                       <a href=""
                          className="btn btn-sm btn-default"
                          onClick={(e) => this.toggleEditItemPrice(e, key)}>
                         {' '}cancel
                       </a>
                     </p>
                    }
                    {!this.props.invoice.pk
                    && this.state.editingLineItemId !== key &&
                     <a href=""
                        onClick={(e) => this.toggleEditItemPrice(e, key)}>
                       {' '}edit
                     </a>
                    }
                  </div>
                  {!this.props.invoice.pk &&
                   <div className="col-md-1 col-md-offset-1">
                     <a className="glyphicon glyphicon-remove"
                        href=""
                        onClick={(e) => this.removeItem(e, key)}></a>
                   </div>
                  }
                </div>
            );
        });
        return (
            <div className="row">
              <div className="col-md-12">
                {lineItems}
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </div>
        );
    }

    renderTotals() {
        const { invoice } = this.props;
        const { lineItems, includeTax, includeShipping } = this.state;
        const lItemCount = lineItems.length;
        const tax = invoice.tax_quote
                 && invoice.tax_quote.tax
                 && invoice.tax_quote.tax.amount_to_collect;
        const subtotal = lineItems.reduce((acc, li) =>
            acc + Number(li.price), 0);
        let total = subtotal;
        if (includeTax)
            total += Number(tax);
        if (includeShipping
            && invoice.shipping_quote
            && invoice.shipping_quote.cost)
            total += Number(invoice.shipping_quote.cost);
        return (
            <div className="row well">
              <div className="col-md-12"
                   style={invoice.deactivated ? {textDecoration: 'line-through'} : {}}>
                <dl className="dl-horizontal">
                  <dt>Subtotal ({lItemCount} item{lItemCount !== 1 && 's'}):</dt>
                  <dd>{formatCurrency(subtotal)}</dd>
                  {includeTax &&
                   <React.Fragment>
                     <dt>Tax:</dt>
                     <dd>{formatCurrency(tax)}</dd>
                   </React.Fragment>
                  }
                  {includeShipping
                  && invoice.shipping_quote
                  && invoice.shipping_quote.cost &&
                   <React.Fragment>
                     <dt>Shipping:</dt>
                     <dd>{formatCurrency(invoice.shipping_quote.cost)}</dd>
                   </React.Fragment>
                  }
                  <dt>Total:</dt>
                  <dd>
                    {formatCurrency(total)}
                    {invoice.pk && invoice.is_paid &&
                     <span>
                       {' '}
                       <span className="label label-success">PAID</span>
                     </span>
                    }
                  </dd>
                </dl>
                {invoice.amount_refunded &&
                 <span>
                   {' '}
                   (
                   {formatCurrency(Number(invoice.amount_refunded))}
                   {' '}
                   refunded)
                 </span>
                }
              </div>
            </div>
        );
    }

    createInvoice(e) {
        this.setState({saving: true});
        const url = `/api/v2/invoices/${this.props.claimbatch.pk}/`;
        const data = {
            invoice: JSON.stringify(this.props.invoice),
            includeShipping: this.state.includeShipping,
            emailCustomer: this.state.emailCustomer,
            emailRecieptWhenPaid: this.state.emailRecieptWhenPaid,
            lineItems: JSON.stringify(this.state.lineItems),
            notes: this.state.notes,
        };
        $.post(url, data, (rsp, status, xhr) => {
            this.setState({saving: false});
            const successMessage = this.state.emailCustomer
                                 ? `Invoice saved and emailed to ${this.props.claimbatch.email}`
                                 : 'Invoice saved';
            toast.success(successMessage);
            if (this.props.markInvoiced)
                this.props.markInvoiced();
            this.props.callback(rsp);
        }).fail((rsp) => {
            this.setState({saving: false});
        });
    }

    renderCreate() {
        const { invoice, claimbatch, loading } = this.props;
        const { showAdvanced, saving, addName, addAmt, includeShipping,
                emailCustomer, emailRecieptWhenPaid, notes } = this.state;
        const advancedClass = showAdvanced
                            ? "glyphicon glyphicon-menu-down"
                            : "glyphicon glyphicon-menu-right";
        const addLiStyle = {marginLeft: '10px'};
        const btnText = saving
                      ? <span>
                        <LoadingSpinner />
                        {' '}
                        Saving Invoice
                        {emailCustomer ? ' and sending to customer' : ''}
                        ...
                      </span>
                      : `Save${emailCustomer ? ' and send ' : ' '}Invoice`;
        return (
            <div>
              <small className="text-muted"
                     style={{cursor: 'pointer'}}
                     onClick={() => {
                         this.setState(prevState => {
                             return {showAdvanced: !prevState.showAdvanced};
                         });
                     }}>
                <span className={advancedClass}></span>
                Advanced Options
              </small>
              <br />
              {showAdvanced &&
               <div className="well">
                 <form className="form-inline">
                   <div className="form-group">
                     <input type="text" className="form-control"
                            name="addName"
                            value={addName}
                            style={addLiStyle}
                            onChange={this.updateStateThing}
                            placeholder="Name" />
                   </div>
                   <div className="form-group">
                     <input type="number"
                            className="form-control"
                            name="addAmt"
                            value={addAmt}
                            style={addLiStyle}
                            onChange={this.updateStateThing}
                            placeholder="cost" />
                   </div>
                   <button className="btn btn-default"
                           disabled={!addName || !addAmt}
                           onClick={this.addItem}
                           style={addLiStyle}>
                     Add Custom Line Item
                   </button>
                 </form>
                 <br />
                 {invoice.shipping_quote && invoice.shipping_quote.cost &&
                  <div>
                    <label>
                      <input type="checkbox"
                             disabled={saving}
                             checked={includeShipping}
                             name="includeShipping"
                             onChange={this.updateStateThing} />
                      {' '}
                      <span>Include Shipping</span>
                    </label>
                    <br />
                  </div>
                 }
                 <label
                     style={!claimbatch.email
                          ? {cursor: 'not-allowed'} : {}}
                     title={!claimbatch.email
                          ? 'Customer email is not set' : ''}>
                   <input type="checkbox"
                          disabled={saving || !claimbatch.email}
                          checked={emailCustomer}
                          name="emailCustomer"
                          onChange={this.updateStateThing} />
                   {' '}
                   <span>Email customer</span>
                 </label>
                 <br />
                 <label>
                   <input type="checkbox"
                          disabled={saving}
                          name="emailRecieptWhenPaid"
                          checked={emailRecieptWhenPaid}
                          onChange={this.updateStateThing} />
                   {' '}
                   <span>Automatically email receipt when paid</span>
                 </label>
                 <br />
                 <label>
                   <span>Notes (also sent to customer)</span>
                   <textarea className="form-control"
                             name="notes"
                             rows="2"
                             onChange={this.updateStateThing}
                             value={notes} />
                 </label>
                 <br />
               </div>
              }
              <button className="btn btn-primary btn-large"
                      disabled={saving || loading}
                      style={{marginTop: '10px'}}
                      onClick={this.createInvoice}>
                {btnText}
              </button>
            </div>
        );
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    async payClicked(e) {
        this.setState({saving: true});
        let token = {};
        if (this.props.paymentInfo && this.props.paymentInfo.payment_token
            && !this.state.enterCard) {
            token = {id: this.props.paymentInfo.payment_token};
        } else {
            const cardElement = this.props.elements.getElement(CardElement);
            const tokenRsp = await this.props.stripe.createToken(cardElement);
            token = tokenRsp['token'];
            if (token === undefined) {
                return this.setState({
                    saving: false,
                    errorMessage: "Please enter valid payment information",
                });
            }
        }

        // Check if email is required and valid
        if (str_user_id === -1 && !isValidEmail(this.state.email)) {
            return this.setState({
                saving: false,
                errorMessage: "Please enter a valid email address",
            });
        }

        const data = {
            publicKey: this.props.invoice.public_key,
            stripeToken: token.id,
            email: str_user_id === -1 ? this.state.email : undefined,
        };
        const url = '/api/v2/pay_invoice/';
        $.post(url, data, () => {
            toast.success('Invoice paid!');
            this.setState({
                savingOrder: false,
                errorMessage: null,
            });
            this.props.callback();
        }).fail((xhr) => {
            this.setState({
                saving: false,
                errorMessage: xhr.responseJSON.message,
            });
        });
    }

    renderPay() {
        const { paymentInfo } = this.props;
        const showCardForm = !(paymentInfo && paymentInfo.last_four)
                          || this.state.enterCard;
        const btnText = this.state.saving
                      ? <span><LoadingSpinner /> Paying Invoice...</span>
                      : 'Pay Invoice';
        const isLoggedIn = str_user_id !== -1;
        return (
            <div>
              <div style={{height: '30px'}}></div>
              <h3>Payment</h3>
              <small>All transactions are secure and encrypted.</small>
              <div style={{height: '30px'}}></div>
              {!isLoggedIn &&
                <div style={{marginBottom: '15px'}}>
                  <label htmlFor="email">Email address:</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    placeholder="Enter your email address"
                    required
                  />
                </div>
              }
              {!showCardForm &&
               <div style={{marginBottom: '30px'}}>
                 <b>{paymentInfo.card_brand}</b> on file ending in <code>
                   {paymentInfo.last_four}
                 </code>
                 <button className="btn btn-default btn-sm btn-text btn-small"
                         style={{marginLeft: '15px'}}
                         onClick={() => {this.setState({
                             enterCard: true,
                         });}} >
                   Choose a different card
                 </button>
               </div>
              }
              {showCardForm && <CardElement />}
              {this.state.enterCard &&
               <p>
                 <button className="btn btn-default btn-sm btn-small btn-text"
                         style={{marginTop: '15px', marginBottom: '30px'}}
                         onClick={() => {this.setState({
                             enterCard: null,
                         });}} >
                   Cancel card change
                 </button>
               </p>
              }
              <p>
                <button
                    className="btn btn-primary btn-lg btn-large"
                    style={{marginTop: '10px', marginBottom: '30px'}}
                    disabled={this.state.saving || !this.props.stripe || (!isLoggedIn && !isValidEmail(this.state.email))}
                    onClick={this.payClicked}>
                  {btnText}
                </button>
              </p>
            </div>
        );
    }

    sendInvoice(e) {
        e.preventDefault();
        this.setState({sending: true});
        const url = `/api/v2/email_invoice/${this.props.invoice.public_key}/`;
        $.post(url, (rsp, status, xhr) => {
            this.setState({sending: false});
            toast.success(`Invoice emailed to ${this.props.claimbatch.email}`);
            this.props.callback();
        }).fail((rsp) => {
            this.setState({sending: false});
        });
    }

    sendReceipt(e) {
        e.preventDefault();
        this.setState({sending: true});
        const url = `/api/v2/email_receipt/${this.props.invoice.public_key}/`;
        $.post(url, (rsp, status, xhr) => {
            this.setState({sending: false});
            toast.success(`Receipt emailed to ${this.props.claimbatch.email}`);
            this.props.callback();
        }).fail((rsp) => {
            this.setState({sending: false});
        });
    }

    deactivateInvoice(e) {
        e.preventDefault();
        if (!confirm('Are you sure you want to cancel this invoice? All links to it will become unavailable and it will be unpayable.'))
            return;
        this.setState({canceling: true});
        const url = `/api/v2/deactivate_invoice/${this.props.invoice.public_key}/`;
        $.post(url, (rsp, status, xhr) => {
            this.setState({canceling: false});
            toast.success(`Invoice cancelled`);
            this.props.callback();
        }).fail((rsp) => {
            this.setState({canceling: false});
        });
    }

    renderConsultantSection() {
        const { consultant, invoice, allowCreate,
                claimbatch, addNewInvoice, callback } = this.props;
        const { sending, emailCustomer, canceling } = this.state;
        const invoiceLink = `/invoice/${invoice.public_key}/`;
        const disabled = sending || canceling || !consultant.email;
        if (!invoice.pk && !claimbatch.customer_notes && !claimbatch.notes)
            return null;
        return (
            <div className="panel panel-info">
              <div className="panel-heading">
                <h3 className="panel-title">
                  Seller-only Information and Actions
                  {' '}
                  <PrivateEye />
                </h3>
              </div>
              <div className="panel-body">
                {claimbatch.customer_notes &&
                 <p className="text-small">
                   <b>Customer notes:</b>
                   {' '}
                   {claimbatch.customer_notes}
                 </p>
                }
                {claimbatch.notes &&
                 <p className="text-small">
                   <b>Order notes:</b>
                   {' '}
                   {claimbatch.notes}
                 </p>
                }
                <FeeAndEarningsBreakdown invoice={invoice}
                                         includeTax={this.state.includeTax} />
                {invoice.pk && !invoice.deactivated &&
                 <p>
                   <a href={invoiceLink} target="_blank">
                     Link to invoice no. {invoice.number}
                   </a>
                   {' '}
                   <CopyToClipboard text={`${window.location.host}${invoiceLink}`}
                                    onCopy={this.onCopy}>
                     <button className="btn btn-xs btn-small btn-text" style={{position: 'relative', bottom: '3px'}}>
                       <span className="glyphicon glyphicon-copy" aria-hidden="true"></span>
                     </button>
                   </CopyToClipboard>
                   {' '}
                   {this.state.copied ? <span className="text-muted small">Copied</span> : null}
                 </p>
                }
                {invoice.pk && !invoice.is_paid && !invoice.deactivated &&
                 <p>
                   <button onClick={this.deactivateInvoice}
                           disabled={disabled}
                           className="btn btn-danger btn-small btn-ghost">
                     {canceling
                     && `Cancelling invoice...`
                     || 'Cancel Invoice'}
                   </button>
                 </p>
                }
                {invoice.pk && !invoice.is_paid && !invoice.deactivated &&
                 <p>
                   <button onClick={this.sendInvoice}
                           disabled={disabled}
                           title={disabled
                                ? 'Customer email is not set' : ''}
                           className="btn btn-default btn-small btn-ghost">
                     {sending
                     && `Emailing invoice...`
                     || 'Email Customer Invoice'}
                   </button>
                   {invoice.emailed_invoice &&
                    <small style={{marginLeft: '10px'}}>
                      An invoice was emailed to the customer and you were CC'd.
                    </small>
                   }
                 </p>
                }
                {invoice.is_paid &&
                 <p>
                   <button onClick={this.sendReceipt}
                           disabled={disabled}
                           title={disabled
                                ? 'Customer email is not set' : ''}
                           className="btn btn-default btn-small btn-ghost">
                     {sending
                     && `Emailing receipt...`
                     || 'Email Customer Receipt'}
                   </button>
                   {invoice.emailed_receipt &&
                    <small style={{marginLeft: '10px'}}>
                      A receipt was emailed to the customer and you were CC'd.
                    </small>
                   }
                 </p>
                }
                {allowCreate && <p>{addNewInvoice}</p>}
                {invoice.is_paid && <InvoiceRefund invoicePk={invoice.pk}
                                                   callback={callback} />}
              </div>
            </div>
        );
    }

    render() {
        const { consultant, invoice, claimbatch } = this.props;
        return (
            <div>
              {this.renderImage()}
              {invoice.pk && this.renderDateAndNumber()}
              {consultant && claimbatch && this.renderInfo()}
              {this.renderLineItems()}
              {invoice.notes &&
               <p><small>Notes:</small><br />{invoice.notes}</p>
              }
              {this.renderTotals()}
              {!invoice.pk && this.renderCreate()}
              {invoice.pk && this.props.allowPay && !invoice.is_paid
              && this.renderPay()}
              {this.state.errorMessage &&
               <div style={{marginTop: '15px'}}
                    className="alert alert-danger"
                    role="alert">
                 {this.state.errorMessage}
               </div>
              }
              {this.props.allowPay && invoice.footer_markdown &&
               <small>
                 <p>Seller Terms:</p>
                 <MarkdownView markdown={invoice.footer_markdown} />
               </small>
              }
              {this.isOwner() && !invoice.footer_markdown &&
               <p className="text-small text-muted">
                 You can add custom terms for your invoices
                 in your <a href="/settings/billing/#invoicing-settings">settings</a>.
               </p>
              }
              {this.isOwner() && this.renderConsultantSection()}
            </div>
        );
    }
}

Invoice.propTypes = {
    invoice: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    claimbatch: PropTypes.object,
    consultant: PropTypes.object,
    allowPay: PropTypes.bool,
    allowCreate: PropTypes.bool,
    stripe: PropTypes.object,
    elements: PropTypes.object,
    paymentInfo: PropTypes.object,
    markInvoiced: PropTypes.func,
    loading: PropTypes.bool,
    addNewInvoice: PropTypes.node,
};

export default function InjectedInvoice(props) {
    return (
        <ElementsConsumer>
          {({stripe, elements}) => (
              <Invoice stripe={stripe}
                       elements={elements}
                       {...props} />
          )}
        </ElementsConsumer>
    );
}
