import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import PropTypes from "prop-types";
import StrDropzone from './StrDropzone.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import StrCopyToClipboard from './StrCopyToClipboard.jsx';

let id = 1;

function getId() {
    return id++;
}

export default function DigitalDownloadsEditor(props) {
    const [loading, setLoading] = useState(false);
    const [showUploadWidget, setShowUploadWidget] = useState(false);
    /* uploads schema:
     * [{
     *     uploading: bool,
     *     file: File, // only on freshly uploaded files
     *     fileName: str,
     *     id: int,
     *     error: str,
     *     success: bool, // just finished uploading
     *     result: DigitalDownloadSerializer,
     * },] */
    const [uploads, setUploads] = useState([]);
    const [rejections, setRejections] = useState([]);

    useEffect(refresh, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (rejections.length < 1) {
            return;
        }
        toast.error(
            <div>
              Couldn't upload:
              <ul>
                {rejections.map(r => {
                    return (
                        <li key={r.file.name}>
                          {r.file.name}: {r.errors.map(e => e.message).join(", ")}
                        </li>
                    );
                })}
              </ul>
            </div>
        );
    }, [rejections]);

    function refresh() {
        if (props.invItemPk) {
            setLoading(true);
            const endpoint = props.viewOnly ? 'public_items' : 'listed_items';
            $.getJSON(
                `/api/v2/${endpoint}/${props.invItemPk}/digital_downloads/`,
                (data) => {
                    setUploads(data.map(digitalDownload => {
                        return {
                            fileName: digitalDownload.filename,
                            result: digitalDownload,
                            id: digitalDownload.pk,
                            uploading: false,
                        };
                    }));
                    setLoading(false);
                    // show upload widget if no files yet
                    setShowUploadWidget(data.length === 0);
                },
            );
        } else {
            // show upload widget for new invitem
            setShowUploadWidget(true);
        }
    }

    function onRemoveClick(digitalDownload) {
        $.ajax({
            type: 'DELETE',
            url: `/api/v2/digital_downloads/${digitalDownload.pk}/`,
            success: () => {
                toast.success(`Removed ${digitalDownload.filename}`);
                refresh();
            },
            error: (content) => {
                toast.error(content.responseJSON);
            },
        });
    }

    function replaceUpload(updatedUpload) {
        setUploads(prevUploads => {
            let newUploads = prevUploads.slice();
            newUploads.splice(
                prevUploads.findIndex(u => u.id == updatedUpload.id),
                1,
                updatedUpload,
            );
            return newUploads;
        });
    }

    function onDrop(acceptedFiles, fileRejections) {
        // adapted from ImageUploadOrSelect onDrop
        const newUploads = acceptedFiles.map(f => ({
            file: f,
            fileName: f.name,
            id: getId(),
            uploading: true,
        }));
        setUploads(uploads.concat(newUploads));
        setRejections(fileRejections);
        newUploads.forEach(upload => {
            const file = upload.file;
            let formData = new FormData();
            formData.append("the_file", file);
            if (props.invItemPk) {
                formData.append("item_id", props.invItemPk);
            }
            $.ajax({
                url: '/api/v2/digital_downloads/',
                method: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                complete: function(xhr, status) {
                    upload.uploading = false;
                    if (status === "success") {
                        const result = xhr.responseJSON;
                        upload.success = true;
                        upload.result = result;
                        upload.fileName = result.filename;
                        upload.error = null;
                        if (props.onAdd)
                            props.onAdd(result);
                    } else {
                        upload.error = xhr.responseText;
                    }
                    replaceUpload(upload);
                },
            });
        });
    }

    const uploadArea = <div>
      {showUploadWidget &&
       <StrDropzone onDrop={onDrop}
                    accept={[
                        '.bmp',
                        '.doc',
                        '.gif',
                        '.jpeg',
                        '.jpg',
                        '.mobi',
                        '.mov',
                        '.mp3',
                        '.mpeg',
                        '.pdf',
                        '.png',
                        '.psp',
                        '.rtf',
                        '.stl',
                        '.txt',
                        '.zip',
                        '.ePUB',
                        '.iBook',
                    ].join(',')}
                    maxSize={20000000}
                    multiple={true} />
      ||
       <button className="btn btn-default btn-sm"
               onClick={() => setShowUploadWidget(true)}>
         Add {uploads.length > 0 && 'More '}Digital Files
       </button>
      }
    </div>;

    return <div className="row">
      <div className="col-md-12" style={{padding: 0}}>
        {loading && <p><LoadingSpinner /></p>}
        {!props.viewOnly && uploadArea}
        <aside style={{
            maxHeight: "200px",
            overflowY: "auto",
            marginBottom: "30px",
        }}>
          <ul className="list-unstyled">
            {uploads.map(upload => {
                return <li key={upload.id}>
                  {upload.uploading &&
                   <div className="progress">
                     <div className="progress-bar progress-bar-striped active"
                          style={{width: "100%"}}>
                     </div>
                   </div>
                  }
                  {upload.success &&
                   <i className="fa fa-check-circle text-success"
                      style={{marginRight: '5px'}} />
                  }
                  {!props.viewOnly && upload.result &&
                   <span>
                     <StrCopyToClipboard text={upload.result.download_url} />
                     {' '}
                     <a href={upload.result.download_url}>
                       {upload.fileName}
                     </a>
                   </span>
                  ||
                   <span>{upload.fileName}</span>
                  }
                  {upload.error &&
                   <small className="text-danger"
                          style={{marginLeft: '5px'}}>
                     Upload error: {upload.error}
                   </small>
                  }
                  {!props.viewOnly && upload.result &&
                   <i className="fa fa-trash text-danger"
                      onClick={() => onRemoveClick(upload.result)}
                      style={{marginLeft: '5px', cursor: 'pointer'}} />
                  }
                </li>;
            }).reverse()}
          </ul>
        </aside>
      </div>
    </div>;
}

DigitalDownloadsEditor.propTypes = {
    onAdd: PropTypes.func,
    invItemPk: PropTypes.number,
    viewOnly: PropTypes.bool,
};

export function DigitalDownloadsEditorApp(el, onAdd, itemPk) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<DigitalDownloadsEditor onAdd={onAdd}
                                        itemPk={itemPk} />);
}
