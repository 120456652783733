import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import RewardCard from './RewardCard.jsx';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { successOrRedirect, updateStateThing } from '../util.jsx';
import { ModalContainer } from '../StrModal.jsx';

const RewardCardWithConsultantControls = (props) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const {rewardCard} = props;
    return (
        <div className="panel panel-bluegray" key={rewardCard.pk}>
          <div className="panel-body">
            <ModalContainer isOpen={showDeleteConfirm}
                            onClose={() => setShowDeleteConfirm(false)}>
              <p>Are you sure you want to delete this rewards card?</p>
              <button className="btn btn-danger"
                      onClick={() => props.handleDeleteCard(rewardCard)}>Yes, delete!</button>
            </ModalContainer>
            <div className="row">
              <div className="col-md-6">
                <h4>{rewardCard.customer_name}</h4>
                <p>{rewardCard.customer_email}</p>
                <span className="checkbox" style={{marginRight:'10px'}}>
                  <label
                      title="Shoppers will not see hidden reward cards in their dashboard">
                    <input type="checkbox"
                           checked={rewardCard.hidden}
                           onChange={(e) => props.handleHiddenChk(rewardCard)} />
                    Hidden from shopper
                  </label>
                </span>
                <a className="text-danger"
                   style={{cursor: 'pointer'}}
                   onClick={() => setShowDeleteConfirm(true)}>
                  Delete Card
                </a>
              </div>
              <div className="col-md-6">
                <RewardCard
                    programData={props.programData}
                    rewardCard={rewardCard}
                    owner={true}
                    updateFunc={props.updateRewardCardEarned}
                    awardFunc={props.alterAwards}
                />
              </div>
            </div>
          </div>
        </div>
    );
};

RewardCardWithConsultantControls.propTypes = {
    rewardCard: PropTypes.object.isRequired,
    programData: PropTypes.object.isRequired,
    handleHiddenChk: PropTypes.func.isRequired,
    handleDeleteCard: PropTypes.func.isRequired,
    updateRewardCardEarned: PropTypes.func.isRequired,
    alterAwards: PropTypes.func.isRequired,
};

class RewardsConsultants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            rewardCards: [],
            end_date: this.props.data.end_date,
            deleted: false,
            copied: false,
            searchFilter: '',
            deleteConfirmIsOpen: false,
        };
        this.fetchRewardCards = this.fetchRewardCards.bind(this);
        this.clickExpander = this.clickExpander.bind(this);
        this.updateRewardCardEarned = this.updateRewardCardEarned.bind(this);
        this.alterAwards = this.alterAwards.bind(this);
        this.handleHiddenChk = this.handleHiddenChk.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.handleEndProgram = this.handleEndProgram.bind(this);
        this.handleDeleteProgram = this.handleDeleteProgram.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.onDeleteConfirmClose = this.onDeleteConfirmClose.bind(this);
    }

    render() {
        if (this.state.deleted)
            return <p className="alert alert-info">Deleted</p>;
        let filteredCards = this.state.rewardCards;
        if (this.state.searchFilter) {
            filteredCards = filteredCards.filter(card => {
                return [
                    card.customer_name.toLowerCase(),
                    card.customer_email.toLowerCase(),
                ].some(el => el.includes(
                    this.state.searchFilter.toLowerCase())
                );
            });
        }
        const rewardCards = filteredCards.map(
            rewardCard => <RewardCardWithConsultantControls
                              key={rewardCard.pk}
                              programData={this.props.data}
                              rewardCard={rewardCard}
                              handleHiddenChk={this.handleHiddenChk}
                              handleDeleteCard={this.handleDeleteCard}
                              updateRewardCardEarned={this.updateRewardCardEarned}
                              alterAwards={this.alterAwards} />
        );

        const expandText = this.state.expanded ? "Hide participant cards" : "Show participant cards";
        const endedText = () => {
            if (moment(this.state.end_date).isSameOrBefore(moment())) {
                return "ended on";
            } else {
                return "will end on";
            }
        };

        return (
            <div className="row">
              <div className="col-md-12">
                <ModalContainer isOpen={this.state.deleteConfirmIsOpen}
                                onClose={this.onDeleteConfirmClose}>
                  <p>Are you sure you want to delete this rewards program?</p>
                  <button className="btn btn-danger"
                          onClick={this.handleDeleteProgram}>Yes, delete!</button>
                </ModalContainer>
                <div className="panel panel-default">
                  <div className="panel-body">
                    <h2>{this.props.data.name}</h2>
                    <h4>
                      {this.props.data.description_if}
                      {' '}
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      {' '}
                      {this.props.data.description_then}
                    </h4>
                    <p>
                      Magic share link:
                      {' '}
                      <a className="small" href={`/rewards/share/${this.props.data.public_id}/`}>
                        {window.location.origin}/rewards/share/{this.props.data.public_id}/
                      </a>
                      {' '}
                      <CopyToClipboard text={`${window.location.origin}/rewards/share/${this.props.data.public_id}/`}
                                       onCopy={this.onCopy}>
                        <button className="btn btn-xs" style={{position: 'relative', bottom: '3px'}}>
                          <span className="glyphicon glyphicon-copy" aria-hidden="true"></span>
                        </button>
                      </CopyToClipboard>
                      {' '}
                      {this.state.copied ? <span className="text-muted small">Copied</span> : null}
                    </p>
                    <div>
                      <div>Started on {moment(this.props.data.start_date).format("LL")}</div>
                      <div className="btn-group btn-group-sm" role="group">
                        <a className="btn btn-default" href={`/rewards/edit/${this.props.data.pk}/`}>Edit</a>
                        <button className="btn btn-default"
                                onClick={this.handleEndProgram}
                                disabled={this.state.end_date}>
                          {this.state.end_date &&
                           <span>{`${endedText()} ${moment(this.state.end_date).format("LL")}`}</span>
                          }
                          {this.state.end_date === null &&
                           <span>End Reward Program</span>
                          }
                        </button>
                        {moment(this.props.data.end_date).isSameOrBefore(moment()) &&
                         <button className="btn btn-default"
                                 onClick={this.showDeleteConfirm}>Delete Program</button>
                        }
                      </div>
                    </div>
                    <div style={{marginTop: "10px"}}>
                      <a href="#"
                         onClick={this.clickExpander}>{expandText}</a>
                    </div>
                    {this.state.expanded &&
                     <div>
                       <div className="row">
                         <div className="col-md-5"
                              style={{marginTop: '10px', marginBottom: '10px'}}>
                           <input className="form-control"
                                  type="text"
                                  placeholder="Search participant cards"
                                  onChange={this.updateStateThing}
                                  name="searchFilter"
                                  value={this.state.searchFilter} />
                         </div>
                       </div>
                       {rewardCards}
                       {rewardCards.length === 0 &&
                        <p className="text-muted">
                          No rewards cards found
                          {this.state.searchFilter !== '' &&
                           <span><br />Try searching for something else</span>
                          }
                        </p>
                       }
                     </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        );
    }

    clickExpander(e) {
        e.preventDefault();
        if (!this.state.expanded)
            this.fetchRewardCards();
        this.setState((prevState, props) => ({
            expanded: !prevState.expanded,
        }));
    }

    fetchRewardCards() {
        const self = this;
        const url = `/api/v2/reward_programs/${this.props.data.pk}/cards/`;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            self.setState({rewardCards: json});
        });
    }

    updateRewardCardEarned(rewardCard, earned) {
        const self = this;
        /* updating the earned may trigger any number of rewards and the
         * earned value to change so we need to get all that data*/
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        // doing this parsing in case earned is null
        const parsedEarned = rewardCard.earned ? parseFloat(rewardCard.earned) : 0.0;
        const newEarned = parseFloat(earned) + parsedEarned;
        const data = {pk: rewardCard.pk, earned: newEarned};
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

    alterAwards(rewardCard, redeemInc, remove=null) {
        const self = this;
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        let newEarnedRewards = rewardCard.earned_rewards -= redeemInc;
        let newRedeemedRewards = rewardCard.redeemed_rewards += redeemInc;
        if (remove) {
            newEarnedRewards = rewardCard.earned_rewards;
            newRedeemedRewards = rewardCard.redeemed_rewards -= 1;
        }
        const data = {
            pk: rewardCard.pk,
            earned_rewards: newEarnedRewards,
            redeemed_rewards: newRedeemedRewards,
        };
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

    handleHiddenChk(rewardCard) {
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        const data = {
            pk: rewardCard.pk,
            hidden: !rewardCard.hidden,
        };
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response))
                return response.json();
            return [];
        }).then(json => {
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        [tempIdx]: {$set: json},
                    }),
                };
            });
        });
    }

    handleDeleteCard(rewardCard) {
        const url = `/api/v2/reward_cards/${rewardCard.pk}/`;
        return fetch(url, {
            credentials: 'same-origin',
            method: 'DELETE',
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (!successOrRedirect(response))
                return;
            this.setState(prevState => {
                const tempIdx = prevState.rewardCards.findIndex(
                    el => el.pk == rewardCard.pk
                );
                return {
                    rewardCards: update(prevState.rewardCards, {
                        $unset: [tempIdx],
                    }),
                };
            });
        });
    }

    handleEndProgram(e) {
        e.preventDefault();
        const url = `/api/v2/reward_programs/${this.props.data.pk}/`;
        const data = {
            end_date: moment().format(),
        };
        return fetch(url, {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (successOrRedirect(response)) {
                window.location.reload();
            }
            return [];
        }).then(json => {
            this.setState({end_date: json.end_date});
        });
    }

    handleDeleteProgram(e) {
        e.preventDefault();
        const self = this;
        const url = `/api/v2/reward_programs/${this.props.data.pk}/`;
        return fetch(url, {
            credentials: 'same-origin',
            method: 'DELETE',
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (!successOrRedirect(response))
                return;
            if (response.ok)
                this.setState({deleted: true});
        });
    }

    onCopy() {
        this.setState({copied: true});
        setTimeout(() => this.setState({copied: false}), 1500);
    }

    showDeleteConfirm() {
        this.setState({deleteConfirmIsOpen: true});
    }

    onDeleteConfirmClose() {
        this.setState({deleteConfirmIsOpen: false});
    }
}

RewardsConsultants.propTypes = {
    data: PropTypes.object.isRequired,
};

function RewardsConsultantsApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<RewardsConsultants data={data} />);
}

export default RewardsConsultantsApp;
