import React from 'react';
import { createRoot } from 'react-dom/client';
import ShippingCostSetup from './shipping_cost/ShippingCostSetup.jsx';

export default function ShippingCostSetupApp(el, disabled) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<ShippingCostSetup disabled={disabled} />);
}
