import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { moveInArray } from './util.jsx';

const OptionNameAndValues = props => {
    const [editing, setEditing] = useState(
        !props.nameAndValues.name
    );

    const name = props.nameAndValues.name;
    const values = props.nameAndValues.values;

    function updateName(e) {
        props.update(e.target.value, values);
    }

    function updateValue(e, id) {
        const currValue = values.find(v => v.id === id);
        const updatedValue = {
            ...currValue,
            value: e.target.value,
        };
        let updatedValues = values.slice();
        updatedValues.splice(
            values.findIndex(v => v.id == id),
            1,
            updatedValue,
        );
        props.update(name, updatedValues);
    }

    function moveValue(id, direction) {
        const oldIndex = values.findIndex(v => v.id == id);
        const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;
        const newValues = moveInArray(values.slice(), oldIndex, newIndex);
        props.update(name, newValues);
    }

    function removeValue(id) {
        props.update(name, values.filter(v => v.id !== id));
    }

    return <div>
      {editing &&
       <div>
         <p>Option name</p>
         <input type="text"
                className="form-control option-name"
                placeholder="eg Size"
                style={{
                    width: 'calc(100% - 20px)',
                    display: 'inline-block',
                    marginRight: '5px',
                }}
                value={name}
                onChange={updateName} />
         <i className="fa fa-trash name-remove"
            style={{cursor: 'pointer'}}
            onClick={props.remove}
            aria-hidden="true"></i>
         <div>
           <p style={{marginTop: '10px'}}>Option values</p>
           {values.map((value, idx) => {
               return <div key={value.id} style={{marginTop: '5px'}}>
                 {!!value.value &&
                  <span>
                    <button className="btn btn-link value-move-up"
                            disabled={idx === 0}
                            style={{padding: '5px 5px'}}
                            onClick={() => moveValue(value.id, 'up')}
                            tabIndex="-1">
                      <span className={`glyphicon glyphicon-chevron-up`}></span>
                    </button>
                    <button className="btn btn-link value-move-down"
                            disabled={!values[idx+1] || !values[idx+1].value}
                            style={{padding: '5px 5px'}}
                            onClick={() => moveValue(value.id, 'down')}
                            tabIndex="-1">
                      <span className={`glyphicon glyphicon-chevron-down`} />
                    </button>
                  </span>
                 ||
                  <span style={{display: 'inline-block', width: '52px'}}></span>
                 }
                 <input type="text"
                        className="form-control option-value"
                        style={{
                            width: 'calc(100% - 72px)',
                            display: 'inline-block',
                            marginRight: '5px',
                        }}
                        value={value.value}
                        placeholder="eg Medium"
                        onChange={(e) => updateValue(e, value.id)} />
                 {value.value &&
                  <i className="fa fa-trash value-remove"
                     style={{cursor: 'pointer'}}
                     onClick={() => removeValue(value.id)}
                     aria-hidden="true"></i>
                 }
               </div>;
           })}
         </div>
         <button className="btn btn-default"
                 style={{marginTop: '10px'}}
                 onClick={() => setEditing(false)}>
           Done
         </button>
       </div>
      ||
       <div>
         <p><b>{name}</b></p>
         <p>
           {values.map(value =>
               <span key={value.id}
                     style={{marginRight: '5px'}}
                     className="badge">
                 {value.value}
               </span>
           )}
         </p>
         <button className="btn btn-default edit-name-and-values"
                 onClick={() => setEditing(true)}>
           Edit
         </button>
       </div>
      }
    </div>;
};

OptionNameAndValues.propTypes = {
    nameAndValues: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
    // update(name, values);
    update: PropTypes.func.isRequired,
};

export default OptionNameAndValues;
