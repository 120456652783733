import React from 'react';
import { createRoot } from 'react-dom/client';

import { fetchJSON, updateStateThing } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { ModalContainer } from './StrModal.jsx';

class AttributeSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialLoading: true,
            saving: false,
            attrs: [],
            invAttrCounts: {},
            hiddenAttrs: [],
            showingModalName: '',
            selectedAttr: null,
            renameTo: '',
            editingValuesOfAttrName: '',
        };
        this.url = '/api/v2/attribute_settings/';
        this.fetchData = this.fetchData.bind(this);
        this.changedHidden = this.changedHidden.bind(this);
        this.actionClick = this.actionClick.bind(this);
        this.confirmAction = this.confirmAction.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.valuesTable = null;
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const data = await fetchJSON(this.url);
        this.setState({
            attrs: data.attrs,
            invAttrCounts: data.inv_attr_counts,
            hiddenAttrs: data.hidden_attrs,
            initialLoading: false,
        });
    }

    changedHidden(e, attrPk) {
        this.setState({saving: true});
        const data = {
            action: 'change-hidden',
            hidden: e.target.checked,
            attrPk: attrPk,
        };
        $.post(this.url, data, (rsp) => {
            this.setState({
                attrs: rsp.attrs,
                hiddenAttrs: rsp.hidden_attrs,
                saving: false,
            });
        }).fail((xhr) => {
            this.setState({saving: false});
        });
    }

    actionClick(e, attr, modalName) {
        e.preventDefault();
        if (this.state.saving)
            return;
        this.setState({
            showingModalName: modalName,
            selectedAttr: attr,
            renameTo: '',
        });
    }

    confirmAction(attr, action) {
        this.setState({saving: true});
        const data = {
            action: action,
            attrPk: attr.pk,
            renameTo: this.state.renameTo,
        };
        $.post(this.url, data, (rsp) => {
            this.setState({
                attrs: rsp.attrs,
                invAttrCounts: rsp.inv_attr_counts,
                hiddenAttrs: rsp.hidden_attrs,
                saving: false,
                showingModalName: '',
            });
        }).fail((xhr) => {
            this.setState({saving: false});
        });
    }

    renderValuesTable() {
        const { editingValuesOfAttrName, attrs, invAttrCounts } = this.state;
        if (attrs.findIndex(el => el.name === editingValuesOfAttrName) === -1)
            return null;
        const valuesRows = attrs.filter(
            attr => attr.name === editingValuesOfAttrName).map(attr => {
                const attrCount = editingValuesOfAttrName in invAttrCounts
                                ? invAttrCounts[editingValuesOfAttrName]
                                    .find(el => el.value === attr.value)
                                : 0;
                return (
                    <tr key={attr.pk}>
                      <td>{attr.value}</td>
                      <td>{attrCount ? attrCount.count : 0}</td>
                      <td>
                        <a href=""
                           onClick={(e) => this.actionClick(e,
                                                            attr,
                                                            'renameValue')}>
                          Rename
                        </a>
                      </td>
                      <td>
                        <a href=""
                           onClick={(e) => this.actionClick(e,
                                                            attr,
                                                            'deleteValue')}>
                          Delete
                        </a>
                      </td>
                    </tr>
                );
            });
        return (
            <div ref={(el) => this.valuesTable = el}>
              <h5>Values for <b>{editingValuesOfAttrName}</b></h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Items</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {valuesRows}
                </tbody>
              </table>
            </div>
        );
    }

    renderModal() {
        const { attrs, selectedAttr, invAttrCounts, saving,
                showingModalName, renameTo } = this.state;
        if (!selectedAttr)
            return;
        const itemCount = selectedAttr.name in invAttrCounts
                        ? invAttrCounts[selectedAttr.name].reduce(
                            (acc, attrCount) => {
                                return acc + attrCount.count;
                            }, 0)
                        : 0;
        const attrCount = selectedAttr.name in invAttrCounts
                        ? invAttrCounts[selectedAttr.name]
                            .find(el => el.value === selectedAttr.value)
                        : 0;
        const valueCount = attrCount ? attrCount.count : 0;
        let modalBody = null;
        if (showingModalName === 'rename') {
            const btnText = saving
                          ? <span>
                            <LoadingSpinner /> Renaming attributes...
                          </span>
                          : 'Confirm Rename';
            modalBody = (
                <div>
                  <h3>Rename {selectedAttr.name}</h3>
                  <p>
                    <input type="text"
                           autoFocus
                           className="form-control"
                           name="renameTo"
                           disabled={saving}
                           onChange={this.updateStateThing} />
                  </p>
                  {renameTo !== "" &&
                   <p>
                     This will rename the attribute
                     {' '}
                     <code>{selectedAttr.name}</code> to
                     {' '}
                     <code>{renameTo}</code> on {itemCount}
                     {' '}
                     item{itemCount === 1 ? '' : 's'}.
                   </p>
                  }
                  <button className="btn btn-danger"
                          disabled={saving || renameTo === "" }
                          onClick={() => this.confirmAction(selectedAttr,
                                                            'rename')}>
                    {btnText}
                  </button>
                </div>
            );
        } else if (showingModalName === 'delete') {
            const btnText = saving
                          ? <span>
                            <LoadingSpinner /> Deleting attributes...
                          </span>
                          : 'Confirm Delete';
            modalBody = (
                <div>
                  <h3>Delete {selectedAttr.name}</h3>
                  <p>
                    This will remove the
                    {' '}
                    <code>{selectedAttr.name}</code> attribute from
                    {' '}
                    {itemCount} item{itemCount === 1 ? '' : 's'}.
                  </p>
                  <button className="btn btn-danger"
                          disabled={saving}
                          onClick={() => this.confirmAction(selectedAttr,
                                                            'delete')}>
                    {btnText}
                  </button>
                </div>
            );
        } else if (showingModalName === 'renameValue') {
            const btnText = saving
                          ? <span>
                            <LoadingSpinner /> Renaming attributes...
                          </span>
                          : 'Confirm Rename';
            modalBody = (
                <div>
                  <h3>Rename {selectedAttr.name} - {selectedAttr.value}</h3>
                  <p>
                    <input type="text"
                           autoFocus
                           className="form-control"
                           name="renameTo"
                           disabled={saving}
                           onChange={this.updateStateThing} />
                  </p>
                  {renameTo !== "" &&
                   <p>
                     This will rename
                     {' '}
                     <code>{selectedAttr.name} - {selectedAttr.value}</code> to
                     {' '}
                     <code>{selectedAttr.name} - {renameTo}</code>
                     {' '}
                     on {valueCount} item{valueCount === 1 ? '' : 's'}.
                   </p>
                  }
                  <button className="btn btn-danger"
                          disabled={saving || renameTo === "" }
                          onClick={() => this.confirmAction(selectedAttr,
                                                            'renameValue')}>
                    {btnText}
                  </button>
                </div>
            );
        } else if (showingModalName === 'deleteValue') {
            const btnText = saving
                          ? <span>
                            <LoadingSpinner /> Deleting attributes...
                          </span>
                          : 'Confirm Delete';
            modalBody = (
                <div>
                  <h3>Delete {selectedAttr.name} - {selectedAttr.value}</h3>
                  <p>
                    This will remove the
                    {' '}
                    <code>{selectedAttr.name} - {selectedAttr.value}</code>
                    {' '}
                    attribute from
                    {' '}
                    {valueCount} item{valueCount === 1 ? '' : 's'}.
                  </p>
                  <button className="btn btn-danger"
                          disabled={saving}
                          onClick={() => this.confirmAction(selectedAttr,
                                                            'deleteValue')}>
                    {btnText}
                  </button>
                </div>
            );
        }
        return (
            <div>
              <ModalContainer onClose={() => this.setState({showingModalName: ''})}
                              isOpen={showingModalName !== ''}
                              style={{maxWidth: '500px'}}>
                {modalBody}
              </ModalContainer>
            </div>
        );
    }

    render() {
        const { initialLoading, attrs, invAttrCounts,
                hiddenAttrs, showingModalName, saving,
                editingValuesOfAttrName } = this.state;
        if (initialLoading)
            return <LoadingSpinner />;
        let attrsDistinct = {};
        attrs.forEach(attr => {
            if (!(attr.name in attrsDistinct)) {
                attrsDistinct[attr.name] = attr;
                attrsDistinct[attr.name]['valueCount'] = 1;
                if (attr.name in invAttrCounts) {
                    attrsDistinct[attr.name]['totalCount'] = invAttrCounts[attr.name]
                        .reduce((acc, attrCount) => {
                            return acc + attrCount.count;
                        }, 0);
                } else {
                    attrsDistinct[attr.name]['totalCount'] = 0;
                }
            } else {
                attrsDistinct[attr.name]['valueCount']++;
            }
        });
        const attrsRows = Object.values(attrsDistinct).map(attr => {
            return (
                <tr key={attr.pk}>
                  <td>{attr.name}</td>
                  <td>{attr.totalCount}</td>
                  <td style={{textAlign: 'center'}}>
                    <input type="checkbox"
                           disabled={saving}
                           onChange={(e) => this.changedHidden(e, attr.pk)}
                           checked={hiddenAttrs.includes(attr.name)
                                  ? 'checked' : ''} />
                  </td>
                  <td>
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            editingValuesOfAttrName: attr.name,
                        }, () => this.valuesTable.scrollIntoView({
                            behavior: "smooth",
                        }));
                    }}>
                      Edit Values ({attr.valueCount})
                    </a>
                  </td>
                  <td>
                    <a href=""
                       onClick={(e) => this.actionClick(e, attr, 'rename')}>
                      Rename
                    </a>
                  </td>
                  <td>
                    <a href=""
                       onClick={(e) => this.actionClick(e, attr, 'delete')}>
                      Delete
                    </a>
                  </td>
                </tr>
            );
        });
        return (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Items</th>
                    <th title="Hidden attributes can only be seen by you (the seller)">
                      Hidden from shoppers
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {attrsRows}
                </tbody>
              </table>
              {editingValuesOfAttrName !== '' && this.renderValuesTable()}
              {showingModalName !== '' && this.renderModal()}
            </div>
        );
    }
}

export default function AttributeSettingsApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AttributeSettings />);
}
