import React from "react";
import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types";
import moment from 'moment';

import { formatCurrency } from './util.jsx';

/* these templates exist because jsx > handlebars */

function OrderInfo(props) {
    const batch = props;
    let addressText = `${batch.address1}\n`;
    if (batch.address2) {
        addressText += `${batch.address2}\n`;
    }
    addressText += `${batch.city}, ${batch.state}\n${batch.zip}`;
    let invoiceButtonClass = "";
    if (batch.is_paid) {
        invoiceButtonClass = "btn-success";
    } else if (batch.some_payments_paid) {
        invoiceButtonClass = "btn-success btn-outline";
    } else if (batch.payments_invoiced) {
        invoiceButtonClass = "btn-default";
    } else {
        invoiceButtonClass = "btn-primary";
    }
    let invoiceButtonTitle = "Open invoice window. Blue -> not invoiced yet, White -> invoiced but unpaid, Green -> invoice has been paid. Green outline -> invoiced and paid but order is not fully paid.";
    if (!batch.can_be_invoiced) {
        invoiceButtonTitle = "Address information is needed in order to invoice with tax rate calculation.";
    }
    return <td className="orderInfoTd">
      {batch.customer_name}
      {' '}
      <button type="button"
              className="btn btn-xs glyphicon glyphicon-copy clippy"
              data-clipboard-text={batch.customer_name} />
      <div className="send-direct-message text-small"
           data-username={batch.customer_username} />
      <div>
        <a href={`mailto:${batch.email}`} target="_top">{batch.email}</a>
        {' '}
        <button type="button"
                className="btn btn-xs glyphicon glyphicon-copy clippy"
                data-clipboard-text={batch.email}>
        </button>
        <br />
        {batch.phone &&
         <div className="thePhone"
              data-phone-number={batch.phone}>
           {batch.phone}
           {' '}
           <button type="button"
                   className="btn btn-xs glyphicon glyphicon-copy clippy"
                   data-clipboard-text={batch.phone} />
         </div>
        }
        {batch.address1}
        <br />
        {batch.address2 &&
         <span>
           {batch.address2}<br />
         </span>
        }
        {batch.city}, {batch.state}
        <br />
        {batch.zip}
        <br />
        {' '}
        <button type="button"
                className="btn btn-xs glyphicon glyphicon-copy clippy"
                data-clipboard-text={addressText} />
      </div>

      {batch.customer_pk &&
       <div className="customer-notes-container">
         {batch.customer_notes &&
          <div className="alert alert-info customer-notes">
            <strong>Customer notes:</strong>
            <span className="the-customer-notes">
              {batch.customer_notes}
            </span>
            {' '}-{' '}
            <a href="#"
               className="edit-customer-notes"
               data-customer-pk={batch.customer_pk}>Edit</a>
          </div>
         ||
          <a href="#"
             className="edit-customer-notes"
             data-customer-pk={batch.customer_pk}>
            Add customer notes
          </a>
         }
       </div>
      ||
       <p className="text-muted small">
         Anonymous claim, customer notes unavailable
       </p>
      }

      <hr />
      {batch.rewards_available &&
       <button
           type="button"
           className="btn btn-primary noselect"
           style={{marginTop: '5px', marginRight: '5px'}}
           data-toggle="modal"
           data-target="#rewardsModal"
           data-orderno={batch.pk}
           data-customername={batch.customer_name}
           data-customerpk={batch.customer_pk}
           data-itemcount={batch.claimrecord_set.count}
           data-orderprice={batch.total_price}>
         Rewards
       </button>
      }
      <div className="btn-group"
           role="group"
           id={`shippingLabels-${batch.pk}`}
           style={{marginTop: '5px', marginRight: '5px'}}>
        <button
            type="button"
            className="btn btn-primary noselect"
            id={`shippingBtn-${batch.pk}`}
            data-toggle="modal"
            data-target="#shippingLabelsModal"
            data-customername={batch.customer_name}
            data-customeremail={batch.email}
            data-street1={batch.address1 || ""}
            data-street2={batch.address2 || ""}
            data-city={batch.city || ""}
            data-state={batch.state || ""}
            data-zip={batch.zip || ""}
            data-phone={batch.phone || ""}
            data-items={batch.shipping_item_json}
            data-orderno={batch.pk}
            data-grouptype="batch"
            data-shippingpdf={batch.shipping_label && batch.shipping_label.label_pdf_url || ''}
            data-shippingtracker={batch.shipping_label && batch.shipping_label.tracker_url || ''}
            data-labelpk={batch.shipping_label && batch.shipping_label.id || ''}
            data-returnlabelpk={batch.shipping_label &&
                                batch.shipping_label.return_shipping_label &&
                                batch.shipping_label.return_shipping_label.id || '' }>
          Shipping
        </button>
        {batch.shipping_label.id &&
         <React.Fragment>
           <button type="button"
                   className="btn btn-primary dropdown-toggle"
                   data-toggle="dropdown"
                   aria-haspopup="true"
                   aria-expanded="false">
             <span className="caret"></span>
             <span className="sr-only">Toggle Dropdown</span>
           </button>
           <ul className="dropdown-menu">
             <li>
               <a href={batch.shipping_label.label_pdf_url}
                  target="_blank">
                 <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                 {' '}
                 Label PDF
               </a>
             </li>
             {batch.shipping_label.tracker_url &&
              <React.Fragment>
                <li>
                  <a href={batch.shipping_label.tracker_url}
                     target="_blank" >
                    <i className="fa fa-truck" aria-hidden="true"></i>
                    {' '}
                    Label Tracking
                  </a>
                </li>
                <li>
                  <a href=""
                     className="sendTrackingEmailSingle">
                    <i className="fa fa-share" aria-hidden="true"></i>
                    {' '}
                    Send Tracking Email
                  </a>
                </li>
              </React.Fragment>
             ||
              <li className="disabled">
                <a href="#">
                  <i className="fa fa-truck text-muted" aria-hidden="true"></i>
                  {' '}
                  Tracking info pending...
                </a>
              </li>
             }
           </ul>
         </React.Fragment>
        }
      </div>
      {batch.can_process_payments &&
       <button
           type="button"
           className={`btn ${invoiceButtonClass} noselect`}
           id={`invoiceBtn-${batch.pk}`}
           data-toggle="modal"
           data-target="#invoiceModal"
           style={{marginTop: '5px'}}
           title={invoiceButtonTitle}
           data-orderno={batch.pk}>
         Invoice
       </button>
      ||
       <button
           type="button"
           className="btn btn-default noselect"
           data-toggle="modal"
           data-target="#enablePaymentsModal"
           style={{marginTop: "5px"}}>
         Invoice
       </button>
      }
      <div className="text-small">
        <p>
          Shipping method:
          {' '}
          <strong>
            {batch.shipping_method.name}
          </strong>
        </p>
        <p title="Sum of item prices. Does not include taxes, shipping, or other fees.">
          Subtotal price: <strong>{formatCurrency(batch.total_price)}</strong>
        </p>
        {batch.total_referral_fees > 0 &&
         <p>
           Referral fees: <strong>{formatCurrency(batch.total_referral_fees)}</strong>
         </p>
        }
        <p>
          Most recent claim:
          {' '}
          <strong>{batch.formatted_last_claim_date}</strong>
        </p>
        <p>
          Done shopping:
          {' '}
          <strong>{batch.formatted_done_shopping_date}</strong>
        </p>
        {batch.notes &&
         <div className="alert alert-info order-notes">
           <b>Order notes:</b>
           <div>{batch.notes}</div>
         </div>
        }
        <a href={`/order_confirmation/?o=${batch.pk}`}>
          Order #{batch.pk}
        </a>
        <br />
        <a href={`/inventory/claim_customer_update/${batch.pk}?next=${location.pathname}`}>
          Edit order info
        </a>
      </div>
    </td>;
}

OrderInfo.propTypes = {
    // all fields from serverside_sales_pseudoserializer
    // using so much of it I don't want to list them all.
};

// reactifying sold_item.html
function Claim(props) {
    const claimrecord = props.claim;
    const item = props.claim.item;
    const { itemvariant } = claimrecord;
    const { dropship_item } = claimrecord;
    const { thumbnailWrapperClass = "col-md-6" } = props;
    const renderFbp = fbp => {
        return <p key={fbp.pk}>
          <a target="_blank" href={fbp.group_link}>{fbp.group_name}</a>
          {' '}|{' '}
          <a target="_blank" href={fbp.album_link}>{fbp.album_name}</a>
          {' '}|{' '}
          <a target="_blank" href={fbp.fb_photo_link}>Photo</a>
        </p>;
    };
    const renderPartyitem = pi => {
        return <li key={pi.pk}>
          <span className="text-muted">[</span>
          <a title={pi.party}
             className={pi.party_id === claimrecord.claimed_from_id ? "text-strong" : ""}
             href={pi.album_url}>
            {pi.party.substring(0,30)}
          </a>
          <span className="text-muted">]</span>
        </li>;
    };
    const showLinkCount = 5;
    const numMorePosts = claimrecord.item.fbpostinfo_set.length - showLinkCount;
    const numMoreParties = claimrecord.item.partyitems_sorted.length - showLinkCount;
    const item_image = (itemvariant && itemvariant.image_full)
                     ? {
                         image_full: itemvariant.image_full,
                         image_medium: itemvariant.image_medium,
                         image_thumbnail: itemvariant.image_thumbnail,
                     }
                     : {
                         image_full: item.image_full,
                         image_medium: item.image_medium,
                         image_thumbnail: item.image_thumbnail,
                     };
    return (
        <div className="col-md-12">
          <div className="sales-item-container new-sales-item-container text-small"
               key={claimrecord.pk}>
            <span style={{display: "none"}}>{item_image.image_full}</span>

            <div className="dropdown sales-item-menu-dropdown">
              <button className="btn btn-default dropdown-toggle"
                      type="button"
                      id="dropdownMenu1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                <span className="glyphicon glyphicon-menu-hamburger"></span>
              </button>
              <ul className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenu1">
                <li>
                  <a href={`/inventory/cr_update/${claimrecord.pk}/?next=${location.pathname}`}>
                    Edit sale
                  </a>
                </li>
                <li>
                  <a href="#"
                     className="confirmCancelLink"
                     data-toggle="modal"
                     data-target="#confirmCancelModal"
                     data-confirmurl={`/inventory/claimrecord_relist/${claimrecord.pk}/?next=${location.pathname}`}
                     data-img={item_image.image_thumbnail}
                     data-name={item.short_name}>Cancel Sale of this Item</a>
                </li>
                <li><a href={`/i/${item.pk}/`}>Customer view</a></li>
              </ul>
            </div>

            <div className="sales-item-price text-success text-strong">
              {formatCurrency(claimrecord.price)}
            </div>

            <div className="row">
              <div className="col-lg-7">

                <div className="row">
                  <div className={thumbnailWrapperClass}>
                    <div className="thumbnail">
                      <a target="_blank" href={item_image.image_full} className="nounderline">
                        <img className="img-responsive" src={item_image.image_medium} />
                      </a>
                      <div className="caption">
                        <h6 className="text-center">
                          {item.title && item.title}
                          {item.itemchoice &&
                           <React.Fragment>
                             {item.itemchoice}{item.size && <React.Fragment> ({item.size})</React.Fragment>}
                           </React.Fragment>
                          }
                          {itemvariant.pk &&
                           <div>
                             <span className="label label-default text-tiny">
                               {itemvariant.name}
                             </span>
                           </div>
                          }
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className={thumbnailWrapperClass}>
                    {item.sorted_attrs.length !== 0 &&
                     <ul className="list-unstyled text-small attributes-list">
                       {item.sorted_attrs.map(attr => {
                           const content = <React.Fragment>
                             <strong>{attr.name}:</strong> {attr.value}
                           </React.Fragment>;
                           return <li key={attr.name}>
                             {attr.hidden &&
                              <i title="Attribute hidden to customers">{content}</i>
                             || content}
                           </li>;
                       })}
                     </ul>
                    }
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {claimrecord.notes &&
                 <div className="alert alert-info"
                      style={{
                          maxWidth: "300px",
                          // pre-line is like linebreaksbr
                          // https://stackoverflow.com/questions/39325414/line-break-in-html-with-n
                          whiteSpace: "pre-line",
                      }}>
                   <strong>Claim note:</strong>
                   <br />
                   {claimrecord.notes}
                 </div>
                }
                {claimrecord.private_notes &&
                 <div className="alert alert-info"
                      style={{
                          maxWidth: "300px",
                          whiteSpace: "pre-line",
                      }}>
                   <strong>Private claim note:</strong>
                   <br />
                   {claimrecord.private_notes}
                 </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="posts-and-parties">
                  {claimrecord.item.fbpostinfo_set.length !== 0 &&
                   <React.Fragment>
                     <p className="text-muted text-tiny"
                        style={{marginTop: "5px"}}>
                       Facebook links:
                     </p>
                     {claimrecord.item.fbpostinfo_set.slice(0, showLinkCount).map(renderFbp)}
                     {claimrecord.item.fbpostinfo_set.length > showLinkCount &&
                      <React.Fragment>
                        <a className="text-tiny text-muted"
                           data-toggle="collapse"
                           href={`#collapsePosts-${claimrecord.pk}`}>
                          Show {numMorePosts} more Facebook link{numMorePosts === 1 ? '' : 's'}
                        </a>
                        <span className="collapse" id={`collapsePosts-${claimrecord.pk}`}>
                          {claimrecord.item.fbpostinfo_set.slice(
                              showLinkCount,
                              claimrecord.item.fbpostinfo_set.length
                          ).map(renderFbp)}
                        </span>
                      </React.Fragment>
                     }
                   </React.Fragment>
                  }
                  {claimrecord.item.partyitems_sorted.length !== 0 &&
                   <React.Fragment>
                     <p className="text-muted text-tiny"
                        style={{marginTop: "5px"}}>
                       Parties:
                     </p>
                     <ul className="list-inline" style={{marginBottom: 0}}>
                       {claimrecord.item.partyitems_sorted.slice(0, showLinkCount).map(renderPartyitem)}
                     </ul>
                     {claimrecord.item.partyitems_sorted.length > showLinkCount &&
                      <React.Fragment>
                        <a className="text-tiny text-muted"
                           data-toggle="collapse"
                           href={`#collapseParties-${claimrecord.pk}`}>
                          Show {numMoreParties} more party link{numMoreParties === 1 ? '' : 's'}
                        </a>
                        <ul className="list-inline collapse"
                            id={`collapseParties-${claimrecord.pk}`}>
                          {claimrecord.item.partyitems_sorted.slice(
                              showLinkCount,
                              claimrecord.item.partyitems_sorted.length
                          ).map(renderPartyitem)}
                        </ul>
                      </React.Fragment>
                     }
                   </React.Fragment>
                  }
                </div>
                {claimrecord.claimed_from_id &&
                 <p className="text-muted text-tiny">
                   Claimed from party:
                   {' '}
                   <a href={claimrecord.claimed_from_party_link}>
                     {claimrecord.claimed_from_party_name}
                   </a>
                   {claimrecord.claimed_from_party_album_link &&
                    <React.Fragment>
                      {' '}
                      (Album: <a href={claimrecord.claimed_from_party_album_link}>{claimrecord.claimed_from_party_album_name}</a>)
                    </React.Fragment>
                   }
                 </p>
                }
                {!claimrecord.claimed_from_id && claimrecord.liked_from_party &&
                 <p className="text-muted text-tiny">
                   Claimed from a like, originally liked in party:
                   {' '}
                   <a href={claimrecord.liked_from_party.url}>
                     {claimrecord.liked_from_party.name}
                   </a>
                 </p>
                }
                {claimrecord.claimed_from_omniparty &&
                 <p className="text-muted text-tiny">
                   Claimed from Storefront:
                   {' '}
                   <a href={claimrecord.claimed_from_omniparty_link}>
                     {claimrecord.claimed_from_omniparty_name}
                   </a>
                 </p>
                }
                {claimrecord.claimed_from_fb_group_id &&
                 <p className="text-muted text-tiny">
                   Claimed from Facebook group:
                   {' '}
                   <a href={claimrecord.claimed_from_fb_group_link}>
                     {claimrecord.claimed_from_fb_group_name}
                   </a>
                 </p>
                }
                {claimrecord.referral_fee > 0 &&
                 <p className="text-muted text-tiny">
                   Referral fee: {formatCurrency(claimrecord.referral_fee)}
                 </p>
                }
              </div>
            </div>

            {claimrecord.claimquestionresponse_set.length !== 0 &&
             <React.Fragment>
               <hr />
               <h6>Custom Questions</h6>
               {claimrecord.claimquestionresponse_set.map(qr => {
                   return <p key={qr.pk}>
                     {qr.questiontext}
                     {' '}
                     <i>{qr.response}</i>
                   </p>;
               })}
             </React.Fragment>
            }

            {claimrecord.nextcustomer_set.length !== 0 &&
             <React.Fragment>
               <hr />
               <h6>Customers in line</h6>
               <p className="text-small text-muted">(click to reassign item)</p>
               <ol style={{paddingLeft: "15px"}}>
                 {claimrecord.nextcustomer_set.map((nc, idx) => {
                     return <li key={nc.pk}>
                       <a href={nc.promote_url}>{nc.customer}</a>
                       {nc.claimed_from && <span className="text-muted"> (Party: {nc.claimed_from})</span>}
                       {nc.claimed_from_fb_group && <span className="text-muted"> (Facebook group: {nc.claimed_from_fb_group})</span>}
                     </li>;
                 })}
               </ol>
             </React.Fragment>
            }

            {item.is_digital &&
             <React.Fragment>
               <hr />
               <p className="text-tiny text-muted">
                 <i className="fa fa-cloud-download" aria-hidden="true"></i>
                 Digital download
               </p>
             </React.Fragment>
            }
            <hr />
            <p className="text-tiny text-muted">Item #{item.pk}</p>
            {dropship_item &&
             <p className="text-tiny text-muted">
               Dropship retailer:
               {' '}
               <a href={`/u/${dropship_item.consultant.username}/`}>
                 {dropship_item.consultant.username}
               </a>
             </p>
            }
          </div>
        </div>
    );
}

Claim.propTypes = {
    // serverside_sales_pseudoserializer
    // but only using a few so I'll list them
    claim: PropTypes.object.isRequired,
    thumbnailWrapperClass: PropTypes.string,
};

// reactifying part of sales_table_row.html and sold_item.html
function Items(props) {
    return <td>
      <div className="row">
        {props.claimrecord_set.length === 0 && props.payments_invoiced &&
         <div className="alert alert-info">
           This order was cancelled but is being stored here to save the
           invoices that were made for it.
         </div>
        }
        {props.claimrecord_set.map(cr => <Claim key={cr.pk} claim={cr} />)}
      </div>
    </td>;
}

Items.propTypes = {
    claimrecord_set: PropTypes.array.isRequired,
    payments_invoiced: PropTypes.bool.isRequired,
};

function ActionCheckbox(props) {
    const checkedClass = props.checked ? "check" : "unchecked";
    return <td>
      <i data-sale-action={props.action}
         data-batch-pk={props.batchPk}
         data-checked={props.checked}
         className={`sale-action-checkbox glyphicon glyphicon-${checkedClass} pointer`}>
      </i>
    </td>;
}

ActionCheckbox.propTypes = {
    action: PropTypes.string.isRequired,
    batchPk: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
};

const SalesTemplates = {
    OrderInfo: (data) => ReactDOMServer.renderToStaticMarkup(
        <OrderInfo {...data} />
    ),
    Items: (data) => ReactDOMServer.renderToStaticMarkup(
        <Items {...data} />
    ),
    ActionCheckbox: (data) => ReactDOMServer.renderToStaticMarkup(
        <ActionCheckbox {...data} />
    ),
};

export default SalesTemplates;
export { Claim };
