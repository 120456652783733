import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import ImageUploadOrSelect from './ImageUploadOrSelect.jsx';
import { strRequest, strRequestJSON, strRequestJSONWithResponse, fetchJSON } from './util.jsx';
import { BulkAdd } from './bulk_add_app.jsx';
import PartyCreateForm from './PartyCreateForm.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { AddressUpdate } from './AddressUpdate.jsx';

const apiUrl = '/api/v2/account_setup/';

const commonStepProps = {
    goNext: PropTypes.func.isRequired,
    goPrevious: PropTypes.func.isRequired,
};

const SetupAccountType = (props) => {
    const userPrefs = props.userPrefs || {};
    if (userPrefs.is_market_seller || userPrefs.is_classic_seller) {
        return <div>
          Account type already chosen and cannot be changed here.
          <div style={{marginTop: '20px'}}>
            <p className="text-small text-muted">
              <span>
                <React.Fragment>
                  <a href="#"
                     onClick={e => {e.preventDefault(); props.goNext();} }>
                    Continue
                    {' '}
                    <span className="glyphicon glyphicon-chevron-right"></span>
                  </a>
                </React.Fragment>
                <br />
                <br />
                <a className="text-small" href="/">
                  Skip setup and proceed to dashboard
                </a>
              </span>
            </p>
          </div>
        </div>;
    }
    return (
        <div>
          <p>Pick an account type:</p>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title"
                      style={{fontSize: "32px"}}>Shopper</h3>
                </div>
                <div className="panel-body">
                  <p className="text-small help-text">
                    Free forever!
                  </p>
                  <div>
                    <h6 style={{display: "inline-block", paddingRight: "10px", margin: "0"}}>Features:</h6>
                    <ul style={{display: "inline"}} className="list-inline text-small">
                      <li>Order and claim history</li>
                      <li>Digital rewards card</li>
                      <li>Follow your favorite sellers</li>
                      <li>And much more!</li>
                    </ul>
                  </div>
                  {StrUserInfo.isStaffOrConsultant ||
                   <button className="btn btn-purple btn-lg"
                           style={{marginTop: "10px"}}
                           onClick={() => props.saveAccountType('shopper')}>
                     Select
                   </button>
                  }
                  {StrUserInfo.isStaffOrConsultant &&
                   <p className="help-block">
                     You can convert your consultant account into a shopper
                     account by cancelling your seller subscription <a href="/profile/cancel/">here</a>.
                   </p>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title"
                      style={{fontSize: "32px"}}>Classic Seller</h3>
                </div>
                <div className="panel-body">
                  <p className="text-small help-text">
                    Free for 30 days, then $9.99 / month.
                  </p>
                  <div>
                    <h6 style={{display: "inline-block", paddingRight: "10px", margin: "0"}}>Features:</h6>
                    <ul style={{display: "inline"}} className="list-inline text-small">
                      <li>All Shopper features</li>
                      <li>Inventory tracking</li>
                      <li>Order/fulfillment tracking</li>
                      <li>Shipping labels</li>
                      <li>Payments and invoicing</li>
                      <li>Online Storefront</li>
                      <li>Online parties/shops</li>
                      <li>Multi-seller parties/shops</li>
                      <li>Custom URLs</li>
                      <li>Facebook posting</li>
                      <li>Financial reports</li>
                      <li>And much more!</li>
                    </ul>
                  </div>
                  <button className="btn btn-purple btn-lg"
                          style={{marginTop: "10px"}}
                          onClick={() => props.saveAccountType('seller', props.goNext)}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="panel panel-default" id="sonlet-market-panel">
                <div className="panel-heading">
                  <h3 className="panel-title"
                      style={{fontSize: "32px"}}>Sonlet Market Seller</h3>
                </div>
                <div className="panel-body">
                  <div id="sonlet-market-body-wrapper">
                    <p className="text-small help-text">
                      Free for 30 days, then $9.99 / month.
                    </p>
                    <p className="text-small help-text">
                      Perfect for sellers of handmade goods.
                    </p>
                    <div>
                      <h6 style={{display: "inline-block", paddingRight: "10px", margin: "0"}}>Features:</h6>
                      <ul style={{display: "inline"}} className="list-inline text-small">
                        <li>All Classic Seller features</li>
                        <li>Customized interface for handmade goods</li>
                        <li>List your products on the public Sonlet Market</li>
                      </ul>
                    </div>
                  </div>
                  <button className="btn btn-purple btn-lg"
                          style={{marginTop: "10px"}}
                          onClick={() => props.saveAccountType('market', props.goNext)}>
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

SetupAccountType.propTypes = {
    ...commonStepProps,
    saveAccountType: PropTypes.func.isRequired,
    userPrefs: PropTypes.object,
};

const SetupCompany = (props) => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [cellNumber, setCellNumber] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [errors, setErrors] = useState([]);

    const loadData = () => {
        $.getJSON(apiUrl, (data) => {
            setCompanies(data.companies);

            if (data.company !== null)
                setSelectedCompany(data.company);
            if (data.company_logo !== null)
                setLogoUrl(data.company_logo);
            if (data.phone)
                setCellNumber(data.phone);
        });
    };
    useEffect(loadData, []);

    const onLogoUpload = (result) => {
        setLogoUrl(result.file.url);
    };

    const onSaveClick = async (e) => {
        e.preventDefault();
        const requests = [];
        if (selectedCompany) {
            const req = strRequest(apiUrl, 'POST', {
                action: 'set_company',
                company_id: selectedCompany,
            });
            requests.push(req);
        }
        if (cellNumber) {
            const req = strRequest(apiUrl, 'POST', {
                action: 'set_phone_number',
                phone: cellNumber,
            });
            requests.push(req);
        }
        if (requests.length > 0) {
            const rsps = await Promise.all(requests);
            const errors = await Promise.all(
                rsps.filter(r => !r.ok)
                    .map(e => e.json())
            );
            if (errors.length > 0) {
                setErrors(errors);
                return;
            }
            await window.refreshStrUserInfo();
        }
        props.goNext();
    };

    return (
        <div>
          <p>
            Sonlet helps small businesses set up simple online store fronts.
            To get things set up, please tell us more about your business.
          </p>
          <p className="help-block">
            This setup is optional. You can always <a href="/">skip this setup</a> and
            customize your settings later.
          </p>
          <hr />
          <div className="form-horizontal form-horizontal-compact">
            <div className="form-group">
              <p>
                What's your company logo?
              </p>
              <div className="col-md-12">
                <div style={{width: "300px", margin: "0 auto"}}>
                  <ImageUploadOrSelect
                      inputName="upload_company_logo"
                      callback={onLogoUpload}
                      resource="genimage"
                      genImageType="company-logo"
                  />
                </div>
                {logoUrl &&
                 <img style={{width: "300px"}} src={logoUrl} />
                }
              </div>
            </div>
            <div className="form-group" style={{marginTop: "30px"}}>
              <p>
                Do you want to receive text notifications
                (when you receive an Order, etc)?
                Add a phone number:
              </p>
              <div className="col-md-4 col-md-offset-4">
                <input type="text"
                       className="form-control input-lg"
                       value={cellNumber}
                       onChange={(e) => setCellNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group" style={{marginTop: "30px"}}>
              <p>
                Do you want Sonlet to automatically suggest inventory options?
                Select a supported supplier:
              </p>
              <div className="col-md-4 col-md-offset-4">
                <select className="form-control input-lg"
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        value={selectedCompany}>
                  {companies.map(company => <option key={company.id}
                                                    value={company.id}>{company.name}</option>)}
                </select>
              </div>
            </div>
            <button className="btn btn-lg btn-primary"
                    style={{marginTop: "30px"}}
                    onClick={onSaveClick}>
              Save and continue
            </button>
            {errors.length > 0 &&
             <div className="alert alert-danger">
               Please correct the following errors:
               <ul className="list-unstyled">
                 {errors.map((err, idx) => {
                     // eslint-disable-next-line react/no-array-index-key
                     return <li key={idx}>{err.message}</li>;
                 })}
               </ul>
             </div>
            }
          </div>
        </div>
    );
};

SetupCompany.propTypes = {...commonStepProps};

const SetupProducts = (props) => {
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div>
          <p>
            Adding inventory is super easy, give it a shot!
          </p>
          <div style={{marginTop: "30px"}}>
            <BulkAdd showHeader={false}
                     uploaderOnly={true}
                     noEditorsClearfix={true}
                     editorsClassName="col-md-10 col-md-push-1"
                     onImage={() => setShowContinue(true) } />
          </div>
          {showContinue &&
           <button className="btn btn-lg btn-primary"
                   style={{marginTop: "30px"}}
                   onClick={() => props.goNext()}>
             Continue
           </button>
          }
        </div>
    );
};

SetupProducts.propTypes = {...commonStepProps};

const SetupStorefront = (props) => {
    const [enableStorefront, setEnableStorefront] = useState(true);
    const [name, setName] = useState(`${StrUserInfo.username}'s Storefront`);
    const [subdomain, setSubdomain] = useState("");
    const [subdomainError, setSubdomainError] = useState("");

    const onSubdomainChange = (e) => {
        setSubdomain(e.target.value);
    };

    const storeApiUrl = '/api/v2/party_create/';

    const onSubdomainBlur = async (e) => {
        const [json, rsp] = await strRequestJSONWithResponse(
            storeApiUrl,
            'POST',
            {
                action: 'check_subdomain',
                subdomain,
            },
        );
        if (!rsp.ok) {
            setSubdomainError(`Error checking URL availability: ${json.error || rsp.statusText}`);
        } else {
            setSubdomainError(json.success ? "" : "URL is already in use. Please pick a different one.");
        }
    };

    const onSaveClick = async (e) => {
        e.preventDefault();
        const [json, rsp] = await strRequestJSONWithResponse(
            storeApiUrl,
            'POST',
            {
                action: 'save_omniparty',
                enabled: enableStorefront,
                name: name,
                subdomain: subdomain,
            },
        );
        if (rsp.ok) {
            props.goNext();
        } else {
            setSubdomainError(`Error saving: ${json.error || rsp.statusText}`);
        }
    };

    return (
        <div>
          <h3>Set up your Storefront</h3>
          <p>
            Your Storefront contains all of your publicly listed items
            and is the quickest way to create an online store.
          </p>
          <div className="form-horizontal form-horizontal-compact">
            <div className="form-group">
              <label className="col-md-4 control-label">
                Enable public Storefront
              </label>
              <div className="col-md-7" style={{textAlign: "left"}}>
                <input type="checkbox"
                       style={{marginTop: "11px"}}
                       checked={enableStorefront}
                       onChange={() => setEnableStorefront(!enableStorefront)} />
              </div>
            </div>
            {enableStorefront &&
             <div>
               <div className="form-group">
                 <label className="col-md-4 control-label">
                   Name *
                 </label>
                 <div className="col-md-7" style={{textAlign: "left"}}>
                   <input type="text"
                          className="form-control input-lg"
                          value={name}
                          placeholder="Your Storefront Name"
                          onChange={(e) => setName(e.target.value)} />
                 </div>
               </div>
               <div className="form-group">
                 <label className="col-md-4 control-label">
                   Storefront URL
                 </label>
                 <div className="col-md-7" style={{textAlign: "left"}}>
                   <div className="form-inline">
                     <input type="text"
                            className="form-control input-lg"
                            value={subdomain}
                            onChange={onSubdomainChange}
                            onBlur={onSubdomainBlur}
                     />
                     <span style={{paddingLeft: "5px"}}
                           className="hidden-xs">.sonlet.store</span>
                   </div>
                   {subdomainError &&
                    <div className="text-danger">{subdomainError}</div>
                   }
                   {!subdomainError && subdomain &&
                    <div className="text-success" style={{marginTop: "5px"}}>
                      Your Storefront will be available at
                      {' '}
                      <a target="_blank"
                         href={`https://${subdomain}.sonlet.store`}>
                        {subdomain}.sonlet.store
                      </a>.
                    </div>
                   }
                   {!subdomain &&
                    <div className="text-success" style={{marginTop: "5px"}}>
                      If you leave the URL blank your Storefront will
                      have an automatically generated URL. You can
                      always change this later.
                    </div>
                   }
                 </div>
               </div>
             </div>
            }
          </div>
          <p style={{marginTop: "20px"}}>
            In addition to your Storefront, you can create unlimited
            special-purpose {StrUserInfo.words.parties} for everything from special occasions
            and exclusive releases to multi-seller collaborations.
          </p>
          <button className="btn btn-lg btn-primary"
                  style={{marginTop: "30px"}}
                  disabled={enableStorefront && !name}
                  onClick={onSaveClick}>
            Save and continue
          </button>
        </div>
    );
};

SetupStorefront.propTypes = {...commonStepProps};

const SetupParty = (props) => {
    const [numListed, setNumListed] = useState(null);
    const checkUrl = '/api/v2/party_create/';

    const loadData = () => {
        strRequestJSON(checkUrl, 'GET')
            .then(json => {
                setNumListed(json.num_listed);
            });
    };
    useEffect(loadData, []);

    const goPrevious = (e) => {e.preventDefault(); props.goPrevious();};
    const goNext = (e) => {e.preventDefault(); props.goNext();};

    const partyWord = StrUserInfo.words.party;
    const partiesWord = StrUserInfo.words.parties;

    return (
        <div>
          <h3>Create your first {partyWord}</h3>
          <p>
            {partiesWord} are another way to create an online store.
          </p>
          <p>
            While similar to your personal Storefront, {partiesWord}
            {' '}
            don't necessarily contain all of your inventory and you
            can invite other sellers to join your {partyWord} and add
            their items to the mix.
          </p>
          <p>
            Shoppers love multi-seller collaborations!
          </p>
          <p>
            You can create {partiesWord} at any time in the dashboard.
          </p>
          {numListed === 0 &&
           <p>
             Looks like you didn't add any publicly listed inventory
             in the previous step. You can <a href="#" onClick={goPrevious}>go back</a> and
             add some or <a href="#" onClick={goNext}>skip this step</a>.
           </p>
          }
          {numListed > 0 &&
           <PartyCreateForm onCreate={props.goNext}>
             <p>
               This first {StrUserInfo.words.party} will include the
               listed items you just added.
               <br />
               Items can be added and removed later on in the dashboard.
             </p>
           </PartyCreateForm>
          }
        </div>
    );
};

SetupParty.propTypes = {...commonStepProps};

const SetupAddress = (props) => {
    return (
        <div>
          <p>
            Please enter your name and address.
          </p>
          <p className="help-block">
            (Your address is needed to calculate sales tax when shoppers purchase your
            items.)
          </p>
          <AddressUpdate noHeadings={true}
                         onSuccess={props.goNext}
                         updateBtnText="Save" />
        </div>
    );
};

SetupAddress.propTypes = {...commonStepProps};

const SetupPayments = (props) => {
    const onConnectClick = async (e) => {
        e.preventDefault();
        const data = await fetchJSON('/api/v2/stripe_oauth_request/', null, {
            'redirect': '/accounts/setup/',
        });
        window.location.href = data.oauth_url;
    };

    const goNext = (e) => {e.preventDefault(); props.goNext();};

    if (props.userPrefs.can_process_payments) {
        return (
            <div>
              <h3>Your bank account is already connected 🙌</h3>
              <p>
                You can edit your settings or disconnect your bank account
                anytime from your <a href="/settings/billing/">Billing/Payout Settings page</a>.
              </p>
            </div>
        );
    }

    return (
        <div>
          <p>
            Before you can add your products to the Sonlet Market, you'll need
            to link your bank account so that you can receive payouts for your
            sales. The setup process only takes a minute or two.
          </p>
          <p>
            If you're just looking around and don't want to start listing products
            for sale yet you can always <a href="#" onClick={goNext}>skip this step</a> and
            link your bank account later.
          </p>
          <a onClick={onConnectClick} href="#">
            <img src={props.stripeConnectImageUrl} />
          </a>
          <p style={{marginTop: "10px"}}
             className="text-small">You'll be redirected back here after linking your account.</p>
          <p className="text-small">
            Sonlet uses <a target="_blank" href="https://stripe.com/">Stripe</a> for
            payment processing. Your banking info
            is <strong>not</strong> stored on Sonlet's servers. Stripe is a highly
            trusted name in e-commerce, and is certified
            to <a target="_blank" href="https://stripe.com/docs/security/stripe">PCI Service Provider Level 1</a>.
          </p>
        </div>
    );
};

SetupPayments.propTypes = {
    ...commonStepProps,
    userPrefs: PropTypes.object.isRequired,
};

const PostConnect = (props) => {
    return (
        <div>
          {props.userPrefs.can_process_payments &&
           <div>
             <h3>
               <i className="fa fa-check-circle text-success"></i>
               {' '}
               Bank account connected successfully!
             </h3>
             <p className="help-block">Your account is now ready for lift-off.</p>
             <p className="help-block">
               Good luck, have fun, and if you're ever stuck please don't
               hesitate to reach out to our world-class support team at
               {' '}
               <a href="mailto:support@sonlet.com">support@sonlet.com</a>.
             </p>
             <a className="btn btn-purple btn-lg"
                href="/dashboard/"
                style={{marginTop: "10px"}}>🚀 Proceed to Dashboard! 🚀</a>
           </div>
          }
          {props.userPrefs.can_process_payments ||
            <div>
              <p>
                You haven't connected a bank account so you won't
                be able to list anything for sale, but you can still
                add inventory to your account 🙌. When you're ready to
                list products for sale you can connect your bank account
                from your <a href="/settings/billing/">payout settings</a>.
              </p>
              <a href="/">Proceed to Dashboard</a>
            </div>
          }
        </div>
    );
};

PostConnect.propTypes = {
    ...commonStepProps,
    userPrefs: PropTypes.object.isRequired,
};

const SetupReview = (props) => {
    return (
        <div>
          <h1>You're clear for lift-off 🚀</h1>
          <p className="text-muted text-large">Here's a quick review:</p>
          <img src="https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/sonlet_short_and_sweet_mobile_1000.png"
               className="img-responsive visible-xs-block"
          />
          <img src="https://directangular-marketing-media-public.s3.us-west-1.amazonaws.com/sonlet_short_and_sweet_1000.png"
               className="img-responsive hidden-xs"
          />
          <hr />
          <a className="btn btn-purple btn-lg"
             href="/dashboard/"
             style={{marginTop: "10px"}}>🚀 Proceed to Dashboard! 🚀</a>
        </div>
    );
};

const AccountSetup = (props) => {
    const [stepNum, setStepNum] = useState(0);
    const [accountType, setAccountType] = useState("");
    const [userPrefs, setUserPrefs] = useState(null);

    const isLoading = userPrefs === null;

    const saveAccountType = (_accountType, onSave = null) => {
        const data = {
            action: 'set_account_type',
            account_type: _accountType,
        };
        setAccountType(_accountType);
        $.post(apiUrl, data, () => {
            if (onSave)
                onSave();
            else
                window.location.href = "/";
        })
         .fail((rsp) => {
             toast.error(`Couldn't save account type: ${rsp.statusText}`);
         });
    };

    const sellerSteps = [{
        component: SetupAccountType,
        props: {saveAccountType, userPrefs},
    }, {
        component: SetupCompany,
        props: {},
    }, {
        component: SetupProducts,
        props: {},
    }, {
        component: SetupStorefront,
        props: {},
    },{
        component: SetupParty,
        props: {},
    }, {
        component: SetupReview,
        props: {},
    }];

    const marketSteps = [{
        component: SetupAccountType,
        props: {saveAccountType, userPrefs},
    }, {
        component: SetupAddress,
        props: {},
        unskippable: true,
    }, {
        component: SetupPayments,
        props: {userPrefs, stripeConnectImageUrl: props.stripeConnectImageUrl},
    }, {
        component: PostConnect,
        props: {userPrefs},
    }];

    useEffect(() => window.scroll(0, 0), [stepNum]);
    useEffect(() => {
        fetchJSON('/api/v2/user_preferences/').then((prefs) => {
            setUserPrefs(prefs);
            if (prefs.is_market_seller) {
                setAccountType("market");
                /* if we're returning from a successfull Stripe Connect
                   flow, skip forward to PostConnect */
                if (prefs.can_process_payments) {
                    setStepNum(
                        marketSteps.findIndex(s => s.component === PostConnect)
                    );
                } else {
                    setStepNum(
                        marketSteps.findIndex(s => s.component === SetupAddress)
                    );
                }
            } else if (prefs.is_classic_seller) {
                setStepNum(1);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return (
            <div style={{textAlign: "center", paddingTop: "50px"}}>
              <LoadingSpinner />
            </div>
        );
    }

    const isMarketSeller = accountType === "market";

    const steps = isMarketSeller ? marketSteps : sellerSteps;

    const goNext = () => setStepNum(stepNum + 1);
    const goPrevious = () => setStepNum(stepNum - 1);

    const step = steps[stepNum];

    return (
        <div className="row">
          <div className="col-md-6 col-md-push-3 text-center">
            <img src={props.topImageUrl}
                 style={{width: "70px"}} />
            <h1 style={{marginTop: "0"}}>Welcome.</h1>
            <p>
              Let's get your account set up!
            </p>
            <hr />
            <step.component {...step.props}
                            goNext={goNext}
                            goPrevious={goPrevious} />
            {stepNum > 0 &&
             <div style={{marginTop: '20px'}}>
               <p className="text-small text-muted">
                 <a href="#"
                    onClick={e => {e.preventDefault(); goPrevious();}}>
                   <span className="glyphicon glyphicon-chevron-left"></span>
                   {' '}
                   Go back
                 </a>
                 {stepNum < steps.length - 1 &&
                  <span>
                    {!step.unskippable &&
                     <React.Fragment>
                       {' | '}
                       <a href="#"
                          onClick={e => {e.preventDefault(); goNext();} }>
                         Skip this step for now
                         {' '}
                         <span className="glyphicon glyphicon-chevron-right"></span>
                       </a>
                     </React.Fragment>
                    }
                    <br />
                    <br />
                    <a className="text-small" href="/">
                      Skip setup and proceed to dashboard
                    </a>
                  </span>
                 }
               </p>
             </div>
            }
          </div>
        </div>
    );
};

AccountSetup.propTypes = {
    topImageUrl: PropTypes.string.isRequired,
    stripeConnectImageUrl: PropTypes.string.isRequired,
};

export default function AccountSetupApp(el, topImageUrl, stripeConnectImageUrl) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AccountSetup topImageUrl={topImageUrl}
                              stripeConnectImageUrl={stripeConnectImageUrl} />);
}
