import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';

import styles from './css/typeahead.css';
import { useDebounce } from './util.jsx';


function StrTypeAhead(props) {
    const { debounceDelay = 0 } = props;
    const [value, setValue] = useState('');
    const previousMatch = useRef('');
    const [matches, setMatches] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selection, setSelection] = useState(0);
    const [handlingEnter, setHandlingEnter] = useState(false);
    const inputEl = useRef(null);
    const innerDiv = useRef(null);
    const [inputRect, setInputRect] = useState({});

    const { source, display, onSubmit, initialValue, setFocusInput, onSelect } = props;

    /* Our suggestions have to be positioned with "fixed" or "absolute" so
       that they float above everything, so to make the suggestions box
       float with the input we have to move it on document scroll */
    useEffect(() => {
        const handleScroll = throttle(() => {
            if (inputEl.current)
                setInputRect(inputEl.current.getBoundingClientRect());
        }, 10);
        document.addEventListener("scroll", handleScroll);
        /* HACK: if the document never scrolls (e.g. if the content all
           fits in the viewport) we never get our rect, so just re-snag it
           periodically :grimacing: */
        const resnagHackId = setInterval(handleScroll, 2000);
        return () => {
            document.removeEventListener("scroll", handleScroll);
            clearInterval(resnagHackId);
        };
    }, []);

    useEffect(() => {
        if (initialValue)
            setValue(initialValue);
    }, [initialValue]);

    const querySource = useCallback(() => {
        if (value === ''
            || value === previousMatch.current
            || value === props.initialValue) {
            setMatches([]);
            return;
        }
        const queryTheSource = async () => {
            const newMatches = await source(value) || [];
            setMatches(newMatches);
        };
        queryTheSource();
    }, [source, value, props.initialValue]);

    useDebounce(querySource, value, debounceDelay);

    const focusInput = useCallback(() => {
        inputEl.current.focus();
    }, [inputEl]);

    const selectMatch = useCallback((match) => {
        const output =
            value.slice(0, value.length - match.triggerLength)
            + match.output;
        inputEl.current.focus();
        setMatches([]);
        previousMatch.current = output;
        setValue(output);
        onSelect && onSelect(match);
        setSelection(0);
        /* and scroll to the end of the input field */
        /* https://stackoverflow.com/a/10576409/209050 */
        setTimeout(() => {
            inputEl.current.scrollLeft = inputEl.current.scrollWidth;
        }, 100);
    }, [value, onSelect]);

    const onClick = useCallback((e, match) => {
        e.preventDefault();
        selectMatch(match);
    }, [selectMatch]);

    useEffect(() => {
        setInputRect(inputEl.current.getBoundingClientRect());
        if (setFocusInput)
            setFocusInput(focusInput);
    }, [inputEl, setFocusInput, focusInput]);

    useEffect(() => {
        const newSuggestions = matches.map((match, idx) => {
            const moreAttrs = idx === selection ? {className: "selected"} : {};
            return (
                <li key={match.key}
                    {...moreAttrs}
                    onMouseEnter={() => setSelection(idx)}
                    onMouseLeave={() => setSelection(null)}
                    onClick={e => onClick(e, match)}>{display(match.displayData)}</li>
            );
        });
        setSuggestions(newSuggestions);
    }, [matches, display, onClick, selection]);

    const doSubmit = (value) => {
        if (onSubmit) {
            setHandlingEnter(true);
            onSubmit(value, () => {
                setHandlingEnter(false);
                previousMatch.current = '';
                setValue('');
                inputEl.current.focus();
            });
        }
    };

    const onKeyPress = useCallback((e) => {
        if (e.nativeEvent.keyCode != 13
            || e.target.value === ''
            || handlingEnter === true)
            return;
        e.preventDefault();

        if (matches.length > 0 && selection !== null) {
            /* if enter was pressed while we have a selection, insert it */
            selectMatch(matches[selection]);
            if (props.submitOnSelect) {
                doSubmit(matches[selection].key);
            }
        } else {
            /* otherwise submit the input */
            doSubmit(e.target.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMatch, matches, selection, handlingEnter, props.submitOnSelect]);

    const onKeyDown = useCallback((e) => {
        let direction;
        switch (e.nativeEvent.keyCode) {
        case 38: /* UP */
            direction = 'up';
            break;
        case 40: /* DOWN */
            direction = 'down';
            break;
        case 9:  /* TAB */
            direction = e.nativeEvent.shiftKey ? 'up' : 'down';
            break;
        }

        switch (direction) {
        case 'up':
            e.preventDefault();
            if (selection > 0)
                setSelection(selection - 1);
            else if (selection === 0)
                setSelection(matches.length - 1);
            break;
        case 'down':
            e.preventDefault();
            if (selection === null)
                setSelection(0);
            else if (selection < matches.length - 1)
                setSelection(selection + 1);
            else if (selection === matches.length - 1)
                setSelection(0);
            break;
        }
    }, [selection, matches]);

    const top = props.positionAbsolutely ? inputRect.height : inputRect.top + inputRect.height;
    const left = props.positionAbsolutely ? 0 : inputRect.left;
    const innerStyle = {
        top: top,
        left: left,
        position: props.positionAbsolutely ? 'absolute' : 'fixed',
        zIndex: 1029,
    };

    return (
        <div className="StrTypeAhead" style={{position: "relative"}}>
          <input
              disabled={handlingEnter}
              type="text"
              enterKeyHint="send"
              className={props.inputClassName}
              ref={inputEl}
              placeholder={props.placeholder}
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              autoFocus={props.autoFocus}
          />
          {suggestions.length > 0 &&
           <div ref={innerDiv} style={innerStyle} className="StrTypeAhead--inner">
             <ul>
               {suggestions}
             </ul>
             {props.footer}
           </div>
          }
        </div>
    );
}

StrTypeAhead.propTypes = {
    /* Receives (inputValue, cb). Must call cb() when done handling. */
    onSubmit: PropTypes.func,
    /* Receives (inputValue). Should return an array of match objects of
       the form {displayData, triggerLength, output, key}. */
    source: PropTypes.func.isRequired,
    /* Receive (match.displayData) (which comes from source). Should return
       a node to be rendered as the match result. */
    display: PropTypes.func.isRequired,
    /* placeholder string for empty input */
    placeholder: PropTypes.string,
    /* rendered below suggestions */
    footer: PropTypes.node,
    inputClassName: PropTypes.string,
    initialValue: PropTypes.string,
    /* Use absolute positioning rather than fixed (workaround for bootstrap modals) */
    positionAbsolutely: PropTypes.bool,
    /* If you want a handle to the input ref, provide a callback here. Will
       be called with the focus function (which takes no args) as the only
       argument */
    setFocusInput: PropTypes.func,
    autoFocus: PropTypes.bool,
    /* Called when an option is selected. Receives one argument:
       selectedMatch (which comes from `source'). */
    onSelect: PropTypes.func,
    /* debounces the input by this many milliseconds */
    debounceDelay: PropTypes.number,
    submitOnSelect: PropTypes.bool,
};

export default StrTypeAhead;
