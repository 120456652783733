import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import SocialFeedCard from './SocialFeedCard.jsx';
import LoadingSpinner from '../LoadingSpinner.jsx';
import { enrichActivity } from './social_util.jsx';


class SocialFeedCardWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: null,
        };

        this.fetch = this.fetch.bind(this);
    }

    render() {
        const { activity } = this.state;

        if (!activity)
            return <LoadingSpinner />;

        return <SocialFeedCard activity={activity} doRefresh={this.fetch} />;
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        $.getJSON(`/api/v2/social_by_uuid/${this.props.uuid}/`, (activity) => {
            this.setState({activity: enrichActivity(activity)});
        });
    }
}

SocialFeedCardWrapper.propTypes = {
    uuid: PropTypes.string.isRequired,
};

function SocialFeedCardApp(el, uuid) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SocialFeedCardWrapper uuid={uuid} />);
}

export default SocialFeedCardApp;
