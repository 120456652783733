import React from 'react';
import { createRoot } from 'react-dom/client';
import { updateStateThing, fetchJSON, strRequest } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

class SwiftOrderImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            itemsToImport: [],
            loading: false,
            numImported: null,
            importComplete: false,
        };

        this.apiUrl = '/api/v2/swiftorder_import_gallery/';

        this.updateStateThing = updateStateThing.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
    }

    async onNextClick(e) {
        e.preventDefault();
        const url = `${this.apiUrl}?url=${this.state.url}`;
        this.setState({loading: true});
        const data = await fetchJSON(url);
        this.setState({
            loading: false,
            itemsToImport: data.results.map(item => ({...item, "import": true})),
        });
    }

    onCheck(item_id) {
        this.setState(({itemsToImport}) => {
            for (let i = 0; i < itemsToImport.length; i++) {
                if (itemsToImport[i].item_id === item_id) {
                    let newItem = {...itemsToImport[i]};
                    newItem.import = !newItem.import;
                    itemsToImport[i] = newItem;
                    break;
                }
            }
            return {itemsToImport};
        });
    }

    async onImportClick(e) {
        e.preventDefault();

        this.setState({loading: true});
        for (let i = 0; i < this.state.itemsToImport.length; i++) {
            const item = this.state.itemsToImport[i];
            if (!item.import)
                continue;
            const data = {
                price: item.price,
                style: item.style,
                size: item.size,
                image_url: item.image_url,
            };
            await strRequest(this.apiUrl, 'POST', data);
            let itemsToImport = [...this.state.itemsToImport];
            let newItem = {...itemsToImport[i]};
            newItem.imported = true;
            itemsToImport[i] = newItem;
            this.setState((prevState) => ({itemsToImport, numImported: prevState.numImported + 1}));
        }
        this.setState({loading: false, importComplete: true});
    }

    render() {
        const percentDone = this.state.numImported !== null
                          ? (this.state.numImported / this.state.itemsToImport.length) * 100
                          : 0;
        return (
            <div>
              <p>
                Looks like you're joining us from SwiftOrder. Welcome! We would
                like to make the transition process as seamless as
                possible. You can enter your SwiftOrder gallery URL here and
                we'll import your SwiftOrder inventory into your Sonlet account:
              </p>
              {this.state.itemsToImport.length === 0 &&
               <div>
                 <input className="form-control"
                        type="text"
                        name="url"
                        onChange={this.updateStateThing}
                        disabled={this.state.loading}
                        style={{marginBottom: "5px"}}
                        placeholder="https://mygallery.swiftorder.io/my-cool-gallery"
                 />
                 <button className="btn btn-primary"
                         onClick={this.onNextClick}
                         disabled={this.state.loading}>
                   Next
                 </button>
               </div>
              }
              {this.state.loading && <LoadingSpinner />}
              {this.state.itemsToImport.length > 0 &&
               <div>
                 <div className="table-responsive">
                   <table className="table">
                     <thead>
                       <tr>
                         <th>Import?</th>
                         <th>Image</th>
                         <th>Style</th>
                         <th>Size</th>
                         <th>Price</th>
                       </tr>
                     </thead>
                     <tbody>
                       {this.state.itemsToImport.map((item) => {
                           return (
                               <tr key={item.item_id}
                                   className={item.imported && "success"}>
                                 <td>
                                   <input type="checkbox"
                                          checked={item.import}
                                          onChange={() => this.onCheck(item.item_id)}
                                   />
                                 </td>
                                 <td>
                                   <img src={item.image_url}
                                        style={{maxHeight: '300px'}}
                                        className="img-responsive" />
                                 </td>
                                 <td>{item.style}</td>
                                 <td>{item.size}</td>
                                 <td>${item.price}</td>
                               </tr>
                           );
                       })}
                     </tbody>
                   </table>
                 </div>

                 {!this.state.importComplete &&
                  <button className="btn btn-primary btn-lg"
                          onClick={this.onImportClick}
                          disabled={this.state.loading}>
                    Import!
                  </button>
                 }
                 {this.state.loading && <LoadingSpinner />}
                 {this.state.loading && this.state.numImported !== null &&
                  <div>
                    <p className="alert alert-info">{this.state.numImported} items imported</p>
                    <div className="progress">
                      <div className="progress-bar progress-bar-striped active"
                           role="progressbar"
                           aria-valuenow={this.state.numImported}
                           aria-valuemin="0"
                           aria-valuemax={this.state.itemsToImport.length}
                           style={{width: `${percentDone}%`}}>
                      </div>
                    </div>
                  </div>
                 }
               </div>
              }
            </div>
        );
    }
}

export default function SwiftOrderImportApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SwiftOrderImport />);
}
