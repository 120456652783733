import {
    normalizeData,
    normalizeClaim,
} from './StrChatEvents.jsx';


/**
 * http://stackoverflow.com/a/6021027/209050 doesn't actually seem to
 * work with multiple parameters, so buyer beware...
 */
function updateQueryStringParameter(uri, key, value) {
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

const genPartyChatType = (chatId) => {
    return {
        channel: `party-${chatId}`,
        messagesUrl: `/api/v1/party/${chatId}p/recent_messages/`,
        usersUrl: `/api/v1/party/${chatId}p/users/`,
        evtMessage: `party:${chatId}:message`,
        evtRemoveMessage: `party:${chatId}:removemessage`,
        getMessageHref: (msgId) => {
            let messageHref = `/party/${chatId}p/`;
            messageHref = updateQueryStringParameter(messageHref, 'focus_msg', msgId);
            return messageHref;
        },
        getEvtReply: (msgId) => {
            return `party:${chatId}:messagereply:${msgId}`;
        },
        getEvtRemoveReply: (msgId) => {
            return `party:${chatId}:removemessagereply:${msgId}`;
        },
        getRepliesUrl: (msgId) => {
            return `/api/v1/party/${chatId}p/${msgId}/replies/`;
        },
        getRemoveMessageData: (msgId) => {
            // passed to '/realtime/'
            return {
                "event": "party_chat_message_remove",
                "party_public_id": chatId,
                "message_id": msgId,
            };
        },
        getChatInputMessageData: (message) => {
            // passed to '/realtime/'
            return {
                "event": "party_chat_message",
                "party_public_id": chatId,
                "message": message,
            };
        },
        chatDidMountExtra: (self) => {
            if (!(self.chatRoom && self.chatRoom.addExtraListener))
                return;
            // claim special event that is only on parties
            const evt_claim = `party:${self.props.chatId}:claim`;
            self.chatRoom.addExtraListener(data => {
                if (data.event === evt_claim) {
                    const msg = normalizeClaim(data);
                    self.setState((prevState, props) => ({
                        messages: prevState.messages.concat([msg]),
                    }));
                }
            });
        },
        chatMessagesFromData: (data) => {
            // massage message data returned from messagesUrl
            let allActivity = data['activity'].map(normalizeData).concat(
                data['claims'].map(normalizeClaim)
            );
            allActivity.sort(function (left, right) {
                return moment.utc(left.date).diff(moment.utc(right.date));
            });
            return allActivity;
        },
        atMentionNotification: true,
    };
};

const genChatroomChatType = (chatPublicId) => {
    return {
        channel: `private-chatroom-${chatPublicId}`,
        messagesUrl: `/api/v1/chatroom/${chatPublicId}p/recent_messages/`,
        usersUrl: `/api/v1/chatroom/${chatPublicId}p/users/`,
        evtMessage: `chatroom:${chatPublicId}:message`,
        evtRemoveMessage: null,
        getMessageHref: (msgId) => {
            let messageHref = `/messages/${chatPublicId}p/`;
            messageHref = updateQueryStringParameter(messageHref, 'focus_msg', msgId);
            return messageHref;
        },
        getEvtReply: (msgId) => {
            return `chatroom:${chatPublicId}:messagereply:${msgId}`;
        },
        getEvtRemoveReply: (msgId) => {
            return null;
        },
        getRepliesUrl: (msgId) => {
            return `/api/v1/chatroom/${chatPublicId}p/${msgId}/replies/`;
        },
        getRemoveMessageData: (msgId) => {
            // passed to '/realtime/'
            return {
                "event": "chatroom_chat_message_remove",
                "chatroom_public_id": chatPublicId,
                "message_id": msgId,
            };
        },
        getChatInputMessageData: (message) => {
            // passed to '/realtime/'
            return {
                "event": "chatroom_chat_message",
                "chatroom_public_id": chatPublicId,
                "message": message,
            };
        },
        chatMessagesFromData: (data) => {
            // massage message data returned from messagesUrl
            let allActivity = data['activity'].map(normalizeData);
            allActivity.sort(function (left, right) {
                return moment.utc(left.date).diff(moment.utc(right.date));
            });
            return allActivity;
        },
        chatDidMountExtra: (self) => {
            if (!self.chatRoom)
                return;
            // start the "presence" pulse
            const logPresence = () => {
                $.post(`/api/v1/chatroom/${chatPublicId}p/presence/`);
            };
            logPresence();
            self.presenceIntervalId = setInterval(logPresence, 20000);
        },
        chatUnmountExtra: (self) => {
            // stop the "presence" pulse
            clearInterval(self.presenceIntervalId);
        },
    };
};

export default {
    'party': genPartyChatType,
    'chatroom': genChatroomChatType,
};
