import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import { fetchJSON, useInterval } from './util.jsx';


export const InPartyStats = (props) => {
    const [viewerCount, setViewerCount] = useState(0);
    const [showFlashText, setShowFlashText] = useState(false);
    const [flashText, setFlashText] = useState(`Welcome to the ${str_data_party_words.party}`);

    useInterval(fetchStats, 15000);
    useEffect(() => {
        fetchStats();
        setShowFlashText(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchStats() {
        fetchJSON(`/api/v2/in_party_stats/${props.publicId}/`).then(data => {
            if (viewerCount !== 0 && data.viewers !== viewerCount) {
                animateLeavesJoins(data.viewers - viewerCount);
            }
            setViewerCount(data.viewers);
        });
    }

    function animateLeavesJoins(diff) {
        if (diff < 1) return;
        const noun = diff === 1 ? 'person' : 'people';
        setFlashText(`${diff} ${noun} joined the ${str_data_party_words.party.toLowerCase()}!`);
        setShowFlashText(true);
    }

    const fadeDuration = 2500;
    const transitionStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    };
    const defaultStyle = {
        transition: `opacity ${fadeDuration}ms ease-in-out`,
        opacity: 0,
    };

    if (props.isHidden) {return null;}
    return (
        <div>
          {viewerCount > 9 &&
           <div className="well">
             <div className="text-danger" style={{fontSize: "small"}}>
               🔥
               {' '}
               <em>This {str_data_party_words.party.toLowerCase()} is hot!</em>
               {' '}
               🔥
             </div>
             Current viewers:
             {' '}
             <h2 style={{display: 'inline'}}>
               {viewerCount}
             </h2>
           </div>
          }
          <Transition in={showFlashText}
                      timeout={fadeDuration}
                      onEntered={() => setShowFlashText(false)}>
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                }}>
                  {flashText}
                </div>
            )}
          </Transition>
        </div>
    );
};

InPartyStats.propTypes = {
    publicId: PropTypes.number.isRequired,
    isHidden: PropTypes.bool,
};

function InPartyStatsApp(el, publicId, isHidden) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InPartyStats publicId={publicId}
                              isHidden={!!isHidden} />);
}

export default InPartyStatsApp;
