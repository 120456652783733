import React from 'react';
import { createRoot } from 'react-dom/client';
import StrNextButton from './StrNextButton.jsx';


/**
 * el should have a data-item-id attribute.
 */
function NextButton(el, props) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrNextButton {...props} />);
}

export default NextButton;
