import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import MarkdownView from '../MarkdownView.jsx';
import { FollowButton } from './FollowButton.jsx';
import { SendDirectMessage } from '../SendDirectMessage.jsx';
import { fetchJSON } from  '../util.jsx';
import SocialMediaLinks from './SocialMediaLinks.jsx';
import UserProfileChange from './UserProfileChange.jsx';


class MiniProfile extends React.Component {
    constructor(props) {
        super(props);
        this.render_profile_body = this.render_profile_body.bind(this);
        this.render_profile_image = this.render_profile_image.bind(this);
        this.render_horizontal = this.render_horizontal.bind(this);
        this.isOwner = str_user_id === this.props.user.pk;
    }

    render_profile_body() {
        return (
            <React.Fragment>
              <h3 style={{marginTop: '3px'}}>
                <a href={`/u/${this.props.user.username}/`}>
                  {this.props.user.name}
                </a>
              </h3>
              <SendDirectMessage username={this.props.user.username} />
              <SocialMediaLinks user={this.props.user}
                                viewOnly={!this.props.includeLinksEdit}
                                iconSize='25px'
                                bs5={this.props.bs5} />
              {!this.props.hideOmniparty && this.props.user.omniparty &&
               <p>
                 <a href={this.props.user.omniparty.url}>
                   {this.props.user.omniparty.name}
                 </a>
               </p>
              }
              <FollowButton user={this.props.user}
                            onFollow={this.props.onFollow}
                            btnSize="btn-xs"
                            onUnfollow={this.props.onUnfollow} />
              {!this.props.hideBio &&
               <MarkdownView markdown={this.props.user.bio_markdown} />
              }
            </React.Fragment>
        );
    }

    render_profile_image() {
        const profilePhoto = this.props.user.profile_photo_medium;
        return (
            <React.Fragment>
              <img style={{maxWidth: '150px', maxHeight: '150px'}}
                   className="SocialProfileImage"
                   src={profilePhoto} />
              {this.props.includeProfilePictureChange && this.isOwner &&
               <UserProfileChange
                   profilePhoto={profilePhoto}
                   callback={this.props.fetchUser}
                   bs5={this.props.bs5}
                   minimal={this.props.horizontal} />
              }
            </React.Fragment>
        );
    }

    render_horizontal() {
        return (
            <div style={{
                display: "grid",
                gridTemplateColumns: "min-content",
            }}>
              <div style={{gridColumn: "1", paddingRight: "10px"}}>
                {this.render_profile_image()}
              </div>
              <div style={{gridColumn: "2"}}>
                {this.render_profile_body()}
              </div>
            </div>
        );
    }

    render() {
        if (this.props.horizontal)
            return this.render_horizontal();

        return (
            <div className={this.props.centered ? 'text-center' : ''}
                 style={{fontSize: "14px"}}>
              {this.render_profile_image()}
              {this.render_profile_body()}
            </div>
        );
    }
}

MiniProfile.defaultProps = {
    centered: true,
    horizontal: false,
    includeProfilePictureChange: false,
    includeLinksEdit: false,
    bs5: false,
};

MiniProfile.propTypes = {
    user: PropTypes.object.isRequired,
    onFollow: PropTypes.func,
    onUnfollow: PropTypes.func,
    hideBio: PropTypes.bool,
    hideOmniparty: PropTypes.bool,
    centered: PropTypes.bool,
    horizontal: PropTypes.bool,
    includeProfilePictureChange: PropTypes.bool,
    includeLinksEdit: PropTypes.bool,
    bs5: PropTypes.bool,
    fetchUser: PropTypes.func,
};

/* for those times when you just ain't got a user object */
class MiniProfileByPk extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };

        this.fetch = this.fetch.bind(this);
    }

    render() {
        if (!this.state.user)
            return <div style={{height: '250px'}} />;

        return <MiniProfile user={this.state.user}
                            onFollow={this.fetch}
                            hideBio={this.props.hideBio}
                            hideOmniparty={this.props.hideOmniparty}
                            centered={this.props.centered}
                            horizontal={this.props.horizontal}
                            onUnfollow={this.fetch}
                            includeProfilePictureChange={this.props.includeProfilePictureChange}
                            includeLinksEdit={this.props.includeLinksEdit}
                            bs5={this.props.bs5}
                            fetchUser={this.fetch} />;
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        const url = `/api/v2/public_users/${this.props.userPk}/`;
        fetchJSON(url).then(user => this.setState({user}));
    }
}

MiniProfileByPk.defaultProps = {
    centered: true,
    horizontal: false,
    includeProfilePictureChange: false,
    includeLinksEdit: false,
    bs5: false,
};

MiniProfileByPk.propTypes = {
    userPk: PropTypes.number.isRequired,
    hideBio: PropTypes.bool,
    hideOmniparty: PropTypes.bool,
    centered: PropTypes.bool,
    horizontal: PropTypes.bool,
    includeProfilePictureChange: PropTypes.bool,
    includeLinksEdit: PropTypes.bool,
    bs5: PropTypes.bool,
};

function MiniProfileApp(el, userPk, propOptions) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<MiniProfileByPk userPk={userPk}
                                     {...propOptions} />);
}

export { MiniProfile, MiniProfileApp, MiniProfileByPk };
