import React, { useState, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { includes } from 'lodash-es';
import StyleSizeMover from './StyleSizeMover.jsx';
import { AddItemChoice } from './AddItemChoice.jsx';
import { AddSizeChoice } from './AddSizeChoice.jsx';
import { ItemCategoryPicker } from './ItemCategoryPicker.jsx';
import { ItemCatTree, useItemCatTree } from './ItemCatTree.jsx';
import { strRequestJSON } from './util.jsx';

const ItemCategoryDisplayAndMaybeEdit = (props) => {
    const [showEditor, setShowEditor] = useState(false);
    const itemCatTree = useItemCatTree();

    const catPks = props.style.categories;
    const ncats = catPks.length;
    const pluralCats = ncats === 1 ? "category" : "categories";

    const onDone = (itemCats) => {
        setShowEditor(false);
        props.onDone(itemCats);
    };

    const itemCatPkPairs = useMemo(() => {
        if (!itemCatTree || !props.style)
            return [];
        return props.style
                    .categories
                    .map(catPk => [catPk, itemCatTree.getItemCategoryByPk(catPk)]);
    }, [itemCatTree, props.style]);

    if (str_user_id === 1)
        console.log("maybe deets", {itemCatPkPairs, props});

    return (
        <div>
          <div>
            Categories:
            <ul>
              {itemCatPkPairs.map(([catPk, itemCat]) =>
                  <React.Fragment key={catPk}>
                    {itemCat && (
                        <li key={itemCat.pk}>
                          {itemCat.fullpathStrings()}
                        </li>
                    )}
                    {itemCat === undefined && (
                        <li className="text-muted">Unknown category for {catPk}...</li>
                    )}
                  </React.Fragment>
              )}
            </ul>
            {props.isCustom &&
             <button type="button"
                     className="btn btn-link"
                     onClick={() => setShowEditor(true)}>Edit categories</button>
            }
          </div>
          {showEditor &&
           <ItemCategoryPicker
               onDone={onDone}
               multiSelect={true}
               initialSelection={catPks}
           />
          }
        </div>
    );
};

ItemCategoryDisplayAndMaybeEdit.propTypes = {
    onDone: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
    isCustom: PropTypes.bool.isRequired,
};

class StyleSizeManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            styles: [],
            sizes: [],
            stylesLoading: true,
            sizesLoading: true,
            catTree: null,
            styleDisplayOption: "all",
            sizeDisplayOption: "all",
        };

        this.loadStyles = this.loadStyles.bind(this);
        this.loadSizes = this.loadSizes.bind(this);
        this.onItemCatSelectDone = this.onItemCatSelectDone.bind(this);
        this.onStyleDisplayOptionChange = this.onStyleDisplayOptionChange.bind(this);
        this.onSizeDisplayOptionChange = this.onSizeDisplayOptionChange.bind(this);
    }

    render() {
        if (this.state.stylesLoading || this.state.sizesLoading) {
            return (
                <div>
                  <i className="fa fa-spinner fa-spin"></i>
                  {this.state.stylesLoading &&
                   <div>Loading {StrUserInfo.words.styles.toLowerCase()}...</div>
                  }
                  {this.state.sizesLoading &&
                   <div>Loading {StrUserInfo.words.sizes.toLowerCase()}...</div>
                  }
                </div>
            );
        }

        const companyStyles = this.state.styles.filter(s => s.source === 'company');
        const consultantStyles = this.state.styles.filter(s => s.source !== 'company');
        const companyStyleNames = companyStyles.map(s => s.name.toLowerCase());
        const dupeStyles = consultantStyles.filter(s => includes(companyStyleNames, s.name.toLowerCase()));
        const haveDupeStyles = dupeStyles.length > 0;

        const companySizes = this.state.sizes.filter(s => s.source === 'company');
        const consultantSizes = this.state.sizes.filter(s => s.source !== 'company');
        const companySizeNames = companySizes.map(s => s.name.toLowerCase());
        const dupeSizes = consultantSizes.filter(s => includes(companySizeNames, s.name.toLowerCase()));
        const haveDupeSizes = dupeSizes.length > 0;

        const stylesToDisplay = this.state.styleDisplayOption === "all"
                              ? this.state.styles
                              : consultantStyles;
        const sizesToDisplay = this.state.sizeDisplayOption === "all"
                             ? this.state.sizes
                             : consultantSizes;

        if (str_user_id === 1)
            console.log("Key deets", {
                companyStyles, consultantStyles, companyStyleNames,
                dupeStyles, haveDupeStyles, companySizes, consultantSizes,
                companySizeNames, dupeSizes, haveDupeSizes, stylesToDisplay,
                sizesToDisplay,
            });

        const dedupeStyles = (
            <div>
              {haveDupeStyles &&
               <div className="row">
                 <div className="col-md-6">
                   <div className="panel panel-warning">
                     <div className="panel-heading">Duplicate styles</div>
                     <div className="panel-body">
                       You've created the following custom styles which
                       have the same name as a company style default.
                       We recommend moving your items to the company style
                       and then deleting the custom style.
                       <ul>
                         {dupeStyles.map(style => {
                             return (
                                 <li key={style.pk}>
                                   <StyleSizeMover
                                       mode="style"
                                       thing={style}
                                       allThings={this.state.styles}
                                       onChange={this.loadStyles}
                                   />
                                 </li>
                             );
                         })}
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>
              ||
               <p className="text-muted text-small"><i className="fa fa-check text-success"></i>
                 {' '}
                 No duplicate {StrUserInfo.words.styles.toLowerCase()} detected.
               </p>
              }
            </div>
        );

        const dedupeSizes = (
            <div>
              {haveDupeSizes &&
               <div className="row">
                 <div className="col-md-6">
                   <div className="panel panel-warning">
                     <div className="panel-heading">Duplicate {StrUserInfo.words.sizes.toLowerCase()}</div>
                     <div className="panel-body">
                       You've created the following custom {StrUserInfo.words.sizes.toLowerCase()} which have the same name as a company {StrUserInfo.words.size.toLowerCase()} default.
                       We recommend moving your items to the company {StrUserInfo.words.size.toLowerCase()} and then deleting the custom {StrUserInfo.words.size.toLowerCase()}.
                       <ul>
                         {dupeSizes.map(size => {
                             return (
                                 <li key={size.pk}>
                                   <StyleSizeMover
                                       mode="size"
                                       thing={size}
                                       allThings={this.state.sizes}
                                       onChange={this.loadSizes}
                                   />
                                 </li>
                             );
                         })}
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>
              ||
               <p className="text-muted text-small"><i className="fa fa-check text-success"></i>
                 {' '}
                 No duplicate {StrUserInfo.words.sizes.toLowerCase()} detected.
               </p>
              }
            </div>
        );

        const stylemanager = (
            <div className="col-md-4">
              <h2>{StrUserInfo.words.styles}</h2>
              <div className="form-inline">
                Show:
                {' '}
                <div className="form-group">
                  <label className="radio-inline">
                    <input type="radio"
                           value="all"
                           checked={this.state.styleDisplayOption === "all"}
                           onChange={this.onStyleDisplayOptionChange}
                    />
                    All styles
                  </label>
                  <label className="radio-inline">
                    <input type="radio"
                           value="customs"
                           checked={this.state.styleDisplayOption === "customs"}
                           onChange={this.onStyleDisplayOptionChange}
                    />
                    Custom styles only
                  </label>
                </div>
              </div>
              <table className="table table-condensed">
                <tbody>
                  {stylesToDisplay.map(style => {
                      const isCustom = style.source !== 'company';
                      return (
                          <tr key={style.pk}>
                            <td>
                              <h3>{style.name}</h3>
                              {isCustom &&
                               <div><span className="label label-info">Custom</span></div>
                              }
                              <div>You have {style.num_in_inventory} in your inventory.</div>
                              <div>
                                <ItemCategoryDisplayAndMaybeEdit
                                    style={style}
                                    isCustom={isCustom}
                                    onDone={(itemCats) => this.onItemCatSelectDone(style.pk, itemCats)}
                                />
                              </div>
                            </td>
                            <td>
                              <StyleSizeMover
                                  mode="style"
                                  showSummary={false}
                                  thing={style}
                                  allThings={this.state.styles}
                                  onChange={this.loadStyles}
                              />
                            </td>
                          </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
        );

        const sizemanager = (
            <div className="col-md-4">
              <h2>{StrUserInfo.words.sizes}</h2>
              <div className="form-inline">
                Show:
                {' '}
                <div className="form-group">
                  <label className="radio-inline">
                    <input type="radio"
                           value="all"
                           checked={this.state.sizeDisplayOption === "all"}
                           onChange={this.onSizeDisplayOptionChange}
                    />
                    All sizes
                  </label>
                  <label className="radio-inline">
                    <input type="radio"
                           value="customs"
                           checked={this.state.sizeDisplayOption === "customs"}
                           onChange={this.onSizeDisplayOptionChange}
                    />
                    Custom sizes only
                  </label>
                </div>
              </div>
              <table className="table table-condensed">
                <tbody>
                  {sizesToDisplay.map(size => {
                      return (
                          <tr key={size.pk}>
                            <td>{size.name} ({size.num_in_inventory} in your inventory)</td>
                            <td>
                              <StyleSizeMover
                                  mode="size"
                                  showSummary={false}
                                  thing={size}
                                  allThings={this.state.sizes}
                                  onChange={this.loadSizes}
                              />
                            </td>
                          </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
        );

        return (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <AddItemChoice onAdd={this.loadStyles} />
                  {dedupeStyles}
                </div>
                <div className="col-md-6">
                  <AddSizeChoice onAdd={this.loadSizes} />
                  {dedupeSizes}
                </div>
              </div>
              <div className="row">
                {stylemanager}
                <div className="col-md-2"></div>
                {sizemanager}
              </div>
            </div>
        );
    }

    componentDidMount() {
        this.loadStyles();
        this.loadSizes();
    }

    loadStyles() {
        this.setState({stylesLoading: true});
        const self = this;
        $.getJSON('/api/v2/itemchoices_extra/', function(data) {
            self.setState({styles: data.results, stylesLoading: false});
        });
    }

    loadSizes() {
        this.setState({sizesLoading: true});
        const self = this;
        $.getJSON('/api/v2/sizes_extra/', function(data) {
            self.setState({sizes: data.results, sizesLoading: false});
        });
    }

    async onItemCatSelectDone(icPk, itemCats) {
        const url = `/api/v2/itemchoices/${icPk}/`;
        const data = {
            categories: itemCats.map(ic => ic.pk),
        };
        const json = await strRequestJSON(url, "PATCH", data);
        if (!json) {
            toast.error("Error saving");
        } else {
            this.loadStyles();
        }
    }

    onStyleDisplayOptionChange(e) {
        this.setState({styleDisplayOption: e.target.value});
    }

    onSizeDisplayOptionChange(e) {
        this.setState({sizeDisplayOption: e.target.value});
    }
}

StyleSizeManager.propTypes = {
};

function StyleSizeManagerApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StyleSizeManager />);
}

export default StyleSizeManagerApp;
