import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { updateStateThing, formatCurrency, clamp, getUUID, strRequestJSON, fetchJSON } from './util.jsx';
import StrBucks from './StrBucks.jsx';
import ShippingAddressForm from './ShippingAddressForm.jsx';
import ShippingCustomsForm, { checkCustomsItems } from './ShippingCustomsForm.jsx';
import ShippingNotes from './ShippingNotes.jsx';
import { ShippingQuotesV2, ShippingReturnQuotes } from './ShippingQuotesV2.jsx';
import StrTooltip from './StrTooltip.jsx';


const CUSTOMSSTATES = [
    'AA', 'AE', 'AP',  // armed forces
    'AS', 'GU', 'MP', 'PR', 'VI',  // us territories
];

const ShippingAddressInfo = ({name, street1, street2, city, state, zip, email, phone}) => (
    <div>
      <p>Ship to:</p>
      <small>
        {name}
        <br />
        {street1}
        <br />
        {street2 &&
         <span>{street2}<br /></span>
        }
        {city}, {state} {zip}
        {email &&
         <span style={{wordWrap: 'break-word'}}>
           <br />{email}
         </span>
        }
        {phone &&
         <span style={{wordWrap: 'break-word'}}>
           <br />{phone}
         </span>
        }
      </small>
    </div>
);

ShippingAddressInfo.propTypes = {
    name: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
};


const OverrideLabelWarning = ({onOverrideCancel}) => (
    <div style={{marginTop: "20px"}}>
      <div className="alert alert-danger" role="alert">
        This order already has a label, if you really know what you are
        doing you can purchase a new label. If you purchase a new label
        the existing one will be deleted! You should probably go back
        and refund the existing label first.
      </div>
      <button className="btn btn-link"
              onClick={onOverrideCancel}>
        Oops, take me back to my existing label
      </button>
    </div>
);

OverrideLabelWarning.propTypes = {
    onOverrideCancel: PropTypes.func,
};

function BottomActions(props) {
    const [showShippingHelp, setShowShippingHelp] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    return <div className="row">
      <div className="col-md-12">
        <button type="button"
                className="btn btn-link pull-right"
                onClick={() => setShowShippingHelp(!showShippingHelp)}>
          <p className="text-muted text-small">
            Need help with shipping supplies?
          </p>
        </button>
        {props.orderPk &&
         <button type="button"
                 className="btn btn-link pull-right"
                 onClick={() => setShowNotes(!showNotes)}>
           <p className="text-muted text-small">
             {showNotes && 'Hide' || 'Show'} order notes
           </p>
         </button>
        }
        <div className="clearfix"></div>
        {showShippingHelp &&
         <div className="panel panel-default">
           <ul className="list-group">
             <li className="list-group-item">
               <a href="https://shoptheroe.freshdesk.com/support/solutions/articles/16000076914-packaging-for-priority-mail"
                  target="_blank">
                 Packaging for priority mail
               </a>
             </li>
           </ul>
         </div>
        }
        {showNotes && <ShippingNotes orderPk={props.orderPk} />}
      </div>
    </div>;
}

BottomActions.propTypes = {
    orderPk: PropTypes.number,
};

const ShippingOptions = ({signature, onChange, hasInsurance, insuranceValue,
                             onInsuranceChange, insuranceFee}) => {
    return (
        <div>
          <p>Options:</p>
          <ul className="list-unstyled">
            <li>
              <input type="checkbox"
                     name="signature"
                     checked={signature}
                     onChange={onChange}
              />
              {' '}
              <i>Request signature</i>
            </li>
            <li>
              <div>
                <input type="checkbox"
                       name="hasInsurance"
                       checked={hasInsurance}
                       onChange={onChange}
                />
                {' '}
                <i>Add insurance</i>
              </div>
              {hasInsurance &&
               <div>${' '}
                 <input className="form-control"
                        style={{display: "inline", width: "100px"}}
                        type="number"
                        name="insuranceValue"
                        value={insuranceValue}
                        onChange={onInsuranceChange}
                        onFocus={(e) => e.currentTarget.select()}
                        placeholder="Package value"
                 />
                 <div><i>Estimated cost: {formatCurrency(insuranceFee)} *non-refundable</i></div>
               </div>
              }
            </li>
            <li>
              <i>
                Paper Size
                {' '}
                <StrTooltip message="4&quot; x 6&quot; is great for Dymo printers, 8.5&quot; x 11&quot; centers the label so it gets more margin">
                  <i className="fa fa-info-circle fa-1x"></i>
                </StrTooltip>
              </i>
              <select className="form-control"
                      name="labelSize"
                      onChange={onChange}>
                <option value="4x6">4" x 6"</option>
                <option value="8.5x11">8.5" x 11"</option>
              </select>
            </li>
          </ul>
        </div>
    );
};

ShippingOptions.propTypes = {
    signature: PropTypes.bool,
    onChange: PropTypes.func,
    hasInsurance: PropTypes.bool,
    insuranceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onInsuranceChange: PropTypes.func,
    insuranceFee: PropTypes.number,
};


export class ShippingLabels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labelOverride: false,
            loading: false,
            shippingTracker: props.data.shippingTracker,
            shippingPdf: props.data.shippingPdf,
            name: props.data.name || '',
            street1: props.data.street1,
            street2: props.data.street2,
            city: props.data.city,
            state: props.data.state,
            zip: props.data.zip,
            phone: props.data.phone,
            addressFormFilled: !props.data.labelOnly,
            labelPk: props.data.labelPk,
            validAddress: false,
            addressChecked: false,
            errorMessage: null,
            email: props.data.email || '',
            signature: false,
            hasInsurance: false,
            insuranceValue: 0,
            isCustomSize: false,
            weightMessage: null,
            weightOz: '',
            height: '',
            width: '',
            length: '',
            customsItems: props.data.customsItems || [],
            labelSize: '4x6',
            showParcelQuote: false,
            showRefundConfirm: false,
            didRefund: false,
            showReturnLabel: false,
            returnLabelInfo: null,
        };
        this.setLabel = this.setLabel.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.clickedNextBtn = this.clickedNextBtn.bind(this);
        this.clickedProceedBtn = this.clickedProceedBtn.bind(this);
        this.checkAddress = this.checkAddress.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.onInsuranceChange = this.onInsuranceChange.bind(this);
        this.onNumberBlur = clamp.bind(this);
        this.saveCustomSize = this.saveCustomSize.bind(this);
        this.setEntireAddress = this.setEntireAddress.bind(this);
        this.setCustomParcel = this.setCustomParcel.bind(this);
        this.onRefundClick = this.onRefundClick.bind(this);
        this.setReturnLabel = this.setReturnLabel.bind(this);
    }

    checkAddress() {
        this.setState({
            validAddress: false,
            loading: true,
            errorMessage: null,
            addressChecked: false,
        });
        const data = {
            address: JSON.stringify({
                street1: this.state.street1,
                street2: this.state.street2,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
            }),
        };
        const self = this;
        const requiredFields = {
            street1: 'an address',
            city: 'a city',
            state: 'a state',
            zip: 'a zipcode',
        };
        for (let field of Object.keys(requiredFields)) {
            if (!this.state[field]) {
                return self.setState({
                    loading: false,
                    errorMessage: `Please enter ${requiredFields[field]}.`,
                });
            }
        }
        const url = '/api/v2/shipping_check_address/?' + $.param(data);
        $.getJSON(url, function(rsp) {
            self.setState({
                validAddress: rsp.valid_address,
                loading: false,
                errorMessage: !rsp.valid_address
                           && "Address could not be verified. Proceed at your own risk.",
                addressChecked: true,
            });
        });
    }

    componentDidMount() {
        const { returnLabelPk } = this.props.data;
        if (returnLabelPk) {
            const doFetchReturnLabel = async () => {
                const json = await fetchJSON(`/api/v2/shipping_label_info/${returnLabelPk}/`);
                if (json === null)
                    return;
                this.setState({
                    returnLabelInfo: {
                        trackerUrl: json.tracker_url,
                        pdfUrl: json.label_pdf_url,
                        labelPk: json.pk,
                    },
                });
            };
            doFetchReturnLabel();
        }
        if (!this.props.data.labelPk
            && this.state.street1
            && this.state.city
            && this.state.state
            && this.state.zip)
            this.checkAddress();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.orderPk !== prevProps.data.orderPk) {
            throw "The ShippingLabels component cannot be re-used across orders";
        }
    }

    setLoading(loading) {
        this.setState({loading: loading});
    }

    setErrorMessage(msg) {
        this.setState({
            errorMessage: msg,
            loading: false,
        });
    }

    setLabel(shippingTracker, shippingPdf, labelPk) {
        this.setState({
            shippingTracker: shippingTracker,
            shippingPdf: shippingPdf,
            labelPk: labelPk,
            labelOverride: false,
            loading: false,
            errorMessage: null,
        }, this.props.data.setLabel(shippingTracker, shippingPdf));
    }

    chgStateValue(stateVar, e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[stateVar]: value});
    }

    clickedNextBtn() {
        this.checkAddress();
        this.setState({addressFormFilled: true});
    }

    clickedProceedBtn() {
        this.setState({
            validAddress: true,
            errorMessage: null,
        });
    }

    hasToAddress() {
        const {street1, city, state, zip} = this.state;
        const haveRequiredInfo = street1 && city && state && zip;
        return haveRequiredInfo
            && (this.state.addressFormFilled || this.props.data.labelOnly)
            && this.state.validAddress;
    }

    getSaleEditLink() {
        const {labelOnly, groupType, orderPk} = this.props.data;
        if (!labelOnly && !this.hasToAddress() && this.getItems()) {
            return `/inventory/claim_customer_update/${orderPk}/?next=/sales/`;
        }
        return null;
    }

    getItems() {
        const siteItems = is_str ? this.props.data.items
                        : this.props.data.claimRecords.map(cr => {
                            return cr.item;
                        });
        if (this.props.data.labelOnly
            && this.state.customsItems.length > 0) {
            return this.state.customsItems.map(item => {
                return <p key={item.key}>
                      {item.description} - {formatCurrency(item.value)}
                </p>;
            });
        }
        return siteItems && siteItems.map(item => {
            return (
                <img key={item.pk}
                     style={{maxWidth: '75px', maxHeight: '75px'}}
                     src={item.image.image_thumbnail} />
            );
        });
    }

    onInsuranceChange(e) {
        // Only allow insuring $0 - $5k
        const value =  e.target.value === '' ? '' :
            Math.max(Math.min(Number(e.target.value), 5000), 0);
        this.setState({
            insuranceValue: value,
        });
    }

    saveCustomSize() {
        this.setState((prevState) => {
            const {height, width, length} = prevState;
            return {customSize: {height, width, length}};
        });
    }

    preexistingLabelTray() {
        return (
            <div className="row">
              <div className="col-md-12">
                <p style={{marginTop: '15px'}}>
                  Label purchased.
                </p>
                <div className="btn-group-vertical"
                     role="group"
                     style={{marginTop: '15px', marginBottom: '15px'}}>
                  <a href={this.state.shippingPdf}
                     className="btn btn-default"
                     target="_blank">
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Label PDF
                  </a>
                  {this.state.shippingTracker &&
                   <a href={this.state.shippingTracker}
                      className="btn btn-default"
                      target="_blank">
                     <i className="fa fa-truck" aria-hidden="true"></i> Label Tracking
                   </a>
                  || <a href="#"
                        className="btn btn-default"
                        disabled>
                    <i className="fa fa-truck text-muted" aria-hidden="true"></i> Tracking info pending...
                  </a>
                  }
                  <button className="btn btn-default"
                          onClick={() => this.setState({showRefundConfirm: !this.state.showRefundConfirm})}>
                    <i className="fa fa-usd" aria-hidden="true"></i> Refund Label
                  </button>
                  {this.state.returnLabelInfo &&
                   <React.Fragment>
                     <a href={this.state.returnLabelInfo.pdfUrl}
                        className="btn btn-default"
                        target="_blank">
                       <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Return Label PDF
                     </a>
                     <a href={this.state.returnLabelInfo.trackerUrl}
                        className="btn btn-default"
                        target="_blank">
                       <i className="fa fa-truck" aria-hidden="true"></i> Return Label Tracking
                     </a>
                   </React.Fragment>
                  }
                  {!!this.state.returnLabelInfo ||
                   <button className="btn btn-default"
                           onClick={() => this.setState({showReturnLabel: !this.state.showReturnLabel})}>
                     <i className="fa fa-reply-all" aria-hidden="true"></i> Buy Return Label
                   </button>
                  }
                </div>
                <button className="btn btn-link"
                        onClick={() => {
                            this.setState(
                                {labelOverride: true},
                                this.checkAddress
                            );
                        }}>
                  Show me the label quotes anyway
                </button>
              </div>
            </div>
        );
    }

    setCustomParcel(weightOz, height, width, length) {
        this.setState({
            weightOz: weightOz,
            height: height,
            width: width,
            length: length,
        }, this.saveCustomSize);
    }

    renderShippingQuotesV2(insuranceFee, requireCustoms) {
        const props = {
            orderData: this.props.data,
            setLabel: this.setLabel,
            name: this.state.name,
            email: this.state.email,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            needExtraConfirm: !!(this.state.shippingPdf),
            signature: this.state.signature,
            insuranceFee: this.state.hasInsurance && insuranceFee || 0,
            insuranceCoverage: this.state.hasInsurance && this.state.insuranceValue || 0,
            customsItems: requireCustoms ? this.state.customsItems : [],
            labelSize: this.state.labelSize,
        };

        return <ShippingQuotesV2 {...props} />;
    }

    setEntireAddress(customer_name, email, address1,
                     address2, city, state, zipp) {
        this.setState({
            name: customer_name,
            street1: address1,
            street2: address2,
            city: city,
            state: state,
            zip: zipp,
            email: email,
        });
    }

    onRefundClick(e) {
        const doRefund = async () => {
            this.setState({loading: true});
            const self = this;
            const data = {
                labelPk: this.state.labelPk,
            };
            const url = '/api/v2/shipping_labels/';
            const json = await strRequestJSON(url, "PATCH", data);
            if (json === null)
                return;
            if (json.refunded) {
                this.setState({loading: false, didRefund: true, showRefundConfirm: false});
            } else {
                this.setState({loading: false, errorMessage: json.message});
            }
        };
        doRefund();
    }

    setReturnLabel(trackerUrl, pdfUrl, labelPk) {
        this.setState({returnLabelInfo: {trackerUrl, pdfUrl, labelPk}});
    }

    render() {
        const { isModal } = this.props;
        const haveLabel = !!(this.state.shippingPdf);
        const proceedToQuotes = !haveLabel || this.state.labelOverride;
        const items = this.getItems();
        const hasToAddress = this.hasToAddress();
        const {name, email, street1, street2, city, state, zip, phone} = this.state;
        const needsAddress = this.props.data.labelOnly && !hasToAddress;
        const requireCustoms = CUSTOMSSTATES.indexOf(state) > -1;
        const customsOk = !requireCustoms || checkCustomsItems(this.state.customsItems);
        // Any value greater than $0 carries a minimum of $1 fee.
        // Calculations based on: https://www.easypost.com/docs/api#insurance
        const insuranceFee = !this.state.insuranceValue ? 0 :
            Math.max(Math.ceil(this.state.insuranceValue) / 100, 1);
        const isForOrder = this.props.data.orderPk && !this.props.data.orderPkIsALie;
        return (
            <div className="modal-content">
              {isModal === true &&
               <div className="modal-header">
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                 <h2 className="modal-title">
                   {!haveLabel && 'Create '}Shipping Label
                 </h2>
               </div>
              }
              <div className="modal-body">
                <div className="row">
                  {needsAddress &&
                   <ShippingAddressForm {...{name, email, street1, street2, city, state, zip}}
                                        onValueChange={this.updateStateThing}
                                        onNextClick={this.clickedNextBtn}
                                        setEntireAddress={this.setEntireAddress}
                                        disabled={this.state.loading} />
                  }
                  <div className="col-md-4">
                    {hasToAddress && StrUserInfo.has_address &&
                     <ShippingAddressInfo {...{name, street1, street2, city, state, zip, email, phone}} />
                    }
                    {!this.state.errorMessage && !this.props.data.labelOnly
                    && items && !hasToAddress && !this.state.loading && proceedToQuotes &&
                     <div>
                       <p>
                         Please provide a valid address for <a href={this.getSaleEditLink()}>
                         this customer.
                         </a>
                       </p>
                     </div>
                    }
                    {!StrUserInfo.has_address &&
                     <div>
                       <p>
                         Please set your address in <a href="/profile/">
                         your profile.
                         </a>
                       </p>
                     </div>
                    }
                    {this.state.loading &&
                     <div>
                       <p>Checking address...</p>
                     </div>
                    }
                    {!proceedToQuotes && !this.state.didRefund &&
                     this.preexistingLabelTray()
                    }
                    {this.state.showRefundConfirm &&
                     <div>
                       <p className="text-muted">Are you sure you want to refund this label?</p>
                       <button className="btn btn-primary btn-lg"
                               onClick={this.onRefundClick}>
                         Refund Label
                       </button>
                     </div>
                    }
                    {this.state.didRefund &&
                     <div className="alert alert-info">
                       This label has been refunded. Please <a href="#"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   window.location.reload();
                                                               }}>refresh your page</a> to continue.
                     </div>
                    }
                  </div>
                  <div className="col-md-3">
                    {hasToAddress && customsOk && StrUserInfo.has_address && proceedToQuotes &&
                     <ShippingOptions signature={this.state.signature}
                                      onChange={this.updateStateThing}
                                      hasInsurance={this.state.hasInsurance}
                                      insuranceValue={this.state.insuranceValue}
                                      onInsuranceChange={this.onInsuranceChange}
                                      insuranceFee={insuranceFee}
                     />
                    }
                  </div>
                  <div className="col-md-5">
                    {customsOk && items}
                    {this.props.data.labelOnly && hasToAddress && customsOk &&
                     <div>
                       Not correlated to a {is_str ? "Sonlet" : "Pop"} sale.
                     </div>
                    }
                    {isForOrder &&
                     <div className="text-muted text-small">Order #{this.props.data.orderPk}</div>}
                  </div>
                  {hasToAddress && !customsOk && proceedToQuotes &&
                   <ShippingCustomsForm
                       updateCustomsItems={(customsItems) =>
                           this.setState({customsItems: customsItems})}
                       customsItems={this.state.customsItems} />
                  }
                </div>
                {this.state.loading &&
                 <div>
                   <i className="fa fa-spinner fa-spin"></i> Loading
                 </div>
                }
                {this.state.errorMessage &&
                 <div className="alert alert-danger" role="alert">
                   {this.state.errorMessage}
                 </div>
                }
                {this.state.addressChecked && !this.state.validAddress &&
                 <span>
                   <button type="button"
                           className="btn btn-info"
                           onClick={this.clickedProceedBtn}>
                     Proceed Anyway
                   </button>
                 </span>
                }
                {proceedToQuotes && haveLabel && customsOk &&
                 <OverrideLabelWarning onOverrideCancel={() => {this.setState({labelOverride:false});}} />
                }
                {this.state.showReturnLabel &&
                 <div className="row">
                   <div className="col-md-12">
                     <ShippingReturnQuotes originalLabelPk={this.state.labelPk}
                                           setLabel={this.setReturnLabel} />
                   </div>
                 </div>
                }
                {
                    proceedToQuotes && hasToAddress && customsOk && StrUserInfo.has_address &&
                    <div style={{marginTop: "15px"}}>
                      <div className="row">
                        <div className="col-md-12" style={{marginTop: '15px'}}>
                          {is_str ? "Sonlet" : "Pop"} Bucks balance: <StrBucks />
                        </div>
                      </div>
                      {this.renderShippingQuotesV2(insuranceFee, requireCustoms)}
                    </div>
                }
                <BottomActions orderPk={isForOrder
                                      ? this.props.data.orderPk : null} />
              </div>
            </div>
        );
    }
}

ShippingLabels.defaultProps = {
    isModal: true,
};

ShippingLabels.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        phone: PropTypes.string,
        labelOnly: PropTypes.bool,
        items: PropTypes.array,
        claimRecords: PropTypes.array,
        orderPk: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        orderPkIsALie: PropTypes.bool,
        groupType: PropTypes.string,
        shippingPdf: PropTypes.string,
        shippingTracker: PropTypes.string,
        labelPk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setLabel: PropTypes.func.isRequired,
        blessOrderNo: PropTypes.string,
        customsItems: PropTypes.array,
        returnLabelPk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    isModal: PropTypes.bool,
};

let root = null;

export default function ShippingLabelsApp(el, data) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<ShippingLabels data={data} />);
}
