import React from 'react';
import { createRoot } from "react-dom/client";
import SocialTimeline from './SocialTimeline.jsx';

function SocialTimelineApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SocialTimeline />);
}

export default SocialTimelineApp;
