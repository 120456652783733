import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { BulkAdd } from './bulk_add_app.jsx';

function AddInventory(props) {
    const [ inventoryToolKey, setInventoryToolKey ] = useState('bulk');

    function handleTabSelect(key) {
        if (key === "single") {
            window.location.href = "/inventory/add/";
            return;
        }
        setInventoryToolKey(key);
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const url = params.next || '/inventory/';
    const urlNames = {
        '/inventory/': 'Inventory',
        '/dashboard/': 'Dashboard',
        '/listings/': 'Listings',
    };
    const urlName = urlNames[url];

    return (
        <div>
          <h3>Add Inventory</h3>
          <div className="row">
            <div className="col-lg-12">
              {/* Tab navigation */}
              <ul className="nav nav-tabs">
                <li className={inventoryToolKey === 'single' ? 'active' : ''}>
                  <a onClick={() => handleTabSelect('single')}>Single Upload</a>
                </li>
                <li className={inventoryToolKey === 'bulk' ? 'active' : ''}>
                  <a onClick={() => handleTabSelect('bulk')}>Bulk Upload</a>
                </li>
              </ul>

              {/* Tab panes */}
              <div className="tab-content">
                <div className={`tab-pane ${inventoryToolKey === 'bulk' ? 'active' : ''}`}>
                  {inventoryToolKey === 'bulk' && <BulkAdd />}
                </div>
              </div>
            </div>
          </div>
          {urlName &&
           <div>
             <hr />
             <a className="btn btn-default" href={url}>
               <span className="glyphicon glyphicon-menu-left"></span> {urlName}
             </a>
           </div>
          }
        </div>
    );
}

export default function AddInventoryApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AddInventory />);
}
