import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ItemCatTree } from './ItemCatTree.jsx';
import { TreePicker, TreePickerItemPropType } from './TreePicker.jsx';

const catXform = (cat) => ({
    key: cat.pk,
    name: cat.name,
    childItems: cat.children.map(catXform),
});

const FilterTree = (props) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="filter-tree-container">
          <div>
            <h4 onClick={toggleExpanded}>
              <span style={{ float: 'left' }}>{props.header}</span>
              <i style={{ float: 'right' }}
                 className={`fa fa-chevron-${expanded ? 'up' : 'down'}`}
                 aria-hidden="true"></i>
              <div style={{ clear: 'both' }}></div>
            </h4>
            {expanded &&
             <TreePicker
                 items={props.items}
                 onSelectionChange={props.onSelectionChange}
                 multiSelect={true}
             />
            }
          </div>
        </div>
    );
};

FilterTree.propTypes = {
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(TreePickerItemPropType).isRequired,
    // Receives an array of item keys
    onSelectionChange: PropTypes.func.isRequired,
};

export default function PartyDetailsFiltersV2(props) {
    const [searchQuery, setSearchQuery] = useState("");

    const catItems = useMemo(() => {
        if (!props.itemCatTree)
            return null;
        return props.itemCatTree.catTree.map(catXform);
    }, [props.itemCatTree]);

    const sizeItems = useMemo(() => {
        return props.sizeInfo.map(size => ({ key: size.pk, name: size.name }));
    }, [props.sizeInfo]);

    const styleItems = useMemo(() => {
        return props.styleInfo.map(style => ({ key: style.pk, name: style.name }));
    }, [props.styleInfo]);

    const onSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        props.onSearchChange(query);
    };

    if (!catItems)
        return null;

    return (
        <div>
          <div className="pdeets-filters-container">
            <div className="filter-tree-container">
              <input className="form-control input-lg"
                     style={{border: "none"}}
                     placeholder="Search albums by name..."
                     value={searchQuery}
                     onChange={onSearchChange} />
            </div>

            <FilterTree
                header="Category"
                items={catItems}
                onSelectionChange={props.onItemCategorySelectionChange}
            />

            <FilterTree
                header="Size"
                items={sizeItems}
                onSelectionChange={props.onSizeSelectionChange}
            />

            <FilterTree
                header="Style"
                items={styleItems}
                onSelectionChange={props.onStyleSelectionChange}
            />
          </div>
        </div>
    );
}

PartyDetailsFiltersV2.propTypes = {
    sizeInfo: PropTypes.arrayOf(PropTypes.shape({
        "pk": PropTypes.number.isRequired,
        "name": PropTypes.string.isRequired,
    })).isRequired,
    styleInfo: PropTypes.arrayOf(PropTypes.shape({
        "pk": PropTypes.number.isRequired,
        "name": PropTypes.string.isRequired,
    })).isRequired,
    itemCatTree: PropTypes.instanceOf(ItemCatTree),
    // Called with query
    onSearchChange: PropTypes.func.isRequired,
    // Called with [stylePk1, ...]
    onStyleSelectionChange: PropTypes.func.isRequired,
    // Called with [sizePk1, ...]
    onSizeSelectionChange: PropTypes.func.isRequired,
    // Called with [itemCategoryPk1, ...]
    onItemCategorySelectionChange: PropTypes.func.isRequired,
};
