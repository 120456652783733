import React from 'react';
import { func, string, bool } from 'prop-types';
import { isEmail } from 'validator';

import ShippingAddressPicker from './ShippingAddressPicker.jsx';


const AddressFormInput = ({name, value, label, onChange, disabled, type = "text"}) => {
    return (
        <div className="form-group">
          <label htmlFor={name} className="col-md-4 control-label">{label}</label>
          <div className="col-md-8">
            <div className="input-group">
              <input
                  autoFocus
                  type={type}
                  className="form-control"
                  name={name}
                  value={value || ''}
                  onChange={onChange}
                  disabled={disabled}
              />
            </div>
          </div>
        </div>
    );
};

AddressFormInput.propTypes = {
    name: string,
    value: string,
    label: string,
    onChange: func,
    disabled: bool,
    type: string,
};

const ShippingAddressForm = ({
    name, email, street1, street2, city, state, zip,
    onValueChange, onNextClick, setEntireAddress, disabled}) => {return(
        <div>
          <div className="col-md-6">
            <div className="form-horizontal">
              <h3>Enter To Address:</h3>
              <AddressFormInput name='name' value={name}
                                disabled={disabled} label='Name'
                                onChange={onValueChange} />
              <AddressFormInput name='email' value={email}
                                disabled={disabled} label='Email'
                                type='email'
                                onChange={onValueChange} />
              {email && !isEmail(email) &&
               <div className="alert alert-warning" role="alert">
                 Warning, email address is not valid. You can
                 continue but shipping status emails may not be
                 received.
               </div>
              }
              <AddressFormInput name='street1' value={street1}
                                disabled={disabled} label='Street 1'
                                onChange={onValueChange} />
              <AddressFormInput name='street2' value={street2}
                                disabled={disabled} label='Street 2'
                                onChange={onValueChange} />
              <AddressFormInput name='city' value={city}
                                disabled={disabled} label='City'
                                onChange={onValueChange} />
              <AddressFormInput name='state' value={state}
                                disabled={disabled} label='State'
                                onChange={onValueChange} />
              <AddressFormInput name='zip' value={zip}
                                disabled={disabled} label='Zip'
                                onChange={onValueChange} />
              <div className="form-group">
                <div className="col-md-offset-4 col-md-8">
                  <button
                      type="button"
                      className="btn btn-primary"
                      disabled={disabled}
                      onClick={onNextClick}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{maxHeight: '500px', overflowY: 'auto'}}>
            <ShippingAddressPicker onPick={setEntireAddress}
                                   disabled={disabled} />
          </div>
        </div>
    );
};

ShippingAddressForm.propTypes = {
    name: string,
    email: string,
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
    onValueChange: func,
    onNextClick: func,
    setEntireAddress: func,
    disabled: bool,
};

export default ShippingAddressForm;
