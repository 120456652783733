import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import StrChat from './StrChat.jsx';
import { InPartyStats } from './in_party_stats_app.jsx';
import { SocialPartyFeed } from './social/social_party_feed_app.jsx';
import { StrPartyAlbumSet } from './StrPartyAlbum.jsx';
import AttrGroupedAlbums from './AttrGroupedAlbums.jsx';
import PartyDetailsFiltersV2 from './PartyDetailsFiltersV2.jsx';
import { ItemCatTree } from './ItemCatTree.jsx';
import { fetchJSON } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

export function PartyChatApp(el, refCb, inModal, party_public_id, bs5) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrChat ref={refCb}
                         inModal={inModal}
                         chatTypeKey="party"
                         chatId={party_public_id}
                         bs5={bs5} />);
}

function PartyDetailsContent(props) {
    const albumChunkSize = isMobile ? 48 : 256;
    const { party, allAlbums,
            styleToItemCategoryInfo, sizeInfo, styleInfo,
            albumGroups, otherAlbums } = props;
    const [filteredAllAlbums, setFilteredAllAlbums] = useState([]);
    const [selectedStylePks, setSelectedStylePks] = useState([]);
    const [selectedSizePks, setSelectedSizePks] = useState([]);
    const [selectedItemCategoryPks, setSelectedItemCategoryPks] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemCatTree, setItemCatTree] = useState(null);
    const [starredGroupPrefixes, setStarredGroupPrefixes] = useState(null);
    const [scrollPending, setScrollPending] = useState(false);
    const [explicitlyVisibleAlbums, setExplicitlyVisibleAlbums] = useState(new Set());
    const chatRef = useRef(null);

    const scrollToChat = () => {
        setScrollPending(true);
    };

    const addToVisibleAlbums = (name) => {
        setExplicitlyVisibleAlbums(albs => {
            if (albs.has(name))
                return albs;
            const newVis = new Set(albs);
            newVis.add(name);
            return newVis;
        });
    };

    const isExplicitlyVisible = (name) => explicitlyVisibleAlbums.has(name);

    useEffect(() => {
        if (scrollPending) {
            chatRef.current?.scrollIntoView({ behavior: "smooth" });
            setScrollPending(false);
        }
    }, [scrollPending]);

    useEffect(() => {
        if (!styleToItemCategoryInfo)
            return;
        const doInit = async () => {
            const tree = await ItemCatTree.create();
            const allCatPks = new Set();
            for (const styleToCatInfo of styleToItemCategoryInfo) {
                for (const catPk of styleToCatInfo.category_pks) {
                    allCatPks.add(catPk);
                }
            }
            tree.prune(allCatPks);
            setItemCatTree(tree);
        };
        doInit();
    }, [styleToItemCategoryInfo]);

    const catPkToStylePksMap = useMemo(() => {
        if (itemCatTree === null)
            return null;
        const map = new Map(); // maps cat pk to Set of style pks
        for (const styleToCat of styleToItemCategoryInfo) {
            const stylePk = styleToCat.itemchoice_pk;
            // a style belongs to the given cats and their ancestors
            const catPks = itemCatTree.getAncestorCatPksForCatPks(styleToCat.category_pks);
            for (const catPk of catPks) {
                const spks = map.get(catPk) || new Set();
                spks.add(stylePk);
                map.set(catPk, spks);
            }
        }
        return map;
    }, [styleToItemCategoryInfo, itemCatTree]);

    const stylePkToStyleInfo = useMemo(() => {
        if (!styleInfo)
            return null;
        const map = new Map(); // maps style pk to style info object for quicker lookups
        for (const sinfo of styleInfo) {
            map.set(sinfo.pk, sinfo);
        }
        return map;
    }, [styleInfo]);

    const sortAlbumGroups = useCallback((albumGroups) => {
        // Pretty savage, but we want to avoid the initial flicker between
        // albums loading the and starred groups loading.
        if (!starredGroupPrefixes)
            return [];

        // Make [name, albums] into an object augmented with favie info
        return albumGroups
            .map(([name, albums]) => {
                return {
                    name,
                    albums,
                    isFavoriteGroup: albums.length > 1 && starredGroupPrefixes.has(name),
                };
            })
            .sort((a, b) => b.isFavoriteGroup - a.isFavoriteGroup);
    }, [starredGroupPrefixes]);

    const onSearchChange = (query) => {
        setSearchQuery(query);
    };

    const onStyleSelectionChange = (stylePks) => {
        setSelectedStylePks(stylePks);
    };

    const onSizeSelectionChange = (sizePks) => {
        setSelectedSizePks(sizePks);
    };

    const onItemCategorySelectionChange = (itemCategoryPks) => {
        setSelectedItemCategoryPks(itemCategoryPks);
    };

    /* Like Set.has(), but also returns true if set is empty. */
    const setHasIfNonEmpty = (s, search) => s.size === 0 || s.has(search);

    const filterAlbumsV2 = useCallback((albums) => {
        if (itemCatTree === null || catPkToStylePksMap === null)
            return albums;

        const albumPksToKeepViaSize = new Set();
        const albumPksToKeepViaStyle = new Set();
        const filteredSizeNames = new Set();
        const filteredSizePks = new Set();
        for (const sizePk of selectedSizePks) {
            const size = sizeInfo.find(size => size.pk === sizePk);
            if (!size) // should always be there... but just in case...
                continue;
            for (const albumPk of size.albumPks)
                albumPksToKeepViaSize.add(albumPk);
            filteredSizeNames.add(size.name);
            filteredSizePks.add(size.pk);
        }

        // Add descendant cats to selected cats
        const selectedCatPksWithDescendants =
            itemCatTree.getDescendantCatPksForCatPks(new Set(selectedItemCategoryPks));

        let selectedStylePksViaCats = new Set();

        // selected cats first get turned into selected styles
        // (since we don't have category info for albums, only style info,
        // and categories can be mapped to sets of styles without any
        // album knowledge (they're directly related))
        for (const catPk of selectedCatPksWithDescendants) {
            const spks = catPkToStylePksMap.get(catPk);
            selectedStylePksViaCats = new Set([...selectedStylePksViaCats, ...spks]);
        }

        // wordy name for the sake of self-documenting wordiness
        const selectedStylePksViaSelectedStylesAndCats = new Set([
            ...selectedStylePksViaCats,
            ...selectedStylePks,
        ]);

        for (const stylePk of selectedStylePksViaSelectedStylesAndCats) {
            const style = stylePkToStyleInfo.get(stylePk);
            if (!style) // should always be there, but just in case...
                continue;
            for (const albumPkToSizePks of style.albumPksToSizePks) {
                const albumPk = albumPkToSizePks.albumPk;
                const sizePks = albumPkToSizePks.sizePks;
                if (albumPksToKeepViaSize.size > 0 && !albumPksToKeepViaSize.has(albumPk)) {
                    // album has already been filtered out (via size)
                    continue;
                }
                let found = false;
                for (const sizePk of sizePks) {
                    if (setHasIfNonEmpty(filteredSizePks, sizePk)) {
                        found = true;
                        // If any size matches we can keep the whole albee
                        break;
                    }
                }
                if (found)
                    albumPksToKeepViaStyle.add(albumPk);
            }
        }

        const haveSizeFilters = selectedSizePks.length > 0;
        const haveCatOrStyleFilters = selectedStylePks.length > 0
                                   || selectedItemCategoryPks.length > 0;

        return albums
            .map(album => {
                /* here we remove sizes if they are filtered out, this
                 * way the sizes dont render */
                if (filteredSizeNames.size === 0) {
                    return album;
                }
                let newAlbum = Object.assign({}, album);
                newAlbum.sizecounts = album.sizecounts.filter(
                    sc => filteredSizeNames.has(sc.size)
                );
                return newAlbum;
            })
            .filter(album => {
                if (searchQuery && !album.name.toLowerCase().includes(searchQuery.toLowerCase()))
                    return false;
                if (album.sizecounts.length === 0)
                    return false;
                if (haveCatOrStyleFilters) {
                    return albumPksToKeepViaStyle.has(album.pk);
                } else if (haveSizeFilters) {
                    return albumPksToKeepViaSize.has(album.pk);
                }
                return true;
            });
    }, [
        stylePkToStyleInfo,
        sizeInfo,
        catPkToStylePksMap,
        selectedStylePks,
        selectedSizePks,
        selectedItemCategoryPks,
        itemCatTree,
        searchQuery,
    ]);

    /*
     * Group albums based on their "collection prefix".
     * The "collection prefix" is defined as everything before
     * the first ":" in the album name. In the future we might
     * have other grouping strategies besides this hard-coded
     * split on ":".
     *
     * Returns an array of 2-tuples of the form:
     * [name: String, albums: Array]
     */
    const groupAlbums = useCallback((albums) => {
        const albumSets = new Map();
        for (const album of albums) {
            // use full name when we're not grouping, or for featured albs
            // since they shant be grouped
            let namePrefix;
            if (!party.group_similar_albums || album.is_featured) {
                namePrefix = album.name;
            } else {
                namePrefix = album.name.split(':')[0];
            }
            if (!albumSets.has(namePrefix))
                albumSets.set(namePrefix, []);
            let groupwiseName;
            if (album.name.indexOf(':') > -1) {
                groupwiseName = album.name
                                     .split(`${namePrefix}: `)
                                     .slice(1)
                                     .join("");
            } else {
                groupwiseName = album.name;
            }
            albumSets.get(namePrefix).push({
                ...album,
                groupwiseName,
            });
        }
        return Array.from(albumSets.entries());
    }, [party.group_similar_albums]);

    // effect to trigger album filtering when selections change
    useEffect(
        () => {
            if (party.group_by_attr_name) return;
            setFilteredAllAlbums(sortAlbumGroups(groupAlbums(filterAlbumsV2(allAlbums))));
        },
        [
            selectedStylePks,
            selectedSizePks,
            selectedItemCategoryPks,
            stylePkToStyleInfo,
            sizeInfo,
            styleToItemCategoryInfo,
            allAlbums,
            party,
            filterAlbumsV2,
            groupAlbums,
            sortAlbumGroups,
        ]
    );

    const fetchGroupStarInfo = async () => {
        const data = await fetchJSON(`/api/v2/starred_groups/${party.public_id}/`);
        setStarredGroupPrefixes(new Set(data.group_prefixes));
    };

    useEffect(() => {
        fetchGroupStarInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFeaturedGroupChange = () => {
        fetchGroupStarInfo();
    };

    return <div>
      <div className="row" style={{marginBottom: "20px"}}>
        <div className="col-md-9">
          <h1>{party.name}</h1>
        </div>
        <div className="col-md-3">
          <InPartyStats publicId={party.public_id} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-9 col-md-push-3">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="party-cover" style={{padding: '0'}}>
                <img className="img-rounded" src={party.cover_photo.url} />
              </div>
              <div className="visible-xs-block visible-sm-block">
                <PartyDetailsFiltersV2 styleInfo={styleInfo}
                                       sizeInfo={sizeInfo}
                                       itemCatTree={itemCatTree}
                                       onSearchChange={onSearchChange}
                                       onStyleSelectionChange={onStyleSelectionChange}
                                       onSizeSelectionChange={onSizeSelectionChange}
                                       onItemCategorySelectionChange={onItemCategorySelectionChange}
                />
                <div className="text-center"
                     style={{marginTop: "10px"}}>
                  <button className="btn btn-link btn-sm"
                          onClick={scrollToChat}>Jump to chat and activity</button>
                </div>
              </div>
            </div>
            {party.group_by_attr_name &&
             <AttrGroupedAlbums albumGroups={albumGroups}
                                otherAlbums={otherAlbums}
                                groupByAttrName={party.group_by_attr_name}
                                filterAlbums={filterAlbumsV2} />
            ||
             <div>
               {filteredAllAlbums.map(({name, albums, isFavoriteGroup}, index) =>
                   <StrPartyAlbumSet key={name}
                                     name={name}
                                     albums={albums}
                                     onEnter={() => addToVisibleAlbums(name)}
                                     isFavoriteGroup={isFavoriteGroup}
                                     onFeaturedGroupChange={onFeaturedGroupChange}
                                     minimal={index > albumChunkSize && !isExplicitlyVisible(name)} />
               )}
             </div>
            }
          </div>
        </div>

        <div className="col-md-3 col-md-pull-9">
          <div className="visible-md-block visible-lg-block">
            <PartyDetailsFiltersV2 styleInfo={styleInfo}
                                   sizeInfo={sizeInfo}
                                   itemCatTree={itemCatTree}
                                   onSearchChange={onSearchChange}
                                   onStyleSelectionChange={onStyleSelectionChange}
                                   onSizeSelectionChange={onSizeSelectionChange}
                                   onItemCategorySelectionChange={onItemCategorySelectionChange}
            />
          </div>
          <h5 id="chat" ref={chatRef}>Chat</h5>
          <StrChat inModal={false}
                   chatTypeKey="party"
                   chatId={party.public_id} />
          <hr />
          <h5>Recent Activity</h5>
          <SocialPartyFeed publicId={party.public_id} />
        </div>
      </div>
    </div>;
}

PartyDetailsContent.propTypes = {
    party: PropTypes.object.isRequired,
    allAlbums: PropTypes.array.isRequired,
    sizeInfo: PropTypes.array,
    styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
            pk: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            // For each album, which sizes contain this style
            albumPksToSizePks: PropTypes.arrayOf(
                PropTypes.shape({
                    albumPk: PropTypes.number.isRequired,
                    sizePks: PropTypes.arrayOf(
                        PropTypes.oneOfType([
                            PropTypes.number,
                            PropTypes.string, // can be the string "null"
                        ])
                    ).isRequired,
                })
            ).isRequired,
        })
    ),
    styleToItemCategoryInfo: PropTypes.arrayOf(
        PropTypes.shape({
            itemchoice_pk: PropTypes.number.isRequired,
            category_pks: PropTypes.arrayOf(PropTypes.number).isRequired,
        })
    ),
    albumGroups: PropTypes.object,
    otherAlbums: PropTypes.array,
};

function PartyDetailsContentAppV2 (el,
                                   party,
                                   allAlbums,
                                   styleInfo,
                                   sizeInfo,
                                   styleToItemCategoryInfo,
                                   albumGroups,
                                   otherAlbums) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(
        <PartyDetailsContent party={party}
                             allAlbums={allAlbums}
                             styleInfo={styleInfo}
                             sizeInfo={sizeInfo}
                             styleToItemCategoryInfo={styleToItemCategoryInfo}
                             albumGroups={albumGroups}
                             otherAlbums={otherAlbums}
        />
    );
}

export { PartyDetailsContentAppV2};
