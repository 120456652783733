import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import AttrPicker from './AttrPicker.jsx';

class AttrNamePicker extends React.Component {
    render() {
        const nameProps = {
            name: 'group_by_attr_name',
            placeholder: 'Attribute',
            defaultValue: this.props.attrName,
        };

        return (
            <div>
              <div className="form-inline">
                <AttrPicker creatable={false}
                            nameProps={nameProps}
                            hideValue={true}
                            showClearable={true}
                />
              </div>
            </div>
        );
    }
}

AttrNamePicker.propTypes = {
    attrName: PropTypes.string,
};

/**
 * el should have a data-attr-name attribute.
 */
function AttrNamePickerApp(el) {
    let attrName = el.dataset.attrName;
    const root = createRoot(el);
    root.render(<AttrNamePicker attrName={attrName} />);
}

export default AttrNamePickerApp;
