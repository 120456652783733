// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemAttrsEditor .QuickAdds .label {
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
    display: inline-block;
}

.ItemAttrsEditor .QuickAdds .ClickeyContainer {
    margin: 7px;
}

.ItemAttrsEditor .QuickAdds .ClickeyContainer .ClickeyName {
    margin-right: 5px;
    background: #fff;
    color: #555;
    border: 1px solid #555;
}
`, "",{"version":3,"sources":["webpack://./css/item_attrs_editor.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".ItemAttrsEditor .QuickAdds .label {\n    font-size: 12px;\n    cursor: pointer;\n    margin-right: 5px;\n    display: inline-block;\n}\n\n.ItemAttrsEditor .QuickAdds .ClickeyContainer {\n    margin: 7px;\n}\n\n.ItemAttrsEditor .QuickAdds .ClickeyContainer .ClickeyName {\n    margin-right: 5px;\n    background: #fff;\n    color: #555;\n    border: 1px solid #555;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
