import React from 'react';
import { createRoot } from 'react-dom/client';
import { difference } from 'lodash-es';

import { fetchJSON, currencyStrToNum, getUUID } from './util.jsx';
import ShippingBlessOrder from './ShippingBlessOrder.jsx';

/* default bless export columns:
 * "Order Number","Popup Name","First Name","Last Name","Email","Item
 * Name","Discount","Tax","S&H","S&H Tax","Order Total","Order
 * Date","Order Paid Date","Payment Number","Order Status","Shipping
 * Address 1","Shipping Address 2","Shipping City","Shipping
 * State","Shipping Zip Code","Billing Address 1","Billing Address
 * 2","Billing City","Billing State","Billing Zip Code","Country" */
const REQUIRED_BLESS_CSV_COLUMNS = [
    "Order Number", "First Name", "Last Name", "Email", "Shipping Address 1",
    "Shipping Address 2", "Shipping City", "Shipping State", "Shipping Zip Code",
];

export class UploadBlessCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            blessOrders: [],
            existingBlessLabels: {},
            error: '',
        };

        this.fileChange = this.fileChange.bind(this);
        this.fetchBlessLabels = this.fetchBlessLabels.bind(this);
    }

    async fetchBlessLabels() {
        const blessOrderNos = encodeURIComponent(JSON.stringify(
            this.state.blessOrders.map(bo => bo['Order Number'])
        ));
        const rsp = await fetchJSON(
            `/api/v2/bless_labels/?blessOrderNos=${blessOrderNos}`);
        this.setState({existingBlessLabels: rsp.existing_bless_lbls});
    }

    fileChange() {
        const file = this.fileInput.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            let csvError = false;
            let blessOrders = [];
            try {
                blessOrders = $.csv.toObjects(e.target.result);
                csvError = difference(
                    REQUIRED_BLESS_CSV_COLUMNS,
                    Object.keys(blessOrders[0])
                ).length > 0;
            }
            catch (e) {
                csvError = true;
            }
            if (csvError) {
                this.setState({
                    error: "CSV file incorrect, damaged, or missing Bless information.",
                });
            } else {
                this.setState({
                    filename: file.name,
                    blessOrders: blessOrders,
                    error: '',
                }, this.fetchBlessLabels);
            }
        };
        reader.readAsText(file);
    }

    render() {
        const blessOrders = this.state.blessOrders.map(bo => {
            const hasLabel =
                Number(bo["Order Number"]) in this.state.existingBlessLabels;
            const labelPdf = hasLabel
                           ? this.state
                                 .existingBlessLabels[bo["Order Number"]]
                                 .label_pdf_url
                           : null;
            const labelTracker = hasLabel
                               ? this.state
                                     .existingBlessLabels[bo["Order Number"]]
                                     .tracker_url
                               : null;
            // customsItems spec from strshipping
            const customsItems = [{
                key: getUUID(),
                description: bo["Item Name"],
                quantity: 1,
                value: currencyStrToNum(bo["Order Total"]),
                weight: '',
                hs_tariff_number: '',
            }];
            return <ShippingBlessOrder
                       key={bo["Order Number"]}
                       hasLabel={hasLabel}
                       labelPdf={labelPdf}
                       labelTracker={labelTracker}
                       name={`${bo["First Name"]} ${bo["Last Name"]}`}
                       email={bo["Email"]}
                       street1={bo["Shipping Address 1"]}
                       street2={bo["Shipping Address 2"]}
                       city={bo["Shipping City"]}
                       state={bo["Shipping State"]}
                       zip={bo["Shipping Zip Code"]}
                       customsItems={customsItems}
                       blessOrderNo={bo["Order Number"]} />;
        });
        return (
            <div style={{marginTop: '20px'}}>
              {this.state.error &&
               <p className="alert alert-danger">{this.state.error}</p>
              }
              <label className="btn btn-primary btn-lg"
                     htmlFor="csv_upload">
                Upload CSV
              </label>
              <input type="file" id="csv_upload"
                     name="csv_upload"
                     style={{display: "none"}}
                     onChange={this.fileChange}
                     ref={input => {this.fileInput = input;}}
                     accept=".csv" />
              {this.state.filename &&
               <span style={{marginLeft: '10px'}}>{this.state.filename}</span>
              }
              <div style={{marginTop: '20px'}}>
                {blessOrders}
              </div>
            </div>
        );
    }
}


function UploadBlessCSVApp(el) {
    const root = createRoot(el);
    root.render(<UploadBlessCSV />);
}

export default UploadBlessCSVApp;
