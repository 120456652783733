import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function FeatureButton(props) {
    const [isCreated, setIsCreated] = useState(props.isFeatured);
    const [isFeatured, setIsFeatured] = useState(props.isFeatured);
    const [saving, setSaving] = useState(false);
    const url = `/api/v2/social_featured_item/`;

    function onClick() {
        setSaving(true);
        if (isCreated) {
            const active = isFeatured ? 0 : 1;
            $.ajax({
                url: url,
                method: "PATCH",
                data: {
                    item_pk: props.itemPk,
                    active: active,
                },
                success: () => {
                    setIsFeatured(!!active);
                    setSaving(false);
                },
            });
        } else {
            $.post(url, {item_pk: props.itemPk}, () => {
                setIsCreated(true);
                setIsFeatured(true);
                setSaving(false);
                toast.success('Featured item, it will appear in featured items after reloading the page.');
            });
        }
    }
    const likedClass = isFeatured ? 'fa-star' : 'fa-star-o';
    const title = `Click to ${isFeatured ? 'unfeature' : 'feature'} this item`;
    return <span>
      <button className="btn btn-link"
              style={{paddingLeft: '0', paddingRight: '0'}}
              title={title}
              disabled={saving}
              onClick={onClick}>
        <span style={{color: isFeatured ? '#ffc000' : '#333'}}>
          <i className={`ffArticleActionIcon fa ${likedClass}`}></i>
        </span>
      </button>
    </span>;
}

FeatureButton.propTypes = {
    itemPk: PropTypes.number.isRequired,
    isFeatured: PropTypes.bool.isRequired,
};
