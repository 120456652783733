import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { fetchJSON } from './util.jsx';
import MarkdownView from './MarkdownView.jsx';
import MarkdownEditor from './MarkdownEditor.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';


function nonStringNullMd(md) {
    if (md === 'null') {
        return "";
    } else {
        return md || "";
    }
}

class InvoiceSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasStripeInfo: true,
            editing: false,
            saving: false,
            footerMarkdown: '',
            updatedFooterMarkdown: '',
            image: '',
            newImage: null,
            removeImage: null,
        };

        this.url = '/api/v2/invoice_settings/';

        this.fetch = this.fetch.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.fileInput = null;
    }

    async fetch() {
        const data = await fetchJSON(this.url);
        if (data.has_stripe_info === false) {
            this.setState({
                hasStripeInfo: false,
            });
        } else {
            this.setState({
                hasStripeInfo: true,
                footerMarkdown: nonStringNullMd(data.invoice_footer_markdown),
                image: data.invoice_image,
            });
        }
    }

    componentDidMount() {
        this.fetch();
    }

    onImageChange() {
        let image = document.createElement('img');
        image.src = window.URL.createObjectURL(this.fileInput.files[0]);
        this.setState({
            newImage: image.src,
        });
    }

    onSaveClick() {
        this.setState({saving: true});
        let formData = new FormData();
        formData.append('invoiceFooterMarkdown', this.state.updatedFooterMarkdown);
        if (this.state.newImage) {
            formData.append('invoiceImage',
                            this.fileInput.files[0],
                            this.fileInput.files[0].name);
        }
        if (this.state.removeImage) {
            formData.append('removeImage', true);
        }
        $.ajax({
            url: this.url,
            method: "POST",
            data: formData,
            processData: false,
            contentType: false,
            complete: (xhr) => {
                if (this.state.removeImage) {
                    toast.success("Image removed");
                }
                this.setState({
                    saving: false,
                    editing: false,
                    footerMarkdown: nonStringNullMd(
                        xhr.responseJSON.invoice_footer_markdown
                    ),
                    image: xhr.responseJSON.invoice_image,
                    newImage: null,
                    removeImage: null,
                });
            },
        });
    }

    render() {
        const { editing, saving, footerMarkdown,
                image, newImage, hasStripeInfo} = this.state;
        const saveIcon = saving
                       ? <LoadingSpinner />
                       : <i className="fa fa-floppy-o"></i>;
        return (
            <div title={hasStripeInfo ? ''
                      : 'Link your bank account in Payout Settings above in order to use Invoice Settings'}>
              <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-2"
                     style={{textAlign: 'right', fontWeight: 'bold'}}>
                  Header Image
                </div>
                <div className="col-md-9">
                  <label className="btn btn-default"
                         disabled={saving || !hasStripeInfo}
                         htmlFor="image_upload">
                    Choose new image
                  </label>
                  <br />
                  <img style={{maxWidth: '100%', maxHeight: '200px'}}
                       src={newImage || image} />
                  <input type="file" id="image_upload"
                         name="image_upload"
                         style={{display: "none"}}
                         onChange={this.onImageChange}
                         ref={input => {this.fileInput = input;}}
                         accept=".jpg, .jpeg, .png, .bmp, .gif" />
                  <span className="help-block">
                    <p>
                      Images will be scaled and centered to fit in a 888x200 pixel space.
                    </p>
                  </span>
                  {newImage &&
                   <p>
                     <button className="btn btn-primary"
                             disabled={saving || !hasStripeInfo}
                             onClick={this.onSaveClick}>
                       Save {saveIcon}
                     </button>
                   </p>
                  }
                  {image && !newImage &&
                   <p>
                     <button className="btn btn-default"
                             disabled={saving || !hasStripeInfo}
                             onClick={() =>
                                 this.setState({removeImage: true},
                                               this.onSaveClick)
                             }>
                       Remove Image
                     </button>
                   </p>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-2"
                     style={{textAlign: 'right', fontWeight: 'bold'}}>
                  Footer Terms
                </div>
                <div className="col-md-9">
                  {!editing &&
                   <div>
                     <button className="btn btn-default btn-xs"
                             disabled={saving || !hasStripeInfo}
                             onClick={() => this.setState({editing: true})}>
                       Edit Invoice Footer
                       {' '}
                       <i className="fa fa-pencil-square-o"></i>
                     </button>
                     <MarkdownView markdown={footerMarkdown} />
                   </div>
                  }
                  {editing &&
                   <div>
                     <MarkdownEditor markdown={footerMarkdown}
                                     onChange={(markdown) =>
                                         this.setState({updatedFooterMarkdown: markdown})}
                                     source="invoice-settings"
                     />
                     <button className="btn btn-primary"
                             disabled={saving || !hasStripeInfo}
                             onClick={this.onSaveClick}>
                       Save {saveIcon}
                     </button>
                   </div>
                  }
                  {!footerMarkdown &&
                   <p className="text-small text-muted">
                     Click Edit Invoice Footer to get started.
                   </p>
                  }
                  <small className="text-info">
                    <i className="fa fa-info-circle"></i>
                    {' '}
                    Invoice footers may not appear the same in invoice emails.
                  </small>
                </div>
              </div>
            </div>
        );
    }
}

InvoiceSettings.defaultProps = {
    disabled: false,
};

InvoiceSettings.propTypes = {
    disabled: PropTypes.bool.isRequired,
};

export default function InvoiceSettingsApp(el, disabled=false) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InvoiceSettings disabled={disabled} />);
}
