import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getUUID } from './util.jsx';


function AddFilterDropdown(props) {
    function addFilter(e, filterType, value='') {
        e.preventDefault();
        props.addFilterFunc(filterType, value);
    }
    return <div className="dropdown" style={{marginLeft: '15px'}}>
      <button
          className="btn btn-default dropdown-toggle"
          type="button"
          data-toggle="dropdown"
      >
        <i className="glyphicon glyphicon-plus"></i>
        {' '}
        Add filter
        {' '}
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu">
        {props.addFiltersDropdownData.map(filterDropdownData => {
            if (!filterDropdownData.showFilterInDropdown(props.filters)) {
                return null;
            }
            const filterName = typeof filterDropdownData.name === 'function'
                             ? filterDropdownData.name()
                             : filterDropdownData.name;
            return <li key={filterName}>
              <a
                  href="#"
                  onClick={(e) => addFilter(
                      e,
                      filterDropdownData.filterType,
                      filterDropdownData.initialValue,
                  )}
              >
                {filterName}
              </a>
            </li>;
        })}
      </ul>
    </div>;
}

AddFilterDropdown.propTypes = {
    addFiltersDropdownData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]).isRequired,
            showFilterInDropdown: PropTypes.func.isRequired,
            filterType: PropTypes.shape({
                name: PropTypes.string,

            }).isRequired,
            initialValue: PropTypes.any,
        })
    ).isRequired,
    addFilterFunc: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
};

export default function FilterAppBase(props) {
    /* filters format
     * see api.views.perform_additional_filters for supported columns
     * and operators
     * [{
     *     key: getUUID(),
     *     filterType: {name: string, component: <>, prettyName?: string},
     *     # filterType.name is used in api/views.py to determine the column we filter on
     *     operator: string,
     *     value: value,
     *     include: bool,
     *     require: bool,
     * },] */
    const [filters, setFilters] = useState([]);
    const [unappliedChanges, setUnappliedChanges] = useState(false);
    useEffect(() => setUnappliedChanges(true), [filters]);

    function doCallback() {
        props.callback(filters.map(filter => {
            return {
                filterType: filter.filterType.name,
                operator: filter.operator,
                value: filter.value,
                include: filter.include,
                require: filter.require,
            };
        }));
        setUnappliedChanges(false);
    }

    function addFilter(filterType, value, require) {
        setFilters(filters.concat([{
            key: getUUID(),
            filterType: filterType,
            operator: 'exact',
            value: value,
            include: true,
            require: require,
        }]));
    }

    function addRequiredFilter(filterType, value='') {
        addFilter(filterType, value, true);
    }

    function addUnRequiredFilter(filterType, value='') {
        addFilter(filterType, value, false);
    }

    function removeFilter(key) {
        setFilters(filters.filter(f => f.key !== key));
    }

    function updateFilter(updatedFilter) {
        let newFilters = filters.slice();
        newFilters.splice(
            filters.findIndex(f => f.key == updatedFilter.key),
            1,
            updatedFilter,
        );
        setFilters(newFilters);
    }

    function updateFilterKeyKeyValue(filterKey, valueKey, value) {
        let updatedFilter = filters.find(f => f.key === filterKey);
        updatedFilter[valueKey] = value;
        if (!updatedFilter.require) {
            // don't allow exclude on optional filters
            updatedFilter['include'] = true;
        }
        updateFilter(updatedFilter);
    }

    const requiredFilters = filters.filter(f => f.require);
    const unRequiredFilters = filters.filter(f => !f.require);

    function renderFilter(filter) {
        return <filter.filterType.component
                   key={filter.key}
                   filter={filter}
                   updateFilterKeyValue={(key, value) => {
                       updateFilterKeyKeyValue(
                           filter.key,
                           key,
                           value,
                       );
                   }}
                   removeFilter={() => removeFilter(filter.key)}
               />;
    }
    const applyButtonClass = (filters.length === 0 || !unappliedChanges)
                           ? 'default' : 'primary';
    const errors = props.checkFiltersForErrors(filters);
    return <div>
      <p
          className="text-small text-muted"
          style={{marginBottom: '0', marginTop: '5px'}}
      >
        New to filters? Watch our tutorial video
        {' '}
        <a target="_blank" href="https://www.loom.com/share/17e6c310baa34badb622e258844bac97">here</a>.
      </p>
      <div style={{marginBottom: '10px'}}>
        <p>
          <small>
            Match <strong>all</strong> of the following:
          </small>
        </p>
        {requiredFilters.map(renderFilter)}
        <AddFilterDropdown
            addFilterFunc={addRequiredFilter}
            addFiltersDropdownData={props.addFiltersDropdownData}
            filters={filters}
        />
      </div>
      <div style={{marginBottom: '10px'}}>
        <p>
          <small>
            And match <strong>any</strong> of the following:
          </small>
        </p>
        {unRequiredFilters.map(renderFilter)}
        <AddFilterDropdown
            addFilterFunc={addUnRequiredFilter}
            addFiltersDropdownData={props.addFiltersDropdownData}
            filters={filters}
        />
      </div>
      {errors.length > 0 &&
       <div
           className="alert alert-danger"
           role="alert"
           style={{marginTop: '10px', marginBottom: '10px'}}
       >
         <ul>
           {errors.map((error, idx) => {
               // eslint-disable-next-line react/no-array-index-key
               return <li key={idx}>{error}</li>;
           })}
         </ul>
       </div>
      }
      <button
          type="button"
          className={`btn btn-${applyButtonClass}`}
          style={{marginTop: '10px'}}
          onClick={doCallback}
      >
        <i className="glyphicon glyphicon-search"></i> Apply Filters
      </button>
    </div>;
}

FilterAppBase.propTypes = {
    // function (filters: <list of current filters from state but in additionalFilters format>))
    // see perform_additional_filters() in api/views.py
    callback: PropTypes.func.isRequired,
    // function (filters: <list of current filters from state>)
    // returns an array of error message strings
    checkFiltersForErrors: PropTypes.func.isRequired,
    // shape defined in AddFilterDropdown proptypes
    // determines filters to show in the add filter dropdown
    addFiltersDropdownData: PropTypes.array.isRequired,
};
