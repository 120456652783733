import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function OmniPartyHideItem(props) {
    const [saving, setSaving] = useState(false);
    const [hidden, setHidden] = useState(props.hidden);

    function onClick() {
        setSaving(true);
        const data = {item_pk: props.itemPk};
        $.post('/api/v2/storefront_hide_item/', {
            item_pk: props.itemPk,
        }).success(function(data, status, xhr) {
            setHidden(data.hidden);
            toast.success(`Item ${data.hidden ? 'removed from' : 'included in'} Storefront`);
        }).fail(function(xhr, status, err) {
            let msg = 'There was an error updating the item';
            if (err === 'Unauthorized')
                msg += ". (Are you logged in as the storefront owner?)";
            toast.error(msg);
        }).always(function() {
            setSaving(false);
        });
    }

    const iconClass = hidden ? 'fa-square-o' : 'fa-check-square-o';
    const actionText = `Click to ${hidden ? 'include in' : 'remove from'} Storefront`;
    return <button className="btn btn-link"
                   style={{color: '#333', paddingLeft: '0', paddingRight: '0'}}
                   title={actionText}
                   disabled={saving}
                   onClick={onClick}>
      <i className={`fa ${iconClass} ffArticleActionIcon`}></i>
      {props.showText && <span> Include in Storefront</span>}
    </button>;
}

OmniPartyHideItem.propTypes = {
    itemPk: PropTypes.number.isRequired,
    hidden: PropTypes.bool,
    showText: PropTypes.bool,
};
