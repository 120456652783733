const events = {
    CLAIM: 'claim',
    MSG: 'msg',
    SIGN_ON: 'sign on',
    SIGN_OFF: 'sign off',
};

function normalizeData(data) {
    return {
        id: data.id,
        username: data.username,
        fullname: data.fullname,
        text: data.text,
        date: data.date,
        numReplies: data.num_replies,
        parentMessageId: data.parent_message_id,
    };
}

function normalizeLogOn(data, type) {
    let text = '';

    if (type === events.SIGN_ON) {
        text = `${data.info.username} has joined the party!`;
    } else {
        text = `${data.info.username} has left the party!`;
    }

    return {
        id: data.id + moment().format(),
        text: text,
        type: events.SIGN_ON,
    };
}

function normalizeClaim(data) {
    return {
        id: data.id,
        date: data.date,
        text: `${data.item_shortname} #${data.item_pk} has been claimed by ${data.customer}`,
        customer: data.customer,
        type: events.CLAIM,
    };
}

export default events;
export { normalizeData, normalizeLogOn, normalizeClaim };
