import React from 'react';
import {number, string, func, bool, oneOfType, arrayOf, node, object} from 'prop-types';
import ReactModal from 'react-modal';


const CloseButton = () => (
    <svg width="40" height="40" style={{cursor: "pointer"}}>
      <circle cx="20" cy="20" r="20" fill="black" />
      <g transform="rotate(45 20 20)">
        <rect x="8" y="19.25" width="24" height="1.5" fill="white" />
        <rect y="8" x="19.25" height="24" width="1.5" fill="white" />
      </g>
    </svg>
);


class ModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalTop: 0,
            modalTransformY: 0,
        };
        this.modalContainer = null;
        this.modalContainerRef = element => {
            if (element) {
                this.modalContainer = element;
                this.setVerticalPosition();
            }
        };
    }

    componentDidUpdate() {
        this.setVerticalPosition();
    }

    setVerticalPosition() {
        if (isMobile || this.props.noVerticalCenter) {
            /* this function does not work well for centering the modal
             * on mobile, works great on desktop. on mobile we want the
             * modal at its normal position at the top of the page so
             * that the close button is always visible */
            return;
        }
        if (this.modalContainer) {
            const verticalCenter = this.state.modalTop === "50%";
            const topPosition = this.state.modalTop === 0;
            if (this.modalContainer.clientHeight < window.innerHeight && topPosition) {
                this.setState({
                    modalTop: "50%",
                    modalTransformY: "-50%",
                });
            } else if (this.modalContainer.clientHeight > window.innerHeight && verticalCenter) {
                this.setState({
                    modalTop: 0,
                    modalTransformY: 0,
                });
            }
        }
    }


    render() {
        const {isOpen, onClose, children, style, ...props} = this.props;
        let contentStyle = {
            position: "static",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "none",
            border: "none",
            padding: "none",
            outline: 'none',
        };
        if (props.style) {
            contentStyle = Object.assign(contentStyle, props.style);
        }

        const modalStyle = {
            overlay: {
                zIndex: 2000,
                overflowY: "scroll",
                outline: 'none',
            },
            content: contentStyle,
        };

        let innerModalStyle = {
            top: this.state.modalTop,
            transform: `translateY(${this.state.modalTransformY})`,
        };
        if (style) {
            innerModalStyle = Object.assign(innerModalStyle, style);
        }

        return (
            <ReactModal isOpen={isOpen} onRequestClose={onClose} {...props} style={modalStyle}>
              <div style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
              }}
                   onClick={(e) => {
                       if (e.target === e.currentTarget)
                           onClose();
                   }}>
                <div className='str-modal' ref={this.modalContainerRef}
                     style={innerModalStyle}>
                  <a onClick={onClose} style={{
                      right: '20px',
                      position: 'absolute',
                      top: '-20px',
                      display: 'block',
                      height: '40px',
                  }}>
                    <CloseButton />
                  </a>
                  <div  style={{padding: "20px"}}>
                    {children}
                  </div>
                </div>
              </div>
            </ReactModal>
        );
    }
}

ReactModal.setAppElement('body');

ModalContainer.propTypes = {
    zIndex: number,
    onClose: func,
    isOpen: bool,
    children: oneOfType([arrayOf(node), node]).isRequired,
    style: object,
    noVerticalCenter: bool,
};

export {ModalContainer};
export default ModalContainer;
