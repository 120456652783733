import React from 'react';
import PropTypes from 'prop-types';
import StrChatTypes from './StrChatTypes.jsx';
import MessageList from './MessageList.jsx';
import StrChatInput from './StrChatInput.jsx';
import update from 'immutability-helper';
import { normalizeData } from "./StrChatEvents.jsx";
import { fetchJSON } from './util.jsx';

class StrChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            messages: [],
            focusMsg: strQueryDict.hasOwnProperty('focus_msg') ? (strQueryDict.focus_msg - 0) : undefined,
            submitting: false,
            users: [],
            text: '',
        };
        this.focusInput = null;
        this.setFocusInput = this.setFocusInput.bind(this);
        this.chatType = StrChatTypes[props.chatTypeKey](props.chatId);
        this.chatRoom = null;
        this.rootContainerRef = React.createRef();
    }

    setFocusInput(fn) {
        this.focusInput = fn;
    }

    render() {
        var bottom;
        if (str_user_id == -1) {
            var the_login_url = `${str_login_url}?next=${window.location.pathname}`;
            bottom = <p className="text-muted text-small">Please <a href={the_login_url}>log in</a> to enable chat.</p>;
        } else {
            let pClassNames = "text-muted ";
            let pStyle = { marginTop: "5px" };
            if (this.props.bs5) {
                pStyle = {
                    fontSize: "14px",
                    ...pStyle,
                };
            } else {
                pClassNames += "text-small";
            }

            const proTipClassNames = this.props.bs5
                                   ? "badge rounded-pill bg-light text-dark"
                                   : "label label-info";
            bottom = (
                <div>
                  {this.chatType.atMentionNotification &&
                   <p className={pClassNames}
                      style={pStyle}>
                     <span className={proTipClassNames}>Pro-tip</span>
                     {' '}
                     Use an @mention to send someone a notification.
                     Try typing @ now to see a list of users that can
                     be mentioned here.
                   </p>
                  }
                </div>
            );
        }
        return (
            <div ref={this.rootContainerRef}>
              <MessageList messages={this.state.messages}
                           loading={this.state.loading}
                           focusMsg={this.state.focusMsg}
                           users={this.state.users}
                           inModal={this.props.inModal}
                           chatRoom={this.chatRoom}
                           chatType={this.chatType}
                           ref={(el) => { this.messageList = el; } } />
              {str_user_id > 0 &&
               <StrChatInput placeholder="Your message"
                             initialValue={this.state.text}
                             setFocusInput={this.setFocusInput}
                             inputClassName="form-control"
                             inModal={this.props.inModal}
                             chatType={this.chatType}
                             autoFocus={this.props.autoFocus}
                             users={this.state.users} />
              }
              {bottom}
            </div>
        );
    }

    componentWillUnmount() {
        if (this.chatType.chatUnmountExtra) {
            this.chatType.chatUnmountExtra(this);
        }
        if (this.chatRoom && this.chatRoom.closeNoRetry)
            this.chatRoom.closeNoRetry();
    }

    async componentDidMount() {
        if (StrUserInfo.banned) return;
        if (str_user_id === -1) return;
        fetchJSON(this.chatType.usersUrl)
            .then(data => this.setState({users: data.data}));
        const self = this;
        this.chatRoom = await StrApp.strWebsocket(
            this.chatType.channel,
            data => {
                var msg = normalizeData(data);
                if (data.event === this.chatType.evtMessage) {
                    self.setState((prevState, props) => ({
                        messages: prevState.messages.concat([msg]),
                    }));
                } else if (data.event === this.chatType.evtRemoveMessage) {
                    self.setState((prevState, props) => ({
                        messages: prevState.messages.filter(
                            msg => Number(msg.id) !== Number(data.remove_message_id)
                        ),
                    }));
                }
            },
        );
        if (this.chatType.chatDidMountExtra) {
            this.chatType.chatDidMountExtra(this);
        }
        let data = {};
        if (self.state.focusMsg)
            data['focus_msg'] = self.state.focusMsg;
        $.getJSON(this.chatType.messagesUrl, data, function(data) {
            self.setState({
                messages: self.chatType.chatMessagesFromData(data),
                loading: false,
            });
        });
        if (this.state.focusMsg && this.rootContainerRef.current) {
            setTimeout(() => {
                this.rootContainerRef.current.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
    }

    scrollMessageListToBottom() {
        this.messageList.scrollToBottom();
    }

    setText(text) {
        this.setState({text});
        return this.focusInput;
    }
}

StrChat.defaultProps = {
    bs5: false,
};

StrChat.propTypes = {
    /* set to true if this chat widget lives within a modal (which might
       require special styling/positioning hacks) */
    inModal: PropTypes.bool,
    chatTypeKey: PropTypes.oneOf(Object.keys(StrChatTypes)).isRequired,
    // id + chatType uniquely identify this chat
    // chatId can be party public id or direct message id etc.
    chatId: PropTypes.number.isRequired,
    autoFocus: PropTypes.bool,
    bs5: PropTypes.bool,
};

export default StrChat;
