import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import Confetti from 'react-dom-confetti';

const Fetti = (props) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsActive(true), 10);
    });

    return (
        <Confetti active={isActive} config={props.config} />
    );
};

Fetti.propTypes = {
    config: PropTypes.object.isRequired,
};

export function ConfettiApp(el, config) {
    const cfg = {
        angle: 90,
        spread: 360,
        startVelocity: "50",
        elementCount: "86",
        dragFriction: "0.13",
        duration: "3320",
        stagger: "9",
        width: "25px",
        height: "26px",
        perspective: "594px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
        ...(config || {}),
    };
    const root = createRoot(el);
    root.render(<Fetti config={cfg} />);
}
