import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { throttle } from 'lodash-es';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(window.pageYOffset > 1000);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const debouncedHandler = throttle(toggleVisibility, 1000);
        window.addEventListener('scroll', debouncedHandler);

        return () => {
            window.removeEventListener('scroll', debouncedHandler);
        };
    }, []);

    const btnStyle = {
        display: isVisible ? "block" : "none",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        boxShadow: "0px 0px 15px #888",
        zIndex: "1000",
    };

    let btnCls = "btn btn-success";
    if (!isMobile)
        btnCls += " btn-lg";

    return (
        <button onClick={scrollToTop}
                className={btnCls}
                style={btnStyle}>
          <i className="fa fa-angle-double-up" aria-hidden="true"></i>
          {' '}
          Back to Top
        </button>
    );
};

let root = null;

export default function BackToTopButtonApp(el) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<BackToTopButton />);
}
