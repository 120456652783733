/*
 * @module Alert
 * @description Uses style classes defined by Definity
 */

import React from "react";
import PropTypes from "prop-types";

const Alert = ({heading, message, type}) => (
    <div className={`alert alert-${type} alert-dismissible`} role="alert">
        <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
        >
            <span aria-hidden="true">×</span>
        </button>

        <strong>{heading} </strong>
        {message}
    </div>
);

Alert.propTypes = {
    heading: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["success", "warning", "info", "danger"]).isRequired,
};

export default Alert;
