import React, { useState } from 'react';
import PropTypes from 'prop-types';

const RadioInputsRow = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.initialValue || '');

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedOption(newValue);
        props.onChange(newValue);
    };

    const containerStyle = {
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
    };

    const labelStyle = {
        margin: "0 5px 5px 0",
    };

    const innerLabelStyle = {
        cursor: "pointer",
    };

    return (
        <div style={containerStyle}>
          <span className="text-small"
                style={labelStyle}>
            {props.label}
          </span>
          {props.options.map(option => (
              <React.Fragment key={option.label}>
                <label className="text-small"
                       style={innerLabelStyle}>
                  <input
                      type="radio"
                      value={option.value}
                      checked={option.value === selectedOption}
                      onChange={handleChange}
                  />
                  {option.label}
                </label>
              </React.Fragment>
          ))}
        </div>
    );
};

RadioInputsRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    })).isRequired,
};

export default RadioInputsRow;
