import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { fetchJSON, strRequestJSONWithResponse } from './util.jsx';
import PartySelect from './PartySelect.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const BiggableImg = (props) => {
    const { srcBig, srcSmall, alt = "" } = props;
    const [useBigThumb, setUseBigThumb] = useState(false);

    return (
        <img
            src={useBigThumb ? srcBig : srcSmall}
            className="img-responsive"
            style={{cursor: 'pointer', width: useBigThumb ? "350px" : "100px"}}
            onClick={() => setUseBigThumb(u => !u)}
            alt={alt}
        />
    );
};

BiggableImg.propTypes = {
    srcBig: PropTypes.string.isRequired,
    srcSmall: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

const CopyButtonCaption = ({doCopyAlbumCovers, doCreateMissingAlbums}) => {
    if (doCopyAlbumCovers && doCreateMissingAlbums)
        return <span>Copy album covers and create missing albums</span>;
    if (doCopyAlbumCovers)
        return <span>Copy album covers</span>;
    if (doCreateMissingAlbums)
        return <span>Create missing albums</span>;
    return <span>Please select the settings to copy above</span>;
};

CopyButtonCaption.propTypes = {
    doCopyAlbumCovers: PropTypes.bool,
    doCreateMissingAlbums: PropTypes.bool,
};

const CopyAlbumCovers = (props) => {
    const [party, setParty] = useState(null);
    const [partyAlbums, setPartyAlbums] = useState([]);
    const [destParty, setDestParty] = useState(null);
    const [destAlbums, setDestAlbums] = useState([]);
    const [loadingAlbums, setLoadingAlbums] = useState(false);
    const [doCopyAlbumCovers, setDoCopyAlbumCovers] = useState(true);
    const [doCreateMissingAlbums, setDoCreateMissingAlbums] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const party = await fetchJSON(`/api/v2/myparties/${props.partyPublicId}/`);
            setDestParty(party);
            const albsData = await fetchJSON(`/api/v2/party_albums/${party.pk}/`);
            setDestAlbums(albsData.albums);
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchData = async () => {
            if (party) {
                setLoadingAlbums(true);
                const data = await fetchJSON(`/api/v2/party_albums/${party.pk}/`);
                setPartyAlbums(data.albums);
                setLoadingAlbums(false);
            }
        };

        fetchData();
    }, [party]);

    const onCopyClick = async () => {
        // do the copy by POST'ing to an API endpoint
        const params = {
            dest_party_pk: destParty.pk,
            copy_album_covers: doCopyAlbumCovers,
            create_missing_albums: doCreateMissingAlbums,
        };
        const apiUrl = `/api/v2/party_albums/${party.pk}/copy/`;
        setDisableSubmit(true);
        const [json, rsp] = await strRequestJSONWithResponse(apiUrl, 'POST', params);
        if (!rsp.ok) {
            toast.error(`Couldn't copy albums: ${json.message || rsp.statusText}`);
            setDisableSubmit(false);
        } else {
            toast.success(`${json.message}. Reloading page...`);
            // wait for 2 seconds
            await new Promise(resolve => setTimeout(resolve, 2000));
            // reload the page
            location.reload();
        }
    };

    const onPartyPick = (selectedParty) => {
        setParty(selectedParty);
    };

    // search-efficient Set of all album names in dest party
    const destAlbumsSet = new Set(destAlbums.map(a => a.name));

    // All custom albs from source without a matching alb in dest
    const missingAlbums = partyAlbums.filter(
        album => !album.style_album && !destAlbumsSet.has(album.name)
    );

    // search-efficient Set of album names present in source party but missing in dest party
    const missingAlbumsSet = new Set(missingAlbums.map(a => a.name));

    // All albs from source with a cover and matching alb (which is either there
    // presently or will be created by doCreateMissingAlbums) in dest
    const albsWithCovers = partyAlbums.filter(
        album => album.cover && (destAlbumsSet.has(album.name)
                              || (doCreateMissingAlbums && missingAlbumsSet.has(album.name)))
    );

    const shouldDisableSubmit = !(doCopyAlbumCovers || doCreateMissingAlbums) || disableSubmit;

    return (
        <div className="row">
          <div className="col-md-6">
            <p className="text-muted text-small" style={{marginTop: "10px"}}>
              First, pick a party from which you'd like to copy albums and/or covers:
            </p>
            {destParty &&
             <PartySelect onSelect={onPartyPick}
                          orderByDate={true}
                          selectPreviousOnInit={false}
                          partyFilter={party => party.pk !== destParty.pk}
                          adminOnly={true} />
            }
            {party &&
             <React.Fragment>
               <p className="text-muted text-small" style={{marginTop: "10px"}}>
                 Select which settings to copy:
               </p>
               <div className="checkbox">
                 <label className="text-small">
                   <input
                       type="checkbox"
                       checked={doCreateMissingAlbums}
                       onChange={(e) => setDoCreateMissingAlbums(e.target.checked)}
                   />
                   Create missing custom albums
                 </label>
               </div>
               <div className="checkbox">
                 <label className="text-small">
                   <input
                       type="checkbox"
                       checked={doCopyAlbumCovers}
                       onChange={(e) => setDoCopyAlbumCovers(e.target.checked)}
                   />
                   Copy album covers
                 </label>
               </div>
             </React.Fragment>
            }
            {loadingAlbums && <LoadingSpinner />}
            {party && !loadingAlbums && (
                <div style={{marginTop: "20px"}}>
                  {doCreateMissingAlbums && missingAlbums.length === 0 &&
                   <p className="text-small text-muted">No missing custom albums need to be created.</p>
                  }
                  {doCreateMissingAlbums && missingAlbums.length > 0 &&
                   <React.Fragment>
                     <h4>Missing custom albums to be created in {destParty.name}</h4>
                     <ul className="list-unstyled">
                       {missingAlbums.map(album => <li key={album.pk}>{album.name}</li>)}
                     </ul>
                   </React.Fragment>
                  }
                  {doCopyAlbumCovers && albsWithCovers.length === 0 &&
                   <p className="text-small text-muted">No custom album covers in {party.name}</p>
                  }
                  {doCopyAlbumCovers && albsWithCovers.length > 0 &&
                   <React.Fragment>
                     <h4>Custom album covers to be copied to {destParty.name}</h4>
                     <div className="panel" style={{ maxHeight: "400px", overflow: "auto" }}>
                       {albsWithCovers.map(album => (
                           <div key={album.pk} className="thumbnail">
                             <div style={{ display: "flex", alignItems: "center" }}>
                               <div>
                                 <BiggableImg
                                     srcSmall={album.cover.thumbnail}
                                     srcBig={album.cover.image_medium}
                                     alt={album.name}
                                 />
                               </div>
                               <div style={{ flex: 1 }}>
                                 <div className="caption">{album.name}</div>
                               </div>
                             </div>
                           </div>
                       ))}
                     </div>
                   </React.Fragment>
                  }
                  {((doCreateMissingAlbums && missingAlbums.length > 0)
                  || (doCopyAlbumCovers && albsWithCovers.length > 0)) &&
                   <React.Fragment>
                     <h4>
                       {party.name}
                       {' '}
                       <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                       {' '}
                       {destParty.name}
                     </h4>
                     <button className="btn btn-primary btn-lg"
                             disabled={shouldDisableSubmit}
                             onClick={onCopyClick}>
                       <i className="fa fa-clone" aria-hidden="true"></i>
                       {' '}
                       <CopyButtonCaption {...{doCopyAlbumCovers, doCreateMissingAlbums}} />
                     </button>
                   </React.Fragment>
                  }
                </div>
            )}
          </div>
        </div>
    );
};

CopyAlbumCovers.propTypes = {
    partyPublicId: PropTypes.number.isRequired,
};

export default function CopyAlbumSettingsApp(el, partyPublicId) {
    if (el === null) return;
    const root = createRoot(el);
    root.render(<CopyAlbumCovers partyPublicId={partyPublicId} />);
}
