import React from 'react';
import PropTypes from 'prop-types';
import StrChatEvents from './StrChatEvents.jsx';
import StrChatMessage from './StrChatMessage.jsx';


class MessageList extends React.Component {
    constructor(props) {
        super(props);
        this.firstMessageSeen = false;
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    render() {
        if (str_user_id === -1)
            return null;
        const list = this.props.messages.map((message) => {
            if (message.type === StrChatEvents.CLAIM) {
                if (!message.customer)
                    return null;

                return (
                    <li key={message.id}>
                      <i className="fa fa-icon fa-shopping-bag"> </i> {message.text}
                      <span className="text-muted"> - {moment(message.date).fromNow()}</span>
                    </li>
                );
            } else if (message.type === StrChatEvents.SIGN_ON) {
                return (
                    <li key={message.id}>
                      <i className="fa fa-icon fa-sign-in"> </i> {message.text}
                      <span className="text-muted"> - {moment(message.date).fromNow()}</span>
                    </li>
                );
            }

            const isFocused = this.props.focusMsg === message.id;

            return (
                <StrChatMessage key={message.id}
                                message={message}
                                isFocused={isFocused}
                                scrollListToBottom={this.scrollToBottom}
                                users={this.props.users}
                                inModal={this.props.inModal}
                                chatRoom={this.props.chatRoom}
                                chatType={this.props.chatType} />
            );
        }).filter(item => item !== null);
        return (
            <ul className="partyChatMessages"
                ref={(el) => { this.messagesContainer = el; } }>
              {list.length === 0 && !this.props.loading &&
               <p className="text-center">
                 No one has chatted yet, say something!
               </p>
              }
              {list.length > 0 && list}
            </ul>
        );
    }

    scrollToBottom() {
        if (str_user_id === -1)
            return;
        this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight - this.messagesContainer.clientHeight;
    }

    componentDidMount() {
        // to update the timestamps automagically
        setInterval(() => this.forceUpdate(), 10000);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        this.checkBottomSnap();
        return null;
    }

    componentDidUpdate(prevProps) {
        if (!this.messagesContainer)
            return;

        if (!this.firstMessageSeen && this.props.messages.length > 0) {
            // The scroll-distance-from-the-bottom check below doesn't work
            // the first time our messages get slammed in if there are
            // enough of them to overflow (since we're scrolled to the top
            // initially). Just force a scroll to the bottom the first time
            // we see messages. After that, the scroll distance check below
            // can take over.
            this.scrollToBottom();
            this.firstMessageSeen = true;
        }

        const hasNewMessage = prevProps.messages.length < this.props.messages.length;

        if (this.isAtBottom && hasNewMessage) {
            this.scrollToBottom();
        }
    }

    checkBottomSnap() {
        if (!this.messagesContainer)
            return;
        const bottomSnapDistance = 15;
        const distanceFromBottom = this.messagesContainer.scrollTop - this.messagesContainer.scrollHeight + this.messagesContainer.clientHeight;
        this.isAtBottom = Math.abs(distanceFromBottom) < bottomSnapDistance;
    }
}

MessageList.propTypes = {
    chatRoom: PropTypes.object,
    messages: PropTypes.array.isRequired,
    focusMsg: PropTypes.number,
    users: PropTypes.array.isRequired,
    inModal: PropTypes.bool,
    // instantiated StrChatTypes entry
    chatType: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

export default MessageList;
