import React from 'react';
import StrTooltip from './StrTooltip.jsx';

const PrivateEye = () => {
    return (
        <StrTooltip message="This is only visible to you.">
          <i className="fa fa-eye fa-1x"
             aria-describedby="private-tooltip">
          </i>
        </StrTooltip>
    );
};

export default PrivateEye;
