import React from 'react';
import PropTypes from 'prop-types';
import { socialStylify, urlify } from "../../util.jsx";
import LinkPreview from "../LinkPreview.jsx";
import Gallery from "../../Gallery.jsx";
import SocialPostEntryForm from "../SocialPostEntryForm.jsx";


export default class SocialPostCard extends React.Component {
    render() {
        if (this.props.editing)
            return <SocialPostEntryForm
                       editing={true}
                       onEditFinish={this.props.onEditFinish}
                       activity={this.props.activity} />;
        const html = urlify(socialStylify(this.props.activity.text), 40)
            .replace(/(?:\r\n|\r|\n)/g, '<br>');
        const links = this.props.activity.data
                   && this.props.activity.data.opengraph
                   && this.props.activity.data.opengraph.map(link =>
                       <LinkPreview key={link.url} link={link} />);
        const images = this.props.activity.data
                    && this.props.activity.data.imageUrls
                    && this.props.activity.data.imageUrls.map(image => {
                        return {
                            image_thumbnail: image.toString(),
                            image_medium: image.toString(),
                            image_full: image.toString(),
                        };
                    });
        const imageClass = images && images.length > 1 ? " many"
                         : "";
        return (
            <div>
              <div style={{fontSize: '18px', wordWrap: "break-word"}}
                   dangerouslySetInnerHTML={{__html: html}}
              >
              </div>
              {images &&
               <div className={`ffPostImages${imageClass}`}>
                 <Gallery item={{}} images={images} />
               </div>
              }
              {links}
            </div>
        );
    }
}

SocialPostCard.propTypes = {
    activity: PropTypes.object.isRequired,
    editing: PropTypes.bool,
    onEditFinish: PropTypes.func,
};
