/**
 * Depends on the following globals:
 *   - str_user_id
 *   - str_login_url
 */


import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner.jsx';

class FollowButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.onClick = this.onClick.bind(this);
        this.doFollow = this.doFollow.bind(this);
        this.doUnfollow = this.doUnfollow.bind(this);

        this.followUrl = `/api/v2/social_follow/${this.props.user.pk}/`;
    }

    render() {
        if (str_user_id === this.props.user.pk)
            return null;

        if (str_user_id === -1) {
            const loginUrl = `${str_login_url}?next=${window.location.pathname}`;
            return (
                <div>
                  <a href={loginUrl}>Login</a> to follow {this.props.user.username}
                </div>
            );
        }

        let txt, btnClass = "btn-success";
        if (this.state.loading) {
            txt = <LoadingSpinner />;
        } else {
            switch (this.props.user.following) {
            case false:
                txt = "Follow";
                break;
            case true:
                txt = "Unfollow";
                btnClass = "btn-default";
                break;
            }
        }
        btnClass = `btn ${btnClass} ${this.props.btnSize}`;

        return (
            <div style={this.props.style}>
              <button type="button" className={btnClass}
                      onClick={this.onClick}
                      style={{fontWeight: "bold"}}>
                <img src="https://s3-us-west-1.amazonaws.com/str-static-staging/static/images/favicon.png"
                     style={{width: "16px"}} /> {txt}
              </button>
            </div>
        );
    }

    onClick(e) {
        e.preventDefault();

        switch (this.props.user.following) {
        case null:
            return;
        case true:
            this.doUnfollow();
            break;
        case false:
            this.doFollow();
            break;
        }
    }

    doFollow() {
        this.setState({loading: true});
        $.post(this.followUrl).done(() => {
            this.setState({loading: false});
            if (this.props.onFollow)
                this.props.onFollow();
        });
    }

    doUnfollow() {
        const self = this;
        this.setState({loading: true});
        $.ajax({
            url: this.followUrl,
            method: "DELETE",
            complete: () => {
                this.setState({loading: false});
                if (self.props.onUnfollow)
                    self.props.onUnfollow();
            },
        });
    }
}

FollowButton.propTypes = {
    user: PropTypes.object.isRequired,
    onFollow: PropTypes.func,
    onUnfollow: PropTypes.func,
    style: PropTypes.object,
    btnSize: PropTypes.string,
};

FollowButton.defaultProps = {
    btnSize: "",
};

function FollowButtonApp(el, props) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<FollowButton {...props} />);
}

export { FollowButton, FollowButtonApp };
