import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { ModalContainer } from './StrModal.jsx';
import StrChat from './StrChat.jsx';

export function SendDirectMessage(props) {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showNoDMsModal, setShowNoDMsModal] = useState(false);
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const [newChatMessage, setNewChatMessage] = useState("");
    const [newChatError, setNewChatError] = useState("");
    const [contactDetails, setContactDetails] = useState("");
    const [chatPublicId, setChatPublicId] = useState(null);
    const url = '/api/v2/send_direct_message/';
    function onClickLink(e) {
        // this is nice to prevent scrolling the page
        e.preventDefault();
        $.getJSON(url, {username: props.username}, (data, status, xhr) => {
            if (data.public_id) {
                setChatPublicId(data.public_id);
            } else if (data.direct_messaging_disabled) {
                setContactDetails(data.contact_details);
                setShowNoDMsModal(true);
            } else {
                setShowCreateModal(true);
            }
        }).fail((xhr, textStatus, errorThrown) => {
            if (xhr.status === 401) {
                setShowCreateAccountModal(true);
            }
        });
    }
    function onCreateChat() {
        const data = {
            username: props.username,
            message: newChatMessage,
        };
        $.post(url, data, (chatroom, status, xhr) => {
            window.location.href = `/messages/${chatroom.public_id}p/`;
        }).fail(rsp => {
            if (rsp.responseJSON[0]) {
                setNewChatError(rsp.responseJSON[0]);
            }
        });
    }
    if (props.username === '' || props.username === StrUserInfo.username) {
        return null;
    }
    return <div>
      <a href="#" onClick={onClickLink}>
        Direct Message{props.suffix && (' ' + props.suffix)}
      </a>
      {chatPublicId &&
       <ModalContainer onClose={() => setChatPublicId(null)}
                       isOpen={true}
                       style={{
                           width: isMobile ? '100%' : '700px',
                           maxWidth: isMobile ? '100%' : '700px',
                       }}>
         <h3>
           <a href={`/messages/${chatPublicId}p/`}>
             Messaging {props.username}
           </a>
         </h3>
         <StrChat chatTypeKey="chatroom"
                  chatId={chatPublicId}
                  autoFocus={true} />
       </ModalContainer>
      }
      {showCreateModal &&
       <ModalContainer onClose={() => setShowCreateModal(false)}
                       isOpen={true}
                       style={{
                           width: '400px',
                           maxWidth: '400px',
                       }}>
         <div className="row">
           <div className="col-md-12">
             <div className="form-group">
               <label htmlFor="message">
                 Send a message to {props.username}
               </label>
               <input type="text"
                      className="form-control"
                      name="message"
                      value={newChatMessage}
                      autoFocus
                      onChange={(e) => setNewChatMessage(e.target.value)} />
             </div>
             {newChatError &&
              <div className="alert alert-danger">
                {newChatError}
              </div>
             }
             <button className="btn btn-primary"
                     onClick={onCreateChat}
                     disabled={!newChatMessage}>
               Start new chat
             </button>
           </div>
         </div>
       </ModalContainer>
      }
      {showNoDMsModal &&
       <ModalContainer onClose={() => setShowNoDMsModal(false)}
                       isOpen={true}>
         <div className="row">
           <div className="col-md-12">

             <p style={{paddingTop: "10px"}}>
               This user has disabled Direct Messaging.
               Please use the contact info below to get in touch:
             </p>
             <p className="well" style={{whiteSpace: "pre-wrap"}}>{contactDetails}</p>

             <button className="btn btn-primary"
                     onClick={() => setShowNoDMsModal(false) }>
               Close
             </button>
           </div>
         </div>
       </ModalContainer>
      }
      <ModalContainer onClose={() => setShowCreateAccountModal(false)}
                      isOpen={showCreateAccountModal}>
        <div className="row">
          <div className="col-md-12">
            <p style={{paddingTop: "10px"}}>
              <a href="/accounts/register/">Create an account</a> to send direct messages to retailers.
            </p>

            <button className="btn btn-primary"
                    onClick={() => setShowCreateAccountModal(false) }>
              Close
            </button>
          </div>
        </div>
      </ModalContainer>
    </div>;
}

SendDirectMessage.propTypes = {
    username: PropTypes.string.isRequired,
    suffix: PropTypes.string,
};

export default function SendDirectMessageApp(el, username, suffix=null) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SendDirectMessage username={username} suffix={suffix} />);
}
