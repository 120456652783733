import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import LoadingSpinner from './LoadingSpinner.jsx';
import { fetchJSON } from './util.jsx';

import StrPartyItem from './StrPartyItem.jsx';
import StrSelect from './StrSelect.jsx';

function StrPartyItems(props) {
    const [loading, setLoading] = useState(false);
    const [endOfItems, setEndOfItems] = useState(false);
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState([]);
    const [numLoads, setNumLoads] = useState(0);
    const [isOwner, setIsOwner] = useState(false);
    const [partyPk, setPartyPk] = useState(null);
    const [albumSizesInfo, setAlbumSizesInfo] = useState([]);
    const [promotedProducts, setPromotedProducts] = useState([]);
    const [promotedProductsIdx, setPromotedProductsIdx] = useState(0);
    const [promotedProductsLoaded, setPromotedProductsLoaded] = useState(false);
    useEffect(
        () => {
            fetchJSON(
                `/api/v2/party_album_size_infos/${props.publicId}/${props.albumPk}/`
            ).then(setAlbumSizesInfo);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const sizeParam = props.sizePk ? `${props.sizePk}/` : '';
    const attrParam = props.attrValue ? `&v=${props.attrValue}` : '';
    const url = `/party/${props.publicId}p/album/${props.albumPk}/${sizeParam}?json=1&offset=${offset}${attrParam}`;

    useEffect(() => {
        const fetchData = async () => {
            const url = `/api/v2/promoted_products/party/${props.publicId}/`;
            const products = await fetchJSON(url);
            setPromotedProducts(products);
            setPromotedProductsLoaded(true);
        };
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function loadMoreItems() {
        setLoading(true);
        setNumLoads(numLoads + 1);
        const data = await fetchJSON(url);
        const haveMore = data.next_offset > 0;
        if (haveMore) {
            setOffset(data.next_offset);
        } else {
            setEndOfItems(true);
        }
        setPartyPk(data.party.pk);
        setIsOwner(data.is_owner);
        let newItems = items.concat(data.items);
        if (promotedProducts.length > 0 && (!haveMore || (numLoads > 1))) {
            const nextPromotedProduct = promotedProducts[promotedProductsIdx];
            newItems.push(nextPromotedProduct);
            setPromotedProductsIdx(
                promotedProductsIdx === (promotedProducts.length - 1)
                ? 0
                : promotedProductsIdx + 1
            );
        }
        setItems(newItems);
        setLoading(false);
    }

    const partyItems = items.map((item, idx) => {
        const key = `${item.pk}-${idx}`;
        return (
            <div key={key}>
              <StrPartyItem item={item}
                            partyPk={partyPk}
                            partyAlbumPk={props.albumPk}
                            partyPublicId={props.publicId}
                            isLiked={item.is_liked}
                            isOwner={isOwner} />
              {(idx + 1) % 3 === 0 &&
               <div className="clearfix visible-sm-block"></div>
              }
              {(idx + 1) % 4 === 0 &&
               <div className="clearfix visible-md-block visible-lg-block"></div>
              }
            </div>
        );
    });

    const onSizeSelectChange = (o) => {
        if (o && o.data && o.data.url)
            window.location.href = o.data.url;
    };

    const selectOpts = {};
    if (props.sizePk && albumSizesInfo.length > 0) {
        selectOpts["initialValue"] = albumSizesInfo.find(
            size => size.pk === props.sizePk
        ).name;
    }

    return (
        <div>
          {albumSizesInfo.length > 0 &&
           <div className="row" style={{marginBottom: "20px"}}>
             <div className="col-md-3">
               <StrSelect
                   options={albumSizesInfo.map(o => ({
                       "name": `${o.name} (${o.count})`,
                       "value": o.name,
                       "data": o,
                   }))}
                   onChange={onSizeSelectChange}
                   placeholder="Browse by size"
                   isMulti={false}
                   {...selectOpts}
               />
             </div>
           </div>
          }
          <div id="party-items-container">
            {partyItems}
          </div>
          {loading &&
           <div style={{textAlign: 'center', marginTop: '30px'}}>
             <LoadingSpinner />
           </div>
          }
          {!loading && !endOfItems && promotedProductsLoaded &&
           <Waypoint onEnter={loadMoreItems} />
          }
        </div>
    );
}

StrPartyItems.propTypes = {
    publicId: PropTypes.number.isRequired,
    albumPk: PropTypes.number.isRequired,
    sizePk: PropTypes.number,
    attrValue: PropTypes.string,
    sizeName: PropTypes.string,
};

export default function StrPartyItemsApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrPartyItems publicId={data.publicId}
                               albumPk={data.albumPk}
                               sizePk={data.sizePk}
                               attrValue={data.attrValue}
                               sizeName={data.sizeName} />);
}
