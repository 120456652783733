import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import LoadingSpinner from './LoadingSpinner.jsx';

const periodOptions = {
    "today": "Today",
    "7days": "Last 7 days",
    "4weeks": "Last 4 weeks",
    "3months": "Last 3 months",
    "6months": "Last 6 months",
    "this_month": "Month to date",
};

function RecentSalesTrends(props) {
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [includeSize, setIncludeSize] = useState(false);
    const [timePeriod, setTimePeriod] = useState("4weeks");
    const [itemSaleInfos, setItemSaleInfos] = useState([]);
    const [unsortedItemSaleInfos, setUnsortedItemSaleInfos] = useState([]);
    const [sortKey, setSortKey] = useState('saleCount');
    const [sortDirection, setSortDirection] = useState(-1);

    function fetch() {
        setLoading(true);
        let url = `/api/v2/recent_sales_trends/${timePeriod}/`;
        if (includeSize) {
            url = `${url}?include_size=t`;
        }
        $.getJSON(url, function(data) {
            let quantityMap = {};
            data.quantity_infos.forEach(qi => {
                quantityMap[`${qi.itemchoice__pk}${qi.size__pk}${qi.category__pk}`] = qi.quantity;
            });
            setUnsortedItemSaleInfos(
                data.item_sale_infos.map(isi => {
                    const styleId = isi.item__itemchoice__pk;
                    const sizeId = isi.item__size__pk;
                    const categoryId = isi.item__category__pk;
                    const key = `${styleId}${sizeId}${categoryId}`;
                    return {
                        key: key,
                        // doing the || here so that sorting on style still works
                        style: isi.item__itemchoice__name || isi.item__category__name || 'Other',
                        styleId: styleId,
                        size: isi.item__size__name,
                        sizeId: sizeId,
                        saleCount: isi.sale_count,
                        quantity: quantityMap[key],
                    };
                })
            );
            setLoading(false);
            setFirstLoading(false);
        });
    }

    function changeTimePeriod(event) {
        setTimePeriod(event.target.value);
    }

    function changeIncludeSize(event) {
        setIncludeSize(event.target.checked);
    }

    useEffect(fetch, [
        timePeriod,
        includeSize,
    ]);

    useEffect(() => {
        const sortItemSaleInfos = (saleInfos) => {
            if (!sortKey) {
                return saleInfos;
            }
            return saleInfos.slice().sort((a, b) => {
                if (a[sortKey] < b[sortKey]) {
                    return sortDirection * -1;
                }
                if (a[sortKey] > b[sortKey]) {
                    return sortDirection;
                }
                return 0;
            });
        };

        setItemSaleInfos(sortItemSaleInfos(unsortedItemSaleInfos));
    }, [unsortedItemSaleInfos, sortDirection, sortKey]);

    function clickSortingHeader(key) {
        if (key === sortKey) {
            setSortDirection(sortDirection * -1);
        } else {
            setSortKey(key);
        }
    }

    const sortArrow = sortDirection === 1 ? '' : '-alt';
    const sortIndicator = <span>
      {' '}
      <span className={`glyphicon glyphicon-sort-by-attributes${sortArrow}`}
            aria-hidden="true"></span>
    </span>;

    return <div>
      <div className="row">
        <div className="col-md-8">
          <select className="form-control"
                  disabled={loading}
                  onChange={changeTimePeriod}
                  value={timePeriod}>
            {Object.entries(periodOptions).map(([period, label]) => {
                return <option key={period} value={period}>
                  {label}
                </option>;
            })}
          </select>
        </div>
        <div className="col-md-4">
          <label>
            <input type="checkbox"
                   disabled={loading}
                   checked={includeSize}
                   onChange={changeIncludeSize} />
            <span className="text-muted text-small">
              {' '}
              {StrUserInfo.words.size}
            </span>
          </label>
        </div>
      </div>
      {firstLoading && <LoadingSpinner /> ||
       <table className="table">
         <thead>
           <tr>
             <th style={{cursor: 'pointer'}}
                 onClick={(e) => clickSortingHeader('style')}>
               {StrUserInfo.words.style}
               {includeSize &&
                <span>/{StrUserInfo.words.size}</span>
               }
               {sortKey === 'style' && sortIndicator}
             </th>
             <th style={{cursor: 'pointer'}}
                 onClick={(e) => clickSortingHeader('saleCount')}>
               Sales
               {sortKey === 'saleCount' && sortIndicator}
             </th>
             <th style={{cursor: 'pointer'}}
                 onClick={(e) => clickSortingHeader('quantity')}>
               # On Hand
               {sortKey === 'quantity' && sortIndicator}
             </th>
           </tr>
         </thead>
         <tbody>
           {itemSaleInfos.map(isi => {
               return <tr key={isi.key}>
                 <td>
                   {isi.style}
                   {includeSize &&
                    <span>{' '}({isi.size || 'Other'})</span>
                   }
                 </td>
                 <td>{isi.saleCount}</td>
                 <td>{isi.quantity}</td>
               </tr>;
           })}
           {itemSaleInfos.length === 0 &&
            <tr><td colSpan="3">No sales for this time period</td></tr>
           }
         </tbody>
       </table>
      }
    </div>;
}

export default function RecentSalesTrendsApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<RecentSalesTrends />);
}
