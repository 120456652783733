import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import ProfilePopover from './ProfilePopover.jsx';
import { FollowButton } from './FollowButton.jsx';


class SocialSuggestions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestedUsers: [],
            suggestedTags: [],
            page: 1,
        };

        this.fetchSuggestions = this.fetchSuggestions.bind(this);
        this.follow = this.follow.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    fetchSuggestions() {
        $.getJSON(`/api/v2/social_suggestions/`, (data) =>  {
            this.setState({
                suggestedUsers: data.suggested_users,
                suggestedTags: data.suggested_tags,
            });
        });
    }

    componentDidMount() {
        this.fetchSuggestions();
    }

    follow(userPk, state) {
        this.setState((prevState) => {
            let tempUsers = prevState.suggestedUsers.slice();
            tempUsers.find(el => el.pk === userPk)['following'] = state;
            return {suggestedUsers: tempUsers};
        });
    }

    dismiss(e, dismissUserPk) {
        e.preventDefault();
        const self = this;
        const data = {dismissUserPk: dismissUserPk};
        $.post('/api/v2/dismiss_user/', data, function(result) {
            if (result.success) {
                self.fetchSuggestions();
            }
        });
    }

    loadMore(e) {
        e.preventDefault();
        this.setState((prevState) => {
            return {page: prevState.page + 1};
        });
    }

    render() {
        const showLoadMore = this.state.suggestedUsers.length >
                             this.props.pageSize * this.state.page;
        const visibleUsers = this.state.suggestedUsers
                                 .slice(0, this.props.pageSize * this.state.page);
        const suggestedUsers = visibleUsers.map(user => {
            const profile = user.profile_photo_thumbnail;
            return (
                <div key={user.pk} className="list-group-item">
                  <ProfilePopover userPk={user.pk}>
                    <img className="SocialProfileImageSmall"
                         src={profile} />
                    <span className="ffArticleHeaderUser ffAnchorColor">
                      {user.name}
                    </span>
                  </ProfilePopover>
                  <div className="pull-right"
                       style={{position: "relative", top: "10px"}}>
                    <FollowButton user={user}
                                  onFollow={() => {this.follow(user.pk, true);}}
                                  onUnfollow={() => {this.follow(user.pk, false);}}
                                  style={{display: 'inline'}}
                                  btnSize="btn-sm" />
                    <a href=""
                       style={{marginLeft: '10px'}}
                       onClick={(e) => {this.dismiss(e, user.pk);}}
                       title={`Dismiss suggestion for ${user.name}`}>
                      <i className="fa fa-times"></i>
                    </a>
                  </div>
                </div>
            );
        });
        const suggestedTags = this.state.suggestedTags.map(tag => {
            return (
                <div key={tag} className="list-group-item">
                </div>
            );
        });
        const haveSuggestions = this.state.suggestedUsers.length > 0 ||
                                this.state.suggestedTags.length > 0;
        return (
            <div>
              {!haveSuggestions &&
               <p className="text-small text-muted">We'll suggest users for you to follow here as you shop around!</p>
              }
              <div className="list-group">
                {suggestedUsers}
                {suggestedTags}
              </div>
              {showLoadMore &&
               <a href="" onClick={this.loadMore}>Load more</a>
              }
            </div>
        );
    }
}

SocialSuggestions.propTypes = {
    pageSize: PropTypes.number,
};

SocialSuggestions.defaultProps = {
    pageSize: 10,
};

function SocialSuggestionsApp(el, props) {
    if (el === null)
        return;
    props = props || {};
    const root = createRoot(el);
    root.render(<SocialSuggestions {...props} />);
}

export default SocialSuggestionsApp;
