import React from 'react';
import PropTypes from 'prop-types';
import SocialFeedCard from "./SocialFeedCard.jsx";
import { enrichActivity } from './social_util.jsx';

const ScheduledPost = ({post, doRefresh}) => {
    const { raw_activity, channels } = post;
    return (
        <SocialFeedCard
            activity={enrichActivity(raw_activity)}
            doRefresh={doRefresh}
            isPreview={true}
            channels={channels}
        />
    );
};

ScheduledPost.propTypes = {
    post: PropTypes.object,
    doRefresh: PropTypes.func,
};

export default ScheduledPost;
