// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#social-timeline .nav-tabs > li {
    float:none;
    display:inline-block;
}

#social-timeline .nav-tabs>li.active>a {
    background-color: unset;
    border-bottom: 1px solid #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./social/css/SocialTimeline.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,gCAAgC;AACpC","sourcesContent":["#social-timeline .nav-tabs > li {\n    float:none;\n    display:inline-block;\n}\n\n#social-timeline .nav-tabs>li.active>a {\n    background-color: unset;\n    border-bottom: 1px solid #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
