import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// *** TreePicker ***
//
// General purpose Tree Picker component. Takes an array of items
// (potentially nested with more items) and renders a tree from
// which items can be picked.
//
// In multiSelect mode, renders a checkbox next to items and calls
// onSelectionChange with the array of currently selected items each
// time the selection changes.
//
// In single select mode (multiSelect=false) renders the items without
// a checkbox and calls onSelectionChange with the currently selected
// item (if any, null if nothing is selected) each time the selection
// changes.
//
// Page where this is used should also include tree-picker.css

const TreePickerItem = (props) => {
    const [expanded, setExpanded] = useState(props.forceExpanded);
    const { item, indentLevel, multiSelect } = props;
    const haveChildren = item.childItems && item.childItems.length > 0;
    const isChecked = multiSelect && props.selectedItemKeys.includes(item.key);

    useEffect(() => {
        setExpanded(props.forceExpanded);
    }, [props.forceExpanded]);

    return (
        <React.Fragment>
          <div className={`tree-picker-item tree-picker-item-indent-${indentLevel}`}
               key={item.key}
               onClick={() => props.onSelectionChange(item.key)}>
            <div className="tree-picker-item-expando"
                 onClick={(e) => {
                     e.stopPropagation();
                     setExpanded(!expanded);
                 }}>
              {haveChildren && <i className={`fa fa-${expanded ? "caret-down" : "caret-right"}`} />}
            </div>
            {multiSelect && (
                <input className="tree-picker-item-checkbox"
                       type="checkbox"
                       checked={isChecked}
                       readOnly={true} />
            )}
            <label>{item.name}</label>
          </div>
          {expanded && item.childItems && item.childItems.map(innerItem => (
              <TreePickerItem
                  key={innerItem.key}
                  item={innerItem}
                  onSelectionChange={props.onSelectionChange}
                  selectedItemKeys={props.selectedItemKeys}
                  indentLevel={indentLevel + 1}
                  forceExpanded={props.forceExpanded}
                  multiSelect={multiSelect}
              />
          ))}
        </React.Fragment>
    );
};

const TreePickerItemKeyPropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
]);
const TreePickerItemPropType = PropTypes.shape({
    key: TreePickerItemKeyPropType.isRequired,
    name: PropTypes.string.isRequired,
    /* not using the name children here since that's confusing with JSX children */
    childItems: PropTypes.array, // arrayOf(TreePickerItemPropType)
});

TreePickerItem.propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
    // required when multiSelect=true
    selectedItemKeys: PropTypes.arrayOf(TreePickerItemKeyPropType),
    item: TreePickerItemPropType,
    indentLevel: PropTypes.number.isRequired,
    forceExpanded: PropTypes.bool.isRequired,
    multiSelect: PropTypes.bool,
};

function filterItems(items, searchTerm) {
    let filteredItems = [];

    for (const item of items) {
        let copy = { ...item }; // Shallow copy of the current item

        // Recursive call for child items
        if (item.childItems)
            copy.childItems = filterItems(item.childItems, searchTerm);

        // Condition to check if the item name matches the search term
        const isMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

        // Include item if it or any of its descendants match the search term
        if (isMatch || (copy.childItems && copy.childItems.length > 0)) {
            filteredItems.push(copy);
        }
    }

    return filteredItems;
}

// Adds or removes item from array
function toggleArrayItem(arr, item) {
    return arr.includes(item)
         ? arr.filter(el => el !== item)
         : [...arr, item];
}

const TreePicker = (props) => {
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);

    const handleSelectionChange = (itemKey) => {
        const newKeys = toggleArrayItem(selectedItemKeys, itemKey);
        setSelectedItemKeys(newKeys);
        if (props.multiSelect)
            props.onSelectionChange(newKeys);
        else
            props.onSelectionChange(newKeys.length > 0 ? newKeys[0] : null);
    };

    useEffect(() => {
        if (!props.initialSelection || props.initialSelection.length === 0)
            return;
        setSelectedItemKeys(props.initialSelection);
    }, [props.initialSelection]);

    const filteredItems = searchTerm ? filterItems(props.items, searchTerm) : props.items;
    const displayMoreButton = props.items.length > 10 && !(showMoreClicked || searchTerm);

    const itemsToDisplay = displayMoreButton ? props.items.slice(0, 10) : filteredItems;

    return (
        <React.Fragment>
          <div style={{margin: "5px 10px"}}>
            <input type="text"
                   placeholder="Search..."
                   className="form-control"
                   value={searchTerm}
                   onChange={e => setSearchTerm(e.target.value)} />
          </div>
          {itemsToDisplay.map((item) => (
              <TreePickerItem
                  key={item.key}
                  item={item}
                  indentLevel={0}
                  onSelectionChange={handleSelectionChange}
                  selectedItemKeys={selectedItemKeys}
                  forceExpanded={!!searchTerm}
                  multiSelect={props.multiSelect}
              />
          ))}
          {displayMoreButton &&
           <button className="btn btn-link" onClick={() => setShowMoreClicked(true)}>
             More...
           </button>
          }
        </React.Fragment>
    );
};

TreePicker.propTypes = {
    items: PropTypes.arrayOf(TreePickerItemPropType).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    multiSelect: PropTypes.bool,
    initialSelection: PropTypes.arrayOf(PropTypes.number),
};

export { TreePicker, TreePickerItemPropType };
