import React from 'react';
import PropTypes from "prop-types";
import LoadingSpinner from './LoadingSpinner.jsx';
import { mapOrderParams } from './piu_table_utils.js';

const Order = {
    customer: PropTypes.number,
    pk: PropTypes.number.isRequired,
    customer_name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
};

const MergeOrdersEntry = (props) => {
    const {pk, customer_name, address1, address2, city, state, selected } = props;
    const btnClass = selected ? " btn-primary" : " btn-default";
    return (
        <button className={`btn${btnClass}`}
                style={{
                    marginRight: '10px',
                    marginTop: '10px',
                    textAlign: 'left',
                }}
                onClick={props.handleClick.bind(props.pk)}>
          <ul className="list-unstyled">
            <li>Order #{pk}</li>
            <li>{customer_name || <br />}</li>
            <li>{address1 || <br />}</li>
            {address2 &&
             <li>{address2 || <br />}</li>
            }
            <li>{city}{state && <span>, {state}</span>}</li>
          </ul>
        </button>
    );
};

MergeOrdersEntry.propTypes = {
    selected: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    ...Order,
};

const MergeOrdersList = (props) => {
    return (
      <div>
        {props.orders.map((order) => (
          <MergeOrdersEntry
              {...order}
              handleClick={(e) => {
                  e.preventDefault();
                  props.handleOrderClick(order.pk);
              }}
              selected={props.selectedOrderNo === order.pk}
              key={order.pk} />
        ))}
      </div>
    );
};

MergeOrdersList.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape(Order)).isRequired,
    selectedOrderNo: PropTypes.number.isRequired,
    handleOrderClick: PropTypes.func.isRequired,
};


const FormGroup = (props) => {
    const {name, value, title, type, maxLength, onChange} = props;
    const id = "id_" + name;
    return (
        <div className="form-group">
          <label className="col-md-3 control-label" htmlFor={id}>{title}</label>
          <div className="col-md-9">
            <input type={type} name={name} value={value || ""}
                   id={id} className="form-control"
                   maxLength={maxLength || "200"}
                   onChange={onChange} />
            </div>
        </div>
    );
};

FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};

class MergeOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerNo: null,
            selectedOrderNo: props.orders[0].pk,
            formData: {...props.orders[0]},
            orderNumbers: props.orders.map(order => order.pk),
            saving: false,
        };

        this.apiEndpoint = "/api/v2/orders/merge/";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
    }

    render() {
        const disabledClass = this.state.saving ? " disabled" : "";
        return (
          <div id="merge-orders-app" className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span></button>
                    <h2 className="modal-title">Merge orders</h2>
              </div>
              <div className="modal-body">
                <div>
                  <h3 style={{marginBottom: '10px', marginTop: '0px'}}>
                    Select shipping info to use in merged order
                  </h3>
                  <MergeOrdersList orders={this.props.orders}
                      handleOrderClick={this.handleOrderClick}
                      selectedOrderNo={this.state.selectedOrderNo} />
                </div>
                <br />
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <form className="form-horizontal">
                        <fieldset>
                          <FormGroup name="customer_name"
                                     value={this.state.formData.customer_name}
                                     title="Customer Name" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="email"
                                     value={this.state.formData.email}
                                     title="Email" type="email"
                                     onChange={this.handleChange} />
                          <FormGroup name="address1"
                                     value={this.state.formData.address1}
                                     title="Address1" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="address2"
                                     value={this.state.formData.address2}
                                     title="Address2" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="city"
                                     value={this.state.formData.city}
                                     title="City" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="state"
                                     value={this.state.formData.state}
                                     title="State" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="zip"
                                     value={this.state.formData.zip}
                                     title="Zip" type="text"
                                     onChange={this.handleChange} />
                          <FormGroup name="phone"
                                     value={this.state.formData.phone}
                                     title="Phone" type="text"
                                     onChange={this.handleChange} />
                        </fieldset>
                        <div className="modal-footer">
                          {this.state.errorMessage &&
                          <span>{this.state.errorMessage} </span>}
                          {this.state.saving && <LoadingSpinner />}
                          <input className={"btn btn-primary" + disabledClass}
                                 type="button"
                                 name="merge-orders"
                                 value="Merge orders"
                                 onClick={this.handleSubmit} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props.orders[0]) {
            this.setState ({
                customerNo: null,
                selectedOrderNo: this.props.orders[0].pk,
                formData: this.props.orders[0],
                orderNumbers: this.props.orders.map(order => order.pk),
                saving: false,
                errorMessage: null,
            });
        }
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;

        this.setState((prevState) => {
            const formData = {...prevState.formData};
            formData[name] = value;
            return { formData };
        });
    }

    handleSubmit(event) {
        this.setState({saving:true});
        const {customer_name, email, address1, address2, city, state, zip, phone} = this.state.formData;
        const data = {
            customer: this.state.customerNo,
            customer_name: customer_name,
            email: email,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zip: zip,
            phone: phone,
            order_nums: this.state.orderNumbers,
            selected_batch: this.state.selectedOrderNo,
        };

        const onSuccess = (response) => {
            if (is_str) {
                this.props.onSuccess(response);
            } else {
                const rowData = mapOrderParams(response);
                this.props.onSuccess(rowData);
            }
            this.setState({saving:false});
        };

        const onError = (res) => {
            this.setState({
                saving: false,
                errorMessage: res.responseJSON.message,
            });
        };

        $.ajax({
            type: 'POST',
            url: this.apiEndpoint,
            contentType: 'application/json',
            data: JSON.stringify(data),
            dataType: 'json',
            success: onSuccess,
            error: onError,
        });

    }

    handleOrderClick(pk) {
        const selectedOrder = this.props.orders.find(order => order.pk === pk);
        const customerNo = selectedOrder.customer;
        this.setState({
            customerNo: customerNo,
            orderNo: this.props.orders.filter(order => order.customer)[0],
            formData: {...selectedOrder},
            selectedOrderNo: pk,
        });
    }
}

MergeOrders.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape(Order)),
    onSuccess: PropTypes.func.isRequired,
};

export {MergeOrders, MergeOrdersList, MergeOrdersEntry, FormGroup};
