const codemodeTag = '<div class="_udm">';
const codemodeEndTag = '</div>';

function makeCodemode(markdown) {
    return codemodeTag + "\n\n" + markdown + "\n\n" + codemodeEndTag;
}

function isCodemode(markdown) {
    return markdown && markdown.startsWith(codemodeTag);
}

function stripCodemode(markdown) {
    return isCodemode(markdown)
        ? markdown.substring(codemodeTag.length,
                             markdown.length - codemodeEndTag.length).trim()
        : markdown;
}

export {
    makeCodemode,
    isCodemode,
    stripCodemode,
};
