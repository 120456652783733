import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from './LoadingSpinner.jsx';

export default function ShippingNotes(props) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [orderNotes, setOrderNotes] = useState('');
    const [hasChanged, setHasChanged] = useState(false);
    useEffect(getNotes, []); // eslint-disable-line react-hooks/exhaustive-deps
    const url = `/api/v2/claimbatches/${props.orderPk}/`;
    function getNotes() {
        $.getJSON(url, rsp => {
            setOrderNotes(rsp.notes);
            setLoading(false);
        });
    }
    function onSave() {
        setSaving(true);
        $.ajax({
            url: url,
            method: 'PATCH',
            data: {notes: orderNotes},
            success: (rsp, status, xhr) => {
                setHasChanged(false);
                setSaving(false);
            },
        });
    }
    if (loading) {
        return <LoadingSpinner />;
    }
    return <div>
      <textarea className="form-control"
                rows="4"
                style={{
                    resize: 'vertical',
                    marginBottom: '10px',
                }}
                onChange={(e) => {
                    setHasChanged(true);
                    setOrderNotes(e.target.value);
                }}
                name="orderNotes"
                autoFocus
                value={orderNotes} />
      <button className="btn btn-sm btn-primary pull-right"
              disabled={!hasChanged || saving}
              onClick={onSave}>
        {saving && 'Saving...' || 'Save notes'}
      </button>
    </div>;
}

ShippingNotes.propTypes = {
    orderPk: PropTypes.number.isRequired,
};
