import React from 'react';
import { createRoot } from 'react-dom/client';
import ClaimButton from './ClaimButton.jsx';


function ClaimButtonApp(el, props) {
    if (el === null)
        return;
    props = props || {};
    const root = createRoot(el);
    root.render(<ClaimButton {...props} />);
}

export default ClaimButtonApp;
