import React from 'react';
import { createRoot } from 'react-dom/client';
import RewardCard from './RewardCard.jsx';

class RewardsShoppers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardCards: [],
        };
        this.fetchRewardCards = this.fetchRewardCards.bind(this);
        this.fetchRewardCards();
    }

    render() {
        const rewardCards = this.state.rewardCards.map(rewardCard => {
            return (
                <div className="row" key={rewardCard.pk}>
                  <div className="col-md-6">
                    <h4>{rewardCard.reward_program.consultant_name}</h4>
                    <p style={{wordWrap:'break-word'}}>
                      {rewardCard.reward_program.consultant_email}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h4>{rewardCard.reward_program.name}</h4>
                    <div className="text-small">
                      {rewardCard.reward_program.description_if}
                      {' '}
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      {' '}
                      {rewardCard.reward_program.description_then}
                    </div>
                    <div className="text-small"><a href={`mailto:${rewardCard.reward_program.consultant_email}`}>Contact</a></div>
                    {rewardCard.reward_program.shop_now_url &&
                     <a className="btn btn-primary"
                         href={rewardCard.reward_program.shop_now_url}
                         style={{marginBottom: '10px'}}>
                       Shop Now
                     </a>
                    }
                  </div>
                  <div className="col-md-12">
                    <RewardCard
                        programData={rewardCard.reward_program}
                        rewardCard={rewardCard}
                        owner={false}
                    />
                  </div>
                </div>
            );
        });
        return (
            <div>{rewardCards}</div>
        );
    }

    fetchRewardCards() {
        const url = `/api/v2/users/reward_cards/`;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              this.setState({rewardCards: json});
          });
    }
}

function RewardsShoppersApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<RewardsShoppers />);
}

export default RewardsShoppersApp;
