import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { ModalContainer } from './StrModal.jsx';


class AddSizeChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingModal: props.showModal,
            name: props.initialName,
            error: null,
        };

        this.onAddSizeClick = this.onAddSizeClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            isShowingModal: nextProps.showModal,
            name: nextProps.initialName,
        });
    }

    render() {
        const sizeNoun = StrUserInfo.words['size'];
        return (
            <div style={{display: 'inline-block'}}>
              <button type="button"
                      className="btn btn-default btn-xs"
                      onClick={this.onAddSizeClick}>
                Add custom {sizeNoun.toLowerCase()}
              </button>
              <ModalContainer onClose={this.onClose} isOpen={this.state.isShowingModal}>
                <h3>Add {sizeNoun}</h3>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="col-xs-12 col-sm-5">
                      {sizeNoun} name
                    </label>
                    <div className="col-xs-12 col-sm-6">
                      <input
                          autoFocus
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          onChange={this.onNameChange}
                          onKeyPress={this.onKeyPress}
                      />
                    </div>
                  </div>
                  <button type="button"
                          className="btn btn-primary"
                          onClick={this.onSubmit}>Add {sizeNoun}</button>
                </div>
                {this.state.error &&
                 <p className="alert alert-danger">{this.state.error}</p>
                }
              </ModalContainer>
            </div>
        );
    }

    onAddSizeClick() {
        this.setState({ isShowingModal: true });
    }

    onClose() {
        this.setState({ isShowingModal: false });
    }

    onNameChange(e) {
        this.setState({ name: e.target.value });
    }

    onSubmit() {
        const url = '/api/v2/sizes/';
        const data = {
            name: this.state.name,
        };
        const self = this;
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: function(rsp, status, xhr) {
                if (self.props.onAdd)
                    self.props.onAdd(rsp);
                self.setState({ isShowingModal: false });
            },
            error: function(xhr, status, err) {
                const json = xhr.responseJSON;
                const msg = Object.keys(json).map(k => k + ": " + json[k]).join(", ");
                self.setState({ error: "Couldn't save: " + msg });
            },
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.onSubmit();
        }
    }
}

AddSizeChoice.propTypes = {
    onAdd: PropTypes.func,
    showModal: PropTypes.bool,
    initialName: PropTypes.string,
};

AddSizeChoice.defaultProps = {
    showModal: false,
    initialName: "",
};

function AddSizeChoiceApp(el, onAdd) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AddSizeChoice onAdd={onAdd} />);
}

export { AddSizeChoice, AddSizeChoiceApp };
