import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { debounce } from 'lodash-es';

class StockItemSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selected: {},
            itemsLoaded: false,
            prevUrl: null,
            nextUrl: null,
            searchQuery: "",
            fetching: false,
        };
        this.id = 1;
        this.handleClick = this.handleClick.bind(this);
        this.addToSelected = this.addToSelected.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.doFilterWork = debounce(this.doFilterWork, 500).bind(this);
        this.pageNext = this.pageNext.bind(this);
        this.pagePrevious = this.pagePrevious.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.initFetchUrl = "/api/v2/stockitems/";
        this.getUID = this.getUID.bind(this);
        this._uid = 1;
    }

    getUID() {
        return this._uid++;
    }

    pageNext(e) {
        e.preventDefault();
        this.fetchItems(this.state.nextUrl);
    }

    pagePrevious(e) {
        e.preventDefault();
        this.fetchItems(this.state.prevUrl);
    }

    filterChange(event) {
        if (this.state.fetching)
            return;
        /*
         * We need to clear prev and next URLs since searching should reset
         * paging. We also need to call doFilterWork separately since it's
         * debounced and we don't want to debounce this method directly
         * because of React's event pooling shenanigans.
         */
        this.setState({
            prevUrl: null,
            nextUrl: null,
            searchQuery: event.target.value,
        }, this.doFilterWork);
    }

    doFilterWork() {
        this.fetchItems(this.initFetchUrl,
                        this.state.searchQuery.toLowerCase());
    }

    addToSelected(pk) {
        const key = pk.toString();
        let mergeSpec = {};
        mergeSpec[key] = true;
        this.setState(
            update(this.state, {
                selected: {$merge: mergeSpec},
            })
        );
    }

    handleClick(item) {
        this.props.callback(item);
        this.addToSelected(item.pk);
    }

    render() {
        if (this.state.itemsLoaded === false) {
            return (<div>Loading stock items. </div>);
        }


        const items = this.state.items.map((i) => {
            const selected = this.state.selected.hasOwnProperty(i.pk.toString());
            return (
                <div className="col-xs-12 col-sm-6 col-md-4"
                     key={i.pk} style={{marginBottom: "30px"}}>
                  <div className="enlarge">
                    <a onClick={() => this.handleClick(i)} >
                      <img src={i.image.image_thumbnail} className="thumbnail" />
                    </a>
                    <img className="preview-full hidden-xs hidden-sm"
                         data-src={i.image.image_full} />
                  </div>
                  {i.data.attributes.map((attr) => (
                    <div className="caption" key={'attr-' + this.getUID()}>
                      <small>
                          <strong>{attr.name} </strong>{attr.value}<br />
                      </small>
                    </div>
                  ))}
                  {selected &&
                   <div className="text-success" style={{marginTop:'15px', fontSize: '13px'}}>
                     Added. Customize and save <a href="#editors">below</a>.
                   </div>}
                </div>
            );
        });
        var itemsCleared = [];
        for (var i = 1; i <= items.length; i++) {
            itemsCleared.push(items[i-1]);
            if (i % 2 === 0) {
                itemsCleared.push(<div className="clearfix visible-sm-block" key={'cleafix-' + this.getUID()}></div>);
            }
            if (i % 3 === 0) {
                itemsCleared.push(<div className="clearfix visible-md-block visible-lg-block" key={'cleafix-' + this.getUID()}></div>);
            }
        }

        const prevButton = !this.state.fetching && this.state.prevUrl ? (
            <li>
              <a href="#" aria-label="Previous" onClick={this.pagePrevious}>
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
        ) : (
            <li className="disabled">
              <span>
                <span aria-hidden="true">&laquo;</span>
              </span>
            </li>
        );

        const nextButton = !this.state.fetching && this.state.nextUrl ? (
            <li>
              <a href="#" aria-label="Next" onClick={this.pageNext}>
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
        ) : (
            <li className="disabled">
              <span>
                <span aria-hidden="true">&raquo;</span>
              </span>
            </li>
        );

        const pager = (
            <div style={{display: "flex"}}>
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  {prevButton}
                  {nextButton}
                </ul>
              </nav>
              {this.state.fetching &&
               <div style={{
                   position: "relative",
                   top: "16px",
                   left: "10px",
               }}><i className="fa fa-spinner fa-spin"></i></div>
              }
            </div>
        );

        return (
            <div>
              <div className="row">
                <div className="filter-list">
                  <div className="col-lg-12" style={{marginBottom: "10px"}}>
                    <input type="text" placeholder="Search"
                           value={this.state.searchQuery}
                           onChange={this.filterChange} />
                    {pager}
                  </div>
                </div>
              </div>
              <div className="row" id="previewLeft">{itemsCleared}</div>
              <div className="row">
                <div className="col-md-12">{pager}</div>
              </div>
            </div>
        );
    }

    componentDidMount() {
        this.fetchItems(this.initFetchUrl);
    }

    fetchItems(url, query) {
        this.setState({fetching: true});

        const self = this;
        $.getJSON(url, query && { search: query }, function(data) {
            const items = data.results.filter(i => i.data.attributes.length >= 1);
            // tack on attribute IDs for interoperability with other components
            for (let i = 0; i < items.length; ++i)
                for (let j = 0; j < items[i].data.attributes.length; ++j)
                    items[i].data.attributes[j].id = j;
            self.setState({
                items: items,
                itemsLoaded: true,
                prevUrl: data.previous,
                nextUrl: data.next,
                fetching: false,
            });
        });
    }
}

StockItemSelect.propTypes = {
    callback: PropTypes.func,
};

export default StockItemSelect;
