import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { ModalContainer } from './StrModal.jsx';
import { ItemCategoryPicker } from './ItemCategoryPicker.jsx';

// Page where this is used should also include tree-picker.css (due to ItemCategoryPicker)

class AddItemChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingModal: props.showModal,
            name: props.initialName,
            wholesalePrice: "",
            retailBottom: "",
            retailTop: "",
            error: null,
            selectedItemCats: [],
        };

        this.onAddStyleClick = this.onAddStyleClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onWholesalePriceChange = this.onWholesalePriceChange.bind(this);
        this.onRetailBottomChange = this.onRetailBottomChange.bind(this);
        this.onRetailTopChange = this.onRetailTopChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onItemCategorySelect = this.onItemCategorySelect.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            isShowingModal: nextProps.showModal,
            name: nextProps.initialName,
        });
    }

    render() {
        const styleNoun = StrUserInfo.words['style'];
        return (
            <div style={{display: 'inline-block'}}>
              <button type="button"
                      className="btn btn-default btn-xs"
                      onClick={this.onAddStyleClick}>
                Add custom {styleNoun.toLowerCase()}
              </button>
              <ModalContainer onClose={this.onClose} isOpen={this.state.isShowingModal} className="itemchoice">
                <h3>Add {styleNoun}</h3>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="col-xs-12 col-sm-6 col-md-5">
                      {styleNoun} name
                    </label>
                    <div className="col-xs-12 col-sm-6">
                      <input
                          autoFocus
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          onChange={this.onNameChange}
                          onKeyPress={this.onKeyPress}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-xs-12 col-sm-6 col-md-5">Categories</label>
                    <div className="col-xs-12 col-sm-6 col-md-7">
                      <ItemCategoryPicker onSelect={this.onItemCategorySelect}
                                          multiSelect={true} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-xs-12 col-sm-6 col-md-5">Wholesale price</label>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                      <div className="input-group">
                        <span className="input-group-addon"
                              style={{width: "auto"}}>$</span>
                        <input
                            type="number"
                            className="form-control"
                            value={this.state.wholesalePrice}
                            onChange={this.onWholesalePriceChange}
                            onKeyPress={this.onKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-xs-12 col-sm-6 col-md-5">Retail price range</label>
                    <div className="col-sm-12 col-md-12 col-lg-6"
                         style={{padding: '0px'}}>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-5">
                          <div className="input-group">
                            <span className="input-group-addon"
                                  style={{width: "auto"}}>$</span>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Low"
                                value={this.state.retailBottom}
                                onChange={this.onRetailBottomChange}
                                onKeyPress={this.onKeyPress}
                            />
                          </div>
                        </div>
                        <div className="hidden-xs" style={{
                            textAlign: "center",
                            width: "20px",
                            float: "left",
                            marginLeft: "-10px",
                            marginRight: "-10px",
                        }}>-</div>
                        <div className="col-xs-12 col-sm-5 col-md-5">
                          <div className="input-group">
                            <span className="input-group-addon"
                                  style={{width: "auto"}}>$</span>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="High"
                                value={this.state.retailTop}
                                onChange={this.onRetailTopChange}
                                onKeyPress={this.onKeyPress}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button"
                          className="btn btn-primary"
                          onClick={this.onSubmit}>Add {styleNoun}</button>
                </div>
                {this.state.error &&
                 <p className="alert alert-danger">{this.state.error}</p>
                }
              </ModalContainer>
            </div>
        );
    }

    onAddStyleClick() {
        this.setState({ isShowingModal: true });
    }

    onClose() {
        this.setState({ isShowingModal: false });
    }

    onNameChange(e) {
        this.setState({ name: e.target.value });
    }

    onItemCategorySelect(itemCats) {
        this.setState({
            selectedItemCats: itemCats,
        });
    }

    onWholesalePriceChange(e) {
        this.setState({ wholesalePrice: e.target.value });
    }

    onRetailBottomChange(e) {
        this.setState({ retailBottom: e.target.value });
    }

    onRetailTopChange(e) {
        this.setState({ retailTop: e.target.value });
    }

    onSubmit() {
        const url = '/api/v2/itemchoices/';
        const data = {
            name: this.state.name,
            wholesale_price: this.state.wholesalePrice,
            suggested_retail_price_bottom: this.state.retailBottom,
            suggested_retail_price_top: this.state.retailTop,
            minimum_advertised_price: this.state.retailTop,
            categories: this.state.selectedItemCats.map(ic => ic.pk),
        };
        const self = this;
        $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json",
            success: function(rsp, status, xhr) {
                if (self.props.onAdd)
                    self.props.onAdd(rsp);
                self.setState({ isShowingModal: false });
            },
            error: function(xhr, status, err) {
                const json = xhr.responseJSON;
                let msg;
                if (json)
                    msg = Object.keys(json).map(k => k + ": " + json[k]).join(", ");
                else
                    msg = xhr.statusText;
                self.setState({ error: "Couldn't save: " + msg });
            },
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.onSubmit();
        }
    }
}

AddItemChoice.propTypes = {
    onAdd: PropTypes.func,
    showModal: PropTypes.bool,
    initialName: PropTypes.string,
};

AddItemChoice.defaultProps = {
    showModal: false,
    initialName: "",
};

function AddItemChoiceApp(el, onAdd) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AddItemChoice onAdd={onAdd} />);
}

export { AddItemChoice, AddItemChoiceApp };
