import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import StrSelect from './StrSelect.jsx';

export default function ClaimQuestionsPicker(props) {
    const { selectedQuestionPks = [] } = props;
    const [claimQuestions, setClaimQuestions] = useState([]);
    async function getQuestions() {
        $.getJSON('/api/v2/claimquestions/', questions => {
            // avoiding console warnings due to jquery slamming more arguments in
            setClaimQuestions(questions);
        });
    }
    useEffect(() => {
        // avoiding console warnings with async
        getQuestions();
    }, []);
    const options = useMemo(
        () => claimQuestions.map(question => {
            const required = ' [required to answer]';
            return {
                name: `${question.text}${question.is_required ? required : ''}`,
                value: question.pk,
                question: question,
            };
        }),
        [claimQuestions]
    );
    function onChange(selectedOptions) {
        props.callback(
            selectedOptions.map(option => option.question.pk)
        );
    }
    return <div>
      {options.length > 0 &&
       <StrSelect
           isMulti={true}
           options={options}
           onChange={onChange}
           initialValue={selectedQuestionPks}
           placeholder="Select custom checkout questions"
       />
      ||
       <p>No questions to choose from, manage them with the link below.</p>
      }
      <button className="btn btn-link" onClick={getQuestions}>
        Refresh question list
      </button>
      <a className="btn btn-link" target="_blank" href="/claimquestions/">
        Manage questions (new tab)
      </a>
    </div>;
}

ClaimQuestionsPicker.propTypes = {
    // callback receives a list of selected claimquestion pks
    callback: PropTypes.func.isRequired,
    selectedQuestionPks: PropTypes.array,
};
