import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { intersection } from 'lodash-es';
import FilterAppBase from './FilterAppBase.jsx';
import {
    DatePurchasedFilter,
    NumberFilter,
    AttributeFilter,
    StyleSizeFilter,
    PartyFilter,
    TextFilter,
} from './FilterTypes.jsx';


const filterTypes = {
    datePurchased: {
        name: 'purchase_date',
        component: DatePurchasedFilter,
    },
    quantity: {
        name: 'quantity',
        component: NumberFilter,
    },
    price: {
        name: 'price',
        component: NumberFilter,
    },
    wholesale_price: {
        name: 'wholesale_price',
        component: NumberFilter,
    },
    attribute: {
        name: 'attribute',
        component: AttributeFilter,
    },
    styleSize: {
        name: 'style_size',
        component: StyleSizeFilter,
    },
    party: {
        name: 'party',
        component: PartyFilter,
    },
    facebookGroup: {
        name: 'facebook_group',
        component: TextFilter,
    },
    facebookAlbum: {
        name: 'facebook_album',
        component: TextFilter,
    },
};

function checkFiltersForErrors(filters) {
    let errors = [];
    const attributeFilters = filters.filter(f => {
        return f.filterType === filterTypes.attribute
            && f.value && f.value.name;
    });
    const requiredAttrFilterNames = attributeFilters.filter(
        f => f.require
    ).map(f => f.value.name);
    // eslint-disable-next-line no-undef
    const uniqueRequiredAttrFilterNames = new Set(requiredAttrFilterNames);
    // https://attacomsian.com/blog/javascript-array-find-duplicates
    const duplicateRequiredAttrNames = requiredAttrFilterNames.filter(name => {
        if (uniqueRequiredAttrFilterNames.has(name)) {
            uniqueRequiredAttrFilterNames.delete(name);
        } else {
            return name;
        }
    });
    if (duplicateRequiredAttrNames.length > 0) {
        errors.push(`You have multiple required attribute filters for ${duplicateRequiredAttrNames.join(', ')} which will lead to unexpected results.`);
    }

    const optionalAttrFilterNames = attributeFilters.filter(
        f => !f.require
    ).map(f => f.value.name);
    const optionalAndRequiredAttrNames = intersection(requiredAttrFilterNames,
                                                        optionalAttrFilterNames);
    if (optionalAndRequiredAttrNames.length > 0) {
        errors.push(`You have a required attribute filter and an additional attribute filter for ${optionalAndRequiredAttrNames.join(', ')} which may show no results.`);
    }

    const requiredStyleSizeFilters = filters.filter(f => {
        return f.filterType === filterTypes.styleSize
            && f.value && f.value.style
            && f.require;
    });
    if (requiredStyleSizeFilters.length > 1) {
        errors.push(`You have multiple required ${StrUserInfo.words.style} filters which will lead to unexpected results.`);
    }

    return errors;
}

const listingsAddFiltersDropdownData = [
    {
        name: "Attribute",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.attribute,
        initialValue: {},
    },
    {
        name: "Date purchased",
        showFilterInDropdown: (filters) => filters.filter(
            f => f.filterType === filterTypes.datePurchased
        ).length === 0,
        filterType: filterTypes.datePurchased,
        initialValue: '',
    },
    {
        name: "Quantity",
        showFilterInDropdown: (filters) => filters.filter(
            f => f.filterType === filterTypes.quantity
        ).length === 0,
        filterType: filterTypes.quantity,
        initialValue: 0,
    },
    {
        name: "Price",
        showFilterInDropdown: (filters) => filters.filter(
            f => f.filterType === filterTypes.price
        ).length === 0,
        filterType: filterTypes.price,
        initialValue: 0,
    },
    {
        name: "Wholesale Price",
        showFilterInDropdown: (filters) => filters.filter(
            f => f.filterType === filterTypes.wholesale_price
        ).length === 0,
        filterType: filterTypes.wholesale_price,
        initialValue: 0,
    },
    {
        name: () => `${StrUserInfo.words.style} / ${StrUserInfo.words.size}`,
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.styleSize,
        initialValue: '',
    },
    {
        name: "Party",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.party,
        initialValue: '',
    },
    {
        name: "Facebook Group",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.facebookGroup,
        initialValue: '',
    },
    {
        name: "Facebook Album",
        showFilterInDropdown: (filters) => true,
        filterType: filterTypes.facebookAlbum,
        initialValue: '',
    },
];

function ListingsFilters(props) {
    return <FilterAppBase
               callback={props.callback}
               addFiltersDropdownData={listingsAddFiltersDropdownData}
               checkFiltersForErrors={checkFiltersForErrors}
           />;
}

ListingsFilters.propTypes = {
    callback: PropTypes.func.isRequired,
};

export default function ListingsFiltersApp(el, callback) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<ListingsFilters callback={callback} />);
}
