import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { range } from 'lodash-es';

import LoadingSpinner from './LoadingSpinner.jsx';
import { ModalContainer } from './StrModal.jsx';

export default function InventoryLevelAlertModal(props) {
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [styles, setStyles] = useState([]);
    const [style, setStyle] = useState(props.existingAlert ? {
        label: props.existingAlert.itemchoice_name,
        value: props.existingAlert.itemchoice,
    } : null);
    const [sizes, setSizes] = useState([]);
    const [size, setSize] = useState(
        (props.existingAlert && props.existingAlert.size) ? {
            label: props.existingAlert.size_name,
            value: props.existingAlert.size,
        } : null
    );
    const [alertLevel, setAlertLevel] = useState(props.existingAlert ? {
        label: props.existingAlert.level,
        value: props.existingAlert.level,
    } : null);

    useEffect(fetch, []); // eslint-disable-line react-hooks/exhaustive-deps

    function fetch() {
        $.getJSON('/api/v2/itemchoices/', (data) => setStyles(data.results));
        $.getJSON('/api/v2/sizes/', (data) => setSizes(data.results));
        setLoading(false);
        setFirstLoading(false);
    }
    let url = `/api/v2/inventory_level_alerts/`;
    function save() {
        setLoading(true);
        const data = {
            itemchoice: style.value,
            size: size ? size.value : null,
            level: alertLevel.value,
        };
        if (props.existingAlert) {
            $.ajax({
                type: 'PUT',
                dataType: 'json',
                url: `${url}${props.existingAlert.pk}/`,
                data: data,
                complete: props.callback,
            });
        } else {
            $.post(url, data, props.callback);
        }
        setLoading(false);
    }
    function deleteAlert() {
        setLoading(true);
        $.ajax({
            type: 'DELETE',
            url: `${url}${props.existingAlert.pk}/`,
            complete: props.callback,
        });
        setLoading(false);
    }
    const styleOptions = styles.map(style => {
        return {
            label: style.name,
            value: style.pk,
        };
    });
    const sizeOptions = sizes.map(size => {
        return {
            label: size.name,
            value: size.pk,
        };
    });
    const thresholdOptions = range(1, 101).map(opt => {
        return {
            label: opt,
            value: opt,
        };
    });
    return <ModalContainer onClose={props.onClose}
                           isOpen={true}
                           style={{
                               width: '100%',
                               maxWidth: '800px',
                           }}>
      <div className="row">
        <div className="col-md-12">
          <h2>{props.existingAlert && 'Update' || 'Create'} Inventory Level Alert</h2>
          {props.existingAlert &&
           <p>Currently alerting when {props.existingAlert.itemchoice_name} {props.existingAlert.size_name} inventory drops below {props.existingAlert.level}</p>
          }
        </div>
      </div>
      <div className="row" style={{margin: '20px 0px'}}>
        <div className="col-md-4">
          <Select value={style}
                  onChange={(data) => setStyle(data)}
                  placeholder={StrUserInfo.words.style}
                  options={styleOptions} />
        </div>
        <div className="col-md-4">
          {style &&
           <Select value={size}
                   onChange={(data) => setSize(data)}
                   placeholder={`${StrUserInfo.words.size} (optional)`}
                   isClearable={true}
                   options={sizeOptions} />
          }
        </div>
        <div className="col-md-4">
          {style &&
           <Select value={alertLevel}
                   onChange={(data) => setAlertLevel(data)}
                   placeholder="Alert threshold"
                   options={thresholdOptions} />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p>
            Receive a notification when your inventory level for a particular {StrUserInfo.words.style.toLowerCase()}/{StrUserInfo.words.size.toLowerCase()} drops below the threshold.
          </p>
          {props.existingAlert &&
           <button className="btn btn-link"
                   style={{float: 'right'}}
                   disabled={firstLoading || loading}
                   onClick={deleteAlert}>
             <span className="text-danger">Delete</span>
           </button>
          }
          <button className="btn btn-primary"
                  disabled={firstLoading || loading || !style || !alertLevel}
                  onClick={save}>Save</button>
        </div>
      </div>
    </ModalContainer>;
}

InventoryLevelAlertModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    existingAlert: PropTypes.shape({
        pk: PropTypes.number,
        itemchoice: PropTypes.number,
        size: PropTypes.number,
        level: PropTypes.number,
        itemchoice_name: PropTypes.string,
        size_name: PropTypes.string,
    }),
};
