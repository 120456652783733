import React, { useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import ImageUploadOrSelect from './ImageUploadOrSelect.jsx';
import ImageCropper from './ImageCropper.jsx';
import ImageLibraryPicker from './ImageLibraryPicker.jsx';

/*
   renders a "the_images" hidden input into the page, meant for use on the
   invitem form.
*/

function InvItemImageUpload(props) {
    const [theUploadedImageFiles, setTheUploadedImageFiles] = useState([]);
    const [imagesFromLibrary, setImagesFromLibrary] = useState([]);
    const [showUpload, setShowUpload] = useState(true);
    const [croppingImageFile, setCroppingImageFile] = useState(null);

    function onUpload({ file }, originalImage) {
        // { file } format defined in jqueryform/serialize.py, it has been
        // processed by our servers
        // originalImage is the user's original file, we're stashing it for later
        file.originalImage = originalImage;
        setTheUploadedImageFiles(prevFiles => {
            const newFiles = [...prevFiles, file];
            props.onChange(newFiles.length + imagesFromLibrary.length);
            return newFiles;
        });
        setShowUpload(false);
    }

    function onDelete(id) {
        setTheUploadedImageFiles(theUploadedImageFiles.filter(imageFile => imageFile.id !== id));
    }

    function startCrop(imageFile) {
        setCroppingImageFile(imageFile);
    }

    function cropCallback(responseCropImageFile) {
        setTheUploadedImageFiles(theUploadedImageFiles.map(imageFile => {
            return imageFile.id === croppingImageFile.id
                 ? responseCropImageFile
                 : imageFile;
        }));
        setCroppingImageFile(null);
    }

    const onImageLibraryPick = (images) => {
        setImagesFromLibrary(images);
        props.onChange(theUploadedImageFiles.length + images.length);
    };

    const uploadedOrSelectedImageIds =
        theUploadedImageFiles
        .map(i => i.id)
        .concat(imagesFromLibrary.map(i => i.pk));

    return (
        <React.Fragment>
          {showUpload &&
           <ImageUploadOrSelect
               multiple={true}
               callback={onUpload}
               invItem={true}
               inputName="unusedFaker"
               showThumbnails={false}
           />
          ||
           <div style={{display: 'block'}}>
             <button
                 className="btn btn-xs btn-link"
                 onClick={() => setShowUpload(true)}
             >Upload more</button>
           </div>
          }
          {theUploadedImageFiles.map(imageFile => {
              return (
                  <div key={imageFile.id} className="uploaded-image">
                    <div className="text-tiny text-muted">
                      {imageFile.name}
                    </div>
                    <a target="_blank" href={imageFile.url}>
                      <img src={imageFile.thumbnailUrl} />
                    </a>
                    <button
                        className="btn btn-danger btn-xs"
                        style={{marginLeft: '5px'}}
                        onClick={() => onDelete(imageFile.id)}
                    >
                      <span className="glyphicon glyphicon-trash" />
                    </button>
                    {!croppingImageFile && imageFile.originalImage &&
                     <div
                         className="text-small text-muted"
                         role="button"
                         onClick={() => startCrop(imageFile)}
                     >
                       <u>crop</u>
                     </div>
                    }
                  </div>
              );
          })}
          {croppingImageFile &&
           <ImageCropper
               serializedImage={croppingImageFile}
               callback={cropCallback}
           />
          }
          <h6>Or...</h6>
          <ImageLibraryPicker onPick={onImageLibraryPick} />
          <input
              type="hidden"
              name="the_images"
              value={uploadedOrSelectedImageIds.join(",")}
          />
        </React.Fragment>
    );
}

InvItemImageUpload.propTypes = {
    // Receives the number of image files currently uploaded/selected
    onChange: PropTypes.func.isRequired,
};

export default function InvItemImageUploadApp(el, onChange) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<InvItemImageUpload onChange={onChange} />);
}
