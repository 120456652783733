/**
 * foreignIdParse - Takes a foreign_id and parses out the model the
 * name and object ID.
 *
 * Activity foreign_id strings are formatted thusly:
 *
 *    appname.ModelName:pk
 *
 * Example:
 *   foreignIdParse('tracking.SocialFeaturedParty:1')
 *   ===> {obj: SocialFeaturedParty, objId: 1}
 */
function foreignIdParse(foreign_id) {
    const split = foreign_id.split(":");
    const obj = split[0].split(".")[1];
    const objId = split[1];
    return {obj, objId};
}

function activityCommentUrl(activity) {
    const parts = foreignIdParse(activity.foreign_id);
    return `/api/v2/social_comment/${parts.obj}/${parts.objId}/`;
}

function activityLikeUrl(activity) {
    const parts = foreignIdParse(activity.foreign_id);
    return `/api/v2/social_like/${parts.obj}/${parts.objId}/`;
}

function activityUrl(activity) {
    const parts = foreignIdParse(activity.foreign_id);
    return `/api/v2/social_activity/${parts.obj}/${parts.objId}/`;
}

/**
 * enrichActivity - augments an activity object with some additional
 * fields that are useful for working with the activity, namely:
 *
 *   - commentUrl :: API endpoint to work with activity comments
 *   - liketUrl :: API endpoint to work with activity likes
 *
 * Also performs some fixups for bad data coming from the backend.
 */
function enrichActivity(activity) {
    return parseActivityForBytestrings({
        ...activity,
        commentUrl: activityCommentUrl(activity),
        likeUrl: activityLikeUrl(activity),
        activityUrl: activityUrl(activity),
    });
}

function checkAndRemoveBytestring(string) {
    if (typeof(string) !== 'string')
        return string;
    const match = string.match(/^b'(.*)'$/);
    return match ? match[1] : string;
}

function parseActivityForBytestrings(activity) {
    let parsedActivity = {};
    for (var key in activity) {
        parsedActivity[key] = checkAndRemoveBytestring(activity[key]);
    }
    return parsedActivity;
}

export { enrichActivity, foreignIdParse, parseActivityForBytestrings };
