import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { strPostJSONWithResponse } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const PartyMonetization = (props) => {
    const { party } = props;
    const [loading, setLoading] = useState(false);

    const changePayoutTarget = async (target) => {
        setLoading(true);
        const [data, rsp] = await strPostJSONWithResponse('/api/v2/referral_payouts_ctl/', {
            target: target,
        });
        if (props.onPartyChange)
            props.onPartyChange();
        setLoading(false);
    };

    const doEnable = async () => {
        setLoading(true);
        const url = `/api/v2/party_monetization_ctl/${party.pk}/`;
        const [data, rsp] = await strPostJSONWithResponse(url, {
            enable: 1,
        });
        if (props.onPartyChange)
            props.onPartyChange();
        setLoading(false);
    };

    const haveStripeConnect = false;

    return (
        <div>
          {party.enable_platform_ads ||
           <button className="btn btn-default btn-sm"
                   onClick={doEnable}>Enable monetization</button>
          }
          {party.enable_platform_ads &&
           <div>
             <div className="radio">
               <label>
                 <input type="radio"
                        name="referralPayouts"
                        value="stripe" />
                   Stripe Connect
                   <span className="help-block">
                     Receive direct deposits to your linked Stripe Connect
                     account.
                     {haveStripeConnect ||
                      <div style={{marginTop: "3px"}}>
                        Please set up Stripe Connect in
                        your <a href="/settings/billing/">billing settings</a> before
                        selecting this option.
                      </div>
                     }
                   </span>
               </label>
             </div>
             <div className="radio">
               <label>
                 <input type="radio"
                        name="referralPayouts"
                        value="bucks" />
                   Sonlet Bucks
                   <span className="help-block">
                     Credit your Sonlet Bucks balance.
                   </span>
               </label>
             </div>
           </div>
          }
          {loading && <LoadingSpinner />}
        </div>
    );
};

PartyMonetization.propTypes = {
    party: PropTypes.object.isRequired,
    onPartyChange: PropTypes.func,
};

export default PartyMonetization;
