import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class StyleSizeMover extends React.Component {
    constructor(props) {
        super(props);

        const defaultThing = this.props.allThings
                                 .find(s => s.source === 'company'
                                       && s.name.toLowerCase() === this.props.thing.name.toLowerCase());
        const defaultThingPk = defaultThing
                             ? defaultThing.pk
                             : this.props.allThings[0].pk;

        this.state = {
            moveClicked: false,
            moveTarget: defaultThingPk,
            error: "",
        };

        this.onMoveClick = this.onMoveClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    render() {
        let moveStuff;
        if (this.state.moveClicked) {
            moveStuff = (
                <span className="text-small">
                  Move your <strong>{this.props.thing.name}</strong> items to:
                  <select style={{marginLeft: "10px"}}
                          className="form-control"
                          onChange={e => this.setState({moveTarget: e.target.value})}
                          value={this.state.moveTarget}
                  >
                    {this.props.allThings.map(s => {
                        const suffix = s.source === "company" ? "(company default)" : "(custom)";
                        const lbl = `${s.name} ${suffix}`;
                        return <option key={s.pk} value={s.pk}>{lbl}</option>;
                    })}
                  </select>
                  <span className="btn btn-link text-small"
                        onClick={this.onMoveClick}>
                    Move
                  </span>
                  <span className="btn btn-link text-small"
                        onClick={() => this.setState({moveClicked: false}) }>
                    Cancel
                  </span>
                </span>
            );
        } else {
            moveStuff = (
                <span className="btn btn-link text-small"
                      onClick={() => this.setState({moveClicked: true}) }>
                  Move items <i className="fa fa-chevron-circle-right"></i>
                </span>
            );
        }

        const { num_in_inventory } = this.props.thing;

        return (
            <div>
              {this.props.showSummary &&
               <span style={{marginRight: "10px"}}>{this.props.thing.name} ({num_in_inventory} in your inventory)</span>
              }
              {num_in_inventory > 0 && moveStuff}
              {num_in_inventory === 0 && this.props.thing.source === 'consultant' &&
               <span className="btn btn-xs btn-danger"
                     onClick={this.onDeleteClick}>
                 <i className="fa fa-trash-o"></i>
                 {' '}
                 Delete {StrUserInfo.words[this.props.mode].toLowerCase()}
               </span>
              }
              {this.state.error &&
               <p className="alert alert-danger">{this.state.error}</p>
              }
            </div>
        );
    }

    onMoveClick() {
        const url = this.props.mode === 'style'
                  ? `/api/v2/itemchoices/${this.props.thing.pk}/move/`
                  : `/api/v2/sizes/${this.props.thing.pk}/move/`;
        const self = this;
        $.ajax({
            type: "POST",
            url: url,
            data: { destination: this.state.moveTarget },
            success: function(rsp, status, xhr) {
                /* self destruct. Don't add anything after this line. */
                self.props.onChange();
            },
            error: function(xhr, status, err) {
                self.setState({ error: "Couldn't move: " + err });
            },
        });
    }

    onDeleteClick() {
        const url = this.props.mode === 'style'
                  ? `/api/v2/itemchoices/${this.props.thing.pk}/`
                  : `/api/v2/sizes/${this.props.thing.pk}/`;
        const self = this;
        $.ajax({
            type: "DELETE",
            url: url,
            data: { destination: this.state.moveTarget },
            success: function(rsp, status, xhr) {
                /* self destruct. Don't add anything after this line. */
                self.props.onChange();
            },
            error: function(xhr, status, err) {
                self.setState({ error: "Couldn't delete: " + err });
            },
        });
    }
}

StyleSizeMover.propTypes = {
    /* should be 'style' or 'size' */
    mode: PropTypes.string.isRequired,
    thing: PropTypes.object.isRequired,
    allThings: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    showSummary: PropTypes.bool,
};

StyleSizeMover.defaultProps = {
    showSummary: true,
};

export default StyleSizeMover;
