import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

const adminEditLinks = {
    item: '/unicorn/tracking/featureditem/<>/change/',
    category: '/unicorn/tracking/featuredcategory/<>/change/',
    party: '/unicorn/tracking/featuredparty/<>/change/',
    storefront: '/unicorn/tracking/featuredomniparty/<>/change/',
};

export function MarketFeatureButton(props) {
    const [isFeatured, setIsFeatured] = useState(props.isFeatured);
    const [saving, setSaving] = useState(false);
    const [reviewedToPk, setReviewedToPk] = useState(props.reviewedToPk);
    function onClick(e, position="end") {
        e.preventDefault();
        setSaving(true);
        $.post('/api/v2/market_curation_ctl/', {
            action: 'feature',
            object_pk: props.objectPk,
            feature_type: props.featureType,
            active: !isFeatured,
            position: position,
        }, rsp => {
            setIsFeatured(rsp.active);
            setSaving(false);
            const message = rsp.active
                          ? 'Featured, it will appear on the market home page.'
                          : 'No longer featured';
            toast.success(message);
        });
    }
    const title = `Click to ${isFeatured ? 'unfeature' : 'feature'} this`;
    function markReviewedToHere(e) {
        e.preventDefault();
        $.post('/api/v2/market_curation_ctl/', {
            action: 'reviewed',
            object_pk: props.objectPk,
            feature_type: props.featureType,
        }, rsp => {
            setReviewedToPk(props.objectPk);
            toast.success('Set reviewed bookmark, all items at and before the bookmark will disappear on next page reload');
        });
    }
    function onAdminLinkClick(e) {
        e.preventDefault();
        $.post('/api/v2/market_curation_ctl/', {
            action: 'admin_link',
            object_pk: props.objectPk,
            feature_type: props.featureType,
        }, rsp => {
            window.open(
                adminEditLinks[props.featureType].replace('<>', rsp.feature_pk)
            );
        });
    }
    return <React.Fragment>
      {isFeatured &&
       <React.Fragment>
         <button className="btn btn-link btn-sm"
                 title={title}
                 disabled={saving}
                 onClick={onClick}>
           <span style={{color: '#ffc000'}}>
             <i className="ffArticleActionIcon fa fa-star"></i>
           </span>
         </button>
         <span style={{marginLeft: '10px'}}>
           <a href="" title={title} onClick={onClick}>unfeature</a>
         </span>
       </React.Fragment>
      ||
       <div className="dropdown">
         <button className="btn btn-outline-secondary btn-sm dropdown-toggle"
                 title={title}
                 disabled={saving}
                 type="button"
                 id="dropdownMenuButton1"
                 data-bs-toggle="dropdown"
                 aria-expanded="false">
           Click to feature
         </button>
         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
           <li>
             <a className="dropdown-item" href="" onClick={(e) => onClick(e, "start")}>
               At the top
             </a>
           </li>
           <li>
             <a className="dropdown-item" href="" onClick={(e) => onClick(e, "middle")}>
               In the middle
             </a>
           </li>
           <li>
             <a className="dropdown-item" href="" onClick={(e) => onClick(e, "end")}>
               At the bottom
             </a>
           </li>
           <li>
             <a className="dropdown-item" href="" onClick={(e) => onClick(e, "random")}>
               Random
             </a>
           </li>
         </ul>
       </div>
      }
      {isFeatured &&
       <span style={{marginLeft: '10px'}}>
         <a href="" onClick={onAdminLinkClick}>admin link</a>
       </span>
      }
      {props.reviewedToPk !== null && reviewedToPk < props.objectPk &&
       <p>
         <a href="" onClick={markReviewedToHere}>
           mark reviewed to here
         </a>
       </p>
      }
    </React.Fragment>;
}

MarketFeatureButton.propTypes = {
    isFeatured: PropTypes.bool.isRequired,
    featureType: PropTypes.string.isRequired,
    objectPk: PropTypes.number.isRequired,
    // when null dont even show the mark reviewed link
    reviewedToPk: PropTypes.number,
};

export default function MarketFeatureButtonApp(el, props) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<MarketFeatureButton {...props} />);
}
