import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import moment from 'moment';

import LoadingSpinner from './LoadingSpinner.jsx';
import { fetchJSON, useInterval } from './util.jsx';

function StrPartyShuffleStatus(props) {
    const [nextShuffleTime, setNextShuffleTime] = useState(null);
    const [didInit, setDidInit] = useState(false);
    const [recentShuffles, setRecentShuffles] = useState([]);
    const [processingShuffles, setProcessingShuffles] = useState([]);
    const [allSellers, setAllSellers] = useState([]);
    const [pinnedSellers, setPinnedSellers] = useState([]);
    const [newPinnedSellerText, setNewPinnedSellerText] = useState('');

    const statusUrl = `/api/v2/party_shuffle_status/${props.partyPk}/`;
    const sellersUrl = `/api/v2/party_sellers/${props.partyPk}/`;

    useInterval(fetchData, 5000);
    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function fetchData() {
        let data = await fetchJSON(statusUrl);
        setNextShuffleTime(moment(data.next_shuffle_time));
        setRecentShuffles(data.recent_shuffles);
        setProcessingShuffles(data.processing_shuffles);
        setPinnedSellers(data.pinned_sellers);
        data = await fetchJSON(sellersUrl);
        setAllSellers(data.sellers);
        setDidInit(true);
    }

    function newPinnedSellerChange(e) {
        setNewPinnedSellerText(e.target.value);
    }

    function onPinClick(e, seller) {
        $.post(sellersUrl, {action: 'pin', id: seller.pk}, fetchData);
    }

    function onUnpinClick(e, pk) {
        $.post(sellersUrl, {action: 'unpin', id: pk}, fetchData);
    }

    function movePinnedSeller(direction, pk) {
        $.post(sellersUrl, {direction, action: 'move', id: pk}, fetchData);
    }

    function onPinAdminsClick(e) {
        $.post(sellersUrl, {action: 'pinAdmins'}, fetchData);
    }

    if (!didInit)
        return <LoadingSpinner />;

    let sellerSearchResults = null;
    if (newPinnedSellerText.length > 0) {
        sellerSearchResults = allSellers
            .filter(s => !s.pinned)
            .filter(s => s.username.toLowerCase().includes(newPinnedSellerText.toLowerCase()))
            .map(s => (
                <div key={s.pk}>
                  <button className="btn btn-default btn-xs"
                          onClick={(e) => onPinClick(e, s)}>
                    <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                    {' '}
                    Pin
                  </button>
                  <div style={{
                      display: 'inline-block',
                      marginLeft: '5px',
                      position: 'relative',
                      top: '2px',
                  }}>
                    {s.username}
                    {' '}
                    {s.admin && <span className="label label-danger text-tiny">admin</span>}
                  </div>
                </div>
            ));
    }

    let shuffStuff;

    if (nextShuffleTime === null || !nextShuffleTime.isValid()) {
        shuffStuff = (
            <p>No shuffles currently scheduled</p>
        );
    } else {
        shuffStuff = <p>Next shuffle {nextShuffleTime.fromNow()}</p>;
    }

    let recentShuffs = recentShuffles.map(([id, s]) => <li key={id}>{moment(s).fromNow()}</li>);
    let processingShuffs = processingShuffles.map(
        ([id, s]) => <li key={id}>{moment(s).calendar()} <LoadingSpinner /></li>
    );

    return (
        <div>
          <hr />
          <h4>Pinned retailers</h4>
          <p className="text-small">
            These lucky folks will be on top after shuffling.
          </p>
          <div style={{display: 'inline-block'}}>
            {pinnedSellers.map(({pk, username, admin}, i) => (
                <div key={pk}
                     style={{
                         padding: '0.2em 0.5em',
                         margin: '0.2em',
                         border: '1px dashed #aaa',
                         background: '#f5f5f5',
                     }}>
                  <div className="btn-group btn-group-xs"
                       style={{
                           marginLeft: '10px',
                           position: 'relative',
                           top: '-2px',
                       }}>
                    <button className="btn btn-default"
                            onClick={e => movePinnedSeller('up', pk)}>
                      <i className="fa fa-arrow-up"></i>
                    </button>
                    <button className="btn btn-default"
                            onClick={e => movePinnedSeller('down', pk)}>
                      <i className="fa fa-arrow-down"></i>
                    </button>
                    <button className="btn btn-default"
                            onClick={(e) => onUnpinClick(e, pk)}>
                      <i className="fa fa-chain-broken" aria-hidden="true"></i>
                      {' '}
                      Unpin
                    </button>
                  </div>
                  {' '}
                  {username}
                  {' '}
                  {admin && <span className="label label-danger text-tiny">admin</span>}
                </div>
            ))}
          </div>
          <div style={{marginBottom: "10px"}}>
            <button className="btn btn-default btn-xs" onClick={onPinAdminsClick}>
              <i className="fa fa-thumb-tack" aria-hidden="true"></i>
              {' '}
              Pin all admins
            </button>
          </div>
          <div className="row">
            <div className="col-md-3">
              <input className="form-control"
                     type="text"
                     onChange={newPinnedSellerChange}
                     value={newPinnedSellerText}
                     placeholder="Search by username" />
            </div>
          </div>
          <div>
            {sellerSearchResults}
          </div>
          <hr />
          <h4>Status</h4>
          <div className="well" style={{display: 'inline-block'}}>
            {shuffStuff}
            {processingShuffs.length > 0 &&
             <div>
               <h5>Shuffles in progress</h5>
               <ul className="list-unstyled">{processingShuffs}</ul>
             </div>
            }
            <h5>Recent shuffles</h5>
            <ul className="list-unstyled">
              {recentShuffs}
              {recentShuffs.length === 0 && <li>None</li>}
            </ul>
          </div>
        </div>
    );
}

StrPartyShuffleStatus.propTypes = {
    partyPk: PropTypes.number.isRequired,
};

export default function StrPartyShuffleApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrPartyShuffleStatus partyPk={data.partyPk} />);
}
