import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

const CompanySettingsReview = (props) => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');

    const apiUrl = '/api/v2/account_setup/';

    useEffect(fetch, []);

    function fetch() {
        $.getJSON(apiUrl, (data) => {
            setCompanies(data.companies);

            if (data.company !== null)
                setSelectedCompany(data.company);
        });
    }

    const saveCompany = () => {
        const data = {
            action: 'set_company',
            company_id: selectedCompany,
        };
        $.post(apiUrl, data, () => {
            toast.success("Company saved");
            props.onComplete();
        })
         .fail((rsp) => {
             toast.error(`Couldn't save company: ${rsp.statusText}`);
         });
    };

    return (
        <div>
          <div className="form-horizontal form-horizontal-compact">
            <div className="form-group">
              <div className="row">
                <div className="col-md-10 col-md-offset-1" style={{marginTop: "5px"}}>
                  <div className="help-block">
                    Picking a company allows Sonlet to automatically suggest inventory options.
                  </div>
                  <div className="help-block">
                    Selling your own custom items? Just select <strong>Other</strong>.
                  </div>
                </div>
              </div>
              <label htmlFor="bizidSelect" className="col-md-6 control-label">Which company are you selling for?</label>
              <div className="col-md-4">
                <select className="form-control input-lg"
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        value={selectedCompany}>
                  {companies.map(company => <option key={company.id}
                                                    value={company.id}>{company.name}</option>)}
                </select>
              </div>
              <div className="row">
                <div className="col-md-10 col-md-offset-1" style={{marginTop: "5px", marginBottom: "20px"}}>
                  <button className="btn btn-primary"
                          onClick={() => saveCompany()}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

CompanySettingsReview.propTypes = {
    onComplete: PropTypes.func.isRequired,
};

const AccountOnboardingJourney = (props) => {
    const [steps, setSteps] = useState([]);
    const [partyWord, setPartyWord] = useState("");
    const [showCompanySettings, setShowCompanySettings] = useState(false);

    const apiUrl = '/api/v2/onboarding_journey/';
    const accountSetupApiUrl = '/api/v2/account_setup/';

    const fetch = () => {
        $.getJSON(apiUrl, (data) => {
            setSteps(data.steps);
            setPartyWord(data.party_word);
        });
    };

    useEffect(fetch, []);

    const onDismissClick = (e) => {
        e.preventDefault();
        const data = {
            action: 'dismiss_setup_journey',
        };
        $.post(accountSetupApiUrl, data, () => location.reload());
    };

    if (steps.length === 0)
        return null;

    const jsCTAs = {
        "js:companySettings": () => setShowCompanySettings(!showCompanySettings),
    };

    const jsDescriptions = {
        "js:getCreatePartyDescription": () =>
            `Create your first ${partyWord || StrUserInfo.words['party']}`,
    };

    const postCTAs = {
        // eslint-disable-next-line react/display-name
        "js:companySettings": () => {
            return showCompanySettings
                 ? <CompanySettingsReview
                       onComplete={() => {
                           setShowCompanySettings(false);
                           fetch();
                       }} />
                 : null;
        },
    };

    const numComplete = steps.filter(s => s.completed).length;
    const pctComplete = 100.0 * (numComplete / steps.length);

    return (
        <div>
          <p className="text-small">Account setup progress:</p>
          <div className="progress">
            <div className="progress-bar progress-bar-success"
                 role="progressbar"
                 aria-valuenow={pctComplete}
                 aria-valuemin="0"
                 aria-valuemax="100"
                 style={{width: `${pctComplete}%`}}>
              <span className="sr-only">{pctComplete}% Complete</span>
            </div>
          </div>
          <ul className="list-unstyled">
            {steps.map((step) => {
                let cta;
                const ctaFn = jsCTAs[step.cta];
                let postCTA = null;
                let description;
                const descriptionFn = jsDescriptions[step.description];
                if (descriptionFn) {
                    description = descriptionFn();
                } else {
                    description = step.description;
                }
                if (ctaFn) {
                    cta = (
                        <a href="#" onClick={(e) => {e.preventDefault(); ctaFn();} }>
                          {description}
                        </a>
                    );
                    postCTA = postCTAs[step.cta]();
                } else {
                    cta = <a href={step.cta}>{description}</a>;
                }
                return (
                    <li key={step.name}>
                      {step.completed ||
                       <i className="fa fa-square-o"></i>
                      }
                      {step.completed &&
                       <i className="fa fa-check-square-o"></i>
                      }
                      {' '}
                      {cta}
                      {postCTA}
                    </li>
                );
            })}
          </ul>
          {numComplete === steps.length &&
           <div>
             <p className="alert alert-info">
               Nice work!
             </p>
             <div className="clearfix">
               <a className="text-small pull-right" href="#" onClick={onDismissClick}>Dismiss</a>
             </div>
           </div>
          }
        </div>
    );
};

AccountOnboardingJourney.propTypes = {
};

export default function AccountOnboardingJourneyApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<AccountOnboardingJourney />);
}
