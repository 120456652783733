import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { StrDayPicker } from './StrDates.jsx';
import CreatableSelect from 'react-select/creatable';
import { difference } from 'lodash-es';

import { ItemAttrsEditor } from './item_attrs_editor.jsx';
import ImageUploadOrSelect from './ImageUploadOrSelect.jsx';
import StockItemSelect from './StockItemSelect.jsx';
import InvItemEditor from './InvItemEditor.jsx';
import { AddItemChoice } from './AddItemChoice.jsx';
import { AddSizeChoice } from './AddSizeChoice.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import { getUUID, styleSizeCustomLabel } from './util.jsx';
import { updateStateThing } from './util.jsx';


/**
 * Why so much copying from InvItemEditor.jsx??  Pull up a chair...
 * I tried factoring out the item form to a separate component but
 * between validation and attributes and everything else I ended
 * up passing data forwards to InvItemEditor through props, and
 * to BulkAdd through callbacks.  The dependencies got so entangled
 * that an update to InvItemEditor was making a callback to BulkAdd,
 * which would trigger a re-render of InvItemEditor which could
 * result in another state update and callback, and so on and so
 * forth.  D:  There's probably a good way to do it, but for now...
 *
 *                    _
 *  _ __  _   _ _ __ | |_
 * | '_ \| | | | '_ \| __|
 * | |_) | |_| | | | | |_
 * | .__/ \__,_|_| |_|\__|
 * |_|
 *
 */


const SaveAllButton = (props) => {
    let saveAllBtnCls = "btn btn-primary btn-lg";
    if (props.disabled)
        saveAllBtnCls += " disabled";
    const spinning = !props.isComplete && props.disabled;
    return (
        <div className="row" style={{marginBottom: "10px"}}>
          <div className="col-md-12">
            <button type="button"
                    className={saveAllBtnCls}
                    onClick={props.callback}>
              <i className="fa fa-floppy-o"></i> Save all
            </button>
            {spinning && <div className="help-text text-small"> Saving... </div>}
            {spinning && <LoadingSpinner />}
          </div>
        </div>
    );
};

SaveAllButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
    isComplete: PropTypes.bool,
};


export class BulkAdd extends React.Component {
    constructor(props) {
        super(props);

        this.settingsDefaults = {
            quantity: 1,
            wholesalePrice: "",
            price: "",
            description: "",
            purchaseDate: moment(),
            attrs: [],
            itemchoice: null,
            size: null,
            createAsListed: true,
        };

        this.activeSaves = {};

        this.state = {
            items: [],
            settingsVisible: false,
            saving: false,
            saveComplete: false,
            styleOptions: [],
            sizeOptions: [],
            savedUids: [],

            /* copied from InvItemEditor.jsx */
            quantity: this.settingsDefaults.quantity,
            wholesalePrice: this.settingsDefaults.wholesalePrice,
            price: this.settingsDefaults.price,
            description: this.settingsDefaults.description,
            purchaseDate: this.settingsDefaults.purchaseDate,
            attrs: this.settingsDefaults.attrs,
            itemchoice: this.settingsDefaults.itemchoice,
            size: this.settingsDefaults.size,
            pricesEdited: false,
            createAsListed: this.settingsDefaults.createAsListed,
            showItemChoiceModal: false,
            itemChoiceInputValue: "",
            showSizeChoiceModal: false,
            sizeInputValue: "",
            /* end copy */
        };

        this.onImage = this.onImage.bind(this);
        this.onStockSelect = this.onStockSelect.bind(this);

        this.editorRefs = {};
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.removeActiveSave = this.removeActiveSave.bind(this);
        this.registerActiveSave = this.registerActiveSave.bind(this);
        this.onCancelSave = this.onCancelSave.bind(this);

        /* copied from InvItemEditor.jsx */
        this.getItemForm = this.getItemForm.bind(this);
        this.onChange = updateStateThing.bind(this);
        this.receivedAttrs = this.receivedAttrs.bind(this);
        this.loadSizeOptions = this.loadSizeOptions.bind(this);
        this.onStyleChange = this.onStyleChange.bind(this);
        this.onCreateItemChoiceOption = this.onCreateItemChoiceOption.bind(this);
        this.onCreateSizeChoiceOption = this.onCreateSizeChoiceOption.bind(this);
        /* end copy */
        this.getDefaultSettings = this.getDefaultSettings.bind(this);

        this.clearSettings = this.clearSettings.bind(this);
        this.onItemChoiceAdd = this.onItemChoiceAdd.bind(this);
        this.onSizeChoiceAdd = this.onSizeChoiceAdd.bind(this);
        this.getUploadArea = this.getUploadArea.bind(this);
        this.getUID = this.getUID.bind(this);
        this._uid = 1;
        this.refreshEditorsChoices = this.refreshEditorsChoices.bind(this);
    }

    getUID() {
        return this._uid++;
    }

    componentDidMount() {
        this.loadStyleOptions();
        this.loadSizeOptions();
    }

    registerActiveSave(uid) {
        this.activeSaves[uid] = true;
        this.setState({saving: true});
    }

    removeActiveSave(uid) {
        if (this.activeSaves[uid]) {
            delete this.activeSaves[uid];
        }
        if (!Object.keys(this.activeSaves).length) {
            this.setState({
                saving: false,
                saveComplete: true,
            });
        }
        this.setState(prevState => ({
            savedUids: [...prevState.savedUids, uid],
        }));
    }

    getUploadArea() {
        const uploader = <ImageUploadOrSelect inputName="bulkImage"
                                              callback={this.onImage}
                                              multiple={true}
                                              invItem={true}
                                              showThumbnails={false} />;

        if (this.props.uploaderOnly)
            return uploader;

        return (
        <div>
          <div className="row" style={{marginTop: "30px"}}>
            <div className="col-md-5">
              <h4>Step 1: Choose default settings (optional)</h4>
              <p className="help-block">
                Set the default {StrUserInfo.words.style}, {StrUserInfo.words.size}, etc. that will be applied to each uploaded image.
              </p>
              <button type="button"
                      className="btn btn-link btn-lg"
                      onClick={() => this.setState(prevState => ({settingsVisible: !prevState.settingsVisible}))}>
                Default settings (optional)
              </button>
              {this.state.settingsVisible &&
               <div>
                 <div className="btn btn-default" onClick={this.clearSettings}>Clear</div>
                 {this.getItemForm()}
               </div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <h4>Step 2: Upload images</h4>
              {uploader}
            </div>
          </div>
        </div>
        );
    }

    render() {
        /* Shenanigans...  Since arrays have to return a single parent
           element we can't shove the clearfix after the col-md-4.  Instead,
           we have to build a separate array where we do the modulo and insert
           a placeholder */
        let itemsWithClears;
        if (this.props.noEditorsClearfix) {
            itemsWithClears = this.state.items;
        } else {
            itemsWithClears = [];
            let i = 0;
            for (const item of this.state.items) {
                if (i && (i % 3) === 0)
                    itemsWithClears.push(null);
                itemsWithClears.push(item);
                i++;
            }
        }
        const defaultSettings = this.getDefaultSettings();
        const editors = itemsWithClears.map(ii => {
            if (!ii)
                return <div key={getUUID()} className="clearfix"></div>;
            return (
                <div key={ii.uid}
                     className={this.props.editorsClassName}
                     style={{textAlign: "left"}}>
                  <InvItemEditor ref={el => { if (el) this.editorRefs[ii.uid] = el; } }
                                 key={ii.uid}
                                 id={ii.uid}
                                 onDeleteClick={this.onDeleteClick.bind(this, ii.uid)}
                                 image={ii.image}
                                 originalImage={ii.originalImage}
                                 defaultSettings={this.mergeDefaults(ii, defaultSettings)}
                                 onSaveComplete={this.removeActiveSave.bind(this, ii.uid)}
                                 onSaveError={this.onCancelSave}
                                 onItemChoiceAdd={this.refreshEditorsChoices}
                                 onSizeChoiceAdd={this.refreshEditorsChoices} />
                </div>
            );
        });

        const haveUnsaved = difference(this.state.items.map(ii => ii.uid),
                                         this.state.savedUids)
                             .length > 0;
        return (
            <div>
              {this.getUploadArea()}
              <div id="editors"></div>
              {editors.length > 0 &&
               <SaveAllButton disabled={this.state.saving || !haveUnsaved}
                              isComplete={this.state.saveComplete}
                              callback={this.onSaveAllClick} />
              }
              <div className="row">
                {editors}
              </div>
              {editors.length > 0 &&
               <SaveAllButton disabled={this.state.saving || !haveUnsaved}
                              isComplete={this.state.saveComplete}
                              callback={this.onSaveAllClick} />
              }
            </div>
        );
    }

    onDeleteClick(uid) {
        this.setState(prevState => {
            const idx = prevState.items.findIndex(ii => ii.uid === uid);
            return {
                items: update(prevState.items, {
                    $splice: [[idx, 1]],
                }),
            };
        });
        /* we have to clean up the editorRefs object since it's not cleaned
           up anywhere else.  We can't clean it up in render(), for example,
           since that could be called during partial renders, etc. where we
           don't see all of the editor refs, meaning we'd be deleting some and
           never replacing them. */
        delete this.editorRefs[uid];
    }

    onImage(info, originalImage) {
        const newImage = {
            image: info.file,
            uid: this.getUID(),
            originalImage: originalImage,
        };
        this.setState((prevState, props) => ({
            items: update(prevState.items, {
                $push: [newImage],
            }),
            saveComplete: false,
        }));
        this.props.onImage && this.props.onImage(newImage);
    }

    onStockSelect(item) {
        const uiditem = update(item, {$merge: {
            image: {
                thumbnailUrl: item.image.image_thumbnail,
                url: item.image.image_full,
                id: item.image.pk,
            },
            uid: this.getUID(),
        }});

        this.setState((prevState, props) => ({
            items: update(prevState.items, {
                $push: [uiditem],
            }),
        }));
    }

    /* from InvItemEditor.jsx */
    onCreateItemChoiceOption(inputValue) {
        this.setState({
            showItemChoiceModal: true,
            itemChoiceInputValue: inputValue,
        });
    }

    onCreateSizeChoiceOption(inputValue) {
        this.setState({
            showSizeChoiceModal: true,
            sizeInputValue: inputValue,
        });
    }

    getItemForm() {
        let bizParams = (typeof str_biz_params !== 'undefined')
                      ? str_biz_params
                      : {'suggested_attrs': []};

        return (
            <div className="well well-sm">
              <div className="row">
                <div className="col-md-12">
                  <p className="help-block">These settings can be changed on a per-item basis after upload.</p>
                  <div>
                    <div style={{marginBottom: '10px'}}>
                      <CreatableSelect name="itemchoice_id"
                                       value={this.state.itemchoice}
                                       onChange={this.onStyleChange}
                                       placeholder={StrUserInfo.words.style}
                                       options={this.state.styleOptions}
                                       onCreateOption={this.onCreateItemChoiceOption}
                      />
                      <AddItemChoice onAdd={this.onItemChoiceAdd}
                                     initialName={this.state.itemChoiceInputValue}
                                     showModal={this.state.showItemChoiceModal}
                      />
                    </div>
                    <div>
                      <CreatableSelect name="size_id"
                                       value={this.state.size}
                                       onChange={(size) => this.setState({size}) }
                                       placeholder={StrUserInfo.words.size}
                                       options={this.state.sizeOptions}
                                       onCreateOption={this.onCreateSizeChoiceOption}
                      />
                      <AddSizeChoice onAdd={this.onSizeChoiceAdd}
                                     initialName={this.state.sizeInputValue}
                                     showModal={this.state.showSizeChoiceModal}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="help-block">Attributes</p>
                    <ItemAttrsEditor bizParams={bizParams}
                                     defaultAttrs={this.state.attrs}
                                     receivedAttrs={this.receivedAttrs}
                                     quickAdds={strDefaultQuickAdds}
                                     liteStyle={false} />
                  </div>
                </div>
                <div className="col-md-12 form-horizontal">
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Quantity</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" min="1"
                             onChange={this.onChange}
                             name="quantity"
                             value={this.state.quantity} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Wholesale price</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" min="0" step="0.01"
                             onChange={this.onChange}
                             onKeyPress={() => this.setState({pricesEdited: true})}
                             name="wholesalePrice"
                             value={this.state.wholesalePrice} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Price</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" min="0" step="0.01"
                             onChange={this.onChange}
                             onKeyPress={() => this.setState({pricesEdited: true})}
                             name="price"
                             value={this.state.price} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Description</label>
                    <div className="col-sm-8">
                      <textarea className="form-control" rows="3"
                                onChange={this.onChange}
                                name="description"
                                value={this.state.description} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Purchase date</label>
                    <div className="col-sm-8" style={{paddingTop: "6px"}}>
                      <StrDayPicker
                         mode="single"
                         selected={this.state.purchaseDate.toDate()}
                         onSelect={d => this.setState({ purchaseDate: moment(d) })}
                     />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Auto-list</label>
                    <div className="col-sm-8"
                         style={{paddingTop: '7px'}}>
                      <label className="text-muted text-small"
                             style={{fontWeight: 'normal'}}>
                        <input type="checkbox"
                               checked={this.state.createAsListed}
                               name="createAsListed"
                               onChange={this.onChange} />
                        {' '}
                        Make these items available for sale right away.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }

    /* from InvItemEditor.jsx */
    receivedAttrs(attrs) {
        this.setState({attrs:attrs});
        if (!is_str && !this.state.pricesEdited) {
            const self = this;
            const data = encodeURIComponent(JSON.stringify(attrs));
            const url = '/api/v2/stockitems/pricing/?attrs=' + data;
            $.getJSON(url, function(json) {
                if (json.length > 0) {
                    self.setState({
                        wholesalePrice: json[0].wholesale_price,
                        price: json[0].suggested_retail_price,
                    });
                }
            });
        }
    }

    /* from InvItemEditor.jsx */
    loadStyleOptions() {
        const url = '/api/v2/itemchoices/';
        const self = this;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              const options = json.results.map(el => ({
                  label: styleSizeCustomLabel(el),
                  value: el.pk,
              }));
              self.setState({ styleOptions: options });
          });
    }

    /* from InvItemEditor.jsx */
    loadSizeOptions() {
        const url = '/api/v2/sizes/';
        const self = this;
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              const options = json.results.sort(
                  (a, b) => Number.parseInt(a.sort_order)
                        - Number.parseInt(b.sort_order)
              ).map(el => ({
                  label: styleSizeCustomLabel(el),
                  value: el.pk,
              }));
              self.setState({ sizeOptions: options });
          });
    }

    /* from InvItemEditor.jsx */
    onStyleChange(data) {
        this.setState({itemchoice: data});
        const url = `/itemchoice_params/${data.value}/`;
        const self = this;
        $.getJSON(url, function(data) {
            self.setState({
                wholesalePrice: data.wholesale_price + '',
                price: data.suggested_retail_price_top + '',
            });
        });
    }

    mergeDefaults(i, defaults) {
        if (i.data) {
            return update(defaults, {$merge: {
                wholesalePrice: i.wholesale_price,
                price: i.suggested_retail_price,
                attrs: i.data.attributes,
            }});
        }
        return defaults;
    }

    getDefaultSettings() {
        return {
            quantity: this.state.quantity,
            wholesalePrice: this.state.wholesalePrice,
            price: this.state.price,
            description: this.state.description,
            purchaseDate: this.state.purchaseDate,
            attrs: this.state.attrs,
            itemchoice: this.state.itemchoice,
            size: this.state.size,
            createAsListed: this.state.createAsListed,
        };
    }

    clearSettings() {
        const newState = {};
        for (const setting in this.settingsDefaults)
            newState[setting] = this.settingsDefaults[setting];
        this.setState(newState);
    }

    onSaveAllClick() {
        for (const i in this.editorRefs) {
            const editor = this.editorRefs[i];
            if (!editor.state.pk) {
                this.registerActiveSave(i);
                editor.onSaveClick();
            }
        }
    }

    onCancelSave() {
        this.setState({
            saving: false,
            saveComplete: false,
        });
    }

    onItemChoiceAdd(data) {
        const newStyle = {label: data.name, value: data.pk};
        this.setState((prevState, props) => ({
            styleOptions: update(prevState.styleOptions, {
                $push: [newStyle],
            }),
            showItemChoiceModal: false,
        }), () => {
            this.onStyleChange(newStyle);
        });
    }

    onSizeChoiceAdd(data) {
        const newSize = {label: data.name, value: data.pk};
        this.setState((prevState, props) => ({
            sizeOptions: update(prevState.sizeOptions, {
                $push: [newSize],
            }),
            showSizeChoiceModal: false,
        }), () => {
            this.setState({size: newSize});
        });
    }

    refreshEditorsChoices() {
        for (const i in this.editorRefs) {
            const editor = this.editorRefs[i];
            editor.refreshStyleSizeOptions();
        }
    }
}

BulkAdd.propTypes = {
    uploaderOnly: PropTypes.bool,
    noEditorsClearfix: PropTypes.bool,
    editorsClassName: PropTypes.string,
    onImage: PropTypes.func,
};

BulkAdd.defaultProps = {
    uploaderOnly: false,
    noEditorsClearfix: false,
    editorsClassName: "col-md-4",
};
