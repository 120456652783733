import React from 'react';
import { createRoot } from 'react-dom/client';

import {MergeOrders} from './MergeOrders.jsx';
import { fetchJSON } from './util.jsx';

async function MergeOrdersApp(el, orders, onSuccess) {
    if (el === null)
        return;
    /* this lets us pass in an array of customer objects OR an array of
     * claimbatch pks. map + async: https://stackoverflow.com/a/40140562*/
    if (typeof orders[0] !== 'object') {
        orders = await Promise.all(
            orders.map(async order => {
                return await fetchJSON(`/api/v2/merge_order_data/${order}/`);
            })
        );
    }
    const root = createRoot(el);
    root.render(<MergeOrders orders={orders} onSuccess={onSuccess} />);
}

export default MergeOrdersApp;
