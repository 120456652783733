import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ClaimButton from './ClaimButton.jsx';
import { formatCurrency } from './util.jsx';
import { ItemDetails } from './item_details_app.jsx';
import { LikeButton } from './LikeButton.jsx';
import FeatureButton from './FeatureButton.jsx';
import AdminRemoveItem from './AdminRemoveItem.jsx';
import OmniPartyHideItem from './OmniPartyHideItem.jsx';

const get_item_images = (item, selectedVariant) => {
    if (selectedVariant && selectedVariant.image_full) {
        return {
            full: selectedVariant.image_full,
            medium: selectedVariant.image_medium,
        };
    }
    return {
        full: item.image_full_url || item.image.image_full,
        medium: item.image_medium_url || item.image.image_medium,
    };
};

export default function StrPartyItem(props) {
    const { item, partyPk, partyAlbumPk, isOwner, isLiked, isOmniParty,
            partyPublicId } = props;
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState(null);

    const onVariantChange = (variant) => {
        setSelectedVariant(variant);
    };

    const iclaimed = typeof item.iclaimed === 'string'
                   ? JSON.parse(item.iclaimed)
                   : item.iclaimed;
    /* show as claimed if the current user claimed it and it is not
     * batched, otherwise show as sold */
    const verbed = iclaimed.date && !iclaimed.is_batched
                 ? str_data_party_words.claimed : str_data_party_words.sold;
    const claimRowColClasses = "col-md-12";
    const images = get_item_images(item, selectedVariant);
    return (
        <div className="col-sm-4 col-md-3">
          <div className="thumbnail"
               style={{
                   boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.13)',
                   border: 'none',
               }}>
            {item.is_promoted &&
             <div className="text-small text-center text-muted"
                  style={{marginBottom: "5px"}}>
               <span className="label label-success">
                 Promoted
                 {' '}
                 <i className="fa fa-check-circle"></i>
               </span>
             </div>
            }
            <div className="custom-carousel">
              <div className="carousel-inner">
                <a className="partyitem active"
                   href={images.full}
                   disabled={StrUserInfo.banned}
                   onClick={(e) => {
                       e.preventDefault();
                       setShowDetailsModal(true);
                   }}>
                  <figure>
                    <img alt={item.name}
                         src={images.medium} />
                  </figure>
                </a>
              </div>
            </div>

            <div className="caption">
              <div className="row">
                <div className={`${claimRowColClasses}`}
                     style={{paddingTop: '10px'}}>
                  {item.title &&
                   <h4 style={{"color": "#333", "margin": "0"}}>
                     {item.title}
                   </h4>
                  }
                  {item.style &&
                   <h4 style={{"color": "#333", "margin": "0"}}>
                     {item.style}{item.size ? ` (${item.size})` : ''}
                   </h4>
                  }
                  {!isOmniParty &&
                   <h5 style={{"margin": "0 0 5px"}}>
                     {item.dropship_item &&
                      <React.Fragment>
                        By {item.dropship_item.consultant}
                        <div>
                          <span className="label label-info text-tiny">{item.consultant}</span>
                        </div>
                      </React.Fragment>
                     }
                     {!item.dropship_item &&
                      <React.Fragment>By {item.consultant}</React.Fragment>
                     }
                   </h5>
                  }
                  <p className="price" style={{marginBottom: '0'}}>
                    {formatCurrency(item.price)}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className={claimRowColClasses}>
                  {item.is_digital &&
                   <p className="text-small text-muted"
                      style={{paddingTop: '10px'}}>
                     <i className="fa fa-cloud-download" aria-hidden="true"></i>
                     {' '}
                     Digital download
                   </p>
                  }
                  <ClaimButton itemPk={item.pk}
                               dsItemPk={item.dropship_item ? item.dropship_item.pk : null}
                               btnSize='btn-block'
                               claimedFromPartyPk={partyPk}
                               claimedFromPartyAlbumPk={partyAlbumPk}
                               claimedFromOmniParty={isOmniParty}
                               disableAnonClaims={item.disable_anon_claims}
                               quantity={item.quantity}
                               iclaimed={iclaimed}
                               words={str_data_party_words}
                               variantData={item.variant_data}
                               onVariantChange={onVariantChange}
                               isMarketItem={item.is_market_item}
                               isPromoted={item.is_promoted} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  {isOwner && !isOmniParty && !item.is_promoted &&
                   <span style={{marginRight: '7px'}}>
                     <AdminRemoveItem partyPublicId={partyPublicId}
                                      itemPk={item.pk} />
                   </span>
                  }
                  {!props.hideAskQuestion &&
                   <button className="btn btn-link question-btn"
                           style={{paddingLeft: '0', paddingRight: '0'}}
                           disabled={!!StrUserInfo.banned}
                           data-item-id={item.pk}
                           data-username={item.consultant_username }>
                     <i className="fa fa-question-circle" aria-hidden="true"></i>
                     {' '}
                     Ask a question
                   </button>
                  }
                  <span className="pull-left" style={{marginRight: '5px'}}>
                    <LikeButton itemPk={item.pk}
                                isLiked={isLiked}
                                partyPublicId={partyPublicId}
                                isOmniParty={isOmniParty} />
                  </span>
                  {props.showFeatured &&
                   <span className="pull-left" style={{marginRight: '5px'}}>
                     <FeatureButton itemPk={item.pk}
                                    isFeatured={props.isFeatured} />
                   </span>
                  }
                  {isOwner && isOmniParty &&
                   <span className="pull-left" style={{marginRight: '5px'}}>
                     <OmniPartyHideItem itemPk={item.pk} />
                   </span>
                  }
                  <a href={item.image_full_url}
                     className="btn btn-link"
                     style={{paddingLeft: '5px', paddingRight: '0'}}
                     disabled={StrUserInfo.banned}
                     onClick={(e) => {
                         e.preventDefault();
                         setShowDetailsModal(true);
                     }}>
                    Details
                  </a>
                </div>
              </div>
            </div>
          </div>

          {showDetailsModal &&
           <ItemDetails itemId={item.pk}
                        onClose={() => setShowDetailsModal(false)}
                        isOmniParty={isOmniParty}
                        partyPk={partyPk}
                        partyAlbumPk={partyAlbumPk} />
          }
        </div>
    );
}

StrPartyItem.propTypes = {
    item: PropTypes.shape({
        pk: PropTypes.number,
        image_full_url: PropTypes.string,
        image_medium_url: PropTypes.string,
        // Market items have an image object rather than prev 2 fields
        image: PropTypes.shape({
            image_full: PropTypes.string.isRequired,
            image_medium: PropTypes.string.isRequired,
        }),
        iclaimed: PropTypes.oneOfType([
            PropTypes.string, // Sometimes the backend gives a string that needs json decoding
            PropTypes.object,
        ]),
        quantity: PropTypes.number,
        disable_anon_claims: PropTypes.bool,
        name: PropTypes.string,
        consultant: PropTypes.string,
        price: PropTypes.number,
        consultant_username: PropTypes.string,
        is_digital: PropTypes.bool,
        variant_data: PropTypes.array,
        style: PropTypes.string,
        size: PropTypes.string,
        title: PropTypes.string,
        is_market_item: PropTypes.bool,
        is_promoted: PropTypes.bool,
        dropship_item: PropTypes.shape({
            pk: PropTypes.number.isRequired,
            consultant: PropTypes.string.isRequired,
        }),
    }).isRequired,
    partyPk: PropTypes.number,
    partyAlbumPk: PropTypes.number,
    partyPublicId: PropTypes.number,
    isOmniParty: PropTypes.bool,
    isOwner: PropTypes.bool.isRequired,
    isLiked: PropTypes.bool.isRequired,
    hideAskQuestion: PropTypes.bool,
    showFeatured: PropTypes.bool,
    isFeatured: PropTypes.bool,
};
