import React from 'react';
import PropTypes from 'prop-types';
import { fetchJSON } from './util.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';


class OrderPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            selectedPk: null,
            orders: [],
            didInitialLoad: false,
            nextOffset: null,
            loading: false,
        };

        this.onClick = this.onClick.bind(this);
        this.onMoreClick = this.onMoreClick.bind(this);
        this.handleBodyClick = this.handleBodyClick.bind(this);
    }

    render() {
        if (this.state.hidden) {
            return <button className="btn btn-default"
                           onClick={() => {
                               this.getReady();
                           }}>
              Add to a recent order
            </button>;
        }

        const thumbStyle = {
            display: "inline-block",
            marginRight: "3px",
        };
        const groupItemStyle = {
            cursor: "pointer",
        };

        const ordersList = this.state.orders.map(order => {
            const items = order.claimrecord_set.map(claim => {
                return (
                    <span key={claim.item_pk}>
                      <div className="thumbnail" style={thumbStyle}>
                        <img className="img-responsive"
                             src={claim.item_thumbnail} />
                        <div className="caption">
                          <div className="text-small">
                            {claim.item}
                          </div>
                        </div>
                      </div>
                    </span>
                );
            });

            let groupItemCls = "list-group-item";
            if (order.pk === this.state.selectedPk)
                groupItemCls += " active";

            return (
                <div className={groupItemCls}
                     style={groupItemStyle}
                     key={order.pk}
                     onClick={e => this.onClick(order)}>
                  <h6 className="list-group-item-heading">Order #{order.pk} - {order.customer_name}</h6>
                  <div className="list-group-item-text">
                    <div className="text-small" style={{marginBottom: "5px"}}>
                      Date: {order.done_shopping_date}
                    </div>
                    {items}
                  </div>
                </div>
            );
        });

        return (
            <div className="StrOrderPickerRoot">
              <h5>Select a recent order</h5>
              <div className="list-group">
                {ordersList}
                {this.state.nextOffset &&
                 <div className="list-group-item text-center">
                   <a href="#"
                      onClick={this.onMoreClick}>More</a>
                 </div>
                }
                {this.state.loading &&
                 <span>
                   {' '}
                   <LoadingSpinner />
                 </span>
                }
              </div>
            </div>
        );
    }

    async getReady() {
        this.setState({
            hidden: false,
            loading: true,
        });
        const data = await fetchJSON('/api/v2/order_picker_orders/');
        this.setState({
            orders: data.results,
            nextOffset: data.next_offset,
            didInitialLoad: true,
            loading: false,
        });

        document.body.addEventListener('click', this.handleBodyClick);
    }

    onClick(order) {
        if (this.state.selectedPk === order.pk) {
            this.setState({selectedPk: null});
            if (this.props.onPick)
                this.props.onPick(null);
        } else {
            this.setState({selectedPk: order.pk});
            if (this.props.onPick)
                this.props.onPick(order);
        }
    }

    async onMoreClick(e) {
        if (this.state.loading)
            return;
        this.setState({loading: true});
        e.preventDefault();
        const data = await fetchJSON(`/api/v2/order_picker_orders/?offset=${this.state.nextOffset}`);
        this.setState({
            orders: this.state.orders.concat(data.results),
            nextOffset: data.next_offset,
            loading: false,
        });
    }

    handleBodyClick(e) {
        const $target = $(e.target);
        if ($target.attr('type') === 'submit')
            return;

        if (this.state.selectedPk
            && $target.closest('.StrOrderPickerRoot').length === 0) {
            this.setState({selectedPk: null});
            this.props.onPick(null);
        }
    }
}

OrderPicker.propTypes = {
    onPick: PropTypes.func,
};

export default OrderPicker;
