import React from 'react';
import PropTypes from 'prop-types';
import {socialStylify} from "../../util.jsx";

class SocialFeaturedItemCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { activity } = this.props;
        const item = JSON.parse(activity.item);

        let what;
        if (item.listingLink)
            what = <a href={item.listingLink}>{item.shortName}</a>;
        else
            what = item.shortName;

        return (
            <div className="text-muted">
              <i className="fa fa-star" style={{color: '#ffc000'}} /> <a href={activity.userData.profileLink}>{activity.userData.displayName}</a> featured an item: {what}
              <img className="ffArticleMediaImage img-responsive" src={item.image.image_full} />
              <div className="SocialText" dangerouslySetInnerHTML={{
                  __html: socialStylify(activity.text || ''),
              }} />
            </div>
        );
    }
}

SocialFeaturedItemCard.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default SocialFeaturedItemCard;
