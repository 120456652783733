import React, { useRef } from "react";
import PropTypes from 'prop-types';
import StrTypeAhead from "./StrTypeAhead.jsx";


function StrChatInput(props) {
    const submitting = useRef();

    function handleEnter(value, cb) {
        var url = '/realtime/';
        var data = props.chatType.getChatInputMessageData(value);
        if (props.parentMessageId)
            data.parent_message_id = props.parentMessageId;
        $.post(url, data, (rsp) => {
            cb();
            if (!rsp.success) {
                alert(rsp.message);
            }
        });
    }

    const source = (query) => {
        if (!query || query.endsWith(' '))
            return;
        const parts = query.split(' ');
        let word = parts.slice(-1)[0];
        if (!word.startsWith('@'))
            return;
        word = word.slice(1).toUpperCase();
        return props.users
                    .filter((u) => u.username.toUpperCase().includes(word)
                              || u.fullname.toUpperCase().includes(word))
                    .map((u) => ({
                        'output': `@${u.username} `,
                        'key': u.username,
                        'displayData': {
                            'user': u,
                        },
                        'triggerLength': word.length + 1,
                    }));
    };
    const display = (data) => {
        const iclass = data.user.is_consultant
                     ? 'fa-star'
                     : 'fa-shopping-bag';
        return (
            <div>
              <b>@{data.user.username}</b> {data.user.fullname} <i className={`fa ${iclass}`}></i>
            </div>
        );
    };

    const footer = (
        <div className="partyChatFooter">
          <i className="fa fa-star"></i> = Seller
          <br />
          <i className="fa fa-shopping-bag"></i> = Shopper
        </div>
    );

    return <StrTypeAhead
               placeholder={props.placeholder}
               initialValue={props.initialValue}
               inputClassName="form-control"
               source={source}
               display={display}
               footer={footer}
               autoFocus={props.autoFocus}
               setFocusInput={props.setFocusInput}
               positionAbsolutely={props.inModal}
               onSubmit={handleEnter} />;
}

StrChatInput.propTypes = {
    placeholder: PropTypes.string,
    users: PropTypes.array.isRequired,
    parentMessageId: PropTypes.number,
    initialValue: PropTypes.string,
    inModal: PropTypes.bool,
    /* If you want a handle to the input ref, provide a callback here. Will
       be called with the focus function (which takes no args) as the only
       argument */
    setFocusInput: PropTypes.func,
    // instantiated StrChatTypes entry
    chatType: PropTypes.object.isRequired,
    autoFocus: PropTypes.bool,
};

export default StrChatInput;
