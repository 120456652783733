import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/// this is a select multi that uses react-select on desktop and falls back to a
/// native select on mobile since react-select is pretty bad on mobile

const PLACEHOLDER_OPTION_VALUE = "";

// Transforms props.initialValue into an array suitable for selectedOptions
const initialValueToOptionsArray = ({initialValue, isMulti}) => {
    // <Select> expects an empty array for "nothing selected".
    // For <select> we pass the value of the placeholder <option>.
    if (initialValue === undefined)
        return isMobile ? [PLACEHOLDER_OPTION_VALUE] : [];
    if (isMulti) {
        // Should already be an array
        return initialValue;
    } else {
        // Scalar needs to be wrapped in a cozy array
        return [initialValue];
    }
};

export default function StrSelect(props) {
    const [selectedOptions, setSelectedOptions] = useState(
        initialValueToOptionsArray(props));
    const optionToReactSelectOption = option => {
        return {
            // store the whole thang for custom getLabellers to refer to
            ...option,
            // set these explicitly for react-select to chew on
            value: option.value,
            label: option.name,
        };
    };
    const optionValueToOption = Object.fromEntries(
        props.options.map(o => [o.value, o])
    );
    return <React.Fragment>
      {isMobile &&
       <React.Fragment>
         {props.isMulti && props.placeholder &&
          <span>{props.placeholder}</span>
         }
         <select className='form-control'
                 multiple={props.isMulti}
                 value={props.isMulti ? selectedOptions : selectedOptions[0]}
                 onChange={event => {
                     const newOpts = Array.from(
                         event.target.selectedOptions,
                         option => option.value
                     );
                     setSelectedOptions(newOpts);
                     const sels = newOpts.map(v => optionValueToOption[v]);
                     props.onChange(props.isMulti ? sels : sels[0]);
                 }}>
           {!props.isMulti && props.placeholder &&
            <option key="placeholder"
                    value={PLACEHOLDER_OPTION_VALUE}
                    disabled>
              {props.placeholder}
            </option>
           }
           {props.options.map(option =>
               <option key={option.value}
                       value={option.value}>
                 {option.name}
               </option>
           )}
         </select>
       </React.Fragment>
      ||
       <Select isMulti={props.isMulti}
               closeMenuOnScrol={true}
               isClearable={true}
               maxMenuHeight={500}
               placeholder={props.placeholder}
               getOptionLabel={option => {
                   if (props.getLabel)
                       return props.getLabel(option);
                   return option.label || option.name;
               }}
               value={selectedOptions.map(
                   optionValue => optionToReactSelectOption(
                       props.options.find(so => so.value === optionValue)
                   )
               )}
               onChange={options => {
                   if (Array.isArray(options))
                       setSelectedOptions(options.map(option => option.value));
                   else
                       setSelectedOptions(options ? [options.value] : []);
                   props.onChange(options);
               }}
               closeMenuOnSelect={selectedOptions.length === 0 || !props.isMulti}
               noOptionsMessage={() => {
                   return selectedOptions.length > 0
                                                 ? 'All options selected'
                                                 : 'No options';
               }}
               options={props.options.map(optionToReactSelectOption)}
               styles={props.customStyles || {}}
       />
      }
    </React.Fragment>;
}

StrSelect.propTypes = {
    // [{name: '', value: ''},]
    options: PropTypes.array.isRequired,
    // a "value" (or array of values) for an option(s) from the options
    // array for initial selection
    initialValue: PropTypes.any,
    // Called on change with the chosen option (array of options when isMulti,
    // otherwise single option)
    onChange: PropTypes.func.isRequired,
    getLabel: PropTypes.func,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    customStyles: PropTypes.object,
};
