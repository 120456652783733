import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import MarkdownEditor from './MarkdownEditor.jsx';
import MarkdownView from './MarkdownView.jsx';
import { fetchJSON } from './util.jsx';
import { isCodemode } from './codeMode.js';
import LoadingSpinner from './LoadingSpinner.jsx';

class PartyJoinRules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            saving: false,
            joinRules: '',
            joinRulesIsCodemode: false,
            isOwner: false,
        };

        this.url = `/api/v2/party_join_rules/${props.publicId}/`;

        this.fetch = this.fetch.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        const data = await fetchJSON(this.url);
        this.setState({
            joinRules: data.join_rules || '',
            joinRulesIsCodemode: isCodemode(data.join_rules),
            isOwner: data.is_owner,
        }, this.props.loadCallback);
    }

    onSave(markdown) {
        this.setState({saving: true, joinRules: markdown});
        $.post(this.url, {joinRules: markdown}, () => {
            this.setState({
                saving: false,
                editing: false,
                joinRulesIsCodemode: isCodemode(markdown),
            });
            toast.success('Join rules updated');
        }).fail(() => {
            this.setState({saving: false});
            toast.error('Unable to update join rules');
        });
    }

    onCancel() {
        this.setState({
            editing: false,
        });
    }

    render() {
        const { joinRules, editing, saving, joinRulesIsCodemode,
                isOwner } = this.state;
        const wellClass = joinRulesIsCodemode ? '' :
                          is_str ? "well" : "well well-sm";
        return (
            <div>
              {!editing && joinRules &&
               <div className={wellClass} style={{margin: '10px 0px 0px 0px'}}>
                 {!joinRulesIsCodemode &&
                  <div>
                    {is_str
                    && <h6>Party Join Rules</h6>
                    || <h5>Party Join Rules</h5>
                    }</div>
                 }
                 <MarkdownView markdown={joinRules} />
               </div>
              }
              {!editing && !joinRules && isOwner &&
               <div style={{margin: '10px 0px 0px 0px'}}>
                 (Optional) Add seller rules and
                 requirements. Sellers will see the rules when
                 requesting to join this {StrUserInfo.words.party.toLowerCase()}.
               </div>
              }
              {!editing && isOwner &&
               <button className="btn btn-default btn-light"
                       style={{marginTop: '10px', marginRight: '10px'}}
                       disabled={saving}
                       onClick={() => this.setState({editing: true}, this.fetch)}>
                 Edit Join Rules
               </button>
              }
              {editing &&
               <div>
                 <MarkdownEditor markdown={joinRules}
                                 onSave={this.onSave}
                                 onCancel={this.onCancel}
                                 source="party-join-rules"
                                 sourceData={{ publicId: this.props.publicId }}
                 />
               </div>
              }
            </div>
        );
    }
}

PartyJoinRules.propTypes = {
    publicId: PropTypes.number.isRequired,
    loadCallback: PropTypes.func,
};

let root = null;

function PartyJoinRulesApp(el, props) {
    if (el === null)
        return;
    if (root)
        root.unmount();
    root = createRoot(el);
    root.render(<PartyJoinRules {...props} />);
}

export default PartyJoinRulesApp;

export { PartyJoinRules };
