import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { formatCurrency, fetchJSON } from './util.jsx';
import InjectedStrBucksModal, { StrBucksModal } from './StrBucksModal.jsx';

const STRBUCKS_EVENT_NAME = 'str-bucks-balance-updated';

export function dispatchStrBucksBalanceChanged(balance) {
    window.dispatchEvent(
        new CustomEvent(STRBUCKS_EVENT_NAME, {detail: balance})
    );
}

export default class StrBucks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingModal: false,
            balance: null,
            achAble: false,
            paymentInfo: null,
            hasSubscription: false,
        };
        this.onClose = this.onClose.bind(this);
        this.fetch = this.fetch.bind(this);
        this.stripePromise = loadStripe(stripe_pub_api_key);
    }

    componentDidMount() {
        this.fetchController = new AbortController();
        this.fetch();
        window.addEventListener(STRBUCKS_EVENT_NAME, (e) => {
            this.setState({balance: e.detail});
        }, {signal: this.fetchController.signal});
    }

    componentWillUnmount() {
        this.fetchController.abort();
    }

    async fetch(showModal=false) {
        const self = this;
        const url = '/strbucks_json/';
        const data = await fetchJSON(url, this.fetchController.signal);
        if (data === null)
            return;
        dispatchStrBucksBalanceChanged(data.strbucks);
        self.setState({
            isShowingModal: showModal,
            balance: data.strbucks,
            achAble: data.ach_able,
            paymentInfo: data.payment_info,
            hasSubscription: data.has_subscription,
        });
    }

    onClose() {
        this.setState({isShowingModal: false});
    }

    render() {
        const balance = this.state.balance !== null ? formatCurrency(this.state.balance) : "loading";
        const company = is_str ? "Sonlet" : "Pop";
        const ModalClass = is_str ? InjectedStrBucksModal : StrBucksModal;
        const content = (
            <div style={{display: 'inline'}}>
              {balance}
              {' '}
              {StrUserInfo.is_unverified &&
               <span className="text-info text-small">
                 Please verify your email address to enable Sonlet Bucks
                 (<a href="/resend_verification/">Resend link</a>)
               </span>
              }
              {StrUserInfo.is_unverified ||
               <button type="button"
                       className="btn btn-link"
                       onClick={() => this.setState({isShowingModal: true},
                                                    () => this.fetch(true))}>
                 Add More
               </button>
              }
              {this.state.isShowingModal &&
               <ModalClass onClose={this.onClose}
                           achAble={this.state.achAble}
                           hasSubscription={this.state.hasSubscription}
                           callback={this.fetch}
                           paymentInfo={this.state.paymentInfo} />
              }
            </div>
        );
        if (is_str) {
            // stripe only on str right now
            return (
                <Elements stripe={this.stripePromise}>
                  {content}
                </Elements>
            );
        } else {
            return content;
        }
    }
}

export function StrBucksApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<StrBucks />);
}
