import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';

import 'style-loader!css-loader!react-day-picker/dist/style.css';

const StrDayPicker = (props) => {
    const [showPicker, setShowPicker] = useState(false);

    const onSelect = (date) => {
        setShowPicker(false);
        props.onSelect(date);
    };

    if (!showPicker) {
        return (
            <React.Fragment>
              {props.selected && moment(props.selected).format("MMMM Do YYYY")}
              {' '}
              <button className="btn btn-link"
                      style={{paddingTop: "0"}}
                      onClick={e => setShowPicker(true)}>Change</button>
            </React.Fragment>
        );
    }

    return (
        <DayPicker
            mode={props.mode}
            selected={props.selected}
            onSelect={onSelect}
        />
    );
};

StrDayPicker.propTypes = {
    mode: PropTypes.string.isRequired,
    // Receives a Date object
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.instanceOf(Date),
};

// TODO: Add an StrDateTimePicker

export { StrDayPicker };
