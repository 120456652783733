import React from 'react';
import ReactDOM from 'react-dom';
import { times } from 'lodash-es';
import {formatCurrency} from '../util.jsx';
import PropTypes from 'prop-types';

class RewardCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited_spent_criteria: this.props.orderPrice || 0,
        };
        this.updateSpentCriteria = this.updateSpentCriteria.bind(this);
    }

    render() {
        const rewardsRenderer = () => {
            switch (this.props.programData.criteria_model) {
            case 1: return this.punchesRenderer();
            case 2: return this.spentRenderer();
            }
        };
        const awards = () => {
            const btnStyle = {margin: '0px 10px 10px 0px'};
            const onClickRedeem = this.props.owner ? () =>
                this.props.awardFunc(this.props.rewardCard, 1) : null;
            const earnedText = this.props.owner ? " Mark Redeemed" : "";
            const onClickUnRedeem = this.props.owner ? () =>
                this.props.awardFunc(this.props.rewardCard, -1) : null;
            const removeRedeemedAward = this.props.owner ? () =>
                this.props.awardFunc(this.props.rewardCard, 0, true) : null;
            const earned = (
                <span>
                  {times( this.props.rewardCard.earned_rewards, (idx) =>
                      <button className="btn btn-primary"
                              style={btnStyle}
                              key={idx}
                              onClick={onClickRedeem}>
                        <i className="fa fa-trophy" aria-hidden="true"></i>{earnedText}
                      </button>)}
                </span>
            );
            const redeemed = (
                <span>
                  {times( this.props.rewardCard.redeemed_rewards, (idx) =>
                      <div key={idx}>
                        <button className="btn btn-default"
                                title="Redeemed"
                                style={{marginBottom: '10px'}}
                                onClick={onClickUnRedeem}>
                          <i className="fa fa-trophy" aria-hidden="true"></i></button>
                        {this.props.owner &&
                         <button className="btn btn-danger"
                                 onClick={removeRedeemedAward}
                                 style={btnStyle}>
                           <span className="glyphicon glyphicon-trash" aria-hidden="true"></span>
                         </button>
                        }
                      </div>
                   )}
                </span>
            );
            return (
                <div style={{marginTop: '15px'}}>{earned}{redeemed}</div>
            );
        };
        return (
            <div className="row">
              <div className="col-md-8">
                {rewardsRenderer()}
              </div>
              <div className="col-md-4">
                {awards()}
              </div>
            </div>
        );
    }

    punchesRenderer() {
        const parsedEarned = this.props.rewardCard.earned ? parseInt(this.props.rewardCard.earned) : 0;
        const earned = () => {
            const onclick = this.props.owner ? () => this.props.updateFunc(
                this.props.rewardCard,
                -1) : null;
            const style = this.props.owner ? {cursor: 'pointer'} : null;
            return (
                <span>
                  {times( parsedEarned, (idx) =>
                      <i className="fa fa-circle"
                         key={idx}
                         aria-hidden="true"
                         style={style}
                         onClick={onclick}>
                      </i> )}
                </span>
            );
        };
        const remaining = () => {
            const amt = parseInt(this.props.programData.criteria) - parsedEarned;
            const onclick = this.props.owner ? () => this.props.updateFunc(
                this.props.rewardCard,
                1) : null;
            const style = this.props.owner ? {cursor: 'pointer'} : null;
            return (
                <span>
                  {times( amt, (idx) =>
                      <i className="fa fa-circle-o"
                         key={idx}
                         aria-hidden="true"
                         style={style}
                         onClick={onclick}>
                      </i> )}
                </span>
            );
        };
        return (
            <div>
              <h1>{earned()}{remaining()}</h1>
              {this.props.owner &&
               <div className="row">
                 <div className="col-xs-3">
                   <button type="button"
                           style={{marginBottom: '10px'}}
                           className="btn btn-default btn-sm"
                           onClick={() => this.props.updateFunc(
                                   this.props.rewardCard,
                                   -earned)}>Reset</button>
                 </div>
               </div>
              }
            </div>
        );
    }

    spentRenderer() {
        const earned = this.props.rewardCard.earned ? parseFloat(this.props.rewardCard.earned) : 0;
        const total = parseFloat(this.props.programData.criteria);
        const percent = earned / total * 100;
        return (
            <div>
              <div className="row" style={{marginTop: '15px'}}>
                <div className="col-md-12">
                  <p>
                    {formatCurrency(earned)} of {formatCurrency(total)}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="progress" style={{height: '20px'}}>
                    <div className="progress-bar"
                         role="progressbar"
                         aria-valuenow={percent}
                         aria-valuemin="0"
                         aria-valuemax={total}
                         style={{width:`${percent}%`}}>
                      <span className="sr-only">
                        ${earned} of ${total}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.owner &&
               <div>
                 <div className="row" style={{marginBottom:'15px'}}>
                   <div className="col-xs-8">
                     <div className="input-group">
                       <div className="input-group-addon input-sm">$</div>
                       <input type="number"
                              className="form-control input-sm"
                              key={this.props.orderNo}
                              defaultValue={this.props.orderPrice && this.props.orderPrice.toFixed(2)}
                              onChange={this.updateSpentCriteria} />
                     </div>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-md-12">
                     <button type="button"
                             className="btn btn-default btn-sm"
                             style={{marginRight: "5px"}}
                             onClick={() => this.props.updateFunc(
                                 this.props.rewardCard,
                                 this.state.edited_spent_criteria,
                             )}>Add</button>
                     <button type="button"
                             className="btn btn-default btn-sm"
                             onClick={() => this.props.updateFunc(
                                 this.props.rewardCard,
                                 -earned,
                             )}>Reset</button>
                   </div>
                 </div>
               </div>
              }
            </div>
        );
    }

    updateSpentCriteria(event) {
        const value = parseFloat(event.target.value);
        this.setState({edited_spent_criteria: value});
    }
}

RewardCard.propTypes = {
    owner: PropTypes.bool.isRequired,
    programData: PropTypes.object.isRequired,
    rewardCard: PropTypes.object.isRequired,
    redeemFunc: PropTypes.func,
    updateFunc: PropTypes.func,
    orderPrice: PropTypes.number,
    orderNo: PropTypes.number,
    awardFunc: PropTypes.func,
};

export default RewardCard;
