import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

import styles from 'style-loader!css-loader!react-image-gallery/styles/css/image-gallery.css';

const Gallery = (props) => {
    const { images, item } = props;
    const allImages = images ? images : [item.image];

    const galleryImages = allImages.map(img => ({
        original: img.image_full,
        thumbnail: img.image_thumbnail,
    }));

    const haveMultipleImages = galleryImages.length > 1;

    if (galleryImages.length === 0)
        return null;

    return (
        <ImageGallery items={galleryImages}
                      showThumbnails={haveMultipleImages}
                      showPlayButton={haveMultipleImages} />
    );
};

Gallery.propTypes = {
    item: PropTypes.object,
    images: PropTypes.array,
    galleryStyle: PropTypes.object,
};

export default Gallery;
