import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getUUID } from '../util.jsx';

const SocialFeedCardDropdown = ({ id, menuItems }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className={`dropdown ${isOpen ? 'open' : ''}`} id={id}>
          <button className="btn btn-link ffAnchorColor dropdown-toggle"
                  type="button"
                  onClick={toggle}>
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
            {menuItems.map(item => (
                <li key={getUUID()}>
                  <a href="#"
                     onClick={(e) => {
                         e.preventDefault();
                         toggle();
                         item.onClick();
                     }}>
                    {item.title}
                  </a>
                </li>
            ))}
          </ul>
        </div>
    );
};

SocialFeedCardDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
};

export default SocialFeedCardDropdown;
