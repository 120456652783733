import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import { updateStateThing, fetchJSON } from './util.jsx';
import AchSetup from './AchSetup.jsx';
import { BillingInfo } from './BillingInfo.jsx';
import { ModalContainer } from './StrModal.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';

const methods = {
    'onFile': 'onFile',
    'newCard': 'newCard',
    'ach': 'ach',
};

class SmsAlaCarteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: true,
            saving: false,
            method: methods.onFile,
            enterCard: null,
            showAchSetup: false,
            achAble: false,
            hasSubscription: false,
            paymentInfo: {},
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.updateStateThing = updateStateThing.bind(this);
        this.achCallback = this.achCallback.bind(this);
    }

    achCallback() {
        this.setState({showAchSetup: false}, () => this.props.callback(true));
    }

    async componentDidMount() {
        const rsp = await fetchJSON('/api/v2/sms_topup/');
        this.setState({
            loading: false,
            achAble: rsp.ach_able,
            hasSubscription: rsp.has_subscription,
            paymentInfo: rsp.payment_info,
        });
    }

    async onSubmit() {
        this.setState({saving: true});
        const { paymentInfo, enterCard, method } = this.state;
        let tokenId = '';
        if (method === methods.newCard) {
            if (paymentInfo && paymentInfo.payment_token && !enterCard) {
                tokenId = paymentInfo.payment_token;
            } else {
                const cardElement = this.props.elements.getElement(CardElement);
                const tokenRsp = await this.props.stripe
                                           .createToken(cardElement);
                if (tokenRsp['token'] === undefined) {
                    return this.setState({
                        saving: false,
                        errorMessage: "Please enter valid payment information",
                    });
                }
                tokenId = tokenRsp['token'].id;
            }
        }
        const url = '/api/v2/sms_topup/';
        const data = {
            method: method,
            source: tokenId,
        };
        const self = this;
        $.post(url, data, function(result) {
            if (result.success) {
                toast.success('Added extra texts');
                self.props.callback();
            } else {
                self.setState({
                    error: result.error,
                    saving: false,
                });
            }
        });
    }

    render() {
        const { onClose, stripe } = this.props;
        const { paymentInfo, achAble, hasSubscription, saving,
                loading, enterCard, method, showAchSetup,
                error } = this.state;
        const buttonText = saving ?
                           <span><i className="fa fa-spinner fa-spin"></i> Purchasing...</span>
                         : "Confirm Purchase";
        const showCardForm = !(paymentInfo && paymentInfo.last_four)
                          || enterCard;
        const nonBizContent = (
            <div className="col-md-12">
              <p>
                Please
                {' '}
                <a href="/billing/plan/">updgrade to a Business plan</a>
                {' '}
                in order to purchase extra texts.
              </p>
            </div>
        );
        const bizContent = (
            <div className="col-md-12">
              {hasSubscription &&
               <div>
                 <label style={{display: 'block', marginTop: '10px'}}>
                   <input
                       type="radio"
                       name="method"
                       style={{marginRight: '10px'}}
                       value={methods.onFile}
                       disabled={loading || saving}
                       onChange={this.updateStateThing}
                       checked={method === methods.onFile}
                   />
                   Use subscription billing on file
                 </label>
                 <div style={{marginLeft: '30px'}}>
                   <BillingInfo />
                 </div>
               </div>
              }

              <label style={{display: 'block', marginTop: '10px'}}>
                <input
                    type="radio"
                    name="method"
                    style={{marginRight: '10px'}}
                    value={methods.newCard}
                    disabled={loading || saving}
                    onChange={this.updateStateThing}
                    checked={method === methods.newCard}
                />
                Credit/Debit Card
              </label>
              <div style={{marginLeft: '10px', maxWidth: '600px'}}>
                {loading && <LoadingSpinner />}
                {!loading && !showCardForm &&
                 <div style={{marginLeft: '20px'}}>
                   {paymentInfo.card_brand} on file ending in <code>
                   {paymentInfo.last_four}
                   </code>
                   <button className="btn btn-default btn-sm"
                           type="button"
                           style={{marginLeft: '15px'}}
                           onClick={() => this.setState({
                               enterCard: true,
                           })} >
                     Choose a different card
                   </button>
                   <p className="help-block">
                     Card saved from prior Sonlet purchase.
                   </p>
                 </div>
                }
                {!loading && showCardForm &&
                 <CardElement onFocus={() =>
                     this.setState({method: methods.newCard})} />
                }
                {!loading && enterCard &&
                 <p>
                   <button className="btn btn-default btn-sm"
                           type="button"
                           style={{marginTop: '10px'}}
                           onClick={() => this.setState({
                               enterCard: null,
                           })} >
                     Back to previous card
                   </button>
                 </p>
                }
              </div>

              <div>
                <label style={{display: 'block', marginTop: '10px'}}>
                  <input
                      type="radio"
                      name="method"
                      style={{marginRight: '10px'}}
                      value={methods.ach}
                      disabled={loading || saving || !achAble}
                      onChange={this.updateStateThing}
                      checked={method === methods.ach}
                  />
                  Bank transfer
                </label>
                {loading && <LoadingSpinner />}
                {!loading &&
                 <div className="help-block"
                      style={{marginLeft: '30px'}}>
                   <p>Buy with your bank account.</p>
                   {!achAble && !showAchSetup &&
                    <button type="button"
                            className="btn btn-link"
                            onClick={() => this.setState({showAchSetup: true})}>
                      Setup a Bank Account
                    </button>
                   }
                   {showAchSetup &&
                    <AchSetup stripe={stripe} callback={this.achCallback} />
                   }
                 </div>
                }
              </div>
            </div>
        );
        return (
            <ModalContainer onClose={onClose} isOpen={true}>
              <div className="container-fluid"
                   style={{maxWidth: '600px', cursor: 'default'}}>
                <h3>Buy Extra Texts</h3>
                <div className="col-md-12">
                  <p>
                    5000 extra texts for $99
                  </p>
                </div>

                {StrUserInfo.plan === "bt_plan_biz" &&
                 bizContent || nonBizContent}

                <div className="col-md-12">
                  <p className="text-muted text-small">
                    The extra texts will remain in your account
                    month to month.
                  </p>
                  <p className="text-muted text-small">
                    Any overage over your normal account texts will
                    draw from the extra texts.
                  </p>
                  <p className="text-muted text-small">
                    The extra texts will only have affect while your
                    account is on a Business plan.
                  </p>
                  <button type="submit"
                          className="btn btn-primary"
                          disabled={StrUserInfo.plan !== "bt_plan_biz" || saving || loading || !this.props.stripe}
                          onClick={this.onSubmit}>
                    {buttonText}
                  </button>
                </div>

                {error &&
                 <div className="col-md-12">
                   <p className="alert alert-danger"
                      style={{marginTop: '10px'}}>{error}</p>
                 </div>
                }
              </div>
            </ModalContainer>
        );
    }
}

SmsAlaCarteModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    stripe: PropTypes.object,
    elements: PropTypes.object,
};


export default function InjectedSmsAlaCarteModal(props) {
    return (
        <ElementsConsumer>
          {({stripe, elements}) => (
              <SmsAlaCarteModal stripe={stripe}
                                elements={elements}
                                {...props} />
          )}
        </ElementsConsumer>
    );
}
