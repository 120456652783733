import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import LoadingSpinner from './LoadingSpinner.jsx';


class ItemPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            styles: null,
            sizes: null,
            categories: null,
            selectedStyle: null,
            selectedSize: null,
            selectedCategory: null,
            matches: [],
        };

        this.onStyleSelect = this.onStyleSelect.bind(this);
        this.onSizeSelect = this.onSizeSelect.bind(this);
        this.onCategorySelect = this.onCategorySelect.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    render() {
        if (this.state.styles === null
            || this.state.sizes === null
            || this.state.categories === null)
            return <LoadingSpinner />;

        const styleOptions = this.state.styles.map(style => ({
            label: style.name,
            value: style,
        }));

        const sizeOptions = this.state.sizes.map(size => ({
            label: size.name,
            value: size,
        }));

        const categoryOptions = this.state.categories.map(category => ({
            label: category.name,
            value: category,
        }));

        const matches = this.state.matches.map(item => {
            return (
                <div key={item.pk} className="col-sm-6 col-md-3">
                  <div className="thumbnail"
                       style={{cursor: "pointer"}}
                       onClick={(e) => this.onSelect(e, item)}>
                    <img src={item.image.image_thumbnail} />
                    <div className="caption text-small text-center">
                      {item.shortName}
                      <div>
                        <a target="_blank"
                           href={item.listingLink}
                           onClick={(e) => e.stopPropagation()}>
                          #{item.pk}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
            );
        });

        return (
            <div>
              <h3>Select an item</h3>
              <div className="row">
                {styleOptions.length !== 0 &&
                 <div className="col-md-3">
                   <Select placeholder="Filter by style"
                           options={styleOptions}
                           onChange={this.onStyleSelect}
                           wrapperStyle={{width: '100%'}}
                           isClearable={true}
                           value={this.state.selectedStyle} />
                 </div>
                }
                {sizeOptions.length !== 0 &&
                 <div className="col-md-3">
                   <Select placeholder="Filter by size"
                           options={sizeOptions}
                           onChange={this.onSizeSelect}
                           wrapperStyle={{width: '100%'}}
                           isClearable={true}
                           value={this.state.selectedSize} />
                 </div>
                }
                {categoryOptions.length !== 0 &&
                 <div className="col-md-3">
                   <Select placeholder="Filter by Category"
                           options={categoryOptions}
                           onChange={this.onCategorySelect}
                           wrapperStyle={{width: '100%'}}
                           isClearable={true}
                           value={this.state.selectedCategory} />
                 </div>
                }
              </div>
              {this.state.loading &&
               <div style={{textAlign: 'center', margin: '10px'}}>
                 <LoadingSpinner />
               </div>
              }
              {matches.length > 0 &&
               <div className="row"
                    style={{
                        maxHeight: "50vh",
                        overflowY: "auto",
                        marginTop: "10px",
                    }}>
                 {matches}
               </div>
              }
            </div>
        );
    }

    componentDidMount() {
        $.getJSON('/api/v2/itemchoices/', {listed: '1'}, (data) => {
            this.setState({styles: data.results});
        });
        $.getJSON('/api/v2/sizes/', (data) => {
            this.setState({sizes: data.results});
        });
        $.getJSON('/api/v2/product_categories/user/', (data) => {
            this.setState({categories: data});
        });
    }

    onStyleSelect(style) {
        this.setState({selectedStyle: style}, this.fetchMatches);
    }

    onSizeSelect(size) {
        this.setState({selectedSize: size}, this.fetchMatches);
    }

    onCategorySelect(category) {
        this.setState({selectedCategory: category}, this.fetchMatches);
    }

    fetchMatches() {
        const { selectedStyle, selectedSize, selectedCategory } = this.state;
        if (!(selectedStyle || selectedSize || selectedCategory)) {
            this.setState({matches: []});
            return;
        }
        const url = '/api/v2/listed_items_all_lean/';
        let params = {};
        if (selectedStyle)
            params.itemchoice_pk = selectedStyle.value.pk;
        if (selectedSize)
            params.size_pk = selectedSize.value.pk;
        if (selectedCategory)
            params.category_pk = selectedCategory.value.pk;
        this.setState({loading: true});
        $.getJSON(url, params, (data) => {
            this.setState({matches: data, loading: false});
        });
    }

    onSelect(e, item) {
        e.preventDefault();
        this.props.onSelect(item);
    }
}

ItemPicker.propTypes = {
    onSelect: PropTypes.func,
};

export default ItemPicker;
