import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { formatCurrency, fetchJSON } from './util.jsx';

class RelatedItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            carouselIndex: 0,
            loading: true,
            showThisMany: 1,
            centeredPadding: 45,
        };

        this.carouselInner = null;
    }

    async componentDidMount() {
        const self = this;
        let url = `/api/v2/related_items/${this.props.itemPk}/`;
        if (this.props.partyPublicId)
            url += `?party_public_id=${this.props.partyPublicId}`;
        self.setState({
            items: await fetchJSON(url),
            loading: false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.items.length !== this.state.items.length) {
            const paddings = 40;
            const itemWidth = 70;
            const showThisMany = Math.floor(
                (this.carouselInner.clientWidth - paddings) / itemWidth);
            const centeredPadding = Math.floor(
                (this.carouselInner.clientWidth - (showThisMany * itemWidth)) / 2) - 1;
            this.setState({showThisMany, centeredPadding});
        }
    }

    mapItems(pagedItems) {
        let displayName = '';
        if (is_str) {
            return pagedItems.map(item => {
                displayName = item.itemchoice.name;
                return this.renderItem(item, displayName);
            });
        }
        return pagedItems.map(item => {
            const itemAttr = item.data.attributes.find(
                attr => attr.name === this.props.relatedAttr);
            displayName = itemAttr.value;
            return this.renderItem(item, displayName);
        });
    }

    renderItem(item, displayName) {
        return (
            <a key={item.pk}
               href={`/claim/i/${item.pk}/`}
               title={`${item.shortName} ${formatCurrency(item.price)}`}
               style={{display: 'inline-block', marginLeft: '10px', width: '60px', fontSize: '14px'}}>
              <div style={{height: '60px'}}>
                <img src={item.image.image_thumbnail}
                     style={{maxHeight: '60px', maxWidth: '60px'}} />
              </div>
              <span style={{
                  fontSize: '10px',
                  width: '57px',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
              }}>{displayName}</span>
              {formatCurrency(item.price)}
            </a>
        );
    }

    handleClick(e, images, direction) {
        e.preventDefault();
        const {carouselIndex} = this.state;
        const index = direction === 'right'
                    ? (carouselIndex + 1) % images.length
                    : (carouselIndex + images.length - 1) % images.length;
        this.setState({carouselIndex: index});
    }

    render() {
        const showThisMany = this.state.showThisMany;
        let pagedItems = this.state.items
                         .slice(this.state.carouselIndex,
                                (this.state.carouselIndex + showThisMany));
        if (this.state.items.length > showThisMany) {
            const wrappedItems = this.state.items
                                     .slice(0, (showThisMany - pagedItems.length));
            pagedItems = pagedItems.concat(wrappedItems);
        }
        const items = this.mapItems(pagedItems);
        if (this.state.loading) {
            /* I would have loved to use this.props.children here but
             * couldn't figure out how to pass them with ReactDOM.render
             * below*/
            return <span>Loading Related Items...</span>;
        }
        if (!this.state.items.length)
            return <span></span>; // There are no related items, show nothing.
        const pluralItem = this.state.items.length !== 1 && 's';
        const showArrows = this.state.items.length > showThisMany;
        const titleAttr = this.props.relatedAttr || 'Item';
        return (
            <div>
              <h5>Related {titleAttr}{pluralItem} ({this.state.items.length})</h5>
              <div className="custom-carousel">
                <div className="carousel-inner"
                     ref={(el) => this.carouselInner = el}
                     style={{padding: `0px ${this.state.centeredPadding}px`, height: '100px'}}>
                  {showArrows &&
                   <a href="#"
                       className="left carousel-control"
                       style={{width: '20px'}}
                       onClick={(e) => this.handleClick(e, this.state.items, "left")}>
                     <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                   </a>
                  }
                  {items}
                  {showArrows &&
                   <a href="#"
                       className="right carousel-control"
                       style={{width: '20px'}}
                       onClick={(e) => this.handleClick(e, this.state.items, "right")}>
                     <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                   </a>
                  }
                </div>
              </div>
            </div>
        );
    }
}

RelatedItems.propTypes = {
    itemPk: PropTypes.number.isRequired,
    relatedAttr: PropTypes.string,

    /* Party public id when viewed from a party feed */
    partyPublicId: PropTypes.number,
};

export function RelatedItemsApp(el, itemPk, relatedAttr) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<RelatedItems itemPk={itemPk} relatedAttr={relatedAttr} />);
}

export default RelatedItems;
