import PropTypes from "prop-types";
import React from "react";
import { stylify, fetchJSON } from "./util.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import StrChatInput from "./StrChatInput.jsx";
import { normalizeData } from "./StrChatEvents.jsx";
import moment from 'moment';

// https://github.com/moment/moment/issues/537
// fixes the problem where some messages appear slightly in the future
function fromNowOrNow(a) {
    // 25 seconds before or after now
    if (Math.abs(moment().diff(moment(a))) < 25000) {
        return 'just now';
    }
    return moment(a).fromNow();
}

class StrChatMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            replies: [],
            repliesExpanded: false,
        };

        this.handleRemove = this.handleRemove.bind(this);
        this.confirmRemove = this.confirmRemove.bind(this);
        this.handleRepliesClick = this.handleRepliesClick.bind(this);
        this.fetchReplies = this.fetchReplies.bind(this);
        this.initRealtimeReplies = this.initRealtimeReplies.bind(this);
        this.expandReplies = this.expandReplies.bind(this);
        this.renderReply = this.renderReply.bind(this);
        this.chatroom = null;
    }

    componentDidMount() {
        if (this.props.isFocused && this.props.message.numReplies > 0)
            this.expandReplies().then(() => this.props.scrollListToBottom());
        this.initRealtimeReplies();
    }

    confirmRemove(e, message, parentMessage=null) {
        e.preventDefault();
        if (confirm(`Really remove this chat message?\n\n${message.text}`)) {
            this.handleRemove(message, parentMessage);
        }
    }

    handleRemove(message, parentMessage=null) {
        let data = this.props.chatType.getRemoveMessageData(message.id);
        if (parentMessage)
            data.parent_message_id = parentMessage.id;
        $.post('/realtime/', data);
    }

    initRealtimeReplies() {
        if (this.chatroom)
            return;
        if (StrUserInfo.banned) return;
        if (!(this.props.chatRoom && this.props.chatRoom.addExtraListener))
            return;
        this.chatRoom = this.props.chatRoom;
        const self = this;
        const messageId = this.props.message.id;
        const evtReply = this.props.chatType.getEvtReply(messageId);
        const evtRemoveReply = this.props.chatType.getEvtRemoveReply(messageId);
        this.chatRoom.addExtraListener(data => {
            if (data.event === evtReply) {
                const msg = normalizeData(data);
                self.setState((prevState, props) => ({
                    replies: prevState.replies.concat([msg]),
                }));
            } else if (evtRemoveReply && data.event === evtRemoveReply) {
                self.setState((prevState, props) => ({
                    replies: prevState.replies.filter(
                        msg => Number(msg.id) !== Number(data.remove_message_id)
                    ),
                }));
            }
        });
    }

    handleRepliesClick(e) {
        e.preventDefault();
        this.expandReplies();
    }

    async expandReplies() {
        await this.fetchReplies();
    }

    async fetchReplies() {
        this.setState({
            repliesExpanded: true,
            fetchingReplies: true,
        });
        var self = this;
        const msgId = this.props.message.id;
        $.getJSON(this.props.chatType.getRepliesUrl(msgId), {}, function(data) {
            self.setState({
                fetchingReplies: false,
                replies: data.replies,
            });
        });
    }

    render() {
        const {message, isFocused, chatType} = this.props;
        const {replies, fetchingReplies, repliesExpanded} = this.state;
        /* message.numReplies - count of replies on initial loaded
         * replies.length     - realtime replies count after load
         * combine them to get the realtime reply count. this number is
         * inaccurate after expanding since replies.length will include
         * the initial load count etc. good thing we dont show it after
         * expanding replies. */
        const numReplies = replies.length + message.numReplies;
        const liClass = isFocused ? "alert alert-info" : "";
        return (
            <li key={message.id} className={liClass}>
              <b>{message.username} </b>
              <a href={this.props.chatType.getMessageHref(message.id)}
                 className="text-muted">
                {fromNowOrNow(message.date)}
              </a>
              {StrUserInfo.ownerInHere &&
               <span style={{float: 'right'}}>
                 <a href="#" className="text-muted"
                    onClick={e => this.confirmRemove(e, message)}
                    title="Admins can remove chat messages"
                    data-username={message.username}>
                   <i className="fa fa-trash" aria-hidden="true"></i>
                 </a>
               </span>
              }
              <div className="str-message-bubble"
                   dangerouslySetInnerHTML={{__html: stylify(message.text)}} />
              {fetchingReplies &&
               <LoadingSpinner />
              }
              {!(repliesExpanded || fetchingReplies) &&
               <a href="#" className="text-small" onClick={this.handleRepliesClick}
                  data-username={message.username}>
                 Reply
                 {numReplies > 0 &&
                  <span>
                    {' '}
                    ({numReplies} {numReplies == 1 ? 'reply' : 'replies'})
                  </span>
                 }
               </a>
              }
              {repliesExpanded &&
               <div style={{
                   margin: "5px 5px 5px 15px",
                   padding: "5px",
                   borderRadius: "3px",
                   boxShadow: "rgba(0, 0, 0, 0.12) 0px 3px 8px 0px",
                   borderLeft: "#e9f7ff 10px solid",
               }}>
                 {replies.map(this.renderReply)}
                 <StrChatInput users={this.props.users}
                               placeholder="Your reply"
                               inModal={this.props.inModal}
                               parentMessageId={message.id}
                               chatType={this.props.chatType} />
               </div>
              }
            </li>
        );
    }

    renderReply(message) {
        return (
            <div key={message.id} style={{
                padding: "5px",
                margin: "5px",
                background: "white",
                borderRadius: "3px",
            }}>
              <b>{message.username} </b>
              <a href={this.props.chatType.getMessageHref(
                  message.parent_message_id
              )}
                 className="text-muted">
                {fromNowOrNow(message.date)}
              </a>
              <i className="fa fa-reply" style={{marginLeft: '5px', color: '#c0c0c0'}}></i>
              {StrUserInfo.ownerInHere &&
               <span style={{float: 'right'}}>
                 <a href="#" className="text-muted"
                    onClick={e => this.confirmRemove(e, message, this.props.message)}
                    title="Admins can remove chat messages"
                    data-username={message.username}>
                   <i className="fa fa-trash" aria-hidden="true"></i>
                 </a>
               </span>
              }
              <div dangerouslySetInnerHTML={{__html: stylify(message.text)}} />
            </div>
        );
    }
}

StrChatMessage.propTypes = {
    chatRoom: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    isFocused: PropTypes.bool.isRequired,
    // instantiated StrChatTypes entry
    chatType: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    scrollListToBottom: PropTypes.func.isRequired,
    inModal: PropTypes.bool,
};

export default StrChatMessage;
