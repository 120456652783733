import React from 'react';
import { createRoot } from 'react-dom/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import moment from 'moment';

import LoadingSpinner from './LoadingSpinner.jsx';
import { fetchJSON } from './util.jsx';
import InjectedSmsAlaCarteModal from './SmsAlaCarteModal.jsx';


class SmsUsageInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            usageInfo: null,
            isShowingModal: false,
        };

        this.onClose = this.onClose.bind(this);
        this.fetch = this.fetch.bind(this);
        this.stripePromise = loadStripe(stripe_pub_api_key);
    }

    onClose() {
        this.setState({isShowingModal: false});
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        this.setState({isShowingModal: false});
        const rsp = await fetchJSON('/api/v2/sms_usage/');
        this.setState({
            loading: false,
            usageInfo: {
                'sms_used_this_billing_period': rsp.sms_used_this_billing_period,
                'sms_quota_sans_a_la_carte': rsp.sms_quota_sans_a_la_carte,
                'sms_quota': rsp.sms_quota,
                'next_billing_date': rsp.next_billing_date,
            },
        });
    }

    render() {
        const { loading, usageInfo } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }
        const a_la_carte_amt = usageInfo.sms_quota - usageInfo.sms_quota_sans_a_la_carte;
        const resetDate = moment(usageInfo.next_billing_date)
            .format('MMMM DD, YYYY');
        return (
            <div>
              <small>
                {usageInfo.sms_used_this_billing_period}
                {' '}
                out of {usageInfo.sms_quota_sans_a_la_carte} texts
                sent this billing period.
                <p>Your text quota will reset on {resetDate}.</p>
                {a_la_carte_amt > 0 &&
                 <p>
                   You have <code>{a_la_carte_amt}</code> extra texts
                   that will carry over each billing period if unused.
                 </p>
                }
                <p>
                  <a style={{cursor: 'pointer'}}
                     onClick={() => {this.setState({isShowingModal: true});}}
                     title={StrUserInfo.plan !== "bt_plan_biz" ? '' : 'Business plan feature'}>
                    <span className="label label-danger biz-star">
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </span>
                    {' '}
                    Purchase extra texts
                  </a>
                </p>
                {this.state.isShowingModal &&
                 <Elements stripe={this.stripePromise}>
                   <InjectedSmsAlaCarteModal onClose={this.onClose}
                                             callback={this.fetch} />
                 </Elements>
                }
              </small>
            </div>
        );
    }
}

function SmsUsageInfoApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SmsUsageInfo />);
}

export { SmsUsageInfo, SmsUsageInfoApp };
