import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import LoadingSpinner from './LoadingSpinner.jsx';
import StrPartyItem from './StrPartyItem.jsx';
import { PartyAnnouncement } from  './party_announcement_app.jsx';
import { MiniProfileByPk } from './social/MiniProfile.jsx';
import { PartyDetailsFilters } from './PartyDetailsFilters.jsx';
import PrivateEye from './PrivateEye.jsx';
import { fetchJSON } from  './util.jsx';


function OmniParty(props) {
    const [loading, setLoading] = useState(false);
    const [endOfItems, setEndOfItems] = useState(false);
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState([]);
    const [featuredItems, setFeaturedItems] = useState(props.party.featuredItems);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(
        () => {
            setLoading(false);
            setEndOfItems(false);
            setOffset(0);
            setItems([]);
            setFeaturedItems(props.party.featuredItems.filter(fi => {
                if (selectedStyles.length > 0
                    && selectedStyles.indexOf(fi.style) === -1) {
                    return false;
                }
                if (selectedSizes.length > 0
                    && selectedSizes.indexOf(fi.size) === -1) {
                    return false;
                }
                if (selectedCategories.length > 0
                    && selectedCategories.indexOf(fi.category) === -1) {
                    return false;
                }
                return true;
            }));
        },
        [selectedStyles, selectedSizes, selectedCategories, props.party.featuredItems]
    );
    const isOwner = StrUserInfo.userid === props.party.userPk;
    const url = `/api/v2/storefront_items/${props.party.publicId}/`;

    async function loadMoreItems() {
        setLoading(true);
        const data = new URLSearchParams({
            offset: offset,
            selectedStyles: JSON.stringify(selectedStyles),
            selectedSizes: JSON.stringify(selectedSizes),
            selectedCategories: JSON.stringify(selectedCategories),
        });
        const rsp = await fetchJSON(
            `${url}?${data.toString()}`
        );
        if (rsp.next_offset > 0) {
            setOffset(rsp.next_offset);
        } else {
            setEndOfItems(true);
        }
        setItems(items.concat(rsp.items));
        setLoading(false);
    }

    function setSelectedStylesSizes(styles, sizes, categories) {
        setSelectedStyles(styles);
        setSelectedSizes(sizes);
        setSelectedCategories(categories);
    }

    function renderSomeItems(someItems, isFeatured=false) {
        return someItems.map(
            (item, idx) => <div key={item.pk}>
              <StrPartyItem item={item}
                            isLiked={item.is_liked}
                            hideAskQuestion={true}
                            isOmniParty={true}
                            showFeatured={isOwner}
                            isFeatured={isFeatured}
                            isOwner={isOwner} />
              {(idx + 1) % 3 === 0 &&
               <div className="clearfix visible-sm-block"></div>
              }
              {(idx + 1) % 4 === 0 &&
               <div className="clearfix visible-md-block visible-lg-block"></div>
              }
            </div>
        );
    }

    // filter out featured item pks
    const featuredItemPks = featuredItems.map(fitem => fitem.pk);
    const partyItems = renderSomeItems(
        items.filter(item => featuredItemPks.indexOf(item.pk) === -1)
    );

    /* the -15px negates the 15px margin-bottom on .mainblock so that the
     * banner photo fits flush against the navbar. the 15px margin-top on
     * the row beneath it brings it back when there is no banner photo */
    return <div style={{marginTop: '-15px'}}>
      {props.party.bannerPhotoUrl &&
       <img className="img-responsive"
            style={{
                minHeight: '100px',
                maxHeight: '400px',
                objectFit: 'cover',
                width: '100%',
            }}
            src={props.party.bannerPhotoUrl} />
      }
      <div className="row" style={{marginTop: '15px'}}>
        <div className="col-md-12">
          <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-md-1"></div>
            {props.party.coverPhotoUrl &&
             <div className="col-md-2">
               <div className="party-cover" style={{
                   padding: '0',
                   maxWidth: '400px',
                   marginLeft: 'auto',
                   marginRight: 'auto',
               }}>
                 <img className="img-rounded" src={props.party.coverPhotoUrl} />
               </div>
             </div>
            }
            <div className={props.party.coverPhotoUrl ? "col-md-6" : "col-md-8"}>
              <h1>{props.party.name}</h1>
              {isOwner &&
               <div style={{marginTop: '10px'}}>
                 <a href='/settings/storefront/'
                    className="btn btn-default btn-sm">
                   <span className="glyphicon glyphicon-pencil"
                         style={{color: '#8a6d3b'}}
                         aria-hidden="true"></span>
                   {' '}
                   Settings
                 </a>
               </div>
              }
              <div style={{marginTop: '10px'}}></div>
              <PartyAnnouncement showEditControls={isOwner}
                                 publicId={props.party.publicId}
                                 partyWord="Storefront"
                                 apiPath={"storefront_announcement"} />
            </div>
            <div className="col-md-2" style={{marginTop: '10px'}}>
              <MiniProfileByPk userPk={props.party.userPk}
                               hideBio={true}
                               hideOmniparty={true} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-offset-1 col-md-10" style={{padding: '0'}}>
              <PartyDetailsFilters styleCounts={props.party.styleCounts}
                                   sizeCounts={props.party.sizeCounts}
                                   categoryCounts={props.party.categoryCounts}
                                   refreshCards={setSelectedStylesSizes} />
            </div>
          </div>

          {featuredItems.length > 0 &&
           <div className="row">
             <div className="col-md-offset-1 col-md-10" style={{padding: '0'}}>
               <h4>
                 <i className="fa fa-star" style={{color: '#ffc000'}} />
                 {' '}
                 Featured Items
               </h4>
               {renderSomeItems(featuredItems, true)}
             </div>
           </div>
          }

          <div className="row" style={{marginTop: "20px"}}>
            <div className="col-md-offset-1 col-md-10" style={{padding: '0'}}>
              {featuredItems.length > 0 &&
               <h4>
                 All Items
               </h4>
              }
              <div className="row">
                <div id="party-items-container">
                  {partyItems}
                  {!loading && partyItems.length === 0 &&
                   <p className="text-center">
                     No items listed yet.
                     {isOwner &&
                      <span>
                        {' '}
                        Add some on the <a href="/listings/">listings</a> page.
                      </span>
                     }
                   </p>
                  }
                </div>
              </div>
              {loading &&
               <div style={{textAlign: 'center', marginTop: '30px'}}>
                 <LoadingSpinner />
               </div>
              }
              {!loading && !endOfItems &&
               <Waypoint onEnter={loadMoreItems} />
              }
            </div>
          </div>

        </div>
      </div>
    </div>;
}

OmniParty.propTypes = {
    party: PropTypes.shape({
        pk: PropTypes.number,
        publicId: PropTypes.number,
        name: PropTypes.string,
        coverPhotoUrl: PropTypes.string,
        bannerPhotoUrl: PropTypes.string,
        userPk: PropTypes.number,
        styleCounts: PropTypes.array,
        sizeCounts: PropTypes.array,
        categoryCounts: PropTypes.array,
        featuredItems: PropTypes.array,
    }).isRequired,
};

export default function OmniPartyApp (el, party) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<OmniParty party={party} />);
}
