import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from '../util.jsx';

export default class FlatRateItemCounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfItems: 1,
            rate: '',
            maxRate: props.flatrateItemCounts.max_rate,
        };

        this.addPriceRule = this.addPriceRule.bind(this);
        this.updateMax = this.updateMax.bind(this);
    }

    addPriceRule(e) {
        e.preventDefault();
        const flatrateItemCounts = {
            rules: this.props.flatrateItemCounts.rules.concat([{
                item_count: this.state.numberOfItems,
                rate: this.state.rate,
                ts: Math.round(new Date()/1000),
            }]),
            max_rate: this.props.flatrateItemCounts.max_rate,
        };
        this.props.updateAndPost(null,
                                 'flatrateItemCounts',
                                 flatrateItemCounts);
        this.setState({
            numberOfItems: 1,
            rate: '',
        });
    }

    removePriceRule(ts) {
        const flatrateItemCounts = {
            rules: this.props.flatrateItemCounts.rules.filter(r => r.ts !== ts),
            max_rate: this.props.flatrateItemCounts.max_rate,
        };
        this.props.updateAndPost(null,
                                 'flatrateItemCounts',
                                 flatrateItemCounts);
    }

    updateMax() {
        const flatrateItemCounts = {
            rules: this.props.flatrateItemCounts.rules,
            max_rate: this.state.maxRate,
        };
        this.props.updateAndPost(null,
                                 'flatrateItemCounts',
                                 flatrateItemCounts);
    }

    render() {
        const { flatrateItemCounts, disabled } = this.props;
        const { rate, numberOfItems, maxRate } = this.state;
        const numberOfItemsWithRulesDefined = flatrateItemCounts.rules.reduce(
            (acc, rule) => acc + Number(rule.item_count),
            0
        );
        const existingRules = flatrateItemCounts.rules.map((rule, idx) => {
            const noun = idx === 0 ? 'First' : 'Next';
            const plural = rule.item_count !== 1;
            const last = idx === flatrateItemCounts.rules.length - 1;
            const message = `${noun} ${plural ? rule.item_count : ''} item${plural ? 's' : ''}`
                          + `${last ? " (and any items beyond " + numberOfItemsWithRulesDefined + ")" : ''}`
                          + ' '
                          + `${formatCurrency(rule.rate)}${plural ? ' each' : ''}`;
            return (
                <p key={rule.ts}>
                  {message}
                  <a className="glyphicon glyphicon-remove"
                     onClick={(e) => {
                         e.preventDefault();
                         !disabled && this.removePriceRule(rule.ts);
                     }}
                     href=""
                     style={{
                         marginLeft: '15px',
                         cursor: disabled ? 'not-allowed' : 'pointer',
                     }}></a>
                </p>
            );
        });
        return (
            <div>
              {flatrateItemCounts.rules.length === 0 &&
               <div className="alert alert-danger">
                 At least one price point rule is required for this to
                 function. Customers will not be charged shipping
                 unless this is setup correctly.
               </div>
              }
              <div className="well">
                {existingRules}
                {existingRules.length === 0 &&
                 <span>Use the form below to add some rules</span>
                ||
                 <form className="form-inline">
                   <div className="form-group">
                     <label htmlFor="maxRate"
                            style={{marginRight: '10px', fontWeight: 'normal'}}>
                       To a maximum of
                       {' '}
                     </label>
                     <div className="input-group">
                       <div className="input-group-addon">$</div>
                       <input className="form-control"
                              type="number"
                              name="maxRate"
                              onChange={(e) => this.setState(
                                  {'maxRate': e.target.value},
                                  this.updateMax)}
                              value={maxRate}
                              disabled={disabled || existingRules.length === 0}
                              placeholder="eg 6.00" />
                     </div>
                     {(!maxRate || maxRate === '0') &&
                      <p className="help-block">0 means no maximum.</p>
                     }
                   </div>
                 </form>
                }
              </div>
              <div className="well well-sm"
                   style={{backgroundColor: "#f2f2f2"}}>
                <form className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="noItems"
                           className="col-sm-4 control-label">
                      Number of items
                    </label>
                    <div className="col-sm-4">
                      <input className="form-control"
                             type="number"
                             name="numberOfItems"
                             min="1"
                             onChange={(e) => this.setState(
                                 {'numberOfItems': e.target.value})}
                             value={numberOfItems}
                             disabled={disabled}
                             placeholder="No. Items" />
                    </div>
                    {existingRules.length > 0 &&
                     <p className="help-block">
                       For item {numberOfItemsWithRulesDefined + 1} and above
                     </p>
                    }
                  </div>

                  <div className="form-group">
                    <label htmlFor="rate"
                           className="col-sm-4 control-label">
                      Rate per item
                    </label>
                    <div className="col-sm-5">
                      <div className="input-group">
                        <div className="input-group-addon">$</div>
                        <input className="form-control"
                               type="number"
                               name="rate"
                               onChange={(e) => this.setState(
                                   {'rate': e.target.value})}
                               value={rate}
                               disabled={disabled}
                               placeholder="eg 6.00" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-4 col-sm-8">
                      <button className="btn btn-default"
                              onClick={this.addPriceRule}
                              disabled={disabled
                                     || !rate
                                     || !numberOfItems}
                              type="submit">
                        Add Price Rule
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        );
    }
}

FlatRateItemCounts.propTypes = {
    flatrateItemCounts: PropTypes.object.isRequired,
    updateAndPost: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};
