import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { flatten, partition } from 'lodash-es';

import { fetchJSON } from './util.jsx';
import { Claim } from './SalesTemplates.jsx';
import { SendDirectMessage } from './SendDirectMessage.jsx';
import ModalContainer from './StrModal.jsx';

function PendingSalesTable(props) {
    // these are actually fake batches, as such they use idx (set by the server)
    // instead of pk
    const [batches, setBatches] = useState([]);
    const [loadingBatchIdx, setLoadingBatchIdx] = useState(null);
    const [expandedClaims, setExpandedClaims] = useState([]);
    const [claimsToCancel, setClaimsToCancel] = useState([]);
    async function fetch() {
        const data = await fetchJSON('/api/v2/sales_serverside_pending/');
        data.sort((a, b) => {
            const aPk = Math.max(...a.claimrecord_set.map(cr => cr.pk));
            const bPk = Math.max(...b.claimrecord_set.map(cr => cr.pk));
            if (aPk < bPk) {
                return 1;
            }
            if (aPk > bPk) {
                return -1;
            }
            return 0;
        });
        setBatches(data);
        setTimeout(str_reinstate_clippies, 200);
        setClaimsToCancel([]);
    }
    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    function markDone(e, batch) {
        setLoadingBatchIdx(batch.idx);
        const data = {
            claim_pks: JSON.stringify(batch.claimrecord_set.map(cr => cr.pk)),
        };
        $.post('/api/v1/mark_done_shopping/', data, function(response) {
            if (response.success) {
                fetch();
                props.refreshTable();
            }
            setLoadingBatchIdx(null);
        });
    }
    function clickCancel(e, batches) {
        setClaimsToCancel(
            flatten(batches.map(batch => batch.claimrecord_set))
        );
    }
    function confirmCancel(e) {
        const data = {
            claim_pks: JSON.stringify(claimsToCancel.map(cr => cr.pk)),
        };
        $.post('/api/v1/mass_remove_claim/', data, function(response) {
            if (response.success) {
                fetch();
                props.refreshTable();
            }
        });
    }
    if (batches.length === 0) {
        return null;
    }
    return <div className="row">
      <div className="col-md-8">
        <h3 style={{marginTop: "10px"}}>
          Shopper {StrUserInfo.words.bag.toLowerCase()}s
          {' '}
          <i className="fa fa-shopping-bag" aria-hidden="true"></i>
        </h3>
        <div className="table-responsive">
          <table className="table table-bordered table-condensed" style={{fontSize: "smaller"}}>
            <thead>
              <tr>
                <th></th>
                <th>Info</th>
                <th style={{width: "450px"}}>Items (click an item to expand it)</th>
              </tr>
            </thead>
            <tbody>
              {batches.map(batch => {
                  const [expandedClaimsArray, nonExpandedClaimsArray] = partition(
                      batch.claimrecord_set,
                      claim => expandedClaims.indexOf(claim.pk) !== -1
                  );
                  return <tr key={batch.idx}>
                    <td>
                      <button name="mass-done-shopping-crs"
                              className="btn btn-default btn-xs"
                              disabled={batch.idx === loadingBatchIdx}
                              style={{marginRight: "5px"}}
                              onClick={(e) => markDone(e, batch)}>
                        Mark done shopping
                      </button>
                      <button className="btn btn-default btn-xs"
                              disabled={batch.idx === loadingBatchIdx}
                              onClick={(e) => clickCancel(e, [batch])}>
                        Cancel
                      </button>
                    </td>
                    <td>
                      {batch.customer_name}
                      {batch.customer_username &&
                       <span className="text-small">
                         <SendDirectMessage username={batch.customer_username} />
                       </span>
                      }
                      <div>
                        <a href={`mailto:${batch.email}`}
                           target="_top">
                          {batch.email}
                        </a>
                        {' '}
                        <button type="button"
                                className="btn btn-xs glyphicon glyphicon-copy clippy"
                                data-clipboard-text={batch.email}>
                        </button>
                      </div>
                      <div>Last claim: <strong>{batch.formatted_last_claim_date}</strong></div>
                    </td>
                    <td>
                      <div className="row">
                        {nonExpandedClaimsArray.map(claim =>
                            <img src={claim.item.image_thumbnail}
                                 key={claim.pk}
                                 onClick={() => setExpandedClaims(
                                     expandedClaims.concat([claim.pk])
                                 )}
                                 style={{
                                     display: "inline-block",
                                     marginLeft: "5px",
                                     width: "40px",
                                     maxHeight: "40px",
                                     cursor: "pointer",
                                 }} />
                        )}
                      </div>
                      {expandedClaimsArray.length > 0 && <hr />}
                      <div className="row">
                        {expandedClaimsArray.map(claim =>
                            <Claim key={claim.pk}
                                   claim={claim}
                                   thumbnailWrapperClass="col-md-12" />
                        )}
                      </div>
                    </td>
                  </tr>;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {claimsToCancel.length !== 0 &&
       <ModalContainer onClose={() => setClaimsToCancel([])}
                       isOpen={true}
                       style={{
                           width: '50%',
                           maxWidth: '600px',
                           minWidth: '300px',
                       }}>
         <div>
           <h3 style={{marginBottom: "20px"}}>
             Really cancel the following item sale{claimsToCancel.length === 1 ? '' : 's'}?
           </h3>
           {claimsToCancel.map(claim => {
               return <p key={claim.pk}>
                 <img src={claim.item.image_thumbnail}
                      style={{marginLeft: "15px", marginRight: "10px"}}
                      width={40} />
                 {claim.item.short_name}
               </p>;
           })}
           <button className="btn btn-primary"
                   style={{marginTop: '15px'}}
                   onClick={confirmCancel}>
             Confirm
           </button>
         </div>
       </ModalContainer>
      }
    </div>;
}

PendingSalesTable.propTypes = {
    refreshTable: PropTypes.func.isRequired,
};

export default function PendingSalesTableApp(el, refreshTable) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<PendingSalesTable refreshTable={refreshTable} />);
}
