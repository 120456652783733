import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import update from 'immutability-helper';
import { groupBy, debounce } from 'lodash-es';
import DefaultCard from './feedcards/DefaultCard.jsx';
import SocialLikeItemCard from './feedcards/SocialLikeItemCard.jsx';
import SocialClaimItemCard from './feedcards/SocialClaimItemCard.jsx';
import SocialFeaturedItemCard from './feedcards/SocialFeaturedItemCard.jsx';
import SocialFeaturedPartyCard from './feedcards/SocialFeaturedPartyCard.jsx';
import SocialPostCard from './feedcards/SocialPostCard.jsx';
import SocialShippingUpdateCard from './feedcards/SocialShippingUpdateCard.jsx';
import { getUser, getFollowers } from '../UserCache.jsx';
import SocialFeedCardDropdown from './SocialFeedCardDropdown.jsx';
import { foreignIdParse, enrichActivity } from './social_util.jsx';
import { socialStylify } from '../util.jsx';
import ProfilePopover from './ProfilePopover.jsx';
import ClaimButton from '../ClaimButton.jsx';
import SocialReport from './SocialReport.jsx';
import { FollowButton } from './FollowButton.jsx';
import { MentionsInput, Mention } from 'react-mentions';
import {strRequest, successOrRedirect} from "../util.jsx";
import RelatedItems from '../RelatedItems.jsx';
import { ModalContainer } from '../StrModal.jsx';
import { fetchJSON } from '../util.jsx';


/**
 * Returns the appropriate feed card for the given feed item, based on the
 * activity verb.
 */
function activityCardMux(activity) {
    /* maps activity names to cards */
    const mux = {
        'sociallikeitem': SocialLikeItemCard,
        'socialclaimitem': SocialClaimItemCard,
        'socialfeatureditem': SocialFeaturedItemCard,
        'socialfeaturedparty': SocialFeaturedPartyCard,
        'socialpost': SocialPostCard,
        'socialshippingupdate': SocialShippingUpdateCard,
    };
    return mux[activity.verb] || DefaultCard;
}

const FEATURED_ITEM = 1, FEATURED_PARTY = 2;

function isEditable(activity) {
    const editable = ['socialpost'];
    return editable.includes(activity.verb);
}

function featuredThingMenu(what, activity, doRefresh) {
    if (str_user_id !== activity.userData.pk)
        return null;
    const whatNoun = what === FEATURED_PARTY ? 'party' : 'item';
    return [{
        title: `Unfeature ${whatNoun}`,
        onClick: () => {
            const where = what === FEATURED_PARTY
                        ? 'social_featured_party'
                        : 'social_featured_item';
            const url = `/api/v2/${where}/`;
            const { objId } = foreignIdParse(activity.foreign_id);
            $.ajax({
                url: url,
                method: "PATCH",
                data: {
                    pk: objId,
                    active: 0,
                },
                success: doRefresh,
            });
        },
    }];
}

function activityMenuMux(activity, doRefresh) {
    const mux = {
        'socialfeatureditem': featuredThingMenu.bind(null, FEATURED_ITEM),
        'socialfeaturedparty': featuredThingMenu.bind(null, FEATURED_PARTY),
    };
    const menu = mux[activity.verb];
    if (menu)
        return menu(activity, doRefresh);
}

const AlsoPostedToTray = ({externalPosts, channels, isPreview}) => {
    const groupedByNetwork = groupBy(externalPosts || channels, 'network');
    const networkNames = Object.keys(groupedByNetwork);
    const alsoPostedDropdowns = networkNames.map((networkName) => {
        const dropdownListItems = groupedByNetwork[networkName].map(post => {
            const url = post.link_on_network;
            let linkText = post.channel_name || post.name;
            let aAttrs = { href: url || "#" };
            if (url) {
                aAttrs.target = "_blank";
            } else {
                aAttrs.onClick = (e) => e.preventDefault();
                linkText += " (pending)";
            }
            return (
                <li key={post.pk}>
                  <a {...aAttrs}>{linkText}</a>
                </li>
            );
        });
        return (
            <div key={networkName} className="dropdown">
              <button className="dropdown-toggle" type="button" id="dropdownMenu2"
                      data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true"
                      style={{backgroundColor: 'inherit',
                          border: 'none'}}>
                <i className="fa fa-facebook"
                   style={{padding: '10px', fontSize: '22px'}}></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {dropdownListItems}
              </ul>
            </div>
        );
    });

    const postVerb = isPreview ? 'posting' : 'posted';

    return (
        <div className="col-xs-12 ffAlsoPosted"
             style={{
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: 'space-evenly',
                 backgroundColor: '#ccc',
             }}>
          <p className="ffArticleViews">Also {postVerb} to</p>
            {alsoPostedDropdowns}
        </div>
    );
};

AlsoPostedToTray.propTypes = {
    channels: PropTypes.array,
    externalPosts: PropTypes.array,
    isPreview: PropTypes.bool,
};


class SocialFeedCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: this.props.activity,
            comments: [],
            comment: '',
            commentPlainText: '',
            likeCount: null,
            ilike: null,
            likers: [],
            showLikers: false,
            following: true,
            editingCommentPk: null,
            editingCommentText: '',
            editingCommentPlainText: '',
            inView: false,
            showReportModal: false,
            editing: false,
            mentionUsers: [],
            showDeleteConfirm: false,
            error: null,
            claimButtonHeight: null,
        };

        this.fetch = this.fetch.bind(this);
        this.fetchComments = this.fetchComments.bind(this);
        this.fetchLikes = this.fetchLikes.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onCommentEnter = this.onCommentEnter.bind(this);
        this.onLikeClick = this.onLikeClick.bind(this);
        this.onCommentBubbleClick = this.onCommentBubbleClick.bind(this);
        this.onEditCommentClick = this.onEditCommentClick.bind(this);
        this.onEditCommentEnter = this.onEditCommentEnter.bind(this);
        this.onReplyCommentClick = this.onReplyCommentClick.bind(this);
        this.onScrollIntoView = debounce(this.onScrollIntoView.bind(this), 500);
        this.onScrollOutOfView = debounce(this.onScrollOutOfView.bind(this), 500);
        this.onLikersClick = this.onLikersClick.bind(this);
        this.onLikersClose = this.onLikersClose.bind(this);
        this.onEditFinish = this.onEditFinish.bind(this);
        this.gatherMentionUsers = this.gatherMentionUsers.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.delete = this.delete.bind(this);
        this.confirmDeleteModal = this.confirmDeleteModal.bind(this);
        this.closeConfirmDelete = this.closeConfirmDelete.bind(this);
        this.handleClaimButtonLoaded = this.handleClaimButtonLoaded.bind(this);

        this.item = null;
        this.channel = null;
        this.commentInput = React.createRef();
        this.claimButtonRef = React.createRef();

        if (this.props.activity.item)
            this.item = JSON.parse(this.props.activity.item);
    }

    renderLikesComments() {
        const isLoggedIn = !(window.str_user_id === -1);
        const the_login_url = `${str_login_url}?next=${window.location.pathname}`;
        const comments = this.state.comments.map(comment => {
            const isOwner = comment.user_id === str_user_id;
            return (
                <li key={comment.pk} className="ffComment">
                  <ProfilePopover userPk={comment.user_id}>
                    <span className="ffArticleHeaderUser"
                          style={{marginRight: "3px"}}>{comment.name}</span>
                  </ProfilePopover>
                  {this.state.editingCommentPk !== comment.pk &&
                   <span dangerouslySetInnerHTML={{
                       __html: socialStylify(comment.text),
                   }}></span>
                  }
                  {isOwner && this.state.editingCommentPk != comment.pk &&
                   <small>
                     {' '}
                     <a href=""
                        onClick={(e) => this.onEditCommentClick(e, comment)}>
                       edit
                     </a>
                   </small>
                  }
                  {!isOwner &&
                   <small>
                     {' '}
                     <a href=""
                        onClick={(e) => this.onReplyCommentClick(e, comment)}>
                       reply
                     </a>
                   </small>
                  }
                  {isOwner && this.state.editingCommentPk === comment.pk &&
                   <MentionsInput value={this.state.editingCommentText}
                                  autoFocus
                                  className="ffCommentInput"
                                  inputRef={this.commentInput}
                                  onBlur={() => {this.setState({editingCommentPk: null});}}
                                  onChange={(e, nv, nptv) => this.setState({
                                      editingCommentText: nv,
                                      editingCommentPlainText: nptv,
                                  })}
                                  onKeyPress={this.onEditCommentEnter}
                                  enterKeyHint="send"
                                  placeholder={comment.text}>
                     <Mention
                         trigger="@"
                         data={this.state.mentionUsers}
                     />
                   </MentionsInput>
                  }
                </li>
            );
        });

        let likeCls = "fa ffArticleActionIcon";
        likeCls += this.state.ilike ? " fa-heart ffLiked"  : " fa-heart-o";
        const likeVerb = this.state.ilike ? "Unlike" : "Like";
        const likeNoun = this.state.likeCount === 1 ? "like" : "likes";

        const likersModal = this.state.showLikers &&
            this.likersModal(this.state.likers);

        const onlyILike = this.state.ilike && this.state.likeCount === 1;

        const staticLikes = onlyILike &&
            (<span className="col-xs-12">
              <strong>You like this</strong>
            </span>
            );

        const clickableLikes = this.state.likeCount > 0 &&
            (<span>
              <a href="" onClick={this.onLikersClick}>
                <div className="col-xs-12">
                  <strong>{this.state.likeCount} {likeNoun}</strong>
                </div>
              </a>
              {likersModal}
             </span>
            );

        return (
          <div>
            <div className="col-xs-12 ffArticleActions">
              {isLoggedIn &&
               <div className="ffArticleActionBar">
                 <span className="ffArticleActionIconAnchor"
                       onClick={this.onLikeClick}>
                   <i className={likeCls}></i>
                 </span>
                 <span className="ffArticleActionIconAnchor"
                       onClick={this.onCommentBubbleClick}>
                   <i className="fa fa-comment-o ffArticleActionIcon"></i>
                 </span>
               </div>
              }
            </div>
            {staticLikes || clickableLikes}
            <div className="col-xs-12 ffArticleComments">
              <ul className="ffNoPadding">
                {comments}
              </ul>
              {!this.state.editingCommentPk && isLoggedIn &&
               <MentionsInput value={this.state.comment}
                              className="ffCommentInput"
                              inputRef={this.commentInput}
                              onKeyPress={this.onCommentEnter}
                              enterKeyHint="send"
                              placeholder="Write a comment..."
                              onChange={this.onCommentChange}>
                 <Mention
                     trigger="@"
                     data={this.state.mentionUsers}
                 />
               </MentionsInput>
              }
              {!isLoggedIn &&
               <p className="text-muted text-small">
                 Please <a href={the_login_url}>log in</a> to like and comment.
               </p>
              }
            </div>
          </div>
        );
    }

    render() {
        const isLoggedIn = !(window.str_user_id === -1);
        const { activity, claimButtonHeight } = this.state;
        const { channels, isPreview } = this.props;
        const externalPosts = this.props.activity.external_posts;
        const hasExternalChannels = !!((externalPosts && externalPosts.length) || (channels && channels.length));

        if (!activity.userData)
            return null;
        const isActivityOwner = str_user_id === activity.userData.pk;
        let time;
        if (activity.post_later && !activity.posted_date)
            time = moment.utc(activity.post_later).local().fromNow();
        else {
            time = moment.utc(activity.time).local().fromNow();
        }
        const TheCard = activityCardMux(activity);
        let dropdownItems = [];

        if (isPreview) {
            dropdownItems.push({
                title: 'Delete',
                onClick: this.onDeleteClick,
            });
        } else {
            dropdownItems.push({
                title: "Permalink",
                onClick: () => { window.location = `/social/${activity.uuid}/`; },
            });
            if (str_user_id !== activity.userData.pk) {
                const url = `/api/v2/social_follow/${activity.userData.pk}/`;
                if (this.state.following) {
                    dropdownItems.push({
                        title: `Unfollow ${activity.userData.displayName}`,
                        onClick: () => {
                            $.ajax({
                                url: url,
                                method: "DELETE",
                                complete: () => {
                                    this.setState({following: false});
                                },
                            });
                        },
                    });
                } else {
                    dropdownItems.push({
                        title: `Follow ${activity.userData.displayName}`,
                        onClick: () => {
                            $.post(url).done(() => {
                                this.setState({following: true});
                            });
                        },
                    });
                }
            }
            if (isActivityOwner) {
                if (isEditable(activity)) {
                    dropdownItems.push({
                        title: 'Edit',
                        onClick: () => {this.setState({editing: true});},
                    });
                }
                dropdownItems.push({
                    title: 'Delete',
                    onClick: this.onDeleteClick,
                });
            }

            dropdownItems = dropdownItems.concat(this.props.menuItems);
            dropdownItems.push({
                title: 'Report this post',
                onClick: () => {
                    this.setState({showReportModal: true});
                },
            });
            const activityMenuItems = activityMenuMux(activity, this.props.doRefresh);
            if (activityMenuItems)
                dropdownItems = dropdownItems.concat(activityMenuItems);
        }

        return (
            <div className="SocialFeedCard">
              <Waypoint onEnter={this.onScrollIntoView}
                        onLeave={this.onScrollOutOfView}
                        topOffset="250%"
                        bottomOffset="250%">
                <div className="row">
                  <div className="col-xs-12 ffArticleContainer ffNoPadding">
                    <div className="row ffArticleRow">
                      <header className="col-xs-12 ffArticleHeader">
                        <ProfilePopover userPk={activity.userData.pk}>
                          <span>
                            <img className="SocialProfileImageSmall"
                                 src={activity.userData.profile_photo_thumbnail} />
                            <span className="ffArticleHeaderUser ffAnchorColor">
                              {activity.userData.displayName}
                            </span>
                          </span>
                        </ProfilePopover>
                        <div className="pull-right">
                          <SocialFeedCardDropdown id={`id_feedDropdown_${activity.id}`}
                                                  menuItems={dropdownItems} />
                        </div>
                      </header>

                      <div className="col-xs-12 CardBody">
                        <TheCard activity={activity}
                                 editing={this.state.editing}
                                 onEditFinish={this.onEditFinish} />
                      </div>

                      {this.state.inView && this.item && isLoggedIn ?
                       <div className="col-xs-12" ref={this.claimButtonRef}>
                         <ClaimButton itemPk={this.item.pk}
                                      minHeight={claimButtonHeight}
                                      onLoaded={this.handleClaimButtonLoaded} />
                       </div>
                      :
                       <div className="col-xs-12" style={{height: `${claimButtonHeight}px`}}></div>
                      }
                      {isActivityOwner && hasExternalChannels &&
                       <AlsoPostedToTray externalPosts={externalPosts} channels={channels} isPreview={isPreview} />
                      }
                      {!isPreview && this.renderLikesComments()}
                      <div className="col-xs-12 ffArticleDate">
                        {time}
                      </div>
                      {isActivityOwner && this.props.activity.is_private &&
                       <div className="label label-danger">
                         This is a private post. It won't be visible in your followers' Sonlet Social feeds.
                       </div>
                      }
                    </div>
                  </div>
                </div>
              </Waypoint>
              <SocialReport show={this.state.showReportModal}
                            onClose={() => {this.setState({showReportModal: false});}}
                            uuid={this.props.activity.uuid} />
              {this.state.showDeleteConfirm &&
                this.confirmDeleteModal()
              }
            </div>
        );
    }

    async componentDidMount() {
        this.fetchController = new AbortController();
        const { actor } = this.state.activity;

        if (actor.startsWith('auth.User:')) {
            const userId = actor.split('auth.User:')[1];
            const userData = await getUser(userId);
            this.setState(prevState => ({
                activity: {
                    ...prevState.activity,
                    userData: userData,
                },
            }));
        }
    }

    componentWillUnmount() {
        this.fetchController.abort();
    }

    async onScrollIntoView() {
        this.setState({ inView: true }, this.fetch);
        this.channel = await StrApp.strWebsocket(
            `sa-${this.props.activity.uuid}`,
            this.fetchComments,
            () => {},
        );
    }

    onScrollOutOfView() {
        this.setState({ inView: false });
        if (this.channel)
            this.channel.closeNoRetry();
    }

    fetch() {
        this.fetchComments();
        this.fetchLikes();
    }

    async fetchComments() {
        const data = await fetchJSON(this.state.activity.commentUrl,
                                     this.fetchController.signal);
        if (data === null)
            return;
        this.setState({comments: data}, this.gatherMentionUsers);
    }

    async fetchLikes() {
        const data = await fetchJSON(this.state.activity.likeUrl,
                                     this.fetchController.signal);
        if (data === null)
            return;
        this.setState({
            likeCount: data.count,
            ilike: data.ilike,
            likers: data.users,
        });
    }

    async gatherMentionUsers() {
        const actor = this.state.activity.userData;
        const commentUsers = this.state.comments.map(c => ({
            id: `@${c.username}`,
            display: `@${c.username} ${c.name}`,
        }));
        const followUserData = await getFollowers(str_user_id);
        const followUsers = followUserData.map(f => {
            return {
                id: `@${f.username}`,
                display: `@${f.username} ${f.name}`,
            };
        });
        const users = [{
            id: `@${actor.username}`,
            display: `@${actor.username} ${actor.displayName}`,
        }].concat(commentUsers, followUsers)
        // filter out duplicates by only keeping the first occurrence
          .filter((elem, pos, arr) =>
              arr.findIndex(el => el.id === elem.id) === pos);
        this.setState({mentionUsers: users});
    }

    onCommentChange(e, newValue, newPlainTextValue, mentions) {
        this.setState({
            comment: newValue,
            commentPlainText: newPlainTextValue,
        });
    }

    onCommentEnter(e) {
        if (e.nativeEvent.keyCode != 13
            || e.target.value === '')
            return;
        e.preventDefault();

        const data = {text: this.state.commentPlainText};
        $.post(this.state.activity.commentUrl, data);
        const displayName = (StrUserInfo.first_name || StrUserInfo.last_name)
                          ? `${StrUserInfo.first_name} ${StrUserInfo.last_name}`
                          : StrUserInfo.username;
        /* the temp_comment is just for a nice real time effect, the
         * pusher update will overwrite it with real pk*/
        const temp_comment = {
            user_id: str_user_id,
            name: displayName,
            pk: Date.now(),
            text: this.state.commentPlainText,
        };
        this.setState(prevState => ({
            comment: '',
            commentPlainText: '',
            comments: update(prevState.comments, {
                $push: [temp_comment],
            }),
        }));
    }

    onLikeClick(e) {
        e.preventDefault();

        const data = {
            action: this.state.ilike ? "unlike" : "like",
        };
        $.post(this.state.activity.likeUrl, data, () => {
            this.fetchLikes();
        });
    }

    onCommentBubbleClick(e) {
        e.preventDefault();

        if (this.commentInput
            && this.commentInput.current
            && this.commentInput.current.focus)
            this.commentInput.current.focus();
    }

    onEditCommentClick(e, comment) {
        e.preventDefault();
        this.setState({
            editingCommentPk: comment.pk,
            editingCommentText: comment.text,
        });
    }

    onEditCommentEnter(e) {
        const self = this;
        if (e.nativeEvent.keyCode != 13
            || e.target.value === '')
            return;
        e.preventDefault();
        const data = {
            text: this.state.editingCommentPlainText,
            pk: this.state.editingCommentPk,
        };
        const commentIdx = this.state.comments.findIndex((el) => {
            return el.pk === this.state.editingCommentPk;
        });
        $.ajax({
            type: "PATCH",
            url: this.state.activity.commentUrl,
            data: data,
            success: (data, success) => {
                if (success) {
                    self.setState(prevState => ({
                        editingCommentPk: null,
                        editingCommentText: '',
                        editingCommentPlainText: '',
                        comments: update(prevState.comments, {
                            [commentIdx]: {
                                text: {
                                    $set: this.state.editingCommentPlainText,
                                },
                            },
                        }),
                    }));
                }
            },
        });
    }

    onReplyCommentClick(e, comment) {
        e.preventDefault();
        if(this.commentInput
           && this.commentInput.current
           && this.commentInput.current.focus)
            this.commentInput.current.focus();
        this.setState({comment: `@${comment.username} `});
    }

    onLikersClick(e) {
        e.preventDefault();
        this.setState({showLikers: true});
    }

    onLikersClose() {
        this.setState({showLikers: false});
    }

    likers(users) {
        return users.map(user => {
            const profilePhoto = user.profile_photo_thumbnail;
            if (user.pk === window.str_user_id)
                return null;
            return (
                <div key={user.pk} className="row">
                  <div className="col-md-9">
                    <img className="SocialProfileImage"
                         style={{maxWidth: '50px', maxHeight: '50px'}}
                         src={profilePhoto} />
                    <a href={`/u/${user.username}/`}>{user.name}</a>
                    <small className="text-muted"> {user.username}</small>
                  </div>
                  <div className="col-md-3 pull-right" style={{marginBottom: "10px"}}>
                    <FollowButton user={user}
                                  onFollow={this.fetchLikes}
                                  onUnfollow={this.fetchLikes}
                                  style={{display: 'inline'}}
                                  btnSize="btn-sm" />
                  </div>
                </div>
            );
        });
    }

    likersModal(users) {
        return (
            <ModalContainer onClose={this.onLikersClose}>
              <div>
                <h3>Liked by</h3>
                {this.likers(users)}
              </div>
            </ModalContainer>
        );
    }

    onEditFinish(revisedActivity=null) {
        this.setState(prevState => {
            if (!revisedActivity)
                return {editing: false};
            if (prevState.activity['userData'])
                revisedActivity['userData'] = prevState.activity['userData'];
            return {
                editing: false,
                activity: enrichActivity(revisedActivity),
            };
        });
    }

    confirmDeleteModal() {
        return (
            <ModalContainer isOpen={true} onClose={this.closeConfirmDelete}>
              <h3>Are you sure you want to delete this?</h3>
              <div className="btn-group text-center" role="group">
                <button type="button"
                        className="btn btn-danger"
                        onClick={this.delete}>Delete</button>
                <button type="button"
                        className="btn btn-info"
                        onClick={this.closeConfirmDelete}>Cancel</button>
              </div>
              <div className="text-danger">{this.state.error} &nbsp;</div>
            </ModalContainer>
        );
    }

    onDeleteClick(e) {
        this.openConfirmDelete();
    }

    openConfirmDelete() {
        this.setState({showDeleteConfirm: true});
    }

    closeConfirmDelete() {
        this.setState({showDeleteConfirm: false});
    }

    delete() {
        const url = this.state.activity.activityUrl;
        return strRequest(url, 'DELETE').then(response => {
            if (response.status < 400) {
                this.closeConfirmDelete();
                return this.props.doRefresh();
            }
            const msg = `Error ${response.status}: ${response.statusText}`;
            return this.setState({error: msg});
        });
    }

    handleClaimButtonLoaded() {
        if (this.claimButtonRef.current)
            this.setState({claimButtonHeight: this.claimButtonRef.current.offsetHeight});
    }
}

SocialFeedCard.propTypes = {
    activity: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    /**
     * We'll call this if we want our parent to refresh us
     */
    doRefresh: PropTypes.func.isRequired,

    /* Party public id when viewed from a party feed */
    partyPublicId: PropTypes.number,
    isPreview: PropTypes.bool,
    channels: PropTypes.array,
};

SocialFeedCard.defaultProps = {
    menuItems: [],
};

export default SocialFeedCard;
