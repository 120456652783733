import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

class BillingHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            history: [],
        };
    }

    render() {
        if (this.state.loading)
            return <div><i className="fa fa-spinner fa-spin"></i> Loading...</div>;

        const history = this.state.history.map((txn, idx) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={idx}>
                  <td>{moment(txn.date).format('YYYY-MM-DD')}</td>
                  <td>
                    ${txn.amount.toFixed(2)}
                    {txn.status === 'settled' || ' (Settling)'}
                  </td>
                  <td>{txn.purpose}</td>
                </tr>
            );
        });
        return (
            <div>
              <table className="table table-bordered table-condensed">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  {history}
                </tbody>
              </table>
            </div>
        );
    }

    componentDidMount() {
        const self = this;
        $.getJSON('/api/v2/billing_history/', function(data) {
            self.setState({
                history: data.sort((a, b) =>
                    moment(b.date).valueOf() - moment(a.date).valueOf()),
                loading: false,
            });
        });
    }
}

function BillingHistoryApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<BillingHistory />);
}

export default BillingHistoryApp;
