import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import update from 'immutability-helper';


class ImageUploadOrSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            rejections: [],
        };
        this.id = 1;
        this.getId = this.getId.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.performUpload = this.performUpload.bind(this);
        this.replaceFile = this.replaceFile.bind(this);
    }

    componentDidMount() {
        document.addEventListener("paste", this.onPaste);
    }

    componentWillUnmount() {
        document.removeEventListener("paste", this.onPaste);
    }

    getId() {
        return this.id++;
    }

    render() {
        const uploads = this.state.files.map((upfile, idx) => {
            const uploading = upfile.uploading
                            ? (
                                <div className="progress">
                                  <div className="progress-bar progress-bar-striped active"
                                       style={{width: "100%"}}>
                                  </div>
                                </div>
                            )
                            : null;
            const error = upfile.error ? <small className="text-danger">Upload error: {upfile.error}</small> : null;
            const r = upfile.result;
            const result = r && this.props.showThumbnails ?
                           <div>
                             <a target="_blank" href={r.url}>
                               <img src={r.thumbnailUrl} />
                             </a>
                             <small className="text-muted">{r.name}</small>
                             <input type="hidden" value={r.id} name={this.props.inputName} />
                           </div>
                         : null;
            const positiveAffirmation = r
                                     && !this.props.showThumbnails
                                     && <i className="fa fa-check-circle text-success" aria-hidden="true"></i>;
            const f = upfile.file;
            return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={idx}>
                  {f.name} {uploading} {error} {result} {positiveAffirmation}
                </li>
            );
        });
        const rejects = this.state.rejections.map((r, idx) => {
            // eslint-disable-next-line react/no-array-index-key
            return <li key={idx}>{r.file.name}: {r.errors.map(e => e.message).join(", ")}</li>;
        });

        const maxSize = 10000000;  // 10 MB max file size
        const filePlural = this.props.multiple ? 's' : '';
        const style = {
            cursor: "pointer",
            height: "auto",
            background: "#f0f0f0",
            padding: "15px 30px",
            borderRadius: "3px",
            border: "solid 1px #e0e0e0",
            display: "inline-block",
            textAlign: "center",
        };
        return (
            <div>
              <Dropzone onDrop={this.onDrop}
                        multiple={this.props.multiple}
                        maxSize={maxSize}
                        accept={{"image/*": [".jpeg", ".png", ".jpg"]}}
              >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({style})}>
                      <input {...getInputProps()} />
                      <div style={{color: "#737373"}}>
                        <i className="fa fa-cloud-upload"
                           style={{fontSize: "40px"}}
                           aria-hidden="true"></i>
                        <div style={{
                            fontSize: "13px",
                        }}>Drag and Drop or Select File{filePlural}</div>
                      </div>
                    </div>
                )}
              </Dropzone>
              <aside style={{maxHeight: "200px", overflowY: "auto"}}>
                <ul className="text-small text-muted list-unstyled">{uploads}</ul>
                {rejects.length > 0 &&
                 <div>
                   <p>Couldn't upload:</p>
                   <ul>{rejects}</ul>
                   <p>
                     This could be due to an invalid or corrupt file.
                     Please try another image file that is less than
                     10MB in size.
                   </p>
                 </div>
                }
              </aside>
            </div>
        );
    }

    onDrop(acceptedFiles, fileRejections) {
        const newFiles = acceptedFiles.map(f => ({
            file: f,
            id: this.getId(),
            uploading: true,
        }));
        this.setState((prevState, props) => {
            return update(prevState, {
                files: {$push: newFiles},
                rejections: {$push: fileRejections},
            });
        });
        this.performUpload(newFiles);
    }

    onPaste(event) {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                const blob = item.getAsFile();

                // Generate a filename
                const timestamp = new Date().getTime();
                const filename = `pasted-image-${timestamp}.${blob.type.split('/')[1]}`;
                this.performUpload([{
                    file: new File([blob], filename, { type: blob.type }),
                    id: this.getId(),
                    uploading: true,
                }]);
            }
        }
    }

    performUpload(newFiles) {
        const self = this;

        // Note: the resource prop is meant to declare which endpoint to hit.
        // We could use this declarative approach to replace the ternary logic
        // which determines picupload vs picuploadmisc
        if (this.props.resource === 'extraimages') {
            this.uploadInvItemImages(newFiles, true);
            return;
        }

        if (this.props.resource === 'genimage') {
            this.uploadGenImages(newFiles);
            return;
        }

        if (this.props.resource === 'invitemimage') {
            this.uploadInvItemImages(newFiles, false);
            return;
        }

        const url = this.props.invItem ? '/inventory/picupload/' : '/inventory/picuploadmisc/';

        newFiles.forEach(upfile => {
            const file = upfile.file;
            let formData = new FormData();
            formData.append("image_full", file);

            $.ajax({
                url: url,
                method: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                complete: function(xhr, status) {
                    const error = status === "success" ? null : xhr.responseText;
                    const result = error ? null : xhr.responseJSON.files[0];
                    const updatedFile = update(upfile, {
                        uploading: { $set: false },
                        error: { $set: error },
                        result: { $set: result },
                    });
                    self.replaceFile(updatedFile);
                    if (self.props.callback)
                        self.props.callback({'file': result}, file);
                },
            });
        });
    }

    uploadInvItemImages(newFiles, useExtraImages=true) {
        const {extraImagesLength, invItemPk} = this.props;

        newFiles.forEach((upfile, idx) => {
            const sortOrder = extraImagesLength + idx + 1;
            const file = upfile.file;
            const id = upfile.id;
            const formData = new FormData();

            formData.append('image_full', file);
            formData.append('sort_order', sortOrder);

            if (invItemPk) {
                formData.append('item', invItemPk);
            }

            const endpoint = useExtraImages ? 'extraimages' : 'invitemimage';
            $.ajax({
                url: `/api/v2/${endpoint}/`,
                method: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: result => {
                    const {files} = this.state;
                    this.props.callback({file: result});

                    this.setState({
                        files: files.map(x => {
                            if (x.id === id) {
                                x.uploading = false;
                            }

                            return x;
                        }),
                    });
                },
                error: err => {
                    const {files} = this.state;

                    this.setState({
                        files: files.map(x => {
                            if (x.id === id) {
                                x.uploading = false;
                                x.error = 'This image could not be uploaded';
                            }

                            return x;
                        }),
                    });
                },
            });
        });

    }

    uploadGenImages(newFiles) {
        const { genImageType } = this.props;

        newFiles.forEach((upfile, idx) => {
            const file = upfile.file;
            const id = upfile.id;
            const formData = new FormData();

            formData.append('image_full', file);
            formData.append('image_type', genImageType);

            $.ajax({
                url: '/api/v2/genimage/',
                method: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: result => {
                    const {files} = this.state;
                    this.props.callback({file: result});

                    this.setState({
                        files: files.map(x => {
                            if (x.id === id) {
                                x.uploading = false;
                            }

                            return x;
                        }),
                    });
                },
                error: err => {
                    const {files} = this.state;

                    this.setState({
                        files: files.map(x => {
                            if (x.id === id) {
                                x.uploading = false;
                                x.error = 'This image could not be uploaded';
                            }

                            return x;
                        }),
                    });
                },
            });
        });
    }

    replaceFile(updatedFile) {
        /* We have to search by id through prevState to find the current
           index of the file being replaced */
        this.setState((prevState, props) => {
            let idx = -1, i = 0;
            for (const upfile of prevState.files) {
                if (upfile.id === updatedFile.id) {
                    idx = i;
                    break;
                }
                i++;
            }
            if (idx === -1)
                throw `Couldn't find updated file ${updatedFile.id}`;
            return update(prevState, {
                files: {$splice: [[idx, 1, updatedFile]]},
            });
        });
    }
}

ImageUploadOrSelect.propTypes = {
    inputName: PropTypes.string.isRequired,
    callback: PropTypes.func,
    multiple: PropTypes.bool,
    showThumbnails: PropTypes.bool,
    invItem: PropTypes.bool,
    invItemPk: PropTypes.number,
    extraImagesLength: PropTypes.number,
    resource: PropTypes.string,
    genImageType: PropTypes.string,
};

export default ImageUploadOrSelect;
