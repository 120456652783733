import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatCurrency } from '../util.jsx';
import LoadingSpinner from '../LoadingSpinner.jsx';

export default class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
    }

    chooseInvoice(e, idx) {
        e.preventDefault();
        this.props.setInvoiceIdx(idx);
    }

    render() {
        const { invoices, claimbatch, addNewInvoice, loading } = this.props;
        const invoiceRows = invoices.map((invoice, idx) => {
            const newOrPk = invoice.pk ? invoice.number : 'New';
            return (
                <tr key={newOrPk}
                    style={{cursor: 'pointer'}}
                    onClick={(e) => this.chooseInvoice(e, idx)}>
                  <td>{newOrPk}</td>
                  <td>{moment(invoice.date).format('MMM DD, YYYY')}</td>
                  <td>
                    {formatCurrency(invoice.total)}
                    {invoice.is_paid &&
                     <span>
                       {' '}
                       <span className="label label-success">PAID</span>
                     </span>
                    }
                    {invoice.deactivated &&
                     <span>
                       {' '}
                       <span className="label label-danger">CANCELED</span>
                     </span>
                    }
                    {invoice.amount_refunded &&
                     <span>
                       {' '}
                       ({formatCurrency(Number(invoice.amount_refunded))}
                       {' '}
                       refunded)
                     </span>
                    }
                    {!invoice.pk &&
                     <span>
                       {' '}
                       <span className="label label-warning">UNSAVED</span>
                     </span>
                    }
                  </td>
                </tr>
            );
        });
        return (
            <div>
              <h1>Invoices for Order #{claimbatch.pk}</h1>
              {addNewInvoice}
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Invoice #</th>
                    <th>Date</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceRows}
                </tbody>
              </table>
              {loading &&
               <p><LoadingSpinner /> Loading invoice....</p>
              }
            </div>
        );
    }
}

InvoiceList.propTypes = {
    claimbatch: PropTypes.object.isRequired,
    consultant: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    setInvoiceIdx: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    markInvoiced: PropTypes.func,
    addNewInvoice: PropTypes.node,
};
