import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner.jsx';
import { MiniProfile } from './MiniProfile.jsx';

const ProfilePopover = ({ children, userPk }) => {
    const [user, setUser] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const target = useRef(null);
    const childrenMeasurementRef = useRef(null);
    const [childrenHeight, setChildrenHeight] = useState(0);

    const fetchUser = () => {
        const url = `/api/v2/public_users/${userPk}/`;
        $.getJSON(url, setUser);
    };

    useEffect(() => {
        // Logic to handle click outside to close the popover
        function handleClickOutside(event) {
            if (target.current && !target.current.contains(event.target)) {
                setShowPopover(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (childrenMeasurementRef.current) {
            setChildrenHeight(childrenMeasurementRef.current.offsetHeight);
        }
    }, []);

    if (userPk === anonymous_user_pk) return children;

    const handleOnClick = () => {
        if (!user) fetchUser();
        setShowPopover(!showPopover);
    };

    return (
        <div style={{ display: 'inline', cursor: 'pointer'}}
             ref={target}
             onClick={handleOnClick}>
          <div style={{display: 'inline-block'}}
               ref={childrenMeasurementRef}>
            {typeof children === 'string' ? (
                <a onClick={(e) => e.preventDefault()} href="#">
                  {children}
                </a>
            ) : (
                children
            )}
          </div>
          {showPopover && (
              <div className="popover bottom fade in"
                   style={{display: "block", top: `${childrenHeight}px`}}
                   role="tooltip">
                <div className="arrow"></div>
                <div className="popover-content">
                  {user ? (
                      <MiniProfile user={user} onFollow={fetchUser} onUnfollow={fetchUser} />
                  ) : (
                      <LoadingSpinner />
                  )}
                </div>
              </div>
          )}
        </div>
    );
};

ProfilePopover.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    userPk: PropTypes.number.isRequired,
};

export default ProfilePopover;

export function ProfilePopoverApp(el, props) {
    if (el === null) return;
    const root = createRoot(el);
    root.render(<ProfilePopover {...props} />);
}
