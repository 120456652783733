import React from 'react';
import PropTypes from 'prop-types';
import ProfilePopover from '../ProfilePopover.jsx';


function getTrackerLink(activity) {
    if (activity.tracker_url)
        return (
            <a className="btn btn-primary"
               target="_blank"
               style={{'marginTop': '15px'}}
               href={activity.tracker_url}>
              Track Shipment
            </a>
        );
    return <span>Tracking pending</span>;
}

class SocialShippingUpdateCard extends React.Component {
    render() {
        const { activity } = this.props;
        const items = JSON.parse(activity.items).map(item => {
            return (
                <div key={item.pk}>
                  <img style={{'display': 'inline'}}
                       src={item.image.image_thumbnail} />
                  <span style={{'marginLeft': '15px'}}>
                    {item.shortName}
                  </span>
                </div>
            );
        });
        const statuses = {
            in_transit: "is now shipped",
            delivered: "has been delivered",
        };
        return (
            <div style={{'textAlign': 'center'}}>
              <div style={{fontSize: '18px', wordWrap: "break-word"}}>
                Your package from
                {' '}
                <ProfilePopover
                    userPk={activity.consultant_userpk}>
                  <b>{activity.consultant_name}</b>
                </ProfilePopover>
                {' '}
                {statuses[activity.status]}!
              </div>
              {activity.status !== 'delivered' && getTrackerLink(activity)}
              <div style={{'marginTop': '15px', 'marginBottom': '15px'}}>
                {items}
              </div>
              {activity.status === 'delivered' &&
               <a className="btn btn-primary"
                   style={{'marginBottom': '15px'}}
                   href='/dashboard/shop/'>
                 View Orders
               </a>
              }
            </div>
        );
    }
}

SocialShippingUpdateCard.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default SocialShippingUpdateCard;
