import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { ModalContainer } from './StrModal.jsx';


export function LikeButton(props) {
    const [isLiked, setIsLiked] = useState(props.isLiked);
    const [justDidLike, setJustDidLike] = useState(false);
    const [showAnonModal, setShowAnonModal] = useState(false);
    const likeEvent = props.partyPublicId ? 'party_like' : 'item_like';
    const unLikeEvent = props.partyPublicId ? 'party_unlike' : 'item_unlike';
    const event = isLiked ? unLikeEvent : likeEvent;

    function onClick() {
        if (StrUserInfo.user_type === "anon") {
            setShowAnonModal(true);
            return;
        }
        setIsLiked(!isLiked);
        setJustDidLike(!isLiked);
        let data = {
            event: event,
            item_pk: props.itemPk,
        };
        if (props.partyPublicId) {
            data.party_public_id = props.partyPublicId;
        }
        if (props.isOmniParty) {
            data.is_omni_party = props.isOmniParty;
        }
        if (props.likedFromPk) {
            data.liked_from_pk = props.likedFromPk;
        }
        if (props.likedFromFbid) {
            data.liked_from_fbid = props.likedFromFbid;
        }
        $.post('/realtime/', data, function(rsp) {
            if (!rsp.success) {
                setIsLiked(isLiked);
                toast.error(rsp.message);
            }
        });
    }

    function anonModal() {
        return <ModalContainer onClose={() => setShowAnonModal(false)}
                               isOpen={true}>
          <h3>
            You must be logged in to like items
          </h3>
          <p>
            If you already have an account,
            {' '}
            <a href={`${str_login_url}?next=${location.pathname}`}>
              login
            </a>.
          </p>
          <p>
            <a href={`/accounts/register/?next=${location.pathname}`}>
              Register
            </a> for a new account and enjoy:
          </p>
          <ul>
            <li>Liking items to purchase later</li>
            <li>Tracking your past purchases and receipts</li>
            <li>Communicating with Sellers directly</li>
            <li>Following your favorite sellers latest releases</li>
            <li>Seeing shipping status</li>
          </ul>
        </ModalContainer>;
    }
    const animateCls = justDidLike ? 'click-enlarge-animation' : '';
    return <span>
      <button className="btn btn-link"
              style={{
                  opacity: isLiked ? '1' : '.75',
                  paddingLeft: '0',
                  paddingRight: '0',
              }}
              title={`Click to ${isLiked ? 'unlike' : 'like'} this item`}
              disabled={StrUserInfo.banned}
              onClick={onClick}>
        <i className={`ffArticleActionIcon fa fa-heart ${animateCls}`}
           style={{
               color: isLiked ? '#ed4956' : '#fafafa',
               textShadow: isLiked ? '0 0 2px #ed4956' : '0 0 2px #333',
           }} />
      </button>
      {showAnonModal && anonModal()}
    </span>;
}

LikeButton.propTypes = {
    itemPk: PropTypes.number.isRequired,
    isLiked: PropTypes.bool.isRequired,
    // determines if we are in a party and should use the party_ events
    partyPublicId: PropTypes.number,
    // for item_ events
    isOmniParty: PropTypes.bool,
    likedFromPk: PropTypes.number,
    likedFromFbid: PropTypes.number,
};

export function LikeButtonApp(el, data) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<LikeButton itemPk={data.itemPk}
                            isLiked={data.isLiked}
                            partyPublicId={data.partyPublicId}
                            isOmniParty={data.isOmniParty}
                            likedFromPk={data.likedFromPk}
                            likedFromFbid={data.likedFromFbid} />);
}
