const addFilterParams = row => {
    row.filter_params = row.claimrecord_set.map(cr => {
        return [
            cr.item.shortName,
            cr.claimed_from_fb_group_name,
            cr.claimed_from_party_name,
        ].join(" ");
    });
    return row;
};

const mapOrderParams = o => {
    /* preps a filter_params attribute that we
     * use for filtering later since Datatables
     * has a hard time accessing the object
     * parameters by default*/
    o.DT_RowId = `piu-order-${o.pk}`;
    return addFilterParams(o);
};

const dataToItems = data => {
    let items = [];
    for (const claimBatch of data)
        for (const claimRecord of claimBatch.claimrecord_set)
            items.push(claimRecord.item);
    return items;
};

export {addFilterParams, mapOrderParams, dataToItems};
