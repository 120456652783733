import React from 'react';
import { createRoot } from 'react-dom/client';
import SocialFeed from './SocialFeed.jsx';

function SocialFeedApp(el, tag) {
    if (el === null)
        return;
    const props = {};
    if (tag) {
        props.feedParams = { kind: 'tag', value: tag };
        props.hidePostEntry = true;
    }
    const root = createRoot(el);
    root.render(<SocialFeed {...props} />);
}

export default SocialFeedApp;
