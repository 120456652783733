import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import AttrPicker from './AttrPicker.jsx';

import styles from './css/item_attrs_editor.css';

class ItemAttrsEditor extends React.Component {
    constructor(props) {
        super(props);
        this.getId = this.getId.bind(this);
        this.makeAttrRecord = this.makeAttrRecord.bind(this);
        this.id = 0;
        this.addIsDirty = false;
        this.lastName = null;
        let defaultAttrs;
        if (this.props.defaultAttrs && this.props.defaultAttrs.length > 0) {
            defaultAttrs = this.props.defaultAttrs;
            this.id += this.props.defaultAttrs.length;
        } else {
            defaultAttrs = props.bizParams.suggested_attrs.map(
                name => this.makeAttrRecord(name, null)
            );
        }
        this.state = {
            theAttrs: defaultAttrs,
            quickAdds: this.props.quickAdds && this.props.quickAdds.slice(),
        };
        this.onAddClick = this.onAddClick.bind(this);
        this.getItemRow = this.getItemRow.bind(this);
        this.findIndexById = this.findIndexById.bind(this);
        this.updateAnAttr = this.updateAnAttr.bind(this);
        this.makeQuickAdd = this.makeQuickAdd.bind(this);
        this.quickAddClick = this.quickAddClick.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let defaultAttrs;
        if (nextProps.hasOwnProperty('defaultAttrs') && nextProps.defaultAttrs.length > 0) {
            defaultAttrs = nextProps.defaultAttrs;
            this.id += nextProps.defaultAttrs.length;
        } else {
            defaultAttrs = this.props.bizParams.suggested_attrs.map(
                name => this.makeAttrRecord(name, null)
            );
        }
        this.setState({
            theAttrs: defaultAttrs,
        });
    }

    getId() {
        return this.id++;
    }

    makeAttrRecord(name, value) {
        return {
            name: name,
            value: value,
            id: this.getId(),
        };
    }

    makeQuickAdd(addSpecs) {
        const { name, values } = addSpecs;
        const clickies = values.map(value => {
            return (
                <span className="label label-default"
                      key={this.getId()}
                      onClick={() => this.quickAddClick(name, value) } >
                  {value}
                </span>
            );
        });
        return (
            <div key={this.getId()} className="ClickeyContainer">
              <span className="ClickeyName label label-default"
                    onClick={() => this.quickAddClick(name, null) } >{name}:</span>
              {clickies}
            </div>
        );
    }

    quickAddClick(name, value) {
        this.setState((prevState) => {
            const nameIdx = prevState.quickAdds.findIndex(qa => qa.name === name);
            let newQuickAdds;
            if (value) {
                const valIdx = prevState.quickAdds[nameIdx].values.findIndex(v => v === value);
                newQuickAdds = prevState.quickAdds.slice();
                newQuickAdds[nameIdx].values.splice(valIdx, 1);
            } else {
                newQuickAdds = prevState.quickAdds;
            }
            return {
                theAttrs: prevState.theAttrs.concat([this.makeAttrRecord(name, value)]),
                quickAdds: newQuickAdds,
            };
        }, () => {
            if (this.props.receivedAttrs)
                this.props.receivedAttrs(this.state.theAttrs);
        });
    }

    render() {
        const attrsItems = this.state.theAttrs.map((el, idx) => {
            return this.getItemRow(el, idx);
        });
        const buttonAttrs = this.props.liteStyle ? {
            style: {
                paddingLeft: 0,
            },
            className: "btn btn-link",
        } : {
            className: "btn btn-default btn-xs",
        };

        return (
            <div className="ItemAttrsEditor">
              <div className="form-inline">
                {attrsItems}
              </div>
              <button onClick={this.onAddClick}
                      type="button"
                      {...buttonAttrs}>
                {this.props.liteStyle && "Add +" || "Add attribute"}
              </button>
              {this.state.quickAdds &&
               <div className="QuickAdds" style={{'fontSize': '12px'}}>
                 <span style={{'fontWeight': 'bold'}}>Quick attribute add:</span>
                 {this.state.quickAdds.map(this.makeQuickAdd)}
               </div>
              }
            </div>
        );
    }

    componentDidMount() {
        if (!this.props.itemId || this.props.defaultAttrs)
            return;
        const url = `/api/v2/items/${this.props.itemId}/`;
        const self = this;
        $.getJSON(url, function(data) {
            if (!data.data || !data.data.attributes)
                return;
            let a = data.data.attributes;
            let attrs = [];
            if (a instanceof Array) {
                for (const attr of a) {
                    attrs.push(self.makeAttrRecord(attr['name'], attr['value']));
                }
            } else {
                for (var o in a) {
                    attrs.push(self.makeAttrRecord(o, a[o]));
                }
            }
            self.setState({theAttrs: attrs});
            if (self.props.receivedAttrs)
                self.props.receivedAttrs(attrs);
        });
    }

    componentDidUpdate() {
        if (this.addIsDirty) {
            this.addIsDirty = false;
            /* eslint-disable react/no-find-dom-node */
            if (this.lastName)
                ReactDOM.findDOMNode(this.lastName).focus();
            /* eslint-enable react/no-find-dom-node */
        }
    }

    updateAnAttr(keyName, id, value) {
        var data = this.state.theAttrs;
        const idx = this.findIndexById(id);
        let updatedAttr;

        if (keyName === 'name') {
            updatedAttr = update(data[idx], {
                name: {$set: value},
                value: {$set: null},
            });
        } else {
            updatedAttr = update(data[idx], {[keyName]: {$set: value}});
        }

        var newAttrs = update(data, {
            $splice: [[idx, 1, updatedAttr]],
        });
        this.setState({theAttrs: newAttrs});
        if (this.props.receivedAttrs && keyName !== 'name')
            this.props.receivedAttrs(newAttrs);
    }

    getItemRow(el, idx) {
        const {name, value, id} = el;

        let nameProps = {
            name: `attr_${id}_name`,
            placeholder: 'Attribute',
            defaultValue: name,
        };
        let valueProps = {
            name: `attr_${id}_value`,
            placeholder: 'Value',
            defaultValue: value,
        };

        const nameOptions = (input) => {
            return fetch(`/api/v2/attributestats/name_list/?name=${input}`, {
                credentials: 'same-origin',
            })
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    var options = json.map(el => {
                        return {label: el, value: el};
                    });
                    return {options: options};
                });
        };

        const valueOptions = (input) => {
            const query = encodeURIComponent(input);
            return fetch(`/api/v2/attributestats/value_list/?name_exact=${name}&value=${query}`, {
                credentials: 'same-origin',
            })
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    var options = json.map(el => {
                        return {label: el, value: el};
                    });
                    return {options: options};
                });
        };

        const onChangeName = (data) => {
            this.updateAnAttr('name', id, data.value);
            // TODO instead of using autofocus to select the value
            // dropdown get a refs implementation to work here. there
            // is a built-in .focus() method on react-select instances
            // but it was selecting the incorrect dropdown when I
            // tried to base ref on `id` or `idx`.
        };

        const onChangeValue = (data) => {
            this.updateAnAttr('value', id, data.value);
        };

        const divId = `id_attr_row_${id}`;
        const iptName = `attr_${idx}_idx`;

        // this keeps the value options fresh
        let valueKey = "" + id + name;

        return (
            <div style={{paddingBottom: '5px'}}
                 id={divId}
                 key={id}>
              <input type="hidden" name="attr" value={idx} />
              <input type="hidden" name={iptName} value={id} />
              <div style={{
                  display: 'inline-block',
                  width: 'calc(100% - 25px)',
              }}>
                <AttrPicker creatable={true}
                            onChangeName={onChangeName}
                            onChangeValue={onChangeValue}
                            nameProps={nameProps}
                            valueProps={valueProps}
                            showHiddenToggle={true}
                />
              </div>
              <span onClick={this.onRemoveClick.bind(this, id)}
                    type="button"
                    className="remove-button btn btn-default btn-xs"><span className="glyphicon glyphicon-remove"></span></span>
            </div>
        );
    }

    onAddClick() {
        this.addIsDirty = true;
        this.setState((prevState, props) => ({
            theAttrs: prevState.theAttrs.concat([this.makeAttrRecord(null, null)]),
        }));
    }

    findIndexById(id) {
        return this.state.theAttrs.findIndex((el) => el.id === id);
    }

    onRemoveClick(id) {
        const idx = this.findIndexById(id);
        this.setState((prevState, props) => {
            let theAttrs = prevState.theAttrs.slice();
            theAttrs.splice(idx, 1);
            return { theAttrs: theAttrs };
        }, () => {
            if (this.props.receivedAttrs)
                this.props.receivedAttrs(this.state.theAttrs);
        });
    }

    onTextFieldChange(id, prop, event) {
        const idx = this.findIndexById(id);
        /* hold on to value (since it's freed before the setState callback runs) */
        const val = event.target.value;
        this.setState((prevState, props) => {
            let theAttrs = prevState.theAttrs.slice();
            theAttrs[idx][prop] = val;
            return { theAttrs: theAttrs };
        });
    }
}

ItemAttrsEditor.propTypes = {
    itemId: PropTypes.number,
    item: PropTypes.object,
    bizParams: PropTypes.shape({
        suggested_attrs: PropTypes.array.isRequired,
    }).isRequired,
    receivedAttrs: PropTypes.func,
    defaultAttrs: PropTypes.array,
    quickAdds: PropTypes.array,
    liteStyle: PropTypes.bool,
};

ItemAttrsEditor.defaultProps = {
    liteStyle: true,
};

/**
 * el should have a data-item-id attribute.
 */
function ItemAttrsEditorApp(el, quickAdds) {
    let itemId = el.dataset.itemId - 0;
    if (isNaN(parseFloat(itemId)))
        itemId = null;
    let bizParams = (typeof str_biz_params !== 'undefined'
        && !('hideParams' in el.dataset))
                  ? str_biz_params
                  : {'suggested_attrs': []};
    const root = createRoot(el);
    root.render(<ItemAttrsEditor itemId={itemId}
                                 bizParams={bizParams}
                                 quickAdds={quickAdds} />);
}

export { ItemAttrsEditorApp, ItemAttrsEditor };
