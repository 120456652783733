import React from 'react';
import { createRoot } from 'react-dom/client';
import LoadingSpinner from './LoadingSpinner.jsx';
import { fetchJSON } from './util.jsx';


class BillingInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            paymentInfo: null,
        };
    }

    render() {
        const { loading, paymentInfo } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (!paymentInfo.has_payment_info) {
            return <div className="text-muted text-small">No payment info</div>;
        }

        let discounts = null;
        if (paymentInfo.discounts.length > 0) {
            discounts = (
                <div className="text-success text-small">
                  <strong>Discounts:</strong>
                  {paymentInfo.discounts.map((d, idx) => {
                      // eslint-disable-next-line react/no-array-index-key
                      return <div key={idx}>${d.amount} off ({d.name})</div>;
                  })}
                </div>
            );
        }

        if (paymentInfo.is_paypal) {
            return (
                <div>
                  <img style={{width: "50px"}} src={paymentInfo.image_url} />
                  {' '}
                  <span className="text-muted text-small">{paymentInfo.email}</span>
                  {discounts}
                </div>
            );
        } else {
            return (
                <div>
                  <img style={{width: "30px"}} src={paymentInfo.image_url} />
                  {' '}
                  <span className="text-muted text-small">ending with: {paymentInfo.last_4}</span>
                  {discounts}
                </div>
            );
        }
    }

    async componentDidMount() {
        this.fetchController = new AbortController();
        const paymentInfo = await fetchJSON('/api/v2/billing_info/', this.fetchController.signal);
        if (paymentInfo === null)
            return;
        this.setState({
            loading: false,
            paymentInfo,
        });
    }

    componentWillUnmount() {
        this.fetchController.abort();
    }
}

function BillingInfoApp(el) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<BillingInfo />);
}

export { BillingInfo, BillingInfoApp };
