// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StrTypeAhead--inner {
    background: white;
    padding: 3px;
    border-radius: 3px;
    box-shadow: 0px 0px 7px rgba(158,156,158,0.5);
}
.StrTypeAhead ul {
    list-style: none;
    padding: 0 10px;
}
.StrTypeAhead ul li {
    padding: 3px;
    cursor: pointer;
}
.StrTypeAhead ul li.selected {
    background: #82dacc;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./css/typeahead.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,6CAA6C;AACjD;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".StrTypeAhead--inner {\n    background: white;\n    padding: 3px;\n    border-radius: 3px;\n    box-shadow: 0px 0px 7px rgba(158,156,158,0.5);\n}\n.StrTypeAhead ul {\n    list-style: none;\n    padding: 0 10px;\n}\n.StrTypeAhead ul li {\n    padding: 3px;\n    cursor: pointer;\n}\n.StrTypeAhead ul li.selected {\n    background: #82dacc;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
