import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';

import { fetchJSON } from './util.jsx';


class AttrPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.nameProps && this.props.nameProps.defaultValue,
            value: this.props.valueProps && this.props.valueProps.defaultValue,
            hidden: false,
        };

        this.nameOptions = this.nameOptions.bind(this);
        this.valueOptions = this.valueOptions.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onClickHidden = this.onClickHidden.bind(this);
    }

    render() {
        const { name, value, hidden } = this.state;
        const nameDefault = name ? {label: name, value: name} : null;
        const valueDefault = value ? {label: value, value: value} : null;
        const nameName = this.props.nameProps && this.props.nameProps.name;
        const namePlaceholder = this.props.nameProps && this.props.nameProps.placeholder;
        const valueName = this.props.valueProps && this.props.valueProps.name;
        const valuePlaceholder = this.props.valueProps && this.props.valueProps.placeholder;
        const Thing = this.props.creatable ? AsyncCreatable : Async;
        const showValue = !this.props.hideValue;
        // without === the clearable still shows on pages where it is not defined
        const clearable = (this.props.showClearable === true);
        const nameWidth = this.props.showHiddenToggle ? 'calc(100% - 20px)' : '100%';
        const customStyles = {
            menu: (provided, state) => ({
                ...provided,
                // bump the zIndex out from 1 to 4 to cover datatables
                // paginator's selected value
                zIndex: 4,
            }),
        };
        return (
            <div className="row">
              <div className="col-md-6" style={{paddingLeft: '0'}}>
                <div style={{display: 'inline-block', width: nameWidth}}>
                  <Thing name={nameName}
                         styles={customStyles}
                         defaultOptions
                         value={nameDefault}
                         className="Select-name"
                         classNamePrefix='attrPicker'
                         placeholder={namePlaceholder}
                         loadOptions={this.nameOptions}
                         onChange={this.onChangeName}
                         isClearable={clearable}
                         openMenuOnFocus={true} />
                </div>
                {(name && this.props.showHiddenToggle) &&
                 <span style={{paddingLeft: '3px'}}>
                   <i className={`fa ${hidden ? 'fa-eye-slash' : 'fa-eye'} fa-1x`}
                      style={{cursor: 'pointer'}}
                      onClick={this.onClickHidden}
                      title={`Toggle whether ${name} is visible or hidden from shoppers. Currently ${hidden ? 'hidden' : 'visible'}.`}>
                   </i>
                 </span>
                }
              </div>
              {name && showValue &&
               /* by using key={name} here we force this thing to be thrown
                  away and re-created anytime name changes. */
               <div className="col-md-6" style={{paddingLeft: '0'}}>
                 <Thing key={name}
                        styles={customStyles}
                        defaultOptions
                        name={valueName}
                        value={valueDefault}
                        className="Select-value"
                        classNamePrefix='attrPicker'
                        placeholder={valuePlaceholder}
                        loadOptions={this.valueOptions}
                        onChange={this.onChangeValue}
                        isClearable={clearable}
                        openMenuOnFocus={true} />
               </div>
              }
            </div>
        );
    }

    async checkHidden() {
        const name = encodeURIComponent(this.state.name);
        const hiddenRsp = await fetchJSON(`/api/v2/attribute_hidden/?name=${name}`);
        this.setState({hidden: hiddenRsp.hidden});
    }

    onChangeName(data) {
        if (data) {
            this.setState({name: data.value, value: null});
        } else {
            this.setState({name: null, value: null, hidden: false});
        }
        if (this.props.onChangeName) this.props.onChangeName(data);
    }

    onChangeValue(data) {
        if (data) {
            this.setState({value: data.value});
        } else {
            this.setState({value: null});
        }
        if (this.props.onChangeValue) this.props.onChangeValue(data);
    }

    getOptions(url) {
        return fetch(url, {
            credentials: 'same-origin',
        }).then(response => response.json())
          .then(json => {
              return json.map(el => ({
                  label: el,
                  value: el,
              }));
          });
    }

    nameOptions(input) {
        const name = encodeURIComponent(input);
        const url = `/api/v2/attributestats/name_list/?name=${name}`;
        return this.getOptions(url);
    }

    valueOptions(input) {
        /* doing a checkHidden here since it means we have shifted focus
         * from the name input, this is a nice workaround to checking for
         * hidden on every key press when creating a new attr name */
        this.checkHidden();
        const name_exact = encodeURIComponent(this.state.name);
        const query = encodeURIComponent(input);
        const url = `/api/v2/attributestats/value_list/?name_exact=${name_exact}&value=${query}`;
        return this.getOptions(url);
    }

    onClickHidden() {
        const name = encodeURIComponent(this.state.name);
        $.post(`/api/v2/attribute_hidden/?name=${name}`, (rsp) => {
            this.setState({hidden: rsp.hidden});
            const subMessage = rsp.hidden
                             ? 'hidden from shoppers'
                             : 'visible to shoppers';
            toast.success(`Attribute: ${this.state.name} ${subMessage}`);
        });
    }
}

AttrPicker.propTypes = {
    onChangeName: PropTypes.func,
    onChangeValue: PropTypes.func,
    creatable: PropTypes.bool.isRequired,
    nameProps: PropTypes.shape({
        name: PropTypes.string,
        placeholder: PropTypes.string,
        defaultValue: PropTypes.string,
    }),
    valueProps: PropTypes.shape({
        name: PropTypes.string,
        placeholder: PropTypes.string,
        defaultValue: PropTypes.string,
    }),
    hideValue: PropTypes.bool,
    showClearable: PropTypes.bool,
    showHiddenToggle: PropTypes.bool,
};

export default AttrPicker;
