/**
 * Depends on the following globals:
 *   - str_user_id
 */

import React from 'react';
import PropTypes from 'prop-types';

import styles from './css/UserBio.css';
import './css/ReactDraft.css';
import MarkdownView from '../MarkdownView.jsx';
import LoadingSpinner from '../LoadingSpinner.jsx';
import MarkdownEditor from '../MarkdownEditor.jsx';


class UserBio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            saving: false,
            bioMarkdown: this.props.user.bio_markdown,
        };

        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);

        this.isOwner = str_user_id === this.props.user.pk;
    }

    onSave(markdown) {
        this.setState({saving: true, bioMarkdown: markdown});
        $.ajax({
            url: `/api/v2/user_preferences/`,
            method: "PATCH",
            data: {
                bio_markdown: markdown,
            },
            complete: () => {
                this.setState({saving: false, editing: false});
                if (this.props.onBioChange)
                    this.props.onBioChange();
            },
        });
    }

    onCancel() {
        this.setState({editing: false});
    }

    render() {
        const { editing, saving, bioMarkdown} = this.state;
        const saveIcon = saving
                       ? <LoadingSpinner />
                       : <i className="fa fa-floppy-o"></i>;
        const saveBtnProps = saving
                           ? {disabled: true}
                           : {};
        return (
            <div className="BioContainer">
              {this.state.editing &&
               <div>
                 <MarkdownEditor markdown={bioMarkdown}
                                 onSave={this.onSave}
                                 onCancel={this.onCancel}
                                 source="user-bio"
                 />
               </div>
              }
              {!editing && <MarkdownView markdown={bioMarkdown} />}
              {this.isOwner && !editing &&
               <div>
                 <button className="btn btn-default btn-xs"
                         onClick={() => this.setState({editing: true})}>
                   Edit Bio <i className="fa fa-pencil-square-o"></i>
                 </button>
                 {this.isOwner && !editing && !bioMarkdown &&
                  <p className="text-small text-muted">
                    Your bio goes here.  Click <em>Edit Bio</em> to get started.
                  </p>
                 }
               </div>
              }
            </div>
        );
    }
}

UserBio.propTypes = {
    user: PropTypes.object.isRequired,
    onBioChange: PropTypes.func,
};

export default UserBio;
