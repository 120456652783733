import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScheduledPostContainer from './ScheduledPostContainer.jsx';


class ScheduledPostList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const postText = this.props.scheduledPosts.length ?
            "Your scheduled posts" : "You have no scheduled posts";
        return (
            <div>
              <h3>{postText}</h3>
              {this.props.scheduledPosts.map(post =>
                  <ScheduledPostContainer post={post}
                                          key={post.pk}
                                          doRefresh={this.props.doRefresh} />
              )}
            </div>
        );
    }
}

ScheduledPostList.propTypes = {
    doRefresh: PropTypes.func.isRequired,
    scheduledPosts: PropTypes.array.isRequired,
};

export default ScheduledPostList;
